import { ICollection, IBaseApiClient, IResource } from '../common/index';

export interface IPlatform161TechFee extends IResource {
    value: string;
}

export type IPlatform161TechFeeCollection = ICollection<IPlatform161TechFee>;

export interface IPlatform161TechFeeClient extends IBaseApiClient<IPlatform161TechFee> {
}
