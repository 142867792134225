
import { map } from 'rxjs/operators';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { IdentityStorage } from './modules/common/service/storage/identity-storage';
import { IInstance } from './sdk.index';
import { InstanceDataProviderService } from './shared/instance-list/instance-data-provider.service';

@Component({
    template: ``,
})
export class WildcardRouterDispatcherComponent {
    constructor(
        identityStorage: IdentityStorage,
        private router: Router,
        private instanceCodeProvider: InstanceDataProviderService
    ) {

        const urlSegments = router.url.split('/');

        if (urlSegments.length === 2) {
            if (identityStorage.hasUser()) {
                this.instanceCodeProvider.getByCode(urlSegments[1]).pipe(map((instances: IInstance[]) => {
                    if (instances[0]) {
                        this.router.navigate([`/${urlSegments[1]}/app`]);
                    } else {
                        this.router.navigate([`/app/dashboard`]);
                    }
                })).subscribe();
            } else {
                this.router.navigate([`/${urlSegments[1]}/login`]);
            }
        } else {
            this.router.navigate([`/app/dashboard`]);
        }
    }
}
