
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot, CanActivate, CanActivateChild, Params, Router, RouterStateSnapshot
} from '@angular/router';

import { Observable } from 'rxjs/internal/Observable';
import isEmpty from 'lodash-es/isEmpty';

import { IdentityStorage } from '../../../modules/common/service/storage/identity-storage';
import { InstanceDataProviderService } from '../../instance-list/instance-data-provider.service';
import { IInstance } from '../../../sdk.index';
import { AclRulesProvider } from './acl-rules-provider.service';
import { FeatureAccessResolver } from './access-resolver/feature';

@Injectable()
export class InstanceGuard implements CanActivate, CanActivateChild {
    constructor(
        private router: Router,
        private identityStorage: IdentityStorage,
        private instanceCodeProvider: InstanceDataProviderService,
        private aclRulesProvider: AclRulesProvider
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
        return this.resolveRoute(route, state);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
        return this.canActivate(route, state);
    }

    private resolveRoute(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
        const instanceFromLocalStorage = this.identityStorage.getInstanceCode();
        if (/^\/\w+\/app/.test(state.url)) {
            const instanceFromRoute = state.url.split('/')[1];
            if (instanceFromRoute === instanceFromLocalStorage) {
                return true;
            } else {
                return this.instanceCodeProvider.getByCode(instanceFromRoute).pipe(map((instances: IInstance[]) => {
                    if (instances[0]) {
                        this.identityStorage.setInstance(instances[0], true);
                        this.reloadFeatures();
                        return true;
                    } else {
                        this.router.navigate([`/forbidden`]);
                        return false;
                    }
                }));
            }
        } else if (!state.url.startsWith(`/${instanceFromLocalStorage}`)) {
            let url: string = state.url;
            let queryParams: Params;

            if (!isEmpty(state.root.queryParams)) {
                queryParams = state.root.queryParams;
                url = url.split('?')[0];
            }

            this.router.navigate([`/${instanceFromLocalStorage}${url}`], {queryParams});

            return true;
        }

        return false;
    }

    private reloadFeatures() {
        FeatureAccessResolver.cache.clear();
        this.aclRulesProvider.cleanupFeature();
        this.aclRulesProvider.setFeatures();
    }
}
