/* tslint:disable:variable-name */
import { IMobileAppStatistic, StatisticPeriod } from './interfaces';
import { BaseEntity, Field, BelongsTo } from '../common/meta';
import { IResource } from '../common/interfaces';
import { MobileApp } from '../mobile-app/mobile-app.service';
import { IMobileApp } from '../mobile-app/interfaces';

export class MobileAppStatistic extends BaseEntity<IMobileAppStatistic> implements IMobileAppStatistic {

    public static readonly type: string = 'google_vertical';

    @Field()
    id: string;

    @Field()
    bid_requests: number;

    @Field()
    end_on: string;

    @Field()
    period: StatisticPeriod;

    @Field()
    start_on: string;

    @BelongsTo({ deferredConstructor: () => MobileApp })
    mobile_app: IMobileApp | IResource;

}
