import { Observable } from 'rxjs/internal/Observable';
import { ICollection, IParam, IPersistentApiClient, IResource } from '../../common/interfaces';
import { IThirdPartyDataProvider } from '../interfaces';
import { IAdvertiser } from '../../advertiser/interfaces';

export interface IThirdPartyDataSegment extends IResource {
    name: string;
    segment_name: string;
    code: string;
    cpm_price?: number;
    unique_users: number;
    disabled_in_third_party?: boolean;
    third_party_data_provider: IThirdPartyDataProvider | IResource;
    advertiser: IAdvertiser | IResource;
    campaign_ids: string[];
}
export interface IZipCodesEntity {
    kind: string;
    codes: string;
}

export type IThirdPartyDataSegmentCollection = ICollection<IThirdPartyDataSegment>;
export type ThirdPartyDataSegmentBelongsToRelationships = never;
export type ThirdPartyDataSegmentHasManyRelationships = never;

export interface IThirdPartyDataSegmentClient
    extends IPersistentApiClient<IThirdPartyDataSegment, ThirdPartyDataSegmentBelongsToRelationships,
    ThirdPartyDataSegmentHasManyRelationships> {
    getAllByProvider(
        providerId: string,
        params?: IParam
    ): Observable<IThirdPartyDataSegmentCollection>;
}
