export { IBaseApiClient } from '../common/interfaces';

import { IAdServer } from './interfaces';

import { BaseEntity, Field } from '../common/meta';

export class AdServer extends BaseEntity<IAdServer> implements IAdServer {
    public static readonly type: string = 'ad_server';

    @Field()
    id: string;
    @Field()
    name: string;
    @Field()
    code: string;
}
