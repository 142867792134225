/* tslint:disable:variable-name */

import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

import { ISchema, BasePersistentApiClient } from '../common/index';
import { BaseEntity, Field, BelongsTo, HasMany } from '../common/meta';
import {
    IInstanceCodeClient, instanceCodeClientT, paramsBuilderT,
    IParamsBuilder, IResource
} from '../common/interfaces';

import {
    IDataTargetingSet, IDataTargetingSetClient, DataTargetingSetBelongsToRelationships,
    DataTargetingSetHasManyRelationships
} from './interfaces';
import { Advertiser } from '../advertiser/advertiser.entity';
import { IAdvertiser } from '../advertiser/interfaces';

import { Archivable } from '../common/archivable/archivable';
import { BulkRemovable } from '../common/bulk-removable/bulk-removable';
import { IBulkResponse } from './../common/errors';
import { AuthHttp } from '../authentication/authentication.service';

export class DataTargetingSet extends BaseEntity<IDataTargetingSet> implements IDataTargetingSet {

    public static readonly type: string = 'data_targeting_set';

    @Field()
    id: string;

    @Field()
    name: string;

    @Field()
    kind: string;

    @Field()
    created_at: string;

    @Field()
    updated_at: string;

    @Field()
    max_cpm_price: number;

    @Field()
    targeting_logic: string;

    @Field()
    archived: boolean;

    @Field()
    deleted: boolean;

    @BelongsTo({ deferredConstructor: () => Advertiser })
    advertiser: IAdvertiser | IResource;

}

@Injectable() @Archivable() @BulkRemovable()
export class DataTargetingSetClient
    extends BasePersistentApiClient<IDataTargetingSet, DataTargetingSetBelongsToRelationships, DataTargetingSetHasManyRelationships>
    implements IDataTargetingSetClient {

    protected static classConfig: ISchema = {
        apiResourceName: 'data_targeting_sets'
    };

    archive: (id: string) => Observable<IDataTargetingSet>;
    unarchive: (id: string) => Observable<IDataTargetingSet>;
    bulkRemove: (ids: string[]) => Observable<IBulkResponse[]>;


    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return DataTargetingSet;
    }

}
