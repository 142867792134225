import { Injectable, Inject } from '@angular/core';

import {
    instanceCodeClientT, IInstanceCodeClient, paramsBuilderT, IParamsBuilder
} from '../common/interfaces';
import { ISchema, BaseApiClient } from '../common/index';
import { IThirdPartyDataProvider, IThirdPartyDataProviderClient } from './interfaces';
import { AuthHttp } from '../authentication/authentication.service';
import { ThirdPartyDataProvider } from './third-party-data-provider.entity';

@Injectable()
export class ThirdPartyDataProviderClient
    extends BaseApiClient<IThirdPartyDataProvider> implements IThirdPartyDataProviderClient {

    protected static classConfig: ISchema = {
        apiResourceName: 'third_party_data_providers'
    };

    constructor(
        @Inject(AuthHttp) http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return ThirdPartyDataProvider;
    }
}
