import { Injectable, Inject } from '@angular/core';

import { ISchema, BasePersistentApiClient } from '../common/index';

import {
    IThirdPartyPixelTracking,
    IThirdPartyPixelTrackingClient,
    IThirdPartyPixelTrackingClientFactory,
    ThirdPartyPixelTrackingBelongsToRelationships,
    ThirdPartyPixelTrackingHasManyRelationships,
    DeliveryType, IThirdPartyPixelTrackingCollection
} from './interfaces';

import {
    instanceCodeClientT, IInstanceCodeClient, paramsBuilderT,
    IParamsBuilder
} from '../common/interfaces';
import { Observable } from 'rxjs/internal/Observable';
import { AuthHttp } from '../authentication/authentication.service';
import { environment } from 'environments/environment';
import { ThirdPartyPixelTracking } from './third-party-pixel-tracking.entity';


@Injectable()
export class ThirdPartyPixelTrackingClientFactory implements IThirdPartyPixelTrackingClientFactory {

    constructor(
        private http: AuthHttp,
        @Inject(instanceCodeClientT) private instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) private paramsBuilder: IParamsBuilder
    ) {
    }

    createThirdPartyPixelTrackingClient(
        creativeId: string
    ): ThirdPartyPixelTrackingClient {
        return new ThirdPartyPixelTrackingClient(
            creativeId,
            this.http,
            this.instanceCodeClient,
            this.paramsBuilder);
    }
}

export class ThirdPartyPixelTrackingClient
    extends BasePersistentApiClient<IThirdPartyPixelTracking,
    ThirdPartyPixelTrackingBelongsToRelationships,
    ThirdPartyPixelTrackingHasManyRelationships>
    implements IThirdPartyPixelTrackingClient {

    protected static classConfig: ISchema = {
        apiResourceName: 'third_party_pixel_trackings'
    };

    protected apiBase(): string {
        return `${environment.api.base}/${this.instanceCode}/creatives/${this.creativeId}/${this.classConfig().apiResourceName}`;
    }

    constructor(private creativeId: string,
        http: AuthHttp,
        instanceCodeClient: IInstanceCodeClient,
        paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    getAllByCreative(creativeId: string): Observable<IThirdPartyPixelTrackingCollection> {
        this.creativeId = creativeId;
        return super.getAll();
    }

    protected implementationClass() {
        return ThirdPartyPixelTracking;
    }
}
