/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

import { ISchema, BasePersistentApiClient } from '../common/index';
import { BaseEntity, Field, BelongsTo, HasMany } from '../common/meta';
import {
    IInstanceCodeClient, instanceCodeClientT, paramsBuilderT,
    IParamsBuilder, IResource, IRecursiveArray, IParam
} from '../common/interfaces';

import {
    IDomainSet, IDomainSetClient, DomainSetBelongsToRelationships,
    DomainSetHasManyRelationships
} from './interfaces';
import { Advertiser } from '../advertiser/advertiser.entity';
import { Domain } from '../domain/domain.service';
import { IAdvertiser } from '../advertiser/interfaces';
import { IDomain } from '../domain/interfaces';
import { Archivable } from '../common/archivable/archivable';
import { BulkRemovable } from '../common/bulk-removable/bulk-removable';
import { IBulkResponse } from './../common/errors';
import { IChangeable, IChangeCollection } from '../change/interfaces';
import { Changeable } from '../common/changeable/changeable';
import { IComment, IPersistentComment } from '../comment/interfaces';
import { AuthHttp } from '../authentication/authentication.service';

export class DomainSet extends BaseEntity<IDomainSet> implements IDomainSet {

    public static readonly type: string = 'domain_set';

    @Field()
    id: string;

    @Field()
    name: string;

    @Field()
    archived: boolean;

    @Field()
    deleted: boolean;

    @BelongsTo({ deferredConstructor: () => Advertiser })
    advertiser: IAdvertiser | IResource;

    @HasMany({ deferredConstructor: () => Domain })
    domains: IDomain[] | IResource[];

}

@Injectable()
@Archivable()
@BulkRemovable()
@Changeable()
export class DomainSetClient extends BasePersistentApiClient<IDomainSet, DomainSetBelongsToRelationships, DomainSetHasManyRelationships>
    implements IDomainSetClient {

    protected static classConfig: ISchema = {
        apiResourceName: 'domain_sets'
    };

    archive: (id: string) => Observable<IDomainSet>;
    unarchive: (id: string) => Observable<IDomainSet>;
    bulkRemove: (ids: string[]) => Observable<IBulkResponse[]>;

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return DomainSet;
    }

    getChanges(
        changeableId: string, params?: IParam, include?: IRecursiveArray<string>
    ): Observable<IChangeCollection> {
        throw new Error('Method not implemented.');
    }

    addChangeComment(note: IPersistentComment, changeableId: string): Observable<IComment> {
        throw new Error('Method not implemented.');
    }

}
