/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';
import { HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ISchema, BasePersistentApiClient } from '../common/index';
export { IBaseApiClient } from '../common/interfaces';
import {
    IDealIdAnalyzer, IDealIdAnalyzerClient, DealIdAnalyzerBelongsToRelationships, DealIdAnalyzerHasManyRelationships,
    IDealIdAnalyzerStatsResults, IDealIdAnalyzerStats
} from './interfaces';
import { instanceCodeClientT, IInstanceCodeClient, paramsBuilderT, IParamsBuilder } from '../common/interfaces';
import { BaseEntity, Field } from '../common/meta';
import { IDealIdAnalyzerCollection } from './interfaces';
import { AuthHttp } from '../authentication/authentication.service';
import { AccessResolverHelper } from '@shared/acl/service/helper/access-resolver.service';
export class DealIdAnalyzer extends BaseEntity<IDealIdAnalyzer> implements IDealIdAnalyzer {

    public static readonly type: string = 'direct_deal';

    @Field()
    requests: number;

    @Field()
    bids: number;

    @Field()
    no_bids: number;

    @Field()
    wins: number;

    @Field()
    loss: number;

    @Field()
    deal_id: string;

    @Field()
    inventory_source_id: string;

    @Field()
    value: string | number;

    @Field()
    category: string;

    @Field()
    video_min_duration?: number;

    @Field()
    video_max_duration?: number;

    @Field()
    video_min_bitrate?: number;

    @Field()
    video_max_bitrate?: number;

    @Field()
    video_file_type?: string;
}

@Injectable()
export class DealIdAnalyzerClient
    extends BasePersistentApiClient<IDealIdAnalyzer, DealIdAnalyzerBelongsToRelationships, DealIdAnalyzerHasManyRelationships>
    implements IDealIdAnalyzerClient {

    protected static classConfig: ISchema = {
        apiResourceName: 'direct_deals'
    };

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder,
        private accessResolver: AccessResolverHelper,
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return DealIdAnalyzer;
    }

    create(entity: any): Observable<IDealIdAnalyzerCollection> {
        return this.http.post(`${ this.apiInternal() }/deal_id_analyzer`, entity)
            .pipe(
                map((response: HttpResponse<any>) => {
                    let data = response.body.data.items;
                    let meta = {
                        total_pages: 1,
                        total_count: data.length,
                        current_page: 1,
                        next_page: null,
                        previous_page: null,
                        stats: response.body.data.totals
                    };
                    return {data: data, meta: meta} as IDealIdAnalyzerCollection;
                }),
                catchError(this.handleRequestError)
            );
    }

    stats(entity: any): Observable<IDealIdAnalyzerStatsResults> {
        return this.http.post(`${ this.apiInternal() }/deal_id_analyzer/stats`, entity)
            .pipe(
                map((response: HttpResponse<any>) => {
                    return response.body.data;
                }),
                catchError(this.handleRequestError)
            );
    }

    campaignStats(entity: any): Observable<IDealIdAnalyzerStats> {
        return this.http.post(`${ this.apiInternal() }/deal_id_analyzer/campaign_stats`, entity)
            .pipe(
                map((response: HttpResponse<any>) => {
                    return response.body.data;
                }),
                catchError(this.handleRequestError)
            );
    }
}
