
import { throwError,  Observable, of } from 'rxjs';
import { map, tap, delay, publishLast, refCount, takeWhile } from 'rxjs/operators';

import { IParam } from '../sdk.index';

type CacheItem = {
    value?: any;
    observable?: Observable<any>;
    resolved: boolean;
};

export class SimpleCache {

    private cache: Map<string, CacheItem>;
    private keepInSession: boolean = false;
    private timestamp: number;
    private readonly STORAGE_LIMIT = 5242880; // Session Storage can store up to 5Mb

    constructor(private expiredInSecond: number = 120) {
        this.cache = new Map();
        this.timestamp = (new Date()).getTime();
    }

    useSessionStorage(): void {
        this.keepInSession = true;
    }

    createKey(cacheDomain: string, query: string | IParam): string {
        return this.stringify(cacheDomain, query);
    }

    exists(key: string): boolean {
        return this.cache.has(key) || (this.keepInSession && !!sessionStorage.getItem(key));
    }

    getResult(key: string): Observable<any> {
        if (!this.exists(key)) {
            return throwError(`Cache for key: ${key} does not exist!`);
        }

        let item = this.cache.get(key);

        if (!item) {
            item = {value: JSON.parse(sessionStorage.getItem(key)), resolved: true};
            this.cache.set(key, item);
        }

        if (item.resolved) {
            return of(item.value).pipe(delay(0));
        }

        return item.observable;
    }

    setResolvedResult(key: string, result: string | object | number | Array<any>): void {
        this.beforeSetResult(key);
        let jsonData = JSON.stringify(result);
        if (this.keepInSession && jsonData.length < this.STORAGE_LIMIT) {
            sessionStorage.setItem(key, jsonData);
        }
        this.cache.set(key, {value: result, resolved: true});
    }

    setResult(key: string, result$: Observable<any>): void {
        this.beforeSetResult(key);

        result$ = result$
            .pipe(
                tap(
                (value: any) => {
                    let item = this.cache.get(key);
                    if (!item) {
                        return;
                    }
                    item.value = value;
                    item.resolved = true;
                    item.observable = null;
                    if (this.keepInSession) {
                        sessionStorage.setItem(key, JSON.stringify(value));
                    }
                },
                () => {
                    this.removeResult(key);
                }),
                publishLast(),
                refCount()
            );

        this.cache.set(key, {observable: result$, resolved: false});
    }

    removeResult(key: string): void {
        sessionStorage.removeItem(key);
        if (this.cache.has(key)) {
            this.cache.delete(key);
        }
    }

    clear(): void {
        this.timestamp = (new Date()).getTime();
        Array.from(this.cache.keys()).forEach(key => {
            sessionStorage.removeItem(key);
        });
        this.cache.clear();
    }

    private stringify(cacheDomain: string, query: string | IParam): string {
        return JSON.stringify({cacheDomain, query});
    }

    private beforeSetResult(key: string): void {
        if (this.expiredInSecond > 0) {
            type ExpirationPayload = {key: string; timestamp: number};
            let expirationPayload: ExpirationPayload = {key, timestamp: (new Date()).getTime()};
            of(expirationPayload).pipe(
                delay(this.expiredInSecond * 1000),
                takeWhile((payload) => payload.timestamp > this.timestamp)
            ).subscribe((payload) => {
                if (this.exists(payload.key)) {
                    this.cache.delete(payload.key);
                    sessionStorage.removeItem(payload.key);
                }
            });
        }

        if (this.keepInSession) {
            sessionStorage.removeItem(key);
        }
    }
}
