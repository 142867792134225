import { IPersistentApiClient } from './../common/interfaces';
import { ICollection, IResource } from '../common/index';
import { ICountry } from '../country/interfaces';
import { IAdvertiser } from '../advertiser/interfaces';
import { IArchivable } from './../common/archivable/interfaces';
import { IBulkRemovable } from './../common/bulk-removable/interfaces';

export interface IDataProvider extends IResource {
    name: string;
    address?: string;
    postal_code?: string;
    city?: string;
    use_business_address_for_billing?: boolean;
    email?: string;
    phone?: string;
    mobile?: string;
    fax?: string;
    country?: ICountry | IResource;
    advertiser?: IAdvertiser | IResource;
}

export type IDataProviderCollection = ICollection<IDataProvider>;

export type DataProviderBelongsToRelationships = never;
export type DataProviderHasManyRelationships = never;

export interface IDataProviderClient
    extends IPersistentApiClient<IDataProvider, DataProviderBelongsToRelationships, DataProviderHasManyRelationships>,
        IArchivable<IDataProvider>,
        IBulkRemovable {
}
