/* tslint:disable:variable-name */
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

import {
    ICustomDataSegment,
    ICustomDataSegmentClient,
    CustomDataSegmentBelongsToRelationships,
    CustomDataSegmentHasManyRelationships
} from './interfaces';
import { BaseEntity, BelongsTo, Field } from '../common/meta';
import { BasePersistentApiClient } from '../common/base-api-client';
import {
    IBulkEditable, IEntityConstructor, IInstanceCodeClient, instanceCodeClientT, IParam, IParamsBuilder,
    IRecursiveArray,
    ISchema, paramsBuilderT
} from '../common/interfaces';
import { IChangeable, IChangeCollection } from '../change/interfaces';
import { IBulkResponse } from '../common/errors';
import { IComment, IPersistentComment } from '../comment/interfaces';
import { Advertiser } from '../advertiser/advertiser.entity';
import { IBulkRemovable } from '../common/bulk-removable/interfaces';
import { Changeable } from '../common/changeable/changeable';
import { AuthHttp } from '../authentication/authentication.service';

export class CustomDataSegment extends BaseEntity<ICustomDataSegment> implements ICustomDataSegment {

    public static readonly type: string = 'custom-data-segment';

    @Field()
    id: string;

    @Field()
    name: string;

    @Field()
    code: string;

    @Field()
    campaign_ids: string[];

    @BelongsTo({ deferredConstructor: () => Advertiser })
    advertiser: Advertiser;
}

@Injectable()
@Changeable()
export class CustomDataSegmentClient
    extends BasePersistentApiClient<ICustomDataSegment,
    CustomDataSegmentBelongsToRelationships,
    CustomDataSegmentHasManyRelationships>
    implements ICustomDataSegmentClient, IChangeable, IBulkEditable<ICustomDataSegment>, IBulkRemovable {

    protected static classConfig: ISchema = {
        apiResourceName: 'custom_data_segments'
    };

    protected implementationClass(): IEntityConstructor<ICustomDataSegment> {
        return CustomDataSegment;
    }

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }


    bulkRemove(ids: string[]): Observable<IBulkResponse[]> {
        throw new Error('Method not implemented');
    }

    getChanges(
        changeableId: string,
        params?: IParam,
        include?: IRecursiveArray<string>
    ): Observable<IChangeCollection> {
        throw new Error('Method not implemented');
    }

    addChangeComment(comment: IPersistentComment, changeableId: string): Observable<IComment> {
        throw new Error('Method not implemented');
    }

    bulkEdit(entities: ICustomDataSegment[]): Observable<IBulkResponse[]> {
        throw new Error('Method not implemented');
    }
}
