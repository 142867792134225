import { ICollection, IPersistentApiClient, IResource } from '../common';
import { IAdvertiser } from '../advertiser/interfaces';
import { ICampaign, ConversionEvents } from '../campaign/interfaces';
import { IBulkRemovable } from '../common/bulk-removable/interfaces';
import { IDuplicable } from '../common/duplicable/interfaces';
import { IFavoriteable } from '../common/favoriteable/interfaces';

export type MediaPlanTargetType = 'impressions' | 'clicks' | 'conversions';

export interface IMediaPlan extends IResource {
    name: string;
    media_budget: number;
    booked_budget: number;
    campaigns_count: number;
    remaining_booked_budget: number;
    remaining_booked_budget_yesterday: number;
    agency_fee?: number;
    use_platform161_tech_fee?: boolean;
    platform161_tech_fee?: number;
    unlimited_budget: boolean;
    budget_pacing?: boolean;
    start_on: string;
    end_on: string;
    campaigns_start_date: string;
    campaigns_end_date: string;
    favorite: boolean;
    active?: boolean;
    frequency_cap: number;
    frequency_cap_type?: string;
    frequency_cap_count: number;
    secondary_frequency_cap?: number;
    secondary_frequency_cap_type?: string;
    secondary_frequency_cap_count?: number;
    ctr: number;
    ecpa: number;
    ecpm: number;
    ecpc: number;
    impressions: number;
    clicks: number;
    dma?: boolean;
    cid?: string;
    chid?: string;
    external_id?: string;
    flight_on_time_percentage: number;
    campaigns?: ICampaign[] | IResource[];
    advertiser: IAdvertiser | IResource;
    total_booked_budget_spent?: number;
    alert_on_campaigns: boolean;
    conversions?: number;
    remaining_media_budget: number;
    on_time_percentage_yesterday: number;
    total_cost_ecpm: number;
    total_cost_ecpa: number;
    total_cost_ecpc: number;
    total_spend_yesterday: number;
    total_on_time_percentage_yesterday: number;
    target_value: number;
    target_type: MediaPlanTargetType;
    current_value_for_target?: number;
    spend_yesterday: number;
    summed_budget: number;
    under_over_delivery: number;
    total_media_budget_spent: number;
    projected_spend: number;
    optimal_spend_today: number;
    optimal_spend_yesterday: number;
    total_optimal_spend_yesterday: number;
    has_landing_campaigns?: boolean;
    has_unlimited_budget_campaigns?: boolean;
    client_order_id?: number;
    total_campaigns_budget_spent: number;
    total_campaigns_budget_spent_yesterday: number;
    has_ever_activated_campaigns?: boolean;
    conversion_events?: ConversionEvents;
}

export type IMediaPlanCollection = ICollection<IMediaPlan>;

export type MediaPlanBelongsToRelationships = never;
export type MediaPlanHasManyRelationships = never;

export interface IMediaPlanClient
    extends IPersistentApiClient<IMediaPlan, MediaPlanBelongsToRelationships, MediaPlanHasManyRelationships>,
        IBulkRemovable,
        IDuplicable<IMediaPlan>,
        IFavoriteable<IMediaPlan> { }
