/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';

import { ISchema, BaseApiClient } from '../common/index';
export { IBaseApiClient } from '../common/interfaces';
import { IAdjusterThirdpartyProvider, IAdjusterThirdpartyProviderClient } from './interfaces';
import {
    instanceCodeClientT, IInstanceCodeClient, IParamsBuilder, paramsBuilderT, IResource
} from '../common/interfaces';
import { Field, BelongsTo, BaseEntity, HasMany } from '../common/meta';
import { AuthHttp } from '../authentication/authentication.service';

export class AdjusterThirdpartyProvider
    extends BaseEntity<IAdjusterThirdpartyProvider> implements IAdjusterThirdpartyProvider {

    public static readonly type: string = 'adjuster_thirdparty_provider';

    @Field()
    id: string;

    @Field()
    name: string;

}

@Injectable()
export class AdjusterThirdpartyProviderClient
    extends BaseApiClient<IAdjusterThirdpartyProvider> implements IAdjusterThirdpartyProviderClient {

    protected static classConfig: ISchema = {
        apiResourceName: 'adjuster_thirdparty_providers'
    };

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return AdjusterThirdpartyProvider;
    }
}
