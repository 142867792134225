import { ICollection, IBaseApiClient, IResource } from '../common/index';

export interface IBrowser extends IResource {
    name: string;
    code: string;
    parent?: IBrowser | IResource;
}

export type IBrowserCollection = ICollection<IBrowser>;

export interface IBrowserClient extends IBaseApiClient<IBrowser> { }
