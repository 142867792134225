import { NgModule, LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeEnGB from '@angular/common/locales/en-GB';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, PreloadAllModules } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { authConfig, builderFactory } from '../../platform161-client/platform161-client.module';
import { AuthenticationService, instanceCodeClientT } from './sdk.index';
import { ROUTES } from './app.routes';
import { App } from './app.component';
import { DataResolver } from './app.resolver';
import { AppConfig } from './app.config';
import { NotFoundComponent } from './error/not-found/not-found.component';
import { ForbiddenComponent } from './error/forbidden/forbidden.component';
import { AppRouterDispatcherComponent } from './app.router-dispatcher.component';
import { IdentityStorage } from './modules/common/service/storage/identity-storage';
import { WildcardRouterDispatcherComponent } from './app.wildcard-router-dispatcher.component';
import { paramsBuilderT } from '../../platform161-client/common/interfaces';
import {
    AuthConfig, AuthHttp,
    BaseTokenStorage,
    tokenStorageT,
    AuthGuard
} from '../../platform161-client/authentication/authentication.service';
import { InternalURLService } from './modules/common/service/router/internal-url.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AclModule } from '@shared/acl/acl.module';
import { ErrorInterceptor } from '@shared/error-interceptor/error-interceptor.service';
import { ToastrModule } from 'ngx-toastr';

// Register additional locales
registerLocaleData(localeEnGB);

// Application wide providers
const AUTH_HTTP_PROVIDRS = [
    IdentityStorage,
    BaseTokenStorage,
    AuthHttp,
    AuthenticationService,
    AuthGuard,
    {
        provide: instanceCodeClientT, useExisting: IdentityStorage
    },
    {
        provide: tokenStorageT, useExisting: BaseTokenStorage
    },
    {
        provide: paramsBuilderT, useFactory: builderFactory
    },
    {
        provide: AuthConfig, useFactory: authConfig
    },
];
const APP_PROVIDERS = [
    DataResolver,
    AppConfig,
    InternalURLService,
    ErrorInterceptor,
];

export function identityStorageFactory(identityStorage: IdentityStorage) {
    return identityStorage.getInstance()?.locale;
}
@NgModule({
    bootstrap: [App],
    declarations: [
        App,
        NotFoundComponent,
        ForbiddenComponent,
        AppRouterDispatcherComponent,
        WildcardRouterDispatcherComponent
    ],
    imports: [ // import Angular's modules
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(ROUTES, { useHash: true, preloadingStrategy: PreloadAllModules }),
        ServiceWorkerModule.register('ngsw-worker.js'),
        HttpClientModule,
        AclModule,
        ToastrModule.forRoot(),
    ],
    providers: [
        ...AUTH_HTTP_PROVIDRS,
        ...APP_PROVIDERS,
        {
            provide: LOCALE_ID,
            deps: [IdentityStorage],
            useFactory: identityStorageFactory
        }
    ]
})
export class AppModule { }
