import {
    ICollection, IResource,
    IPersistentApiClient, IParam, IRecursiveArray
} from '../common/interfaces';

import { IUser } from '../user/interfaces';
import { IComment, IPersistentComment } from '../comment/interfaces';
import { Observable } from 'rxjs/internal/Observable';

export interface INote extends IResource {
    noteable_id: string;
    created_at?: string;
    creator?: IResource | IUser;
    subject: string;
    body: string;
    comments?: IResource[] | IComment[];
}

export type INoteCollection = ICollection<INote>;

export type NoteBelongsToRelationships = never;
export type NoteHasManyRelationships = never;

export interface INoteClient extends IPersistentApiClient<INote, NoteBelongsToRelationships, NoteHasManyRelationships> {
}

export interface INoteable {
    getNotes(noteableId: string, params?: IParam, include?: IRecursiveArray<string>): Observable<INoteCollection>;
    addNote(note: INote): Observable<INote>;
    addNoteComment(comment: IPersistentComment, noteableId: string): Observable<IComment>;
}
