import { HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import { map, catchError } from 'rxjs/operators';

import { BaseApiClient } from '../base-api-client';
import { INote, INoteCollection } from '../../note/interfaces';
import { Note } from '../../note/note.service';
import { ICollection, IParam, IRecursiveArray } from '../interfaces';
import { BaseSerializer } from '../base-serializer';
import { IComment, IPersistentComment } from '../../comment/interfaces';
import { Comment } from '../../comment/comment.service';

export function Noteable() {

    return function(target) {

        const getNotes = function(
            id: string, params?: IParam, include?: IRecursiveArray<string>
        ): Observable<INoteCollection> {
            if (!(this instanceof BaseApiClient)) {
                throw new Error('Decorated element must by type BaseApiClient');
            }

            let serializer = new BaseSerializer<INote>(Note);
            this.recreateParams();
            let includes = this.includesConverter.convert(include);
            if (includes) {
                this.params = this.params.set('include', includes);
            }

            this.setImpersonationParams();

            return this.http
                .get(
                    `${this.apiBase()}/${id}/notes`,
                    { params: this.prepareParams(params) }
                )
                .pipe(
                    map((response: HttpResponse<any>) => {
                        let rawData = response.body;
                        let entities = serializer.deserializeMany(rawData, includes);
                        return { data: entities, meta: rawData.meta } as ICollection<INote>;
                    })
                );
        };

        const addNote = function(note: INote): Observable<INote> {
            if (!(this instanceof BaseApiClient)) {
                throw new Error('Decorated element must by type BaseApiClient');
            }

            let serializer = new BaseSerializer<INote>(Note);
            this.recreateParams();
            let includes = this.includesConverter.convert(['creator']);
            if (includes) {
                this.params = this.params.set('include', includes);
            }

            this.setImpersonationParams();

            let wrappedEntity = new Note(note);
            let data = serializer.serialize(wrappedEntity);
            return this.http
                .post(`${this.apiBase()}/${note.noteable_id}/notes`, data, { params: this.params })
                .pipe(
                    map((response: HttpResponse<any>) => {
                        return serializer.deserialize(response.body, includes);
                    }),
                    catchError(this.handleRequestError)
                );
        };

        const addNoteComment = function(entity: IPersistentComment, noteableId: string): Observable<IComment> {
            if (!(this instanceof BaseApiClient)) {
                throw new Error('Decorated element must by type BaseApiClient');
            }
            let serializer = new BaseSerializer<IComment>(Comment);
            let data = serializer.serialize(new Comment(entity));

            this.recreateParams();
            let includes = this.includesConverter.convert(['user']);
            if (includes) {
                this.params = this.params.set('include', includes);
            }

            this.setImpersonationParams();
            return this.http.post(
                `${this.apiBase()}/${noteableId}/notes/${entity.commentable.id}/comments`,
                data,
                { params: this.params }
            )
                .pipe(
                    map((response: HttpResponse<any>) => {
                        return serializer.deserialize(response.body, includes);
                    }),
                    catchError(this.handleRequestError)
                );
        };

        target.prototype.getNotes = getNotes;
        target.prototype.addNote = addNote;
        target.prototype.addNoteComment = addNoteComment;

    };

}
