import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccountClient } from './account/account.service';
import { DoohPublisherClient } from './dooh_publisher/dooh-publisher.service';
import { DoohChannelClient } from './dooh_channel/dooh-channel.service';
import { DoohNetworkClient } from './dooh_network/dooh-network.service';
import { DoohScreenClient } from './dooh_screen/dooh-screen.service';
import { AdServerClient } from './ad-server/ad-server.service';
import { AdvertiserClient } from './advertiser/advertiser.service';
import { BrowserClient } from './browser/browser.service';
import { CampaignClient } from './campaign/campaign.service';
import {
    CampaignInternetServiceProviderClientFactory
} from './campaign-internet-service-provider/campaign-internet-service-provider.service';
import { CityClient } from './city/city.service';
import { ContentCategoryClient } from './content-category/content-category.service';
import { CountryClient } from './country/country.service';
import { ChangeClient } from './change/change.service';
import { CommentClient } from './comment/comment.service';
import { CreativeClient } from './creative/creative.service';
import { CreativeSubmissionClient } from './creative/submission/creative-submission.service';
import { DataTargetingSetClient } from './data-targeting-set/data-targeting-set.service';
import { DesignatedMarketAreaClient } from './designated-market-area/designated-market-area.service';
import { DeviceTypeClient } from './device-type/device-type.service';
import { DeviceClient } from './device/device.service';
import { DealIdAnalyzerClient } from './deal-id-analyzer/deal-id-analyzer.service';
import { DirectDealClient } from './direct-deal/direct-deal.service';
import { ExchangeRateClient } from './exchange-rate/exchange-rate.service';
import { ExternalIdClient, ExternalIdClientFactory } from './external-id/external-id.service';
import { FeatureClient } from './feature/feature.service';
import { GridViewClient } from './grid-view/grid-view.service';
import { InstanceClient } from './instance/instance.service';
import { InternetServiceProviderClient } from './internet-service-provider/internet-service-provider.service';
import { InventorySourceClient } from './inventory-source/inventory-source.service';
import { LanguageClient } from './language/language.service';
import { MarketClient } from './market/market.service';
import { MediaPlanClient } from './media-plan/media-plan.service';
import { MobileAppClient } from './mobile-app/mobile-app.service';
import { MobileCarrierClient } from './mobile-carrier/mobile-carrier.service';
import { OperatingSystemClient } from './operating-system/operating-system.service';
import { PixelClient } from './pixel/pixel.service';
import { PixelOverviewClient } from './pixel-overview/pixel-overview.service';
import { PartialCreativeFileClient } from './partial-creative-file/partial-creative-file.service';
import { RealTimeDataClient } from './real-time-data/real-time-data.service';
import { RegionClient } from './region/region.service';
import { RetargetingPixelClient } from './retargeting-pixel/retargeting-pixel.service';
import { RtbProductClient } from './rtb-product/rtb-product.service';
import { RtbUrlClientFactory } from './rtb-url/rtb-url.service';
import { SiteClient } from './site/site.service';
import { SizeClient } from './size/size.service';
import { TargetingSetClient } from './targeting-set/targeting-set.service';
import { Platform161TechFeeClient } from './tech-fee/tech-fee.service';
import { DomainClient } from './domain/domain.service';
import { DomainSetClient } from './domain-set/domain-set.service';
import { ThirdPartyDataProviderClient } from './third-party-data-provider/third-party-data-provider.service';
import {
    ThirdPartyTechnologyPartnerClient
} from './third-party-technology-partner/third-party-technology-partner.service';
import { IabCategoryClient } from './iab-category/iab-category.service';
import { GeneralReportClient } from './general-report/general-report.service';
import { InvoiceReportClient } from './invoice-report/invoice-report.service';
import { UserClient } from './user/user.service';
import { UserTemporaryAccountClient } from './user/temporary-account/temporary-account.service';
import { UserNotificationClient } from './user-notification/user-notification.service';
import { UserPreferenceClient } from './user-preference/user-preference.service';
import { ParamsBuilder } from './common/params-builder';
import {
    BaseTokenStorage,
    AuthGuard,
    authConfigFactory
} from './authentication/authentication.service';
import { AgencyClient } from './agency/agency.service';
import { CreativeAttributeClient } from './creative/attributes/creative-attribute.service';
import {
    PreviewTrackClickClientFactory,
    TrackClickUrlFactory
} from './creative/preview_track_clicks/preview_track_click.service';
import { ConversionPixelClient } from './conversion-pixel/conversion-pixel.service';
import { RuleClient } from './rule/rule.service';
import { CampaignConversionPixelClientFactory } from './campaign-conversion-pixel/campaign-conversion-pixel.service';
import { PiggybackUrlClientFactory } from './piggyback-url/piggyback-url.service';
import { CustomDataSegmentClient } from './custom-data-segment/custom-data-segment.service';
import { HyperlocalDataSegmentClient } from './hyperlocal-data-segment/hyperlocal-data-segment.service';
import { SectionClient } from './section/section.service';
import { PublisherClient } from './publisher/publisher.service';
import { TrackingUrlClient } from './tracking-urls/tracking-urls.service';
import { ThirdPartyDataSegmentClient } from './third-party-data-provider/segment/third-party-data-segment.service';
import {
    CampaignThirdPartyDataSegmentClient
} from './third-party-data-provider/segment/campaign-third-party-data-segment.service';
import { TagGenerationClient } from './tag-generation/tag-generation.service';
import { PixelGenerationClient } from './pixel-generation/pixel-generation.service';
import { ThirdPartyPixelTrackingClientFactory } from './third-party-pixel-tracking/third-party-pixel-tracking.service';
import { PrivilegeClient } from './privilege/privilege.service';
import { RoleClient } from './role/role.service';
import { DataProviderClient } from './data-provider/data-provider.service';
import { InventorySetClient } from './inventory-set/inventory-set.service';
import { SupplySetClient } from './supply-set/supply-set.service';
import { GoogleVerticalClient } from './google-vertical/google-vertical.service';
import { GoogleBrowserClient, GoogleDeviceCategoryClient } from '../src/app/sdk.index';
import { GoogleOperatingSystemClient } from './google-operating-system/operating-system.service';
import { AdjusterThirdpartyProviderClient } from './adjuster-thirdparty-provider/adjuster-thirdparty-provider.service';

export function builderFactory() {
    return new ParamsBuilder();
}

export function authConfig() {
    const config = {
        globalHeaders: {
            'Content-Type': 'application/vnd.api+json',
            'Accept': 'application/vnd.api+json'
        },
        withCredentials: true,
        withRefreshEndpoint: true
    };
    return authConfigFactory(config);
}

@NgModule({
    imports: [
        CommonModule
    ],
    providers: [
        AccountClient,
        DoohPublisherClient,
        DoohChannelClient,
        DoohNetworkClient,
        DoohScreenClient,
        AdServerClient,
        AdvertiserClient,
        AgencyClient,
        AuthGuard,
        BaseTokenStorage,
        BrowserClient,
        GoogleBrowserClient,
        CampaignClient,
        CampaignInternetServiceProviderClientFactory,
        CampaignThirdPartyDataSegmentClient,
        CityClient,
        ChangeClient,
        CommentClient,
        ConversionPixelClient,
        RuleClient,
        CampaignConversionPixelClientFactory,
        ContentCategoryClient,
        CountryClient,
        CreativeAttributeClient,
        CreativeClient,
        CreativeSubmissionClient,
        CustomDataSegmentClient,
        DataProviderClient,
        DataTargetingSetClient,
        DealIdAnalyzerClient,
        DesignatedMarketAreaClient,
        DeviceClient,
        DeviceTypeClient,
        DirectDealClient,
        DomainClient,
        DomainSetClient,
        ExchangeRateClient,
        ExternalIdClientFactory,
        FeatureClient,
        GridViewClient,
        GeneralReportClient,
        HyperlocalDataSegmentClient,
        InvoiceReportClient,
        IabCategoryClient,
        GoogleVerticalClient,
        InstanceClient,
        InternetServiceProviderClient,
        InventorySetClient,
        InventorySourceClient,
        LanguageClient,
        MarketClient,
        MediaPlanClient,
        MobileAppClient,
        MobileCarrierClient,
        OperatingSystemClient,
        GoogleOperatingSystemClient,
        GoogleDeviceCategoryClient,
        PartialCreativeFileClient,
        PiggybackUrlClientFactory,
        PixelClient,
        PixelOverviewClient,
        Platform161TechFeeClient,
        PreviewTrackClickClientFactory,
        PrivilegeClient,
        PublisherClient,
        RealTimeDataClient,
        RegionClient,
        RetargetingPixelClient,
        RoleClient,
        RtbProductClient,
        SupplySetClient,
        RtbUrlClientFactory,
        SectionClient,
        SiteClient,
        SizeClient,
        TargetingSetClient,
        ThirdPartyDataProviderClient,
        ThirdPartyDataSegmentClient,
        ThirdPartyPixelTrackingClientFactory,
        ThirdPartyTechnologyPartnerClient,
        TrackingUrlClient,
        TrackClickUrlFactory,
        UserClient,
        UserNotificationClient,
        UserPreferenceClient,
        UserTemporaryAccountClient,
        TagGenerationClient,
        PixelGenerationClient,
        AdjusterThirdpartyProviderClient
    ]
})
export class Platform161ClientModule { }
