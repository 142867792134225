import { ICollection, IBaseApiClient, IResource } from '../common/index';

export interface IExternalId extends IResource {
}

export type IExternalIdCollection = ICollection<IExternalId>;

export interface IExternalIdClient extends IBaseApiClient<IExternalId> {
}

export interface IExternalIdClientFactory {
    createExternalIdClient(resourceType: string): IExternalIdClient;
}
