/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';
import { ISchema, BasePersistentApiClient } from '../common/index';
import { Observable } from 'rxjs/internal/Observable';
import { map, catchError } from 'rxjs/operators';

import {
    IInstanceCodeClient,
    IEntityConstructor,
    IParam,
    IParamsBuilder,
    IRecursiveArray,
    IResource,
    instanceCodeClientT,
    paramsBuilderT
} from '../common/interfaces';

import { Field, BaseEntity, BelongsTo } from '../common/meta';

import {
    IRealTimeParams,
    IRealTimeData,
    IRealTimeDataClient,
    IRealTimeDataCollection,
    IRealTimeDataFilter,
    IRealTimeDataResult,
    RealTimeDataBelongsToRelationships,
    RealTimeDataHasManyRelationships
} from './interfaces';
import { AuthHttp } from '../authentication/authentication.service';
import { HttpResponse } from '@angular/common/http';

export class RealTimeData extends BaseEntity<IRealTimeData> implements IRealTimeData {

    public static readonly type: string = 'real_time_data';

    @Field()
    filters: IRealTimeDataFilter;

    @Field()
    id: string;

    @Field()
    local_currency: boolean;

    @Field()
    measures: Array<string>;

    @Field()
    results: IRealTimeDataResult;

    @Field()
    start_from: number;
}

@Injectable()
export class RealTimeDataClient extends BasePersistentApiClient<
IRealTimeData, RealTimeDataBelongsToRelationships, RealTimeDataHasManyRelationships> implements IRealTimeDataClient {
    protected static classConfig: ISchema = {
        apiResourceName: 'real_time_data'
    };

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) { super(http, instanceCodeClient, paramsBuilder); }

    generate(entity: IRealTimeParams): Observable<IRealTimeData> {
        this.setImpersonationParams();
        let wrappedEntity = new (this.implementationClass())(entity);
        let data = this.serializer.serialize(wrappedEntity);
        return this.http.post(`${this.apiBase()}`, data, { params: this.params })
            .pipe(
                map((response: HttpResponse<any>) => {
                    return this.serializer.deserialize(response.body);
                }),
                catchError(this.handleRequestError)
            );
    }

    save(entity: IRealTimeData): Observable<IRealTimeData> {
        throw 'not available';
    }

    remove(id: string): Observable<void> {
        throw 'not available';
    }

    get(id: string): Observable<IRealTimeData> {
        throw 'not available';
    }

    getAll(params?: IParam): Observable<IRealTimeDataCollection> {
        throw 'not available';
    }

    protected implementationClass(): IEntityConstructor<IRealTimeData> {
        return RealTimeData;
    }
}
