/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';

import { ISchema, BaseApiClient, IResource } from '../common/index';
export { IBaseApiClient } from '../common/interfaces';
import { BaseEntity, Field, BelongsTo } from '../common/meta';
import {
    instanceCodeClientT, IInstanceCodeClient, paramsBuilderT,
    IParamsBuilder
} from '../common/interfaces';
import { AuthHttp } from '../authentication/authentication.service';
import { IGoogleOperatingSystem, IGoogleOperatingSystemClient } from './interfaces';

export class GoogleOperatingSystem extends BaseEntity<IGoogleOperatingSystem> implements IGoogleOperatingSystem {

    public static readonly type: string = 'google_operating_system';

    @Field()
    id: string;

    @Field()
    name: string;

    @Field()
    code: string;

    @BelongsTo({ deferredConstructor: () => GoogleOperatingSystem })
    parent: IGoogleOperatingSystem | IResource;

}

@Injectable()
export class GoogleOperatingSystemClient extends BaseApiClient<IGoogleOperatingSystem> implements IGoogleOperatingSystemClient {
    protected static classConfig: ISchema = {
        apiResourceName: 'google_operating_systems'
    };

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return GoogleOperatingSystem;
    }
}
