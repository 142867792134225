
import { map } from 'rxjs/operators';
import { forwardRef, Inject, Injectable } from '@angular/core';

import { PrivilegeAccessResolver } from '../access-resolver/privilege';
import { IAclFeatureDescriptor, IAclPrivilegeDescriptor } from '../../acl.interfaces';
import { ITableSettings } from '../../../../p-table/interfaces/table';
import { FeatureAccessResolver } from '../access-resolver/feature';
import { Observable } from 'rxjs/internal/Observable';

@Injectable()
export class AclTableHelper {

    constructor(
        @Inject(forwardRef(() => PrivilegeAccessResolver)) private privilegeAccessResolver: PrivilegeAccessResolver,
        @Inject(forwardRef(() => FeatureAccessResolver)) private featureAccessResolver: FeatureAccessResolver
    ) {}

    public filterSettingsByPrivilege(settings: ITableSettings,
                                     properties: string[],
                                     privilege: IAclPrivilegeDescriptor): void {
        this.privilegeAccessResolver.resolveFromDescriptor(privilege)
            .subscribe(
                (hasAccess) => {
                    this.filter(settings, properties, hasAccess);
                }
            );
    }

    public filterSettingsByFeature(settings: ITableSettings,
                                     properties: string[],
                                     feature: IAclFeatureDescriptor): Observable<boolean> {
        return this.featureAccessResolver.resolveFromDescriptor(feature).pipe(
            map(
                (hasAccess) => {
                    this.filter(settings, properties, hasAccess);
                    return hasAccess;
                }
            ));
    }

    public hasAccessByPrivilege(privilege: IAclPrivilegeDescriptor): Observable<boolean> {
        return this.privilegeAccessResolver.resolveFromDescriptor(privilege);
    }

    public hasAccessByFeature(feature: IAclFeatureDescriptor): Observable<boolean> {
        return this.featureAccessResolver.resolveFromDescriptor(feature);
    }

    private filter(settings: ITableSettings, properties: string[], hasAccess: boolean): void {
        for (let setting of Object.keys(settings)) {
            if (properties.indexOf(setting) > -1) {
                settings[setting] = hasAccess;
            }
        }
    }
}
