/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';

import { ISchema, BaseApiClient } from '../common/index';
export { IBaseApiClient } from '../common/interfaces';
import { IPrivilege, IPrivilegeClient } from './interfaces';
import {
    instanceCodeClientT, IInstanceCodeClient, paramsBuilderT,
    IParamsBuilder
} from '../common/interfaces';
import { BaseEntity, Field } from '../common/meta';
import { AuthHttp } from '../authentication/authentication.service';

export class Privilege extends BaseEntity<IPrivilege> implements IPrivilege {
    public static readonly type: string = 'privilege';

    @Field()
    id: string;

    @Field()
    description: string;

    @Field()
    domain_object: string;

    @Field()
    action: string;
}

@Injectable()
export class PrivilegeClient extends BaseApiClient<IPrivilege> implements IPrivilegeClient {
    protected static classConfig: ISchema = {
        apiResourceName: 'privileges'
    };

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return Privilege;
    }
}
