import { IBaseApiClient, ICollection, IResource } from '../common/interfaces';
import { IDoohScreen } from '../dooh_screen/interfaces';

export interface IDoohNetwork extends IResource {
    name: string;
    network_id: number;
    provider: string;
    active: boolean;

    dooh_screens: IDoohScreen[] | IResource[];
}

export type IDoohNetworkCollection = ICollection<IDoohNetwork>;

export interface IDoohNetworkClient extends IBaseApiClient<IDoohNetwork> {
}
