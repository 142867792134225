/* tslint:disable:variable-name */
import { Observable } from 'rxjs/internal/Observable';
import { Injectable, Inject } from '@angular/core';

import { ISchema, BasePersistentApiClient } from '../common/index';
export { IBaseApiClient } from '../common/interfaces';
import {
    IDirectDeal, IDirectDealClient, DirectDealBelongsToRelationships,
    DirectDealHasManyRelationships
} from './interfaces';
import {
    instanceCodeClientT, IInstanceCodeClient, paramsBuilderT,
    IParamsBuilder, IResource, ICollection, IParam, IRecursiveArray, IRequestOptions
} from '../common/interfaces';
import { BaseEntity, BelongsTo, Field, HasMany } from '../common/meta';
import { Country } from '../country/country.entity';
import { ICountry } from '../country/interfaces';
import { Campaign } from '../campaign/campaign.entity';
import { ICampaign } from '../campaign/interfaces';
import { CampaignsDirectDeal } from '../campaigns-direct-deal/campaigns-direct-deal.service';
import { ICampaignsDirectDeal } from '../campaigns-direct-deal/interfaces';
import { Archivable } from '../common/archivable/archivable';
import { BulkRemovable } from '../common/bulk-removable/bulk-removable';
import { IBulkResponse } from './../common/errors';
import { IAdvertiser } from '../advertiser/interfaces';
import { ISize } from '../size/interfaces';
import { InventorySource } from '../inventory-source/inventory-source.service';
import { IInventorySource } from '../inventory-source/interfaces';
import { Advertiser } from '../advertiser/advertiser.entity';
import { Size } from '../size/size.service';
import { AuthHttp } from '../authentication/authentication.service';
import { map, catchError } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { DoohPublisher } from '../dooh_publisher/dooh-publisher.service';
import { IDoohPublisher } from '../dooh_publisher/interfaces';

export class DirectDeal extends BaseEntity<IDirectDeal> implements IDirectDeal {

    public static readonly type: string = 'direct_deal';

    @Field()
    id: string;

    @Field()
    name: string;

    @Field()
    deal_id: string;

    @Field()
    start_on: string;

    @Field()
    end_on: string;

    @Field()
    price: number;

    @Field()
    inventory_description: string;

    @Field()
    rich_media: boolean;

    @Field()
    rich_media_format: string;

    @Field()
    archived: boolean;

    @BelongsTo({ deferredConstructor: () => InventorySource })
    inventory_source: IInventorySource | IResource;

    @BelongsTo({ deferredConstructor: () => Advertiser })
    advertiser: IAdvertiser | IResource;

    @BelongsTo({ deferredConstructor: () => DoohPublisher })
    dooh_publisher: IDoohPublisher | IResource;

    @HasMany({ deferredConstructor: () => Size })
    sizes: ISize[] | IResource[];

    @HasMany({ deferredConstructor: () => Country })
    countries: IResource[] | ICountry[];

    @HasMany({ deferredConstructor: () => Campaign })
    campaigns: IResource[] | ICampaign[];

    @HasMany({ deferredConstructor: () => CampaignsDirectDeal })
    campaigns_direct_deals: ICampaignsDirectDeal[];

    statusForCampaign(campaignId: string): string {
        const campaignStatus = this.getCampaignDirectDeal(campaignId);
        return campaignStatus && campaignStatus.status;
    }

    bidPriceForCampaign(campaignId: string): number {
        const campaignDirectDeal = this.getCampaignDirectDeal(campaignId);
        return campaignDirectDeal ? campaignDirectDeal.bid_price : 0;
    }

    private getCampaignDirectDeal(id: string): ICampaignsDirectDeal {
        return (this.campaigns_direct_deals || []).find(campaignDeal => campaignDeal.campaign_id === id);
    }
}

@Injectable()
@Archivable()
@BulkRemovable()
export class DirectDealClient
    extends BasePersistentApiClient<IDirectDeal, DirectDealBelongsToRelationships, DirectDealHasManyRelationships>
    implements IDirectDealClient {

    protected static classConfig: ISchema = {
        apiResourceName: 'direct_deals'
    };

    archive: (id: string) => Observable<IDirectDeal>;
    unarchive: (id: string) => Observable<IDirectDeal>;
    bulkRemove: (ids: string[]) => Observable<IBulkResponse[]>;

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    getAll(params?: IParam, include?: IRecursiveArray<string>,
        options: IRequestOptions = {}): Observable<ICollection<IDirectDeal>> {
        this.recreateParams();
        let includes = this.buildIncludes(this.includesConverter.convert(include), options.ignoreMandatoryIncludes);
        if (includes) {
            this.params = this.params.set('include', includes);
        }
        this.setImpersonationParams();
        this.prepareFieldsParams(options);
        return this.http
            .get(`${this.apiBase()}`,
                { params: this.prepareParams(params) })
            .pipe(
                map((response: HttpResponse<any>) => {
                    const toArchiveObs = [];
                    const rawData = response.body;
                    const entities = this.serializer.deserializeMany(rawData, includes).map(deal => {
                        if (!deal.end_on || deal.archived || new Date(deal.end_on) > new Date) {
                            return deal;
                        } else {
                            toArchiveObs.push(this.archive(deal.id));
                            deal.archived = true;
                            return deal;
                        }
                    });

                    forkJoin(...toArchiveObs).subscribe();
                    return { data: entities, meta: rawData.meta } as ICollection<IDirectDeal>;
                }),
                catchError(this.handleRequestError)
            );
    }

    save(entity: IDirectDeal, inc?: IRecursiveArray<string>, opts: IRequestOptions = {}): Observable<IDirectDeal> {
        // campaigns_direct_deals is readonly table
        // relation should be managed only through campaigns
        delete entity.campaigns_direct_deals;
        return super.save(entity, inc, opts);
    }

    protected implementationClass() {
        return DirectDeal;
    }
}
