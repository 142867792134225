/* tslint:disable:variable-name */
import { Observable } from 'rxjs/internal/Observable';
import { Injectable, Inject } from '@angular/core';

import { ISchema, BasePersistentApiClient } from '../common/index';
export { IBaseApiClient } from '../common/interfaces';
import {
    IPartialCreativeFile,
    IPartialCreativeFileClient,
    PartialCreativeFileBelongsToRelationships,
    PartialCreativeFileHasManyRelationships
} from './interfaces';

import { AdServer } from '../ad-server/ad-server.service';
import { IAdServer } from '../ad-server/interfaces';

import { PartialCreative } from '../partial-creative/partial-creative';
import { IPartialCreative } from '../partial-creative/interfaces';

import {
    instanceCodeClientT, IInstanceCodeClient, paramsBuilderT,
    IParamsBuilder, IResource
} from '../common/interfaces';
import { HasMany, BelongsTo, BaseEntity, Field } from '../common/meta';
import { AuthHttp } from '../authentication/authentication.service';

export class PartialCreativeFile extends BaseEntity<IPartialCreativeFile> implements IPartialCreativeFile {

    public static readonly type: string = 'partial_creative_file';

    @Field()
    id: string;

    @Field()
    base64_data: string;

    @Field()
    original_filename: string;

    @Field()
    content_type: string;

    @Field()
    manual_thirdparty: boolean;

    @BelongsTo({ deferredConstructor: () => AdServer })
    ad_server: IAdServer | IResource;

    @HasMany({ deferredConstructor: () => PartialCreative })
    partial_creatives: IPartialCreative[] | IResource[];

}

@Injectable()
export class PartialCreativeFileClient
    extends BasePersistentApiClient<IPartialCreativeFile,
    PartialCreativeFileBelongsToRelationships,
    PartialCreativeFileHasManyRelationships>
    implements IPartialCreativeFileClient {

    protected static classConfig: ISchema = {
        apiResourceName: 'partial_creative_files'
    };

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    remove(id: string): Observable<void> {
        throw 'not available';
    }

    protected implementationClass() {
        return PartialCreativeFile;
    }
}
