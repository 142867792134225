/* tslint:disable:variable-name */
import { IResource } from '../common/index';
import {
    ISite,
} from './interfaces';
import { BaseEntity, Field, BelongsTo, HasMany } from '../common/meta';
import { IPublisher } from '../publisher/interfaces';
import { Publisher } from '../publisher/publisher.entity';
import { IAdvertiser } from '../advertiser/interfaces';
import { Advertiser } from '../advertiser/advertiser.entity';
import { Campaign } from '../campaign/campaign.entity';
import { ICampaign, ISection } from '..';
import { Section } from '../section/section.entity';

export class Site extends BaseEntity<ISite> implements ISite {

    public static readonly type: string = 'site';

    @Field()
    id: string;

    @Field()
    name: string;

    @Field()
    shared: boolean;

    @Field()
    archived?: boolean;

    @BelongsTo({deferredConstructor: () => Publisher, optional: false })
    publisher: IPublisher | IResource;

    @BelongsTo({deferredConstructor: () => Advertiser, optional: true })
    advertiser: IAdvertiser | IResource;

    @HasMany({ deferredConstructor: () => Section })
    sections: ISection[] | IResource[];
}

