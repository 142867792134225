import { ICollection, IBaseApiClient, IResource } from '../common/interfaces';

export interface IRtbProduct extends IResource {
    name: string;
}

export type IRtbProductCollection = ICollection<IRtbProduct>;

export interface IRtbProductClient extends IBaseApiClient<IRtbProduct> {
}
