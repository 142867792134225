import { Routes } from '@angular/router';
import { AuthGuard } from './sdk.index';
import { NotFoundComponent } from './error/not-found/not-found.component';
import { ForbiddenComponent } from './error/forbidden/forbidden.component';
import { AppRouterDispatcherComponent } from './app.router-dispatcher.component';
import { WildcardRouterDispatcherComponent } from './app.wildcard-router-dispatcher.component';
import { InstanceGuard } from './shared/acl/service/instance-guard.service';
import { DataResolver } from './app.resolver';

export const ROUTES: Routes = [
    {
        path: '',
        component: AppRouterDispatcherComponent
    },
    {
        path: 'app',
        loadChildren: () => import('app/layout/layout.module').then(m => m.LayoutModule),
        canActivate: [InstanceGuard, AuthGuard]
    },
    {
        path: ':instance/download-report',
        loadChildren: () => import('app/download-report/download-report.module').then(m => m.DownloadReportModule),
        canActivate: [AuthGuard]
    },
    {
        path: ':instance/app',
        loadChildren: () => import('app/layout/layout.module').then(m => m.LayoutModule),
        canActivate: [InstanceGuard, AuthGuard],
        resolve: [DataResolver],
    },
    {
        path: 'login',
        loadChildren: () => import('app/login/login.module').then(m => m.LoginModule)
    },
    {
        path: ':instance/login',
        loadChildren: () => import('app/login/login.module').then(m => m.LoginModule)
    },
    {
        path: ':instance/password-reset/:token',
        loadChildren: () => import('app/modules/password-reset/password-reset.module').then(m => m.PasswordResetModule),
        data: {title: 'Reset password'},
    },
    {
        path: ':instance/change-password',
        loadChildren: () => import('app/modules/change-password/change-password.module').
            then(m => m.ChangePasswordModule),
        data: {title: 'Change password'},
    },
    {
        path: ':instance/restore-password',
        loadChildren: () => import('app/modules/restore-password/restore-password.module').
            then(m => m.RestorePasswordModule),
        data: {title: 'Restore password'},
    },
    {
        path: 'not-found',
        component: NotFoundComponent,
        data: {title: '404 Not Found'}
    },
    {
        path: 'forbidden',
        component: ForbiddenComponent,
        data: {title: '403 Forbidden'}
    },
    {
        path: '**',
        component: WildcardRouterDispatcherComponent
    }
];
