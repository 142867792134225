/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';

import { BaseApiClient } from '../../common/index';
import { BaseEntity, Field } from '../../common/meta';
import {
    IInstanceCodeClient, instanceCodeClientT, IParamsBuilder, ISchema, paramsBuilderT
} from '../../common/interfaces';
import { ICreativeAttribute, ICreativeAttributeClient } from './interfaces';
import { AuthHttp } from '../../authentication/authentication.service';

export class CreativeAttribute extends BaseEntity<ICreativeAttribute> implements ICreativeAttribute {

    public static readonly type: string = 'creative_attribute';

    @Field()
    id: string;

    @Field()
    name: string;

    @Field()
    code: number;
}

@Injectable()
export class CreativeAttributeClient extends BaseApiClient<ICreativeAttribute> implements ICreativeAttributeClient {
    protected static classConfig: ISchema = {
        apiResourceName: 'creative_attributes'
    };
    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }


    protected implementationClass() {
        return CreativeAttribute;
    }
}
