import { ICollection, IPersistentApiClient, IResource } from '../common/interfaces';

export interface IRoleTemplate extends IResource {
    name: string;
    kind: string;
}

export type RoleTemplateBelongsToRelationships = never;
export type RoleTemplateHasManyRelationships = never;

export type IRoleTemplateCollection = ICollection<IRoleTemplate>;

export interface IRoleTemplateClient
    extends IPersistentApiClient<IRoleTemplate, RoleTemplateBelongsToRelationships, RoleTemplateHasManyRelationships>
    {}
