/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';
import { ISchema, BaseApiClient } from '../common/index';
import { IExchangeRate, IExchangeRateClient } from './interfaces';
import { BaseEntity, Field } from '../common/meta';
import {
    instanceCodeClientT, IInstanceCodeClient, paramsBuilderT,
    IParamsBuilder
} from '../common/interfaces';
import { AuthHttp } from '../authentication/authentication.service';

export class ExchangeRate extends BaseEntity<IExchangeRate> implements IExchangeRate {

    public static readonly type: string = 'exchange_rate';

    @Field()
    id: string;

    @Field()
    rates: any;

}

@Injectable()
export class ExchangeRateClient extends BaseApiClient<IExchangeRate> implements IExchangeRateClient {
    protected static classConfig: ISchema = {
        apiResourceName: 'exchange_rates'
    };

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return ExchangeRate;
    }
}
