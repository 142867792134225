/* tslint:disable:variable-name */
export { IBaseApiClient } from '../common/interfaces';
import { IRegion } from './interfaces';
import { IResource } from '../common/interfaces';
import { Country } from '../country/country.entity';
import { City } from '../city/city.service';
import { BelongsTo, BaseEntity, Field, HasMany } from '../common/meta';
import { ICity } from '../city/interfaces';
import { ICountry } from '../country/interfaces';

export class Region extends BaseEntity<IRegion> implements IRegion {
    public static readonly type: string = 'region';

    @Field()
    id: string;

    @Field()
    name: string;

    @Field()
    code: string;

    @HasMany({ deferredConstructor: () => City })
    cities: ICity[] | IResource[];

    @BelongsTo({ deferredConstructor: () => Country })
    country: ICountry | IResource;

}
