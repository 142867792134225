<header class="error-page__header">
    <a class="error-logo">
        <img src="/assets/img/logo_hd.png" alt="Platform161">
    </a>
</header>

<div class="row error-container">
    <div class="col-xl-4 col-md-6 col-xs-10 offset-xl-4 offset-md-3 offset-xs-1">
        <h1 class="error-code">404</h1>
        <p class="error-info">
            Sorry, page not found.
        </p>
        <a [routerLink]="['/login']" class="btn-main-icon btn-blue dashboard-icon">Go to login</a>
    </div>
</div>

<div class="error-page__image">
    <img src="/assets/icon/error_page_bg.png" alt="Platform161">
</div>
