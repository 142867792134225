export const STANDARD_REPORT_ACL_OBJECTS = [
    'PacingReportComponent',
    'PerformanceReportComponent',
    'SspReportComponent',
    'VideoReportComponent',
    'VisibilityReportConfigService',
    'DeviceSplitReportComponent',
    'SupplyReportComponent',
    'DomainAppReportComponent',
    'DealIdAnalyzerComponent',
];
