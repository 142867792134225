/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';
import { BasePersistentApiClient } from '../../common/base-api-client';
import { BaseEntity, BelongsTo, Field } from '../../common/meta';
import { ISchema } from '../../common/index';
import {
    IUserTemporaryAccount,
    IUserTemporaryAccountClient,
    UserTemporaryAccountBelongsToRelationships,
    UserTemporaryAccountHasManyRelationships
} from './interfaces';
import {
    IParamsBuilder,
    IInstanceCodeClient,
    instanceCodeClientT,
    paramsBuilderT, IResource
} from '../../common/interfaces';
import { IUser, IAccount } from '../../../src/app/sdk.index';
import { Account } from '../../account/account.service';
import { User } from '../user.entity';
import { BulkRemovable } from '../../common/bulk-removable/bulk-removable';
import { AuthHttp } from '../../authentication/authentication.service';

export class UserTemporaryAccount extends BaseEntity<IUserTemporaryAccount> implements IUserTemporaryAccount {

    public static readonly type: string = 'temporary_account';

    @Field()
    id: string;

    @Field()
    account_name: string;

    @Field()
    expiration_date: string;

    @BelongsTo({ deferredConstructor: () => Account })
    account: IAccount | IResource;

    @BelongsTo({ deferredConstructor: () => User })
    user: IUser | IResource;
}

@Injectable()
@BulkRemovable()
export class UserTemporaryAccountClient
    extends BasePersistentApiClient<IUserTemporaryAccount,
    UserTemporaryAccountBelongsToRelationships,
    UserTemporaryAccountHasManyRelationships>
    implements IUserTemporaryAccountClient {

    protected static classConfig: ISchema = {
        apiResourceName: 'temporary_accounts'
    };

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return UserTemporaryAccount;
    }

}
