/* tslint:disable:variable-name */
import { Inject, Injectable } from '@angular/core';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { map, catchError } from 'rxjs/operators';
import {
    IForecastReport, IMeta
} from './interfaces';
import { IParam, IResource } from '../common/interfaces';

import { Field, BaseEntity, BelongsTo } from '../common/meta';
import { BaseSerializer } from '../common/base-serializer';
import { BasePersistentApiClient } from '../common/index';
import { Grouping, Filters, Measure, Interval, Period, Result } from './types';
import {
    ISchema, instanceCodeClientT, paramsBuilderT, IParamsBuilder, IInstanceCodeClient,
    IRecursiveArray, IRequestOptions
} from '../common/interfaces';
import { BulkRemovable } from '../common/bulk-removable/bulk-removable';
import { Duplicable } from '../common/duplicable/duplicable';
import { AuthHttp } from '../authentication/authentication.service';
import { environment } from '../../src/environments/environment';
import { ReportScheduleType, ReportSendAtType } from '../general-report/types';
import { IReportFormat } from '../../src/app/modules/report/common/interfaces';
import { Serializable } from '../common/interfaces';

@Injectable()
@BulkRemovable()
@Duplicable()
export class ForecastReportClient
    extends BasePersistentApiClient
        <IForecastReport, never, never> {
    protected static classConfig: ISchema = {
        apiResourceName: 'forecasting_reports'
    };

    duplicate: (id: string) => Observable<IForecastReport>;

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return ForecastReport;
    }

    get(id: string, include?: IRecursiveArray<string>, options: IRequestOptions = {}): Observable<IForecastReport> {
        return super.get(id, include, options);
    }

    generate(entity: IForecastReport, params: IParam): Observable<IForecastReport> {
        this.recreateParams();

        this.setImpersonationParams();

        let wrappedEntity = this.wrapAndFilterEntity(entity);
        let data = this.serializer.serialize(wrappedEntity);
        return this.http.post(
            `${ this.apiBase() }/${entity.id}/xlsx_results`, data,
            { params: this.prepareParams(params, {page: 'report_page'}) }
            )
            .pipe(
                map((response: HttpResponse<any>) => {
                    return this.serializer.deserialize(response.body);
                }),
                catchError(this.handleRequestError)
            );
    }


    download(reportId: string, extension: string = 'xlsx', impersonatedUser = null): Observable<any> {
        this.recreateParams();
        let url = `${ this.apiBase() }/${ reportId }/${ extension }_results`;
        if (impersonatedUser) {
            url = url + '/?impersonated_user=' + impersonatedUser;
        }
        return this.http.get(
            url, { responseType: 'blob' }
        )
            .pipe(
                map(
                    (response: HttpResponse<any>) => { return response; }
                ),
                catchError(this.handleRequestError)
            );
    }

    saveAndGenerate(entity: IForecastReport): Observable<IForecastReport> {
        return super.save(entity);
    }

    getResult(reportId: string, params?: IParam): Observable<any> {
        this.recreateParams();
        this.setImpersonationParams();

        return this.http.get(
                `${ this.apiBase() }/${ reportId }/results`,
                { params: this.prepareParams(params, {page: 'report_page'}) }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) => {
                        return this.deserializeWithMeta(response);
                    }
                ),
                catchError(this.handleRequestError)
            );
    }

    saveWithFile(entity: IForecastReport, format: IReportFormat): Observable<IForecastReport> {
        this.customParams = new HttpParams();
        this.customParams = this.customParams.append('generate_file', format);
        return super.save(entity);
    }

    enqueueEmail(reportId: string): Observable<IForecastReport> {
        this.recreateParams();
        let serializer = new BaseSerializer<IForecastReport>(ForecastReport);
        this.setImpersonationParams();
        return this.http.post(`${this.apiBase()}/${reportId}/email`, '', { params: this.prepareParams() })
            .pipe(
                map(
                (response: HttpResponse<any>) => {
                    return serializer.deserialize(response.body);
                }
                ),
                catchError(this.handleRequestError)
            );
    }

    deserializeWithMeta(response: HttpResponse<any>): Serializable<any> {
        let rawData = response.body;
        let meta = rawData.data?.attributes?.meta;
        let serializer = new BaseSerializer<any>(ForecastingReportResult);
        let result = serializer.deserialize(rawData);
        if (!meta) {
            result['meta'] = {
                total_pages: 1,
                total_count: result.data_rows.length,
                current_page: 1,
                next_page: null,
                previous_page: null
            };
        } else {
            result['meta'] = meta;
        }

        return result;
    }

    protected apiBase(): string {
        return `${environment.api.base}/${this.instanceCode}/${this.classConfig().apiResourceName}`;
    }

    private wrapAndFilterEntity(entity): ForecastReport {
        return new (this.implementationClass())(entity);
    }
}

export class ForecastReport extends BaseEntity<IForecastReport> implements IForecastReport {

    public static readonly type: string = 'forecasting_report';

    @Field()
    id: string;

    @Field()
    name: string;

    @Field()
    period: Period;

    @Field()
    date_limit: 'none' | 'range' | 'period';

    @Field()
    interval: Interval;

    @Field()
    start_on: string;

    @Field()
    end_on: string;

    @Field()
    measures: Measure[];

    @Field()
    groupings: Grouping[];

    @Field()
    filters: Filters;

    @Field()
    recipients: string;

    @Field()
    body: string;

    @Field()
    schedule: ReportScheduleType;

    @Field()
    schedule_format: string;

    @Field()
    schedule_start_on: string;

    @Field()
    schedule_end_on: string;

    @Field()
    send_at: ReportSendAtType;

    @Field()
    sort?: string[] | string;

    @Field()
    on_business_days: boolean;

    @Field()
    date: string;

    @Field()
    data_rows?: any[];

    @Field()
    totals?: any[];

    @Field()
    headers?: string[];

    @Field()
    meta?: IMeta;
}


export class ForecastingReportResult extends BaseEntity<any> {
    public static readonly type: string = 'forecasting_report_result';

    @Field()
    id: string;

    @Field()
    headers: string[];

    @Field()
    data_rows: Result[];

    @Field()
    totals: Result[];

    @BelongsTo({ deferredConstructor: () => ForecastReport })
    report: IForecastReport | IResource;

    meta: {
        current_page?: number,
        next_page?: number,
        previous_page?: number,
        total_pages?: number,
        total_count?: number
    };

}
