import { Observable } from 'rxjs/internal/Observable';

export interface IFavoriteItem {
    id: string;
    url: string;
    name: string;
}

export interface IFavoriteProvider<T> {
    loadData(withCache: boolean): Observable<IFavoriteItem[]>;
    addToList(collection: T): void;
    removeFromList(collection: T): void;
}

export interface IFavoriteable<T> {
    favorite(id: string): Observable<T>;
    unfavorite(id: string): Observable<T>;
}
