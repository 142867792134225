import { Observable, of } from 'rxjs';

import { IBulkResponse } from './../errors';

export function BulkRemovable() {

    return function (target) {

        const bulkRemove = function (ids: string[]): Observable<IBulkResponse> {
            // This method has to be implemented in
            // https://jira.platform161.com/browse/PFM-16234
            // [SDK] Implement bulk operations
            return of(null);
        };

        target.prototype.bulkRemove = bulkRemove;

    };

}
