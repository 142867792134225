/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';
import { ISchema } from '../common/index';
import {
    instanceCodeClientT, IInstanceCodeClient, paramsBuilderT,
    IParamsBuilder
} from '../common/interfaces';
import { Field, BaseEntity } from '../common/meta';
import { BasePersistentApiClient } from '../common/base-api-client';
import { AuthHttp } from '../authentication/authentication.service';
import { IEventName } from './interfaces';
import { environment } from '@environments/environment';

export class EventName extends BaseEntity<IEventName> implements IEventName {
    static readonly type: string = 'instance_event_name';

    @Field()
    id: string;

    @Field()
    event_name: string;
}

@Injectable()
export class EventNameClient extends BasePersistentApiClient<IEventName, never, never> {
    protected static classConfig: ISchema = {
        apiResourceName: 'instance_event_names'
    };

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    fieldToDisplay() {
        return 'event_name';
    }

    protected implementationClass() {
        return EventName;
    }

    protected apiBase(): string {
        return `${environment.api.internal}/${this.instanceCode}/${this.classConfig().apiResourceName}`;
    }
}
