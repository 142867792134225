import {
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component, Input, OnInit,
} from '@angular/core';
import { AccessResolverProxy } from '../service/access-resolver-proxy.service';
import { IAclPrivilegeDescriptor } from '../acl.interfaces';

/**
 * Usage
 *
 * ```
 * <acl-privilege [descriptor]="{domains: ['...']}">...</acl-privilege>
 * ```
 */
@Component({
    selector: 'acl-privilege, [acl-privilege]',
    template: `<ng-content *ngIf="render"></ng-content>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrivilegeComponent implements OnInit {

    @Input() descriptor: IAclPrivilegeDescriptor;

    render: boolean = false;

    constructor(private accessResolver: AccessResolverProxy,
                private changeDetector: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        if (!this.descriptor) {
            this.render = true;
            this.changeDetector.markForCheck();
            return;
        }
        this.accessResolver.hasPrivilege(this.descriptor)
            .subscribe(
                (hasAccess: boolean) => {
                    this.render = hasAccess;
                    this.changeDetector.markForCheck();
                }
            )
        ;
    }
}
