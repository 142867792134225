import {
    IBaseEntity, IParam, IParamsConverter, IOrderBy, ISearch, ISearchExpr,
    RelationshipMetadata
} from './interfaces';
import cloneDeep from 'lodash-es/cloneDeep';
import merge from 'lodash-es/merge';

export class ParamsConverter<T> implements IParamsConverter {

    constructor(private namesResolver: IBaseEntity<T>, private resource: string) {

    }

    convert(params?: IParam): IParam {
        let attributes = this.namesResolver.attributes;
        let paramsClone = cloneDeep(params) || {};

        if (paramsClone.order) {
            let orders: IOrderBy[] = [].concat(paramsClone.order);
            orders.forEach((order: IOrderBy) => {
                if (order.by in attributes) {
                    let attribute = attributes[order.by];
                    order.by = attribute.foreignKey;
                }
            });

        }

        if (paramsClone.search) {
            this.prepareSearch(paramsClone.search);
        }

        return paramsClone;
    }

    private prepareSearch(search: ISearch) {
        let attributes = this.namesResolver.attributes;
        let relationships = merge({}, this.namesResolver.belongsTo || {}, this.namesResolver.hasMany || {});
        search.expressions.forEach((expression: ISearchExpr) => {
            let field: string[] = [].concat(expression.field);
            expression.field = this.parseField(field, attributes, relationships, this.resource);
            if (expression.nested) {
                this.prepareSearch(expression.nested);
            }
        });
    }

    private parseField(field: string[],
                       attributes: any,
                       relationships: any,
                       resource: string
    ) {
        let preparedField: string[] = [];
        let len = field.length - 1;
        field.forEach((f: string, index: number) => {
            if (f in attributes && index === len) {
                let key = attributes[f].foreignKey;
                preparedField.push(key ? key : f);
            } else if (f in relationships && index !== len) {
                let relationship: RelationshipMetadata = relationships[f];
                let ctor = relationship.typeResolver();
                let entity = new ctor(null);
                attributes = entity.attributes;
                relationships = merge({}, entity.belongsTo || {}, entity.hasMany || {});
                resource = ctor.type;
                preparedField.push(relationship.foreignKey ? relationship.foreignKey : f);
            } else {
                preparedField.push(f);
            }
        });
        return preparedField;
    }
}
