import { CAMPAIGN_ACL_OBJECTS } from '../../campaign/acl-objects';
import { MEDIA_PLAN_ACL_OBJECTS } from '../../media-plan/acl-objects';
import { AGENCY_ACL_OBJECTS } from '../../agency/acl-objects';
import { ADVERTISER_ACL_OBJECTS } from '../../advertiser/acl-objects';
import { CREATIVE_ACL_OBJECTS } from '../../creative/acl-objects';
import { TARGETING_SET_ACL_OBJECTS } from '../../modules/targeting-set/acl-objects';
import { DIRECT_DEAL_ACL_OBJECTS } from '../../modules/supply/direct-deals/acl-objects';
import { PIXEL_ACL_OBJECTS } from '../../modules/pixel/acl-objects';
import { THIRD_PARTY_DATA_SEGMENT_ACL_OBJECTS } from '../../modules/third-party-data-segment/acl-objects';
import { CUSTOM_DATA_SEGMENT_ACL_OBJECTS } from '../../modules/custom-data-segment/acl-objects';
import { HYPERLOCAL_DATA_SEGMENT_ACL_OBJECTS } from '../../modules/hyperlocal-data-segment/acl-objects';
import { DOMAIN_ACL_OBJECTS } from '../../modules/supply/domain/acl-objects';
import { MOBILE_APS_ACL_OBJECTS } from '../../modules/supply/mobile-apps/acl-objects';
import { PUBLISHER_ACL_OBJECTS } from '../../modules/supply/publisher/acl-objects';
import { SECTION_ACL_OBJECTS } from '../../modules/supply/section/acl-objects';
import { SITE_ACL_OBJECTS } from '../../modules/supply/site/acl-objects';
import { TAG_GENERATION_ACL_OBJECTS } from '../../modules/supply/tag-generation/acl-objects';
import { TRACKING_URL_ACL_OBJECTS } from '../../modules/supply/tracking-url/acl-objects';
import { THIRD_PARTY_TECH_PARTNER_ACL_OBJECTS } from '../../modules/third-party-technology-partner/acl-objects';
import { USER_ACL_OBJECTS } from '../../modules/users/acl-objects';
import { DATA_TARGETING_SET_ACL_OBJECTS } from '../../modules/data-targeting-set/acl-objects';
import { GENERAL_REPORT__ACL_OBJECTS } from '../../modules/report/custom/acl-objects';
import { DASHBOARD_ACL_OBJECTS } from '../../modules/dashboard/acl-objects';
import { MY_REPORT_ACL_OBJECTS } from '../../modules/report/my-report/acl-objects';
import { ISP_ACL_OBJECTS } from '../../modules/internet-service-provider/acl-objects';
import { STANDARD_REPORT_ACL_OBJECTS } from '../../modules/standard-report/acl-objects';
import { INVENTORY_SET_ACL_OBJECTS } from '../../modules/inventory-set/acl-objects';
import { MARKET_ACL_OBJECTS } from '../../modules/market/acl-objects';

export enum AclDomain {
    /**
     * Artificial start
     */
    Any, // is allowed when user has at least one privilege no matter what kind
    Advertiser, // Artificial privilege which is subset of Campaign privilege + user.is_advertiser
    Agency, // Artificial privilege which is subset of Campaign privilege + user.is_advertiser
    /**
     * Artificial end
     */
    Publisher,
    Campaign,
    Pixel,
    GeneralReport,
    CostData,
    InstanceAdmin,
    P161
}

export const ACL_DOMAIN_PRIORITY = {
    [AclDomain.Publisher]: 1,
    [AclDomain.Campaign]: 1,
    [AclDomain.Pixel]: 1,
    [AclDomain.CostData]: 1,
    [AclDomain.GeneralReport]: 1,
    [AclDomain.InstanceAdmin]: 1,
    [AclDomain.Any]: 1,
    [AclDomain.Advertiser]: 1,
    [AclDomain.Agency]: 1,
    [AclDomain.P161]: 10
};

/**
 *
 * Notes, History (Changes) use permission from corresponding resources
 * /users/me?include=roles.privileges - should be accessible by anyone
 */
export const ACL_DOMAIN_OBJECTS = {
    [AclDomain.Any]: [
        ...MOBILE_APS_ACL_OBJECTS, // we need any privilege (there readonly endpoint)
    ],
    [AclDomain.Advertiser]: [ // this is artificial privilege for 'user.is_advertiser' field
        ...ADVERTISER_ACL_OBJECTS,
        ...MARKET_ACL_OBJECTS
    ],
    [AclDomain.Agency]: [ // this is artificial privilege for 'user.is_advertiser' field
        ...AGENCY_ACL_OBJECTS
    ],
    [AclDomain.Publisher]: [
        ...PUBLISHER_ACL_OBJECTS,
        ...SECTION_ACL_OBJECTS,
        ...SITE_ACL_OBJECTS,
        ...TAG_GENERATION_ACL_OBJECTS
    ],
    [AclDomain.Campaign]: [
        ...CAMPAIGN_ACL_OBJECTS,
        ...MEDIA_PLAN_ACL_OBJECTS,
        ...AGENCY_ACL_OBJECTS,
        ...ADVERTISER_ACL_OBJECTS,
        ...CREATIVE_ACL_OBJECTS,
        ...TARGETING_SET_ACL_OBJECTS,
        ...DIRECT_DEAL_ACL_OBJECTS,
        ...DOMAIN_ACL_OBJECTS,
        ...DASHBOARD_ACL_OBJECTS,
        ...ISP_ACL_OBJECTS
    ],
    [AclDomain.Pixel]: [
        ...PIXEL_ACL_OBJECTS,
        ...THIRD_PARTY_DATA_SEGMENT_ACL_OBJECTS,
        ...CUSTOM_DATA_SEGMENT_ACL_OBJECTS,
        ...HYPERLOCAL_DATA_SEGMENT_ACL_OBJECTS,
        ...TRACKING_URL_ACL_OBJECTS,
        ...THIRD_PARTY_TECH_PARTNER_ACL_OBJECTS,
        ...DATA_TARGETING_SET_ACL_OBJECTS
        // to add piggy back url, data_providers,
        // 3rd_party_pixel_tracking - we don't have endpoint, is secured in creative detail
        // campaign_conversion_pixel(url campaign/:id/conversion_pixel), is secured in campaign detail
    ],
    [AclDomain.CostData]: [
        ...THIRD_PARTY_TECH_PARTNER_ACL_OBJECTS,
        // 3rd_party_data_providers - we don't have route for it, see  THIRD_PARTY_DATA_SEGMENT_ACL_OBJECTS
        // general_report_result - it is only about filter out some measures
        // platform_161_tech_fees - we don't have route, it is secured inside campaign and media plan
    ],
    [AclDomain.GeneralReport]: [
        ...GENERAL_REPORT__ACL_OBJECTS,
        ...STANDARD_REPORT_ACL_OBJECTS,
        ...DASHBOARD_ACL_OBJECTS,
        ...MY_REPORT_ACL_OBJECTS,
        // my_report - we don't have such resource but have route where we have tables for both kind of reports
        // (general_report, inventory_research_report) both are secured
    ],
    [AclDomain.InstanceAdmin]: [
        ...USER_ACL_OBJECTS,
        ...INVENTORY_SET_ACL_OBJECTS,
        // privileges - we don't have route for it
    ],
    [AclDomain.P161]: [
        // instances_controller
        // there also some parts only for super admin which we don't have yet in new UI
    ]
};
