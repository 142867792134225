import { ICountry } from '../country/interfaces';
import { ICity } from '../city/interfaces';
import { IBaseApiClient, ICollection, IResource } from '../common/interfaces';

export interface IRegion extends IResource {
    name: string;
    code: string;
    cities: ICity[] | IResource[];
    country: ICountry | IResource;
}

export type IRegionCollection = ICollection<IRegion>;

export interface IRegionClient extends IBaseApiClient<IRegion> {
}
