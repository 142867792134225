/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

import { ISchema, BaseApiClient } from '../common/index';
import {
    IPixelWithTagSettings, IPixelGeneration,
    IPixelGenerationClient, PixelGenerationType, IPixelGenerationResult
} from './interfaces';
import {
    instanceCodeClientT, IInstanceCodeClient,
    paramsBuilderT, IParamsBuilder, IResource
} from '../common/interfaces';
import { BaseEntity, Field, HasMany } from '../common/meta';
import { BaseSerializer } from '../common/base-serializer';
import { Publisher } from '../publisher/publisher.entity';
import { TagGenerationResult } from '../tag-generation/tag-generation.service';
import { ITagGenerationResult } from '../tag-generation/interfaces';
import { IPixel } from '../pixel/interfaces';
import { AuthHttp } from '../authentication/authentication.service';
import { HttpResponse } from '@angular/common/http';

@Injectable()
export class PixelGenerationClient
    extends BaseApiClient<IPixelGeneration>
    implements IPixelGenerationClient {
    protected static classConfig: ISchema = {
        apiResourceName: 'pixel_tags'
    };

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return PixelGeneration;
    }

    generate(entity: IPixelGeneration): Observable<ITagGenerationResult[]> {
        this.recreateParams();
        this.setImpersonationParams();
        let serializer = new BaseSerializer<ITagGenerationResult>(TagGenerationResult);
        let wrappedEntity = new (this.implementationClass())(entity);
        let data = this.serializer.serialize(wrappedEntity);
        return this.http.post(`${this.apiBase()}`, data, { params: this.params })
            .pipe(
                map(
                (response: HttpResponse<any>) => {
                    let deserializeResponse = serializer.deserializeMany(response.body);
                    return [...deserializeResponse] as IPixelGenerationResult[];
                })
            );
    }

    generateBulk(pixels: IPixelWithTagSettings[]): Observable<ITagGenerationResult[]> {
        this.recreateParams();
        this.setImpersonationParams();
        let serializer = new BaseSerializer<ITagGenerationResult>(TagGenerationResult);
        let data = {
            data: {
                type: 'pixel_tags',
                relationships: {
                    pixels: {
                        data: pixels
                    }
                }
            }
        };
        return this.http.post(`${this.apiBase()}`, data, { params: this.params })
            .pipe(
                map(
                (response: HttpResponse<any>) => {
                    let deserializeResponse = serializer.deserializeMany(response.body);
                    return [...deserializeResponse] as IPixelGenerationResult[];
                })
            );
    }
}

export class PixelGeneration extends BaseEntity<IPixelGeneration> implements IPixelGeneration {

    public static readonly type: string = 'pixel_tags';

    @Field()
    id: string;

    @Field()
    generation_type?: PixelGenerationType;

    @Field()
    url_only?: boolean;

    @Field()
    customer_extra?: boolean;

    @Field()
    customer_amount?: boolean;

    @Field()
    customer_id?: boolean;

    @HasMany({ deferredConstructor: () => Publisher, apiRelationshipName: 'pixels' })
    pixels: IPixel[] | IResource[] | IPixelWithTagSettings[];
}

export class PixelGenerationResult extends BaseEntity<IPixelGenerationResult> implements IPixelGenerationResult {
    public static readonly type: string = 'pixel_tags';

    @Field()
    tag: string;
}

