/* tslint:disable:variable-name */
import {
    IResource
} from '../common/interfaces';
import { IPixel } from './interfaces';
import { BaseEntity, Field, BelongsTo } from '../common/meta';
import { Advertiser } from '../advertiser/advertiser.entity';
import { IAdvertiser } from '../advertiser/interfaces';

export class Pixel extends BaseEntity<IPixel> implements IPixel {

    public static readonly type: string = 'pixel';

    @Field()
    id: string;

    @Field()
    name: string;

    @Field()
    type: string;

    @Field()
    unique_users?: number;

    @Field()
    loads?: number;

    @Field()
    post_click_conv?: number;

    @Field()
    postback_tracking?: boolean;

    @BelongsTo({ deferredConstructor: () => Advertiser })
    advertiser: IAdvertiser | IResource;

}
