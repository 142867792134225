import { ICollection, IResource } from '../common/index';
import { ISection } from '../section/interfaces';
import { ICountry } from '../country/interfaces';
import { IPersistentApiClient } from '../common/interfaces';
import { IAdvertiser } from '../advertiser/interfaces';
import { IActivable } from '../common/activable/interfaces';
import { IArchivable } from '../common/archivable/interfaces';

export interface IPublisher extends IResource {
    name: string;
    code: string;
    credit_limit: number;
    db_cr_number: string;
    address: string;
    postal_code: string;
    active: boolean;
    archived: boolean;
    phone: string;
    mobile: string;
    fax: string;
    city: string;
    region: string;
    country: ICountry | IResource;
    email: string;
    is_publisher: boolean;
    filter_anomalies: boolean;
    use_business_address_for_billing: boolean;
    billing_address: string;
    billing_region: string;
    billing_postal_code: string;
    billing_city: string;
    billing_phone: string;
    billing_mobile: string;
    billing_fax: string;
    billing_email: string;
    billing_country: ICountry | IResource;
    sections: ISection[] | IResource[];
    advertiser: IAdvertiser | IResource;
}

export type IPublisherCollection = ICollection<IPublisher>;

export interface IPublisherClient
    extends IPersistentApiClient<IPublisher, never, never>,
    IActivable<IPublisher>,
    IArchivable<IPublisher> { }

