
import { ICollection, IResource, IBaseApiClient } from '../common/interfaces';
import { ICampaign } from '../campaign/interfaces';
import { IAdvertiser } from '../advertiser/interfaces';
import { ISection } from '../section/interfaces';
import { IPublisher } from '../publisher/interfaces';
import { ICreative } from '../creative/interfaces';

export interface ITrackingUrl extends IResource {
    impression_tracking_url: string;
    click_tracking_url: string;
    campaign: ICampaign | IResource;
    advertiser: IAdvertiser | IResource;
    section: ISection | IResource;
    publisher: IPublisher | IResource;
    creative: ICreative[] | IResource[];
}

export type ITrackingUrlCollection = ICollection<ITrackingUrl>;

export interface ITrackingUrlClient
    extends IBaseApiClient<ITrackingUrl> {
}
