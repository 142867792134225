import { ICollection, IBaseApiClient, IResource } from '../common/interfaces';
import { IFeature } from '../feature/interfaces';

export interface IFeatureReminder extends IResource {
    last_shown_at: string;
    feature: IFeature | IResource;
}

export interface IFeatureReminderCollection extends ICollection<IFeatureReminder> { }

export interface IFeatureReminderClient extends IBaseApiClient<IFeatureReminder> { }
