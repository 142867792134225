
export { IBaseApiClient } from '../common/interfaces';

import { IPartialCreative } from './interfaces';
import { CreativeKind } from '../';

import { Size } from '../size/size.service';
import { ISize } from '../size/interfaces';
import { AdServer } from '../ad-server/ad-server.service';
import { IAdServer } from '../ad-server/interfaces';
import { CreativeFile } from '../creative-file/creative-file.service';
import { ICreativeFile } from '../creative-file/interfaces';
import { IResource } from '../common/interfaces';

import { BelongsTo, BaseEntity, Field } from '../common/meta';

export class PartialCreative extends BaseEntity<IPartialCreative> implements IPartialCreative {
    public static readonly type: string = 'partial_creative';

    @Field()
    id: string;

    @Field()
    dma: boolean;

    @Field()
    dma_tracker: boolean;

    @Field()
    postback: boolean;

    @Field()
    kind: CreativeKind;

    @Field()
    click_url: string;

    @Field()
    name: string;

    @BelongsTo({ deferredConstructor: () => AdServer })
    ad_server: IAdServer | IResource;

    @BelongsTo({ deferredConstructor: () => Size })
    size: ISize | IResource;

    @BelongsTo({ deferredConstructor: () => CreativeFile })
    file: ICreativeFile | IResource;
}
