/*tslint:disable*/
import { environment } from '@environments/environment';

const CB_URL = 'http://velocity-staging00.dev161.com/';
const CB_ACCOUNT_ID = 1159;
const DOOH_URL = 'https://doohplanner.dev161.com/';

export function applyFavIconAndTitle(): void {
    let instanceCode = getInstanceCode();
    const host = window.location.hostname;
    if (!host) return null;

    let data = environment && environment.whiteLabelHosts && environment.whiteLabelHosts[host];
    data = data && data[instanceCode] ? {...data, ...data[instanceCode]} : data;
    if (data && data['favIcon']) document.getElementById('appFavicon').setAttribute('href', data['favIcon']);
    if (data && data['title']) document.getElementsByTagName('title')[0].innerHTML = data['title'];
}

export function getInstanceCode(): string {
    let url = window.location.href;
    let regexp = /#\/(\w+)\/login/g;
    let match = regexp.exec(url);
    let instanceCode = match && match[1];
    return instanceCode;
}

export const sharedEnvironment = {
    here_map: {
        api_key: '-SCYaR_W4ohibPv6wxJCJL_YUWYjQZ-Z8DhpMNWS76c',
        app_id: 'ilJTOXJj15sJrgQXtslf'
    },
    cretiveBuilderUrl: CB_URL,
    cretiveBuilderAccountId: CB_ACCOUNT_ID,
    doohPlannerUrl: DOOH_URL,
    whiteLabelHosts: {
        'ui.motusdsp.com': {
            hostname: 'ui.motusdsp.com',
            title: 'Motus DSP',
            instanceCode: 'motus',
            loginLogo: '/assets/whitelabel/motus/img/motus-logo.png',
            loginLogoAltText: 'Motus DSP',
            favIcon: '/assets/whitelabel/motus/img/motus-favicon-32x32.png'
        },
        'dsp.ad-plus.com.tr': {
            hostname: 'dsp.ad-plus.com.tr',
            title: 'AdPlus DSP',
            instanceCode: 'turkcell',
            loginLogo: '/assets/whitelabel/turkcell/img/turkcell-logo.png',
            loginLogoAltText: 'AdPlus DSP',
            favIcon: '/assets/whitelabel/turkcell/img/turkcell-favicon-32x32.png',
            adplushype: {
              instanceCode: 'adplushype'
            }
        }

    }
};
