/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';

import { ISchema, BaseApiClient } from '../common/index';
export { IBaseApiClient } from '../common/interfaces';
import { IDevice, IDeviceClient } from './interfaces';
import {
    instanceCodeClientT, IInstanceCodeClient, IParamsBuilder, paramsBuilderT, IResource
} from '../common/interfaces';
import { Field, BelongsTo, BaseEntity, HasMany } from '../common/meta';
import { AuthHttp } from '../authentication/authentication.service';

export class Device extends BaseEntity<IDevice> implements IDevice {

    public static readonly type: string = 'device';

    @Field()
    id: string;

    @Field()
    code: string;

    @Field()
    name: string;

    @Field()
    order: number;

    @BelongsTo({ deferredConstructor: () => Device })
    parent: IDevice | IResource;

    @HasMany({ deferredConstructor: () => Device })
    versions: IDevice[] | IResource[];

}

@Injectable()
export class DeviceClient extends BaseApiClient<IDevice> implements IDeviceClient {
    protected static classConfig: ISchema = {
        apiResourceName: 'devices'
    };

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return Device;
    }
}
