/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';
import { AuthHttp } from '../authentication/authentication.service';

import { ISchema, BaseApiClient } from '../common/index';
export { IBaseApiClient } from '../common/interfaces';
import { IAccount, IAccountClient } from './interfaces';
import {
    instanceCodeClientT, IInstanceCodeClient, paramsBuilderT, IParamsBuilder,
} from '../common/interfaces';
import { BaseEntity, Field } from '../common/meta';
import { environment } from 'environments/environment';

export class Account extends BaseEntity<IAccount> implements IAccount {

    public static readonly type: string = 'account';

    @Field()
    id: string;

    @Field()
    account_type: string;

    @Field()
    name: string;

}

@Injectable()
export class AccountClient extends BaseApiClient<IAccount> implements IAccountClient {
    protected static classConfig: ISchema = {
        apiResourceName: 'accounts'
    };

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return Account;
    }

    protected apiBase(): string {
        return `${ environment.api.internal }/${ this.instanceCode }/${ this.classConfig().apiResourceName }`;
    }
}
