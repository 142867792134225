import { ICollection, IPersistentApiClient, IResource } from '../common/interfaces';

import { IAdvertiser } from '../advertiser/interfaces';
import { IPiggybackUrl } from '../piggyback-url/interfaces';
import { IArchivable } from './../common/archivable/interfaces';
import { IActivable } from './../common/activable/interfaces';
import { IBulkRemovable } from './../common/bulk-removable/interfaces';
import { IPixel } from '../pixel/interfaces';
import { ICampaign } from '../campaign/interfaces';
import { IRule } from '@platform161-client/rule/interfaces';

export interface IConversionPixel extends IResource {
    name: string;
    conversion_type: string;
    post_view_interval: number;
    post_click_interval?: number;
    post_click_delivery_type: string;
    post_view_delivery_type: string;
    active: boolean;
    archived: boolean;
    deduplication: boolean;
    view_interval?: number;
    loads: number;
    post_view_conversions: number;
    post_click_conversions: number;
    view_delivery_type?: string;
    postback_tracking: boolean;
    sync: boolean;
    cross_device: boolean;
    landing?: boolean;
    event_type?: string;
    advertiser: IResource | IAdvertiser;
    piggyback_urls?: IResource[] | IPiggybackUrl[];
    piggybacks: IPixel[] | IResource[];
    related_campaigns: ICampaign[] | IResource[];
    conversion_logic?: string;
    rules?: IResource[] | IRule[];
}

export type IConversionPixelCollection = ICollection<IConversionPixel>;

export type ConversionPixelBelongsToRelationships = never;
export type ConversionPixelHasManyRelationships = 'piggyback_urls';

export interface IConversionPixelClient
    extends IPersistentApiClient<IConversionPixel,
        ConversionPixelBelongsToRelationships,
        ConversionPixelHasManyRelationships>,
        IArchivable<IConversionPixel>,
        IActivable<IConversionPixel>,
        IBulkRemovable {
}
