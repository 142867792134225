import { ICollection, IPersistentApiClient, IResource } from '../common/interfaces';

import { IAdvertiser } from '../advertiser/interfaces';
import { IPiggybackUrl } from '../piggyback-url/interfaces';
import { IDataProvider } from '../data-provider/interfaces';
import { IArchivable } from './../common/archivable/interfaces';
import { IBulkRemovable } from './../common/bulk-removable/interfaces';
import { IPixel } from '../pixel/interfaces';
import { ICampaign } from '../campaign/interfaces';

export interface IRetargetingPixel extends IResource {
    archived: boolean;
    name: string;
    cpm_price?: number;
    sync: boolean;
    unique_users: number;
    loads: number;
    dynamic_creatives_retargeting?: boolean;
    advertiser: IAdvertiser | IResource;
    campaigns: ICampaign[] | IResource[];
    data_provider: IDataProvider | IResource;
    piggyback_urls?: IResource[] | IPiggybackUrl[];
    piggybacks: IPixel[] | IResource[];
}

export type RetargetingPixelBelongsToRelationships = never;
export type RetargetingPixelHasManyRelationships = 'piggyback_urls';

export interface IRetargetingPixelCollection extends ICollection<IRetargetingPixel> { }

export interface IRetargetingPixelClient
    extends IPersistentApiClient<IRetargetingPixel,
    RetargetingPixelBelongsToRelationships,
    RetargetingPixelHasManyRelationships>,
    IArchivable<IRetargetingPixel>,
    IBulkRemovable { }
