/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';

import { ISchema, BasePersistentApiClient } from '../common/index';
import { BaseEntity, Field } from '../common/meta';
import {
    IInstanceCodeClient, instanceCodeClientT, paramsBuilderT,
    IParamsBuilder
} from '../common/interfaces';

import { ISize, ISizeClient, SizeBelongsToRelationships, SizeHasManyRelationships } from './interfaces';
import { AuthHttp } from '../authentication/authentication.service';

export class Size extends BaseEntity<ISize> implements ISize {

    public static readonly type: string = 'size';

    @Field()
    id: string;

    @Field()
    name: string;

    @Field()
    height: number;

    @Field()
    width: number;

    @Field()
    use_in_meetrics: boolean;

    @Field()
    popular: boolean;
}

@Injectable()
export class SizeClient extends BasePersistentApiClient<ISize, SizeBelongsToRelationships, SizeHasManyRelationships>
    implements ISizeClient {

    protected static classConfig: ISchema = {
        apiResourceName: 'sizes'
    };

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return Size;
    }
}
