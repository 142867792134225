/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';

import { ISchema, BaseApiClient } from '../common/index';
import { ILanguage, ILanguageClient } from './interfaces';
import { BaseEntity, Field } from '../common/meta';
import {
    instanceCodeClientT, IInstanceCodeClient, paramsBuilderT,
    IParamsBuilder
} from '../common/interfaces';
import { AuthHttp } from '../authentication/authentication.service';

export class Language extends BaseEntity<ILanguage> implements ILanguage {

    public static readonly type: string = 'language';

    @Field()
    id: string;

    @Field()
    name: string;

    @Field()
    code: string;

}

@Injectable()
export class LanguageClient extends BaseApiClient<ILanguage> implements ILanguageClient {
    protected static classConfig: ISchema = {
        apiResourceName: 'languages'
    };

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return Language;
    }
}
