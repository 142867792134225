import { IAdvertiser } from '../advertiser/interfaces';
import { IPersistentApiClient, ICollection, IResource } from '../common/interfaces';
import { IArchivable } from './../common/archivable/interfaces';
import { IBulkRemovable } from './../common/bulk-removable/interfaces';

export interface IDataTargetingSet extends IResource {
    archived: boolean;
    deleted: boolean;
    name: string;
    kind: string;
    targeting_logic: string;
    created_at: string;
    updated_at: string;
    max_cpm_price: number;

    advertiser: IAdvertiser | IResource;
}

export type IDataTargetingSetCollection = ICollection<IDataTargetingSet>;

export type DataTargetingSetBelongsToRelationships = never;
export type DataTargetingSetHasManyRelationships = never;

export interface IDataTargetingSetClient
    extends IPersistentApiClient<
        IDataTargetingSet, DataTargetingSetBelongsToRelationships, DataTargetingSetHasManyRelationships
        >,
        IArchivable<IDataTargetingSet>,
        IBulkRemovable {
}
