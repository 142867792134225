import { of as observableOf, Observable, forkJoin } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { IGrouping, IMeasure, IReportOptionAccessDefinition } from '../../common-services/measures/interfaces';
import { AccessResolverHelper } from './helper/access-resolver.service';
import { IdentityStorage, ILoggedInUser } from 'app/modules/common/service/storage/identity-storage';
import { reportingPermissionToNum } from 'app/modules/report/common/reporting-permission-mapper';

@Injectable()
export class ReportOptionFilterService {
    currentUser: ILoggedInUser = <ILoggedInUser>{};
    constructor(
        private accessResolver: AccessResolverHelper,
        private identityStorage: IdentityStorage
    ) {
        this.currentUser = this.identityStorage.getUser();
    }

    filterGrouping(grouping: IGrouping[]): Observable<IGrouping[]> {
        return this.filterReportOption<IGrouping>(grouping);
    }

    filterMeasures(measure: IMeasure[]): Observable<IMeasure[]> {
        return this.filterReportOption<IMeasure>(measure);
    }

    private filterReportOption<T extends IReportOptionAccessDefinition>(reportOptions: T[]): Observable<T[]> {
        let sources$ = [];
        reportOptions.forEach(option => {
            if (option.dashboard_only) {
                sources$.push(observableOf(option.id));
                return;
            }

             // In case there is no "reporting_permission" do not remove any measures
            if (this.currentUser.reporting_permission && (reportingPermissionToNum(option.reporting_permission) >
                reportingPermissionToNum(this.currentUser.reporting_permission))) {
                let source = observableOf(option.id);
                sources$.push(source);
                // return skips the current forEach iteration
                return;
            }
            if (option.feature) {
                if (!Array.isArray(option.feature)) {
                    option.feature = [option.feature];
                }
                option.feature.forEach((feature) => {
                    let source = this.accessResolver.hasFeature(feature).pipe(map(
                        (hasAccess) => {
                            return hasAccess ? '' : option.id;
                        }
                    ));
                    sources$.push(source);
                });
            }

            if (option.privilege) {
                let source = this.accessResolver.hasPrivilege(option.privilege).pipe(map(
                    (hasAccess) => {
                        return hasAccess ? '' : option.id;
                    }
                ));
                sources$.push(source);
            }
        });

        if (!sources$.length) {
            return observableOf(reportOptions);
        }

        return forkJoin(sources$)
            .pipe(map((data: string[]) => reportOptions.filter(x => data.indexOf(x.id) === -1)), take(1));
    }
}
