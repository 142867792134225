/* tslint:disable:variable-name */
import { BaseEntity, BelongsTo, Field } from '../../../common/meta';
import { ThirdPartyDataProvider } from '../../third-party-data-provider.entity';
import { IResource } from '../../../common/interfaces';
import { IThirdPartyDataProvider } from '../../interfaces';
import { IThirdPartyDataSegmentCategory } from './interfaces';

export class ThirdPartyDataSegmentCategory
    extends BaseEntity<IThirdPartyDataSegmentCategory> implements IThirdPartyDataSegmentCategory {

    public static readonly type: string = 'third_party_data_segment';

    @Field()
    id: string;

    @Field()
    name: string;

    @BelongsTo({ deferredConstructor: () => ThirdPartyDataProvider })
    third_party_data_provider: IThirdPartyDataProvider | IResource;
}
