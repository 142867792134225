import { ICollection, IPersistentApiClient, IResource } from '../../common/interfaces';
import { IDomain } from '../../domain/interfaces';
import { IDomainSet } from '../../domain-set/interfaces';
import { IInventorySource } from '../../inventory-source/interfaces';

export interface ICampaignInventorySource extends IResource {
    domain_filter: string;
    inventory_source: IInventorySource | IResource;
    rtb_domain_sets: IDomainSet[] | IResource[];
    rtb_domains: IDomain[] | IResource[];
}

export type ICampaignInventorySourceCollection = ICollection<ICampaignInventorySource>;

export type CampaignInventorySourceBelongsToRelationships = never;
export type CampaignInventorySourceHasManyRelationships = never;

export interface ICampaignInventorySourceClientFactory {
    createCampaignInventorySourceClient(campaignId: string): ICampaignInventorySourceClient;
}

export interface ICampaignInventorySourceClient
    extends IPersistentApiClient<ICampaignInventorySource,
        CampaignInventorySourceBelongsToRelationships,
        CampaignInventorySourceHasManyRelationships> {
}
