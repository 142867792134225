/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';

import { ISchema, BaseApiClient } from '../common/index';
export { IBaseApiClient } from '../common/interfaces';
import { IGridView, IGridViewClient, ResourceName } from './interfaces';
import {
    instanceCodeClientT, IInstanceCodeClient, paramsBuilderT,
    IParamsBuilder
} from '../common/interfaces';
import { BaseEntity, Field } from '../common/meta';
import { AuthHttp } from '../authentication/authentication.service';
import { environment } from 'environments/environment';

export class GridView extends BaseEntity<IGridView> implements IGridView {

    public static readonly type: string = 'grid_view';

    @Field()
    id: string;

    @Field()
    name: string;

    @Field()
    resource_name: ResourceName;

    @Field()
    columns: string[];

}

@Injectable()
export class GridViewClient extends BaseApiClient<IGridView> implements IGridViewClient {
    protected static classConfig: ISchema = {
        apiResourceName: 'grid_views'
    };

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return GridView;
    }

    protected apiBase(): string {
        return `${ environment.api.internal }/${ this.instanceCode }/${ this.classConfig().apiResourceName }`;
    }

}
