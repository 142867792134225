import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { map, catchError } from 'rxjs/operators';

export function Duplicable<T>() {

    return function (target) {

        const duplicate = function (id: string, params = null): Observable<T> {
            let options = this.prepareRequestOptionsForDecorators();
            let data = {};
            if (params) {
                data['data'] = {
                    type: 'campaign',
                    id: id,
                    attributes: params
                };
            }

            return this.http.post(`${this.apiBase()}/${id}/duplicate`, data, options)
                .pipe(
                    map(
                        (response: HttpResponse<any>) => this.serializer.deserialize(response.body)
                    ),
                    catchError(this.handleRequestError)
                );
        };

        target.prototype.duplicate = duplicate;
    };
}
