import {
    ICollection, IResource,
    IBaseApiClient, IParam, IRecursiveArray
} from '../common/interfaces';

import { IUser } from '../user/interfaces';
import { IComment, IPersistentComment } from '../comment/interfaces';
import { Observable } from 'rxjs/internal/Observable';

export interface IChange extends IResource {
    created_at: string;
    name: string;
    from?: string | number;
    to?: string | number;
    user: IResource | IUser;
    resource_names_to?: Array<string>;
    resource_names_from?: Array<string>;
    comments?: IResource[] | IComment[];
}

export type IChangeCollection = ICollection<IChange>;

export interface IChangeClient extends IBaseApiClient<IChange> {}

export interface IChangeable {
    getChanges(changeableId: string, params?: IParam, include?: IRecursiveArray<string>): Observable<IChangeCollection>;
    addChangeComment(comment: IPersistentComment, changeableId: string): Observable<IComment>;
}
