/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';

import { ISchema, BaseApiClient } from '../common/index';
export { IBaseApiClient } from '../common/interfaces';
import { IInventorySource, IInventorySourceClient } from './interfaces';
import { BaseEntity, Field, HasMany, DefaultTransforms } from '../common/meta';
import {
    instanceCodeClientT, IInstanceCodeClient, paramsBuilderT,
    IParamsBuilder, IResource
} from '../common/interfaces';
import { Campaign } from '../campaign/campaign.entity';
import { Country } from '../country/country.entity';
import { RtbSeat } from '../rtb-seat/rtb-seat.service';
import { ICampaign } from '../campaign/interfaces';
import { ICountry } from '../country/interfaces';
import { IRtbSeat } from '../rtb-seat/interfaces';
import { AuthHttp } from '../authentication/authentication.service';

export class InventorySource extends BaseEntity<IInventorySource> implements IInventorySource {

    public static readonly type: string = 'inventory_source';

    @Field()
    id: string;

    @Field()
    name: string;

    @Field({ apiFieldName: 'company_summary' })
    company_summary: string;

    @Field({ apiFieldName: 'creative_policy_and_guidelines' })
    creative_policy_and_guidelines: string;

    @Field({ apiFieldName: 'deal_id_and_pmp' })
    deal_id_and_pmp: string;

    @Field()
    archived: boolean;

    @Field()
    code: string;

    @Field({ apiFieldName: 'creative_policy_and_guidelines' })
    inventory_type: string;

    @Field()
    type: string;

    @Field({ apiFieldName: 'ipw_key' })
    ipw_key: string;

    @Field()
    bidswitch: boolean;

    @Field({ apiFieldName: 'bidswitch_fee' })
    bidswitch_fee: number;

    @Field({ apiFieldName: 'deal_id_enabled' })
    deal_id_enabled: boolean;

    @Field()
    email: string;

    @Field()
    url: string;

    @Field()
    digital_out_of_home: boolean;

    @Field({ apiFieldName: 'early_exit' })
    early_exit: boolean;

    @Field()
    rate: number;

    @Field({ apiFieldName: 'country_filter' })
    country_filter: string;

    @Field({ apiFieldName: 'user_status' })
    user_status: string;

    @Field({ apiFieldName: 'gbq_key' })
    gbq_key: string;

    @Field({ apiFieldName: 'has_requirements_to_be_enabled' })
    has_requirements_to_be_enabled: boolean;

    @Field({ apiFieldName: 'traffic_type' })
    traffic_type: string;

    @Field({ apiFieldName: 'size_filter' })
    size_filter: string;

    @Field({ apiFieldName: 'deal_id_filter' })
    deal_id_filter: string;

    @Field({ apiFieldName: 'apply_to_deals' })
    apply_to_deals: boolean;

    @Field({
        fromApi: DefaultTransforms.ToArrayOfNumbers,
        apiFieldName: 'early_exit_direct_deal_ids',
        toApi: DefaultTransforms.ToArrayOfStrings
    })
    early_exit_direct_deal_ids: number[];

    @Field()
    dooh_provider: string;

    @Field({ apiFieldName: 'enabled_for_all_campaigns' })
    enabled_for_all_campaigns: boolean;

    @HasMany({ deferredConstructor: () => Campaign })
    campaigns: ICampaign[] | IResource[];

    @HasMany({ deferredConstructor: () => Country })
    countries: ICountry[] | IResource[];

    @HasMany({ deferredConstructor: () => RtbSeat })
    rtb_seats: IRtbSeat[] | IResource[];
    /*

    @HasMany({ deferredConstructor: () => DirectDeal, apiRelationshipName: 'direct_deals' })
    direct_deals: IDirectDeal[] | IResource[];

    @HasMany({ deferredConstructor: () => PremiumInventory, apiRelationshipName: 'premium_inventories' })
    premium_inventories: IPremiumInventory[] | IResource[];

    @HasMany({ deferredConstructor: () => Size })
    sizes: ISize[] | IResource[];


    */
}

@Injectable()
export class InventorySourceClient extends BaseApiClient<IInventorySource> implements IInventorySourceClient {
    protected static classConfig: ISchema = {
        apiResourceName: 'inventory_sources'
    };

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return InventorySource;
    }
}
