import { Component } from '@angular/core';

@Component({
    selector: 'not-found',
    templateUrl: './not-found.template.html',
    host: {
        class: 'error-page app'
    },
})
export class NotFoundComponent {}
