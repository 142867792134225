import { Injectable, Inject } from '@angular/core';

import {
    instanceCodeClientT,
    IInstanceCodeClient,
    paramsBuilderT,
    IParamsBuilder,
} from '../common/interfaces';
import { ISchema, BasePersistentApiClient } from '../common/index';
import { IUserPreference, IUserPreferenceClient } from './interfaces';
import { AuthHttp } from '../authentication/authentication.service';
import { environment } from 'environments/environment';
import { UserPreference } from './user-preference.entity';

@Injectable()
export class UserPreferenceClient extends BasePersistentApiClient<IUserPreference> implements IUserPreferenceClient {
    protected static classConfig: ISchema = {
        apiResourceName: 'user_preferences'
    };

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return UserPreference;
    }

    protected apiBase(): string {
        return `${environment.api.internal}/${this.instanceCode}/${this.classConfig().apiResourceName}`;
    }

}
