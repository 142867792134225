/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';

import { ISchema, BasePersistentApiClient } from '../common/index';
import { BaseEntity, Field, BelongsTo, HasMany } from '../common/meta';
import {
    IInstanceCodeClient, instanceCodeClientT, paramsBuilderT,
    IParamsBuilder
} from '../common/interfaces';

import {
    IPiggybackUrl, IPiggybackUrlClientFactory, IPiggybackUrlClient, PiggybackUrlBelongsToRelationships,
    PiggybackUrlHasManyRelationships, PiggybackUrlParentType
} from './interfaces';
import { AuthHttp } from '../authentication/authentication.service';
import { environment } from 'environments/environment';

export class PiggybackUrl extends BaseEntity<IPiggybackUrl> implements IPiggybackUrl {

    public static readonly type: string = 'piggyback_url';

    @Field()
    id: string;

    @Field()
    url: string;

    @Field()
    delivery_type: string;
}

@Injectable()
export class PiggybackUrlClientFactory implements IPiggybackUrlClientFactory {

    constructor(
        private http: AuthHttp,
        @Inject(instanceCodeClientT) private instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) private paramsBuilder: IParamsBuilder
    ) {
    }

    createPiggybackUrlClient(
        pixelType: PiggybackUrlParentType,
        pixelId: string
    ): PiggybackUrlClient {
        return new PiggybackUrlClient(
            pixelType,
            pixelId,
            this.http,
            this.instanceCodeClient,
            this.paramsBuilder);
    }
}

export class PiggybackUrlClient
    extends BasePersistentApiClient<IPiggybackUrl, PiggybackUrlBelongsToRelationships, PiggybackUrlHasManyRelationships>
    implements IPiggybackUrlClient {

    protected static classConfig: ISchema = {
        apiResourceName: 'piggyback_urls'
    };

    protected getParentPixelResourceSegment(pixelType: PiggybackUrlParentType): string {
        switch (pixelType) {
        case PiggybackUrlParentType.ConversionPixel:
            return 'conversion_pixels';
        case PiggybackUrlParentType.RetargetingPixel:
            return 'retargeting_pixels';
        default:
            throw new Error('PiggybackURLClient needs a pixelType to work: no details for parent pixel provided');
        }
    }

    protected apiBase(): string {
        let parentResourceName = this.getParentPixelResourceSegment(this.pixelType);
        return `${environment.api.base}/${this.instanceCode}/${parentResourceName}/${this.pixelId}/${this.classConfig().apiResourceName}`;
    }

    constructor(
        private pixelType: PiggybackUrlParentType,
        private pixelId: string,
        http: AuthHttp,
        instanceCodeClient: IInstanceCodeClient,
        paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return PiggybackUrl;
    }
}
