
import { Observable, throwError, of } from 'rxjs';
import { map, delay, catchError } from 'rxjs/operators';
import {
    IResource, IBaseApiClient, ICollection, IParam, IRecursiveArray, IBasePersistentApiClient, IRequestOptions
} from '@platform161-client';
import { SimpleCache } from './simple-cache';

export function isLoadedResource<T extends IResource>(resource: IResource): resource is T {
    return resource && resource.loaded === true;
}

export function isLoadedArrayOfResources<T extends IResource>(resources: IResource[]): resources is T[] {
    return Array.isArray(resources) && resources.length && isLoadedResource(resources[0]);
}

export abstract class Resource<T extends IResource> {
    protected cache: SimpleCache;

    constructor(public resource: T, cache?: SimpleCache) {
        this.cache = cache ? cache : new SimpleCache();
    }

    copyResource(): T {
        return this.getClient().cloneEntity(this.resource);
    }

    isIncluded(relationName: string, includeWhenEmpty: boolean = false): boolean {
        let relation = this.resource[relationName] || null;
        let isEmptyArray = Array.isArray(relation) && relation.length === 0;

        if (!relation || isEmptyArray) {
            // it means that data not exits and we should return true
            // but we can also force and try to include when empty

            return !includeWhenEmpty;
        }

        if (Array.isArray(relation) && relation.length) {
            relation = relation[0];
        }

        if (Object.keys(relation).length < 2) {
            // it means that we probably have only id
            return false;
        }

        return relation.loaded || false;
    }

    include(relationNames: (Extract<keyof T, string>)[], includeWhenEmpty: boolean = false): Observable<T> {
        let toInclude = [];
        relationNames.forEach(relationName => {
            if (!this.isIncluded(relationName, includeWhenEmpty)) {
                toInclude.push(relationName);
            }
        });

        if (toInclude.length === 0) {
            return of(this.resource).pipe(delay(0));
        }

        return this.getClient().get(this.resource.id, toInclude, {ignoreMandatoryIncludes: true})
            .pipe(
                map(
                    (loadedResource: T) => {
                        toInclude.forEach(relationName => {
                            this.resource[relationName] = loadedResource[relationName];
                        });

                        return this.resource;
                    }
                )
            );
    }

    protected fetchRelationHasMany<R extends IResource, U extends ICollection<R>>(
        relationName: string,
        client: IBaseApiClient<R>,
        limit?: number,
        offset?: number
    ): Observable<ICollection<R>> {
        if (!this.resource.hasOwnProperty(relationName)) {
            return throwError(`Unknown relation name ${relationName}`);
        }

        if (this.isIncluded(relationName)) {
            return of(<U> {
                data: <R[]>this.resource[relationName] || [],
                meta: {
                    current_page: 1,
                    next_page: null,
                    previous_page: null,
                    total_pages: 1,
                    total_count: (this.resource[relationName] || []).length
                }
            });
        }

        return this.includeRelationHasMany(relationName, client, limit, offset);
    }

    protected includeRelationHasMany<R extends IResource, U extends ICollection<R>>(
        relationName: string,
        client: IBaseApiClient<R>,
        limit?: number,
        offset?: number,
        include?: IRecursiveArray<string>
    ): Observable<ICollection<R>> {
        let relation: IResource[] = this.resource[relationName];

        if (!Array.isArray(relation)) {
            return throwError(`Relation ${relationName} is not an array`);
        }

        let ids = relation.map(item => item.id);
        if (ids.length === 0) {
            return of(<U> {
                data: <R[]>[],
                meta: {
                    current_page: 1,
                    next_page: null,
                    previous_page: null,
                    total_pages: 1,
                    total_count: 0
                }
            });
        }

        let param: IParam = {
            search: {
                operation: 'and',
                expressions: [
                    {
                        field: 'id',
                        cond: {
                            'in': ids.join(',')
                        }
                    }
                ]
            },
            limit: limit || ids.length,
            offset: offset || 0
        };

        return this.cacheRelation<U>(relationName, param, (query: IParam) => client.getAll(query), include);
    }

    protected fetchRelationBelongsTo<R extends IResource>(
        relationName: string,
        client: IBaseApiClient<R>,
    ): Observable<R> {
        if (!this.resource.hasOwnProperty(relationName)) {
            return throwError(`Unknown relation name ${relationName}`);
        }

        if (this.isIncluded(relationName)) {
            return of(this.resource[relationName]);
        }

        let relation: IResource = this.resource[relationName];
        if (Array.isArray(relation)) {
            return throwError(`Relation ${relationName} is not a scalar but array`);
        }

        return this.cacheRelation<R>(relationName, relation.id, (query) => client.get(query));
    }

    /**
     * @param {string} id
     * @param {string[]} field - for example if we want count creatives by campaign id - then ['campaigns', 'id']
     * @param {IBaseApiClient<R extends IResource>} client - as above here we need CreativeClient
     * @returns {Observable<number>}
     */
    protected countById<R extends IResource>(
        id: string, field: string[], client: IBaseApiClient<R>
    ): Observable<number> {
        let params: IParam = {
            search: {
                operation: 'and',
                expressions: [
                    {
                        cond: {'=': id},
                        field: field
                    }
                ]
            },
            offset: 0,
            limit: 1,
        };
        let options: IRequestOptions = {
            ignoreMandatoryIncludes: true
        };

        return client.getAll(params, null, options)
            .pipe(map((collection) => collection.meta.total_count));
    }

    protected abstract getClient(): IBaseApiClient<T>;

    private cacheRelation<R>(
        relationName: string,
        param: string | IParam,
        callback,
        include?: IRecursiveArray<string>
    ): Observable<R> {
        let key = this.cache.createKey(relationName, param);
        if (this.cache.exists(key)) {
            return this.cache.getResult(key);
        }

        this.cache.setResult(key, callback(param, include));

        return this.cache.getResult(key);
    }
}

export abstract class PersistentResource<T extends IResource> extends Resource<T> {
    private resourceCopy: T;

    constructor(public resource: T, client: IBasePersistentApiClient<T>, cache?: SimpleCache) {
        super(resource, cache);
        this.resourceCopy = client.cloneEntity(this.resource);
    }

    save(includes?: IRecursiveArray<string>, options: IRequestOptions = {}): Observable<T> {
        return this.getClient().save(this.resource, includes, options)
            .pipe(
                map(resource => {
                    this.cache.clear();
                    Object.assign(this.resource, resource);
                    this.resourceCopy = this.copyResource();

                    return this.resource;
                }),
                catchError(
                    (error) => {
                        Object.assign(this.resource, this.resourceCopy);
                        return throwError(error);
                    }
                )
            );
    }

    savePartial(
        partialResource: Partial<T>,
        includes?: IRecursiveArray<string>,
        options: IRequestOptions = {},
        propsToUpdate: string[] = [],
    ): Observable<T> {

        partialResource.id = this.resource.id;
        return this.getClient().save(<T>partialResource, includes, options)
            .pipe(
                map(resource => {
                    this.cache.clear();

                    let updatePartialResource = {};
                    Object.keys(partialResource).forEach(key => {
                        updatePartialResource[key] = resource[key];
                    });

                    if (includes && Array.isArray(includes)) {
                        includes.forEach(prop => {
                            if (typeof prop === 'string' && prop in resource) {
                                updatePartialResource[prop] = resource[prop];
                            }
                        });
                    }

                    // if includes name is different than resource prop name
                    // eg. prop: creative.file vs. include: 'creative_file'
                    propsToUpdate.forEach(prop => {
                        if (prop in resource) {
                            updatePartialResource[prop] = resource[prop];
                        }
                    });

                    Object.assign(this.resource, updatePartialResource);
                    this.resourceCopy = this.copyResource();

                    return this.resource;
                }),
                catchError(
                    (error) => {
                        Object.assign(this.resource, this.resourceCopy);
                        return throwError(error);
                    }
                )
            );
    }

    protected abstract getClient(): IBasePersistentApiClient<T>;
}
