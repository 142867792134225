
import { map } from 'rxjs/operators';
import { forwardRef, Inject, Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';

import { PrivilegeAccessResolver } from './access-resolver/privilege';
import { IAclPrivilegeDescriptor } from '../acl.interfaces';

type RedirectMap = {[url: string]: IAclPrivilegeDescriptor};
type ResolverResult = {url: string, hasAccess: boolean};

@Injectable()
export class RedirectUrlResolverService {

    private readonly REDIRECT_URL_TO_DESCRIPTOR_MAP: RedirectMap = {
        '/app/dashboard': { domains: ['Campaign.view'], matchAll: false},
        '/app/campaign': { domains: ['Campaign.view'], matchAll: false},
        '/app/report/my-report': { domains: ['GeneralReport.view'], matchAll: false},
        '/app/supply/publisher': { domains: ['Publisher.view'], matchAll: false},
        '/app/pixel': { domains: ['Pixel.view'], matchAll: false},
        '/app/supply/mobile-apps': { domains: ['Any.view'], matchAll: false},
    };

    constructor(
        @Inject(forwardRef(() => PrivilegeAccessResolver)) private accessResolver: PrivilegeAccessResolver
    ) {}

    public resolve(): Observable<string> {
        let subscriptions = Object.keys(this.REDIRECT_URL_TO_DESCRIPTOR_MAP).map(url => {
            return this.accessResolver.resolveFromDescriptor(this.REDIRECT_URL_TO_DESCRIPTOR_MAP[url]).pipe(map(
                (hasAccess: boolean) => {
                    return {
                        url,
                        hasAccess
                    };
                }
            ));
        });

        return forkJoin(subscriptions)
            .pipe(
                map(
                (results: ResolverResult[]) => {
                        let foundResult = results.find(result => result.hasAccess === true);
                        return foundResult ? foundResult.url : '/forbidden';
                    }
                )
        );
    }
}
