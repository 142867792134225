/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';

import { IDoohNetwork, IDoohNetworkClient } from './interfaces';
import { IDoohScreen } from '../dooh_screen/interfaces';
import { DoohScreen } from '../dooh_screen/dooh-screen.service';
import { BaseEntity, Field, HasMany } from '../common/meta';
import { ISchema, BaseApiClient } from '../common/index';
import { IResource } from '../common/interfaces';

import { instanceCodeClientT, IInstanceCodeClient, paramsBuilderT, IParamsBuilder } from '../common/interfaces';
import { AuthHttp } from '../authentication/authentication.service';

export class DoohNetwork extends BaseEntity<IDoohNetwork> implements IDoohNetwork {

    public static readonly type: string = 'dooh_network';

    @Field()
    id: string;

    @Field()
    name: string;

    @Field()
    network_id: number;

    @Field()
    provider: string;

    @Field()
    active: boolean;

    @HasMany({ deferredConstructor: () => DoohScreen })
    dooh_screens: IDoohScreen[] | IResource[];

}

@Injectable()
export class DoohNetworkClient extends BaseApiClient<IDoohNetwork> implements IDoohNetworkClient {
    protected static classConfig: ISchema = {
        apiResourceName: 'dooh_networks'
    };

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return DoohNetwork;
    }

}
