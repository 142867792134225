import { ICollection, IBaseApiClient, IResource } from '../common/interfaces';

export interface IDeviceType extends IResource {
    code: string;
}

export type IDeviceTypeCollection = ICollection<IDeviceType>;

export interface IDeviceTypeClient extends IBaseApiClient<IDeviceType> {
}
