import { ICollection, IPersistentApiClient, IResource } from '../common/interfaces';
import { IBulkRemovable } from './../common/bulk-removable/interfaces';
import { LatLongTargeting } from '../campaign/interfaces';
import { TemporalTargeting } from '../targeting-default/interfaces';
import { ILanguage } from '../language/interfaces';
import { ICampaign } from '../campaign/interfaces';
import { IThirdPartyDataProvider } from '../third-party-data-provider/interfaces';

export interface IHyperlocalDataSegment extends IResource {
    campaign: ICampaign;
    campaign_ids: string[];
    city_filter: string;
    code: string;
    country_filter: string;
    cpm_price: number;
    end_on: string;
    end_on_date?: string;
    end_on_hour?: string;
    end_on_min?: string;
    languages: ILanguage[] | IResource[];
    lat_long_only?: boolean;
    lat_long_targeting?: LatLongTargeting[];
    name: string;
    region_filter: string;
    start_on: string;
    start_on_date?: string;
    start_on_hour?: string;
    start_on_min?: string;
    temporal_targeting?: TemporalTargeting;
    unique_users: number;
    third_party_data_provider: IThirdPartyDataProvider | IResource;
}

export type HyperlocalDataSegmentBelongsToRelationships = never;
export type HyperlocalDataSegmentHasManyRelationships = never;

export interface IHyperlocalDataSegmentCollection extends ICollection<IHyperlocalDataSegment> { }

export interface IHyperlocalDataSegmentClient
    extends IPersistentApiClient<IHyperlocalDataSegment,
    HyperlocalDataSegmentBelongsToRelationships,
    HyperlocalDataSegmentHasManyRelationships>,
    IBulkRemovable { }
