import { Observable } from 'rxjs/internal/Observable';
import { map, tap, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { PersistentResource } from './resource';
import { SimpleCache } from './simple-cache';
import {
    IUser, UserClient, IUserClient, IRecursiveArray, IUserTemporaryAccount,
    UserTemporaryAccountClient, IUserTemporaryAccountCollection,
    RoleClient, IRole, IRoleCollection,
    IPrivilege, IParam
} from '../sdk.index';
import { IRequestOptions } from '../../../platform161-client/common/interfaces';

@Injectable()
export class UserProxy {
    constructor(
        public userClient: UserClient,
        public roleClient: RoleClient,
        public userTemporaryAccountClient: UserTemporaryAccountClient
    ) { }
}

@Injectable()
export class UserResourceFactory {
    private cache: SimpleCache;

    constructor(private proxy: UserProxy) {
        this.cache = new SimpleCache();
    }

    createFromResource(resource: IUser): UserResource {
        return this.create(resource);
    }

    createFromResourceId(id: string, include?: IRecursiveArray<string>): Observable<UserResource> {
        return this.proxy.userClient.get(id, include)
            .pipe(map(user => this.create(user)));
    }

    createFromLoggedInUser(): Observable<UserResource> {
        return this.proxy.userClient.me(['roles.privileges', 'user_preferences'])
            .pipe(map((user: IUser) => this.create(user)));
    }

    private create(resource: IUser): UserResource {
        return new UserResource(resource, this.proxy, this.cache);
    }
}

export class UserResource extends PersistentResource<IUser> {
    constructor(resource: IUser, public proxy: UserProxy, cache: SimpleCache) {
        super(resource, proxy.userClient, cache);
    }

    save(): Observable<IUser> {

        return this.proxy.userClient.save(this.resource, ['account'])
            .pipe(
                tap(resource => {
                    this.cache.clear();
                    this.resource = resource;
                })
            );
    }

    saveTemporaryAccount(data: IUserTemporaryAccount): Observable<IUserTemporaryAccount> {
        return this.proxy.userTemporaryAccountClient.save(data);
    }

    getTemporaryAccounts(
        params?: IParam,
        include?: IRecursiveArray<string>
    ): Observable<IUserTemporaryAccountCollection> {
        return this.proxy.userTemporaryAccountClient.getAll(params, include);
    }

    getRoles(): Observable<IRole[]> {
        return this.fetchRelationHasMany<IRole, IRoleCollection>('roles', this.proxy.roleClient)
            .pipe(map((collection: IRoleCollection) => collection.data));
    }

    getPrivileges(): Observable<IPrivilege[]> {
        return (<IUserClient>this.proxy.userClient).get(this.resource.id, ['roles.privileges'])
            .pipe(
                map((user: IUser) => {
                    let privileges: IPrivilege[] = [];
                    for (let role of user.roles) {
                        privileges = privileges.concat((<IPrivilege[]>(<IRole>role).privileges));
                    }
                    return privileges;
                }),
                catchError(() => [])
            );
    }

    findPrivilege(domainObject: string, action?: string): Observable<IPrivilege | null> {
        return this.getPrivileges()
            .pipe(
                map(
                    (privileges: IPrivilege[]) => {
                        return privileges.find(privilege => {
                            let match = privilege.domain_object === domainObject;
                            if (match && action) {
                                match = privilege.action === action;
                            }

                            return match;
                        });
                    }
                )
            );
    }

    protected getClient(): IUserClient {
        return this.proxy.userClient;
    }
}

@Injectable()
export class UserFactory {
    protected cacheKey: string = '_USER_';
    private cache: SimpleCache;

    constructor(private proxy: UserProxy) {
        this.cache = new SimpleCache();
    }

    getUser(include?: IRecursiveArray<string>, options?: IRequestOptions): Observable<IUser> {
        return this.proxy.userClient.me(include, options)
            .pipe(tap(user => {
                localStorage.setItem('notification-count', String(user.notification_count));
                this.cache.setResolvedResult(this.cacheKey, user);
            }));
    }

    getUserFromCache(include?: IRecursiveArray<string>, options?: IRequestOptions): Observable<IUser> {
        if (this.cache.exists(this.cacheKey)) {
            return this.cache.getResult(this.cacheKey);
        }

        return this.getUser(include, options);
    }
}
