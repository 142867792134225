import { ICollection, IBaseApiClient, IResource } from '../common/interfaces';
import { ICampaign } from '../campaign/interfaces';
import { ICountry } from '../country/interfaces';
import { IRtbSeat } from '../rtb-seat/interfaces';

export interface IInventorySource extends IResource {
    name: string;
    company_summary: string;
    creative_policy_and_guidelines: string;
    deal_id_and_pmp: string;
    archived: boolean;
    code: string;
    inventory_type: string;
    type: string;
    ipw_key: string;
    bidswitch: boolean;
    bidswitch_fee: number;
    deal_id_enabled: boolean;
    email: string;
    url: string;
    digital_out_of_home: boolean;
    early_exit: boolean;
    rate: number;
    country_filter: string;
    user_status: string;
    gbq_key: string;
    has_requirements_to_be_enabled: boolean;
    traffic_type: string;
    size_filter: string;
    deal_id_filter: string;
    apply_to_deals: boolean;
    early_exit_direct_deal_ids: number[];
    campaigns: ICampaign[] | IResource[];
    countries: ICountry[] | IResource[];
    rtb_seats: IRtbSeat[] | IResource[];
    dooh_provider: string;
    enabled_for_all_campaigns: boolean;
}

export type IInventorySourceCollection = ICollection<IInventorySource>;

export interface IInventorySourceClient extends IBaseApiClient<IInventorySource> {
}
