import { Injectable, Inject } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { map, catchError } from 'rxjs/operators';

import { ISchema, BasePersistentApiClient } from '../common/index';
import {
    IAdvertiser, IAdvertiserClient, AdvertiserHasManyRelationships,
    AdvertiserBelongsToRelationships
} from './interfaces';
import {
    IParam,
    instanceCodeClientT,
    paramsBuilderT,
    IParamsBuilder,
    IInstanceCodeClient,
    ICollection,
    IRecursiveArray, IRequestOptions
} from '../common/interfaces';

import { IChangeable, IChangeCollection } from '../change/interfaces';
import { INote, INoteable, INoteCollection } from '../note/interfaces';
import { IComment, IPersistentComment } from '../comment/interfaces';
import { Archivable } from '../common/archivable/archivable';
import { Activable } from '../common/activable/activable';
import { BulkRemovable } from '../common/bulk-removable/bulk-removable';
import { IBulkResponse } from './../common/errors';
import { Changeable } from '../common/changeable/changeable';
import { Noteable } from '../common/noteable/noteable';
import { AuthHttp } from '../authentication/authentication.service';
import { environment } from 'environments/environment';
import { Advertiser } from './advertiser.entity';
import { BaseSerializer } from '@platform161-client/common/base-serializer';
import { Rule } from '@platform161-client/rule/rule.service';

@Injectable()
@Archivable()
@Activable()
@Changeable()
@BulkRemovable()
@Noteable()
export class AdvertiserClient
    extends BasePersistentApiClient<IAdvertiser, AdvertiserBelongsToRelationships, AdvertiserHasManyRelationships>
    implements IAdvertiserClient, IChangeable, INoteable {

    protected static classConfig: ISchema = {
        apiResourceName: 'advertisers'
    };

    archive: (id: string) => Observable<IAdvertiser>;
    unarchive: (id: string) => Observable<IAdvertiser>;
    activate: (id: string) => Observable<IAdvertiser>;
    deactivate: (id: string) => Observable<IAdvertiser>;
    bulkRemove: (ids: string[]) => Observable<IBulkResponse[]>;

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return Advertiser;
    }

    getChanges(
        changeableId: string, params?: IParam, include?: IRecursiveArray<string>
    ): Observable<IChangeCollection> {
        throw new Error('Method not implemented.');
    }

    addChangeComment(note: IPersistentComment, changeableId: string): Observable<IComment> {
        throw new Error('Method not implemented.');
    }

    getNotes(noteableId: string, params?: IParam, include?: IRecursiveArray<string>): Observable<INoteCollection> {
        throw new Error('Method not implemented.');
    }

    addNote(note: INote): Observable<INote> {
        throw new Error('Method not implemented.');
    }

    addNoteComment(note: IPersistentComment): Observable<IComment> {
        throw new Error('Method not implemented.');
    }

    getAll(
        params?: IParam, include?: IRecursiveArray<string>, options: IRequestOptions = {}
    ): Observable<ICollection<IAdvertiser>> {
        // remove old unnecessary fields from params
        this.recreateParams();

        let includes = this.buildIncludes(this.includesConverter.convert(include), options.ignoreMandatoryIncludes);
        if (includes) {
            this.params = this.params.set('include', includes);
        }
        this.setImpersonationParams();
        this.prepareFieldsParams(options);
        return this.http
            .get(
                `${environment.api.internal}/${this.instanceCode}/${this.classConfig().apiResourceName}`,
                { params: this.prepareParams(params) }
            )
            .pipe(
                map((response: HttpResponse<any>) => {
                    let rawData = response.body;
                    let entities = this.serializer.deserializeMany(rawData, includes);
                    return { data: entities, meta: rawData.meta } as ICollection<IAdvertiser>;
                }),
                catchError(this.handleRequestError)
            );
    }

    getRule(ruleId: string, advertiserId: string, include?: IRecursiveArray<string>): Observable<any> {
        this.recreateParams();
        let includes = this.buildIncludes(this.includesConverter.convert(include), true);
        if (includes) {
            this.params = this.params.set('include', includes);
        }
        this.setImpersonationParams();
        this.prepareFieldsParams({});

        return this.http
            .get(`${environment.api.base}/${this.instanceCode}/advertisers/${advertiserId}/rules/${ruleId}`,
                { params: this.params })
            .pipe(
                map((response: HttpResponse<any>) => {
                    let serializer = new BaseSerializer(Rule);
                    let serialized = serializer.deserialize(response.body, includes);
                    return serialized;
                }),
                catchError(this.handleRequestError)
            );
    }

    removeRule(ruleId: string, advertiserId: string): Observable<void> {
        this.recreateParams();
        this.setImpersonationParams();
        return this.http
            .delete(`${environment.api.base}/${this.instanceCode}/advertisers/${advertiserId}/rules/${ruleId}`,
            { params: this.params })
            .pipe(
                map(() => null),
                catchError(this.handleRequestError)
            );
    }
}
