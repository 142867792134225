/* tslint:disable:variable-name */

export { IBaseApiClient } from '../common/interfaces';
import { IUser } from './interfaces';
import {
    IResource
} from '../common/interfaces';

import { BaseEntity, BelongsTo, Field, HasMany } from '../common/meta';
import { Role } from '../role/role.service';
import { IRole } from '../role/interfaces';
import { IUserPreference } from '../user-preference/interfaces';
import { UserPreference } from '../user-preference/user-preference.entity';
import { IAccount } from '../account/interfaces';
import { Account } from '../account/account.service';
import { IFeature } from '../feature/interfaces';
import { Feature } from '../feature/feature.service';

export class User extends BaseEntity<IUser> implements IUser {

    public static readonly type: string = 'user';

    @Field()
    id: string;

    @Field()
    archived: boolean;

    @Field()
    login: string;

    @Field()
    first_name: string;

    @Field()
    last_name: string;

    @Field()
    email: string;

    @HasMany({ deferredConstructor: () => Role })
    roles: IRole[] | IResource[];

    @HasMany({ deferredConstructor: () => Feature })
    features: IFeature[] | IResource[];

    @HasMany({ deferredConstructor: () => UserPreference })
    user_preferences: IUserPreference[] | IResource[];

    @BelongsTo({ deferredConstructor: () => Account, optional: true  })
    account: IAccount | IResource;

    @Field()
    password: string;

    @Field()
    password_confirmation: string;

    @Field()
    existing_password: string;

    @Field()
    is_sales_manager: boolean;

    @Field()
    is_campaign_manager: boolean;

    @Field()
    is_advertiser: boolean;

    @Field()
    is_agency: boolean;

    @Field()
    is_superadmin: boolean;

    @Field()
    use_semicolon_separator: boolean;

    @Field()
    notification_count: number;

    @Field()
    reporting_permission: string;
}

