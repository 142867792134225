/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';
import { ISchema, BaseApiClient } from '../common/index';
import { IMobileCarrier, IMobileCarrierClient } from './interfaces';
import {
    instanceCodeClientT, IInstanceCodeClient, paramsBuilderT,
    IParamsBuilder
} from '../common/interfaces';
import { BaseEntity, Field } from '../common/meta';
import { AuthHttp } from '../authentication/authentication.service';

export class MobileCarrier extends BaseEntity<IMobileCarrier> implements IMobileCarrier {
    public static readonly type: string = 'mobile_carrier';

    @Field()
    id: string;

    @Field()
    name: string;
}

@Injectable()
export class MobileCarrierClient extends BaseApiClient<IMobileCarrier> implements IMobileCarrierClient {
    protected static classConfig: ISchema = {
        apiResourceName: 'mobile_carriers'
    };

    constructor(http: AuthHttp, @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
                @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder) {
                    super(http, instanceCodeClient, paramsBuilder);
                }

    protected implementationClass() {
        return MobileCarrier;
    }
}
