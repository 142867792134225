import { ICollection, IPersistentApiClient, IResource } from '../common/interfaces';

export interface IPiggybackUrl extends IResource {
    url: string;
    delivery_type: string;
}

export type IPiggybackUrlCollection = ICollection<IPiggybackUrl>;

export type PiggybackUrlBelongsToRelationships = never;
export type PiggybackUrlHasManyRelationships = never;

export enum PiggybackUrlParentType {
    ConversionPixel = 1,
    RetargetingPixel
}

export interface IPiggybackUrlClientFactory {
    createPiggybackUrlClient(pixelType: PiggybackUrlParentType, pixelId: string): IPiggybackUrlClient;
}

export interface IPiggybackUrlClient
    extends IPersistentApiClient<IPiggybackUrl,
        PiggybackUrlBelongsToRelationships,
        PiggybackUrlHasManyRelationships> {
}
