/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';

import { ISchema, BaseApiClient } from '../common/index';
import { IBrowser, IBrowserClient } from './interfaces';
import { BaseEntity, Field, BelongsTo } from '../common/meta';
import {
    instanceCodeClientT, IInstanceCodeClient, paramsBuilderT, IParamsBuilder, IResource
} from '../common/interfaces';
import { AuthHttp } from '../authentication/authentication.service';

export class Browser extends BaseEntity<IBrowser> implements IBrowser {

    public static readonly type: string = 'browser';

    @Field()
    id: string;

    @Field()
    name: string;

    @Field()
    code: string;

    @BelongsTo({ deferredConstructor: () => Browser })
    parent: IBrowser | IResource;

}

@Injectable()
export class BrowserClient extends BaseApiClient<IBrowser> implements IBrowserClient {
    protected static classConfig: ISchema = {
        apiResourceName: 'browsers'
    };

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return Browser;
    }
}
