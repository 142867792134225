import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

import { AccessResolverProxy } from '../service/access-resolver-proxy.service';
import { IAclFeatureDescriptor } from '../acl.interfaces';

/**
 * Usage
 * ```
 * *ngFor="... | feature : {features: ['...']} : [..exclude..]"
 * ```
 */
@Pipe({
    name: 'feature'
})
export class FeaturePipe implements PipeTransform {

    constructor(private accessResolver: AccessResolverProxy) {}

    transform(value: any, descriptor: IAclFeatureDescriptor, exclude: any[]): Observable<any> {
        return this.accessResolver.hasFeature(descriptor)
            .pipe(
                map(
                    (hasAccess: boolean) => {
                        if (!hasAccess) {
                            return value.filter((val) => {
                                return exclude.indexOf(val) > -1;
                            });
                        } else {
                            return value;
                        }
                    }
                )
            );
    }
}
