import { ICollection, IBaseApiClient, IResource } from '../common/interfaces';
import { IMobileAppStatistic } from '../mobile-app-statistic/interfaces';

export interface IMobileApp extends IResource {
    name: string;
    blacklist: string;
    statistics?: IMobileAppStatistic[] | IResource[];
}

export type IMobileAppCollection = ICollection<IMobileApp>;

export interface IMobileAppClient extends IBaseApiClient<IMobileApp> {
}
