/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';

import { ISchema, BaseApiClient } from '../common/index';
export { IBaseApiClient } from '../common/interfaces';
import { IExternalId, IExternalIdClient, IExternalIdClientFactory } from './interfaces';
import {
    instanceCodeClientT, IInstanceCodeClient, paramsBuilderT,
    IParamsBuilder, IResource
} from '../common/interfaces';
import { BaseEntity, Field } from '../common/meta';
import { AuthHttp } from '../authentication/authentication.service';
import { environment } from 'environments/environment';

export class ExternalId extends BaseEntity<IExternalId> implements IExternalId {

    public static readonly type: string = 'external_id';

    @Field()
    id: string;
}

@Injectable()
export class ExternalIdClientFactory implements IExternalIdClientFactory {
    constructor(
        private http: AuthHttp,
        @Inject(instanceCodeClientT) private instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) private paramsBuilder: IParamsBuilder
    ) {
    }

    createExternalIdClient(
        resourceType: string
    ): ExternalIdClient {
        return new ExternalIdClient(
            resourceType,
            this.http,
            this.instanceCodeClient,
            this.paramsBuilder);
    }
}

export class ExternalIdClient extends BaseApiClient<IExternalId> implements IExternalIdClient {
    protected static classConfig: ISchema = {
        apiResourceName: 'external_ids'
    };

    constructor(
        private resourceType: string,
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
        this.resourceType = resourceType;
    }

    protected implementationClass() {
        return ExternalId;
    }

    protected apiBase(): string {
        return `${ environment.api.base }/${ this.instanceCode }/${ this.classConfig().apiResourceName }/${ this.resourceType }`;
    }
}
