import { IBaseApiClient, ICollection, IResource } from '../common/interfaces';
import { IDoohPublisher } from '../dooh_publisher/interfaces';
import { IDoohNetwork } from '../dooh_network/interfaces';
import { IDoohChannel } from '../dooh_channel/interfaces';
import { ISize } from '../size/interfaces';
import { ICountry } from '../country/interfaces';
import { IRegion } from '../region/interfaces';
import { ICity } from '../city/interfaces';

export interface IDoohScreen extends IResource {
    name: string;
    screen_id: string;
    location: string;
    provider: string;
    instance_bid_floor: number;
    instance_bid_floor_currency: string;
    all_bid_floors: BidFloorPrice;
    lat: string;
    long: string;
    active: boolean;
    country: string;
    region: string;
    city: string;
    included_in_evergreen?: boolean;
    ad_duration: number;
    ad_types: string;
    screen_image_url: string;
    avg_monthly_reach: number;

    dooh_publisher: IDoohPublisher | IResource;
    dooh_network: IDoohNetwork | IResource;
    dooh_channel: IDoohChannel | IResource;
    size: ISize | IResource;
    osm_country: ICountry | IResource;
    osm_region: IRegion | IResource;
    osm_city: ICity | IResource;

    publisherName(): string | null;
    networkName(): string | null;
    channelName(): string | null;
    countryName(): string | null;
    regionName(): string | null;
    cityName(): string | null;
    screenCurrency(): string;
    latLong(): string;
}

export type IDoohScreenCollection = ICollection<IDoohScreen>;

export interface IDoohScreenClient extends IBaseApiClient<IDoohScreen> {
    addCampaignFilter(campaignId: string): void;
}

export type BidFloorPrice = {
    usd?: number;
    eur?: number;
};
