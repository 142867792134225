/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';

import { ISchema } from '../common/index';
export { IBaseApiClient } from '../common/interfaces';
import { ICommentClient } from './interfaces';
import {
    instanceCodeClientT, IInstanceCodeClient, paramsBuilderT,
    IParamsBuilder, IResource
} from '../common/interfaces';
import { Field, BelongsTo, BaseEntity } from '../common/meta';
import { IComment } from '../comment/interfaces';
import { User } from '../user/user.entity';
import { IUser } from '../user/interfaces';
import { BasePersistentApiClient } from '../common/base-api-client';
import { AuthHttp } from '../authentication/authentication.service';

export class Comment extends BaseEntity<IComment> implements IComment {

    public static readonly type: string = 'comment';

    @Field()
    id: string;

    @Field()
    created_at: string;

    @Field()
    text: string;

    @BelongsTo({ deferredConstructor: () => User })
    user: IUser | IResource;

    commentable: IResource;
}

@Injectable()
export class CommentClient extends BasePersistentApiClient<IComment, never, never> implements ICommentClient {
    protected static classConfig: ISchema = {
        apiResourceName: 'comments'
    };

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return Comment;
    }
}
