
import { map } from 'rxjs/operators';
import { forwardRef, Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { TabsetComponent, TabDirective } from 'ngx-bootstrap/tabs';

import { PrivilegeAccessResolver } from '../access-resolver/privilege';
import { IAclFeatureDescriptor, IAclPrivilegeDescriptor } from '../../acl.interfaces';
import { FeatureAccessResolver } from '../access-resolver/feature';

@Injectable()
export class AclTabsHelper {

    constructor(
        @Inject(forwardRef(() => PrivilegeAccessResolver)) private privilegeAccessResolver: PrivilegeAccessResolver,
        @Inject(forwardRef(() => FeatureAccessResolver)) private featureAccessResolver: FeatureAccessResolver
    ) {}

    public hideByPrivilege(
        tabs: TabsetComponent,
        tabIds: string[] | string,
        privilege: IAclPrivilegeDescriptor
    ): Observable<boolean> {
        if (!Array.isArray(tabIds)) {
            tabIds = [tabIds];
        }
        return this.privilegeAccessResolver.resolveFromDescriptor(privilege).pipe(
            map(
                (hasAccess) => {
                    if (!hasAccess) {
                        this.hide(tabs, tabIds);
                    }
                    return hasAccess;
                }
            ));
    }

    public hideByFeature(
        tabs: TabsetComponent,
        tabIds: string[] | string,
        feature: IAclFeatureDescriptor
    ): Observable<boolean> {
        if (!Array.isArray(tabIds)) {
            tabIds = [tabIds];
        }
        return this.featureAccessResolver.resolveFromDescriptor(feature).pipe(
            map(
                (hasAccess) => {
                    if (!hasAccess) {
                        this.hide(tabs, tabIds);
                    }

                    return hasAccess;
                }
            ));
    }

    private hide(tabs: TabsetComponent, tabIds: string[] | string): void {
        for (let tabId of tabIds) {
            let index = tabs.tabs.findIndex((tab: TabDirective) => tab.id === tabId);
            if (index > -1) {
                tabs.tabs.splice(index, 1);
            }
        }
    }
}
