import { HttpResponse } from '@angular/common/http';
import * as pluralize from 'pluralize';
import { Observable } from 'rxjs/internal/Observable';
import { map, catchError } from 'rxjs/operators';
import { BaseApiClient } from '../base-api-client';

export function Readable<T>() {

    return function (target) {

        const markAsRead = function (id: string): Observable<T> {
            return this.toggleRead(id, true);
        };

        const markAsUnread = function (id: string): Observable<T> {
            return this.toggleRead(id, false);
        };

        const toggleRead = function (id: string, read: boolean): Observable<T> {
            if (!(this instanceof BaseApiClient)) {
                throw new Error('Decorated element must by type BaseApiClient');
            }
            let options = this.prepareRequestOptionsForDecorators();

            return this.http
                .patch(`${this.apiBase()}/${id}`,
                    {
                        'data': {
                            'id': id,
                            'type': pluralize.singular(this.classConfig().apiResourceName),
                            'attributes': {
                                'seen': read
                            }
                        }
                    },
                    options
                )
                .pipe(
                    map((response: HttpResponse<any>) => {
                        return Object.assign({},
                            response.body.data.attributes, { id: response.body.data.id }) as T;
                    }),
                    catchError(this.handleRequestError)
                );
        };

        target.prototype.markAsRead = markAsRead;
        target.prototype.markAsUnread = markAsUnread;
        target.prototype.toggleRead = toggleRead;

    };

}
