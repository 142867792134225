import { IBaseApiClient, ICollection, IResource } from '../common/interfaces';

export interface IPrivilege extends IResource {
    description: string;
    domain_object: string;
    action: string;
}

export type IPrivilegeCollection = ICollection<IPrivilege>;

export interface IPrivilegeClient extends IBaseApiClient<IPrivilege> {}
