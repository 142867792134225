/* tslint:disable:variable-name */
import { ICountry } from './interfaces';
import { IResource } from '../common/interfaces';
import { City } from '../city/city.service';
import { Region } from '../region/region.entity';
import { HasMany, BaseEntity, Field } from '../common/meta';
import { ICity } from '../city/interfaces';
import { IRegion } from '../region/interfaces';


export class Country extends BaseEntity<ICountry> implements ICountry {

    public static readonly type: string = 'country';

    @Field()
    id: string;

    @Field()
    name: string;

    @Field()
    code: string;

    @Field()
    currency: string;

    @Field()
    vat_percentage: number;

    @HasMany({ deferredConstructor: () => City })
    cities: ICity[] | IResource[];

    @HasMany({ deferredConstructor: () => Region })
    regions: IRegion[] | IResource[];

}
