import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';

import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';

import { IThirdPartyDataSegment } from './interfaces';
import { ICollection } from '../../common/interfaces';
import { BulkRemovable } from './../../common/bulk-removable/bulk-removable';
import { ThirdPartyDataSegmentClient } from './third-party-data-segment.service';
import { environment } from 'environments/environment';

@Injectable()
@BulkRemovable()
export class CampaignThirdPartyDataSegmentClient extends ThirdPartyDataSegmentClient {
    getAllForCampaign(id: string): Observable<ICollection<IThirdPartyDataSegment>>{
        return this.http
            .get(`${ this.urlForCampaign(id) }`,
            {})
            .pipe(
                map((response: HttpResponse<any>) => {
                    let rawData = response.body;
                    let entities = this.serializer.deserializeMany(rawData);
                    return { data: entities, meta: rawData.meta } as ICollection<IThirdPartyDataSegment>;
                }),
                catchError(this.handleRequestError)
            );
    }

    protected urlForCampaign(id: string) {
        return `${ environment.api.base }/${ this.instanceCode }/campaigns/${id}/${ this.classConfig().apiResourceName }`;
    }
}
