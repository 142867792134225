import { Component, ViewEncapsulation } from '@angular/core';
import { Router, RoutesRecognized, NavigationEnd } from '@angular/router';

import { map, filter, pairwise } from 'rxjs/operators';

import { PageTitleHelper } from './shared/title-helper/service/page-title-helper';
import { LOCAL_STORAGE_ROUTE_REFERRER } from './app.consts';

/*
 * App Component
 * Top Level Component
 */
@Component({
    selector: 'app',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./scss/application.scss'],
    template: `<router-outlet></router-outlet>`,
    providers: [PageTitleHelper],
})
export class App {
    constructor(
        private router: Router,
        private pageTitle: PageTitleHelper
    ) {
        this.router.events
            .pipe(
                filter(event => event instanceof RoutesRecognized),
                map((recognized: RoutesRecognized) => {
                    let route = recognized.state.root;
                    while (route.firstChild) route = route.firstChild;
                    return route;
                })
            )
            .subscribe(route => {
                if (route.data.title !== undefined) {
                    this.pageTitle.resolveFor(route.data.title);
                }
            });
        this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd)
            ).subscribe((route) => {
                window['_paq']?.push(['trackEvent', 'PageView', (<NavigationEnd>route).url]);
                window['_mtm']?.push({'event': 'mtm.PageView'});
            });
        this.setRouteReferrer();
    }

    private setRouteReferrer(): void {
        this.router.events.pipe(
                filter((item: any) => item instanceof RoutesRecognized),
                pairwise()
            )
            .subscribe((item: any[]) => {
                localStorage.setItem(LOCAL_STORAGE_ROUTE_REFERRER, item[0].urlAfterRedirects);
            });
    }


}
