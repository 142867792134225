/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';

import { ISchema, BasePersistentApiClient } from '../common/index';
import { BaseEntity, Field, BelongsTo } from '../common/meta';
import {
    IInstanceCodeClient, instanceCodeClientT, paramsBuilderT,
    IParamsBuilder, IResource
} from '../common/interfaces';
import { BulkRemovable } from '../common/bulk-removable/bulk-removable';

import {
    ICampaignConversionPixel,
    ICampaignConversionPixelClientFactory,
    ICampaignConversionPixelClient,
    CampaignConversionPixelBelongsToRelationships,
    CampaignConversionPixelHasManyRelationships
} from './interfaces';
import { IConversionPixel } from '../conversion-pixel/interfaces';
import { ConversionPixel } from '../conversion-pixel/conversion-pixel.service';
import { AuthHttp } from '../authentication/authentication.service';
import { environment } from 'environments/environment';

export class CampaignConversionPixel extends BaseEntity<ICampaignConversionPixel> implements ICampaignConversionPixel {

    public static readonly type: string = 'campaign_conversion_pixel';

    @Field()
    id: string;

    @Field()
    post_click_price: number;

    @Field()
    post_view_price: number;

    @Field()
    view_price: number;

    @Field()
    optimizing: boolean;

    @Field()
    loads: number;

    @Field()
    conversions: number;

    @Field()
    post_view_conversions: number;

    @Field()
    post_click_conversions: number;

    @Field()
    cross_device_post_click_conversions?: number;

    @Field()
    cross_device_post_view_conversions?: number;

    @Field()
    total_post_click_conversions?: number;

    @Field()
    total_post_view_conversions?: number;

    @Field()
    total_conversions?: number;

    @Field()
    cross_device_conversions?: number;

    @Field()
    gross_price?: number;

    @BelongsTo({ deferredConstructor: () => ConversionPixel })
    conversion_pixel: IConversionPixel | IResource;
}

@Injectable()
export class CampaignConversionPixelClientFactory implements ICampaignConversionPixelClientFactory {

    constructor(
        private http: AuthHttp,
        @Inject(instanceCodeClientT) private instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) private paramsBuilder: IParamsBuilder
    ) {
    }

    createCampaignConversionPixelClient(
        campaignId: string
    ): CampaignConversionPixelClient {
        return new CampaignConversionPixelClient(
            campaignId,
            this.http,
            this.instanceCodeClient,
            this.paramsBuilder);
    }
}

@BulkRemovable()
export class CampaignConversionPixelClient
    extends BasePersistentApiClient<ICampaignConversionPixel,
        CampaignConversionPixelBelongsToRelationships,
        CampaignConversionPixelHasManyRelationships> implements ICampaignConversionPixelClient {

    protected static classConfig: ISchema = {
        apiResourceName: 'conversion_pixels'
    };

    protected apiBase(): string {
        return `${environment.api.base}/${this.instanceCode}/campaigns/${this.campaignId}/${this.classConfig().apiResourceName}`;
    }

    constructor(
        private campaignId: string,
        http: AuthHttp,
        instanceCodeClient: IInstanceCodeClient,
        paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return CampaignConversionPixel;
    }
}
