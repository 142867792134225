/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';

import { IDoohChannel, IDoohChannelClient } from './interfaces';
import { IDoohScreen } from '../dooh_screen/interfaces';
import { DoohScreen } from '../dooh_screen/dooh-screen.service';
import { BaseEntity, Field, HasMany } from '../common/meta';
import { ISchema, BaseApiClient } from '../common/index';
import { IResource } from '../common/interfaces';

import { instanceCodeClientT, IInstanceCodeClient, paramsBuilderT, IParamsBuilder } from '../common/interfaces';
import { AuthHttp } from '../authentication/authentication.service';

export class DoohChannel extends BaseEntity<IDoohChannel> implements IDoohChannel {

    public static readonly type: string = 'dooh_channel';

    @Field()
    id: string;

    @Field()
    name: string;

    @Field()
    provider: string;

    @Field()
    active: boolean;

    @HasMany({ deferredConstructor: () => DoohScreen })
    dooh_screens: IDoohScreen[] | IResource[];

}

@Injectable()
export class DoohChannelClient extends BaseApiClient<IDoohChannel> implements IDoohChannelClient {
    protected static classConfig: ISchema = {
        apiResourceName: 'dooh_channels'
    };

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return DoohChannel;
    }

}
