import { IBaseApiClient, ICollection, IResource } from '../common/interfaces';
import { IDoohScreen } from '../dooh_screen/interfaces';

export interface IDoohChannel extends IResource {
    name: string;
    provider: string;
    active: boolean;

    dooh_screens: IDoohScreen[] | IResource[];
}

export type IDoohChannelCollection = ICollection<IDoohChannel>;

export interface IDoohChannelClient extends IBaseApiClient<IDoohChannel> {
}
