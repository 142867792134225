/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';

import { ISchema, BaseApiClient } from '../common/index';
import { IRtbProduct, IRtbProductClient } from './interfaces';
import {
    instanceCodeClientT, IInstanceCodeClient, paramsBuilderT,
    IParamsBuilder
} from '../common/interfaces';
import { BaseEntity, Field } from '../common/meta';
import { AuthHttp } from '../authentication/authentication.service';

export class RtbProduct extends BaseEntity<IRtbProduct> implements IRtbProduct {
    public static readonly type: string = 'rtb_product';

    @Field()
    id: string;

    @Field()
    name: string;
}

@Injectable()
export class RtbProductClient extends BaseApiClient<IRtbProduct> implements IRtbProductClient {
    protected static classConfig: ISchema = {
        apiResourceName: 'rtb_products'
    };

    constructor(http: AuthHttp, @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
                @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder) {
                    super(http, instanceCodeClient, paramsBuilder);
                }

    protected implementationClass() {
        return RtbProduct;
    }
}
