import { IPersistentApiClient, ICollection, IResource } from '../common/interfaces';

export interface IRtbUrl extends IResource {
    url: string;
}

export type IRtbUrlCollection = ICollection<IRtbUrl>;

export type RtbUrlBelongsToRelationships = never;
export type RtbUrlHasManyRelationships = never;

export interface IRtbUrlClientFactory {
    createRtbUrlClient(campaignId: string): IRtbUrlClient;
}

export interface IRtbUrlClient
    extends IPersistentApiClient<IRtbUrl, RtbUrlBelongsToRelationships, RtbUrlHasManyRelationships>{ }
