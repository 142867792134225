export { Filters, Status, Period, Interval, Grouping, FilterAssociation, Measure };

type Filters = {
    media_plan?: FilterAssociation;
    campaign?: FilterAssociation;
    start_on?: FilterDateValue;
    end_on?: FilterDateValue;
    pace_end_on?: FilterDateValue;
    advertiser?: FilterAssociation;
    agency?: FilterAssociation;
    campaign_type?: { value: 'delivery' | 'direct_deal' | 'out_of_home' };
    private_exchange?: { value: 'managed' | 'non_managed'};
};

type FilterAssociation = {
    exclude: boolean;
    object_ids: string[];
};

type FilterDateValue = {
    from_date: Date,
    to_date: Date
};

type Period =
    'today'
    | 'yesterday'
    | 'last_2_days'
    | 'last_7_days'
    | 'last_30_days'
    | 'month_to_date'
    | 'last_month';

type Interval =
    'none'
    | 'daily'
    | 'monthly'
    | 'yearly';


type Grouping =
    'campaign_id'
    | 'media_plan_id'
    | 'advertiser_id'
    | 'agency_id'
    | 'campaign'
    | 'media_plan'
    | 'advertiser'
    | 'agency';

type Measure =
    'media_cost'
    | 'tech_cost_billable'
    | 'tech_cost_non_billable'
    | 'dmp_cost'
    | 'agency_margin'
    | 'platform_tech_fee'
    | 'optimized_margin'
    | 'p161_optimized_margin'
    | 'billable_cost'
    | 'credit_note'
    | 'total_cost'
    | 'impressions'
    | 'data_providers_split_cost'
    | 'technology_partners_total_cost'
    | 'technology_partners_split_cost'
    | 'internal_purchase_order_number'
    | 'external_purchase_order_number'
    | 'media_plan_budget';

type Status =
    'queued'
    | 'ready'
    | 'failed';

export const LEGACY_INVOICING_MEASURES = {
    'media_plan_booked_budget': 'media_plan_budget',
    'total_campaign_cost': 'total_cost'
};

