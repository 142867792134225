/* tslint:disable:variable-name */
import { ICreativeFile } from './interfaces';

import { BaseEntity, Field } from '../common/meta';

export class CreativeFile extends BaseEntity<ICreativeFile> implements ICreativeFile {

    public static readonly type: string = 'creative_file';

    @Field()
    id: string;

    @Field()
    filename: string;

    @Field()
    content_type: string;

    @Field()
    size: number;

    @Field()
    url: string;

    @Field()
    base64_data?: string; // available only for creative kind 3rdParty and must be requested in fields params
}
