/* tslint:disable:variable-name */
import { IThirdPartyDataSegment } from './interfaces';
import { BaseEntity, BelongsTo, Field } from '../../common/meta';
import { ThirdPartyDataProvider } from '../third-party-data-provider.entity';
import { IResource } from '../../common/interfaces';
import { IThirdPartyDataProvider } from '../interfaces';
import { IAdvertiser } from '../../advertiser/interfaces';
import { Advertiser } from '../../advertiser/advertiser.entity';
import { IThirdPartyDataSegmentCategory } from './category/interfaces';
import { ThirdPartyDataSegmentCategory } from './category/third-party-data-segment.entity';
import { Country } from '../../country/country.entity';
import { ICountry } from '../../country/interfaces';

export class ThirdPartyDataSegment extends BaseEntity<IThirdPartyDataSegment> implements IThirdPartyDataSegment {

    public static readonly type: string = 'third_party_data_segment';

    @Field()
    id: string;

    @Field()
    name: string;

    @Field()
    code: string;

    @Field()
    cpm_price: number;

    @Field()
    unique_users: number;

    @Field()
    disabled_in_third_party: boolean;

    @Field()
    campaign_ids: string[];

    @Field()
    segment_name: string;

    @BelongsTo({ deferredConstructor: () => ThirdPartyDataProvider })
    third_party_data_provider: IThirdPartyDataProvider | IResource;

    @BelongsTo({ deferredConstructor: () => Advertiser })
    advertiser: IAdvertiser | IResource;

    @BelongsTo({ deferredConstructor: () => ThirdPartyDataSegmentCategory })
    category: IThirdPartyDataSegmentCategory | IResource;

    @BelongsTo({ deferredConstructor: () => Country })
    country: ICountry;
}
