/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';

import { ISchema, BasePersistentApiClient } from '../../common/index';
import { BaseEntity, Field, BelongsTo, HasMany } from '../../common/meta';
import {
    IInstanceCodeClient, instanceCodeClientT, paramsBuilderT,
    IParamsBuilder, IResource
} from '../../common/interfaces';
import { IDomain } from '../../domain/interfaces';
import { Domain } from '../../domain/domain.service';
import { IDomainSet } from '../../domain-set/interfaces';
import { DomainSet } from '../../domain-set/domain-set.service';
import { IInventorySource } from '../../inventory-source/interfaces';
import { InventorySource } from '../../inventory-source/inventory-source.service';

import {
    ICampaignInventorySource, ICampaignInventorySourceClientFactory,
    ICampaignInventorySourceClient,
    CampaignInventorySourceBelongsToRelationships, CampaignInventorySourceHasManyRelationships
} from './interfaces';
import { AuthHttp } from '../../authentication/authentication.service';
import { environment } from 'environments/environment';

export class CampaignInventorySource extends BaseEntity<ICampaignInventorySource> implements ICampaignInventorySource {

    public static readonly type: string = 'campaign_inventory_source';

    @Field()
    id: string;

    @Field()
    domain_filter: string;

    @BelongsTo({ deferredConstructor: () => InventorySource })
    inventory_source: IInventorySource | IResource;

    @HasMany({ deferredConstructor: () => DomainSet })
    rtb_domain_sets: IDomainSet[] | IResource[];

    @HasMany({ deferredConstructor: () => Domain })
    rtb_domains: IDomain[] | IResource[];
}

@Injectable()
export class CampaignInventorySourceClientFactory implements ICampaignInventorySourceClientFactory {

    constructor(
        private http: AuthHttp,
        @Inject(instanceCodeClientT) private instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) private paramsBuilder: IParamsBuilder
    ) {
    }

    createCampaignInventorySourceClient(
        campaignId: string
    ): CampaignInventorySourceClient {
        return new CampaignInventorySourceClient(
            campaignId,
            this.http,
            this.instanceCodeClient,
            this.paramsBuilder);
    }
}

export class CampaignInventorySourceClient
    extends BasePersistentApiClient<ICampaignInventorySource, CampaignInventorySourceBelongsToRelationships, CampaignInventorySourceHasManyRelationships>
    implements ICampaignInventorySourceClient {

    protected static classConfig: ISchema = {
        apiResourceName: 'inventory_sources'
    };

    protected apiBase(): string {
        return `${environment.api.base}/${this.instanceCode}/campaigns/${this.campaignId}/${this.classConfig().apiResourceName}`;
    }

    constructor(
        private campaignId: string,
        http: AuthHttp,
        instanceCodeClient: IInstanceCodeClient,
        paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return CampaignInventorySource;
    }
}
