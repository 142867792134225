/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

import {
    instanceCodeClientT, IInstanceCodeClient, paramsBuilderT, IParamsBuilder
} from '../common/interfaces';
import { ISchema } from '../common/index';
import {
    IThirdPartyTechnologyPartner, IThirdPartyTechnologyPartnerClient,
    ThirdPartyTechnologyPartnerBelongsToRelationships, ThirdPartyTechnologyPartnerHasManyRelationships
} from './interfaces';
import { BasePersistentApiClient } from '../common/base-api-client';
import { Archivable } from '../common/archivable/archivable';
import { AuthHttp } from '../authentication/authentication.service';
import { ThirdPartyTechnologyPartner } from './third-party-technology-partner.entity';

@Injectable()
@Archivable()
export class ThirdPartyTechnologyPartnerClient
    extends BasePersistentApiClient<IThirdPartyTechnologyPartner, ThirdPartyTechnologyPartnerBelongsToRelationships,
        ThirdPartyTechnologyPartnerHasManyRelationships> implements IThirdPartyTechnologyPartnerClient {

    protected static classConfig: ISchema = {
        apiResourceName: 'thirdparty_technology_partners'
    };

    archive: (id: string) => Observable<IThirdPartyTechnologyPartner>;
    unarchive: (id: string) => Observable<IThirdPartyTechnologyPartner>;

    constructor(http: AuthHttp,
                @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
                @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return ThirdPartyTechnologyPartner;
    }
}
