/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';

import { ISchema, BaseApiClient } from '../common/index';
export { IBaseApiClient } from '../common/interfaces';
import { ICity, ICityClient } from './interfaces';
import {
    instanceCodeClientT, IInstanceCodeClient, paramsBuilderT,
    IParamsBuilder, IResource
} from '../common/interfaces';
import { Country } from '../country/country.entity';
import { Region } from '../region/region.entity';
import { BelongsTo, BaseEntity, Field } from '../common/meta';
import { IRegion } from '../region/interfaces';
import { ICountry } from '../country/interfaces';
import { AuthHttp } from '../authentication/authentication.service';

export class City extends BaseEntity<ICity> implements ICity {

    public static readonly type: string = 'city';

    @Field()
    id: string;

    @Field()
    name: string;

    @Field()
    code: string;

    @BelongsTo({ deferredConstructor: () => Region })
    region: IRegion | IResource;

    @BelongsTo({ deferredConstructor: () => Country })
    country: ICountry | IResource;

}

@Injectable()
export class CityClient extends BaseApiClient<ICity> implements ICityClient {
    protected static classConfig: ISchema = {
        apiResourceName: 'cities'
    };

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return City;
    }
}
