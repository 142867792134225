/* tslint:disable:variable-name */
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

import {
    IHyperlocalDataSegment,
    IHyperlocalDataSegmentClient,
    HyperlocalDataSegmentBelongsToRelationships,
    HyperlocalDataSegmentHasManyRelationships
} from './interfaces';
import { IThirdPartyDataProvider } from '../third-party-data-provider/interfaces';
import { ThirdPartyDataProvider } from '../third-party-data-provider/third-party-data-provider.entity';
import { BaseEntity, BelongsTo, Field, HasMany, DefaultTransforms } from '../common/meta';
import { BasePersistentApiClient } from '../common/base-api-client';
import {
    IBulkEditable, IEntityConstructor, IInstanceCodeClient, instanceCodeClientT, IParam, IParamsBuilder,
    IRecursiveArray,
    ISchema, paramsBuilderT
} from '../common/interfaces';
import { IChangeable, IChangeCollection } from '../change/interfaces';
import { IBulkResponse } from '../common/errors';
import { IComment, IPersistentComment } from '../comment/interfaces';
import { IBulkRemovable } from '../common/bulk-removable/interfaces';
import { Changeable } from '../common/changeable/changeable';
import { AuthHttp } from '../authentication/authentication.service';
import { LatLongTargeting } from '../campaign/interfaces';
import { TemporalTargeting } from '../targeting-default/interfaces';
import { ILanguage } from '../language/interfaces';
import { Language } from '../language/language.service';
import { IResource } from '../common/interfaces';
import { ICampaign } from '../campaign/interfaces';
import { ICity } from '../city/interfaces';
import { City } from '../city/city.service';
import { ICountry } from '../country/interfaces';
import { Country } from '../country/country.entity';
import { IRegion } from '../region/interfaces';
import { Region } from '../region/region.entity';

export class HyperlocalDataSegment extends BaseEntity<IHyperlocalDataSegment> implements IHyperlocalDataSegment {

    public static readonly type: string = 'hyperlocal-data-segment';

    @Field()
    id: string;

    @Field()
    campaign_ids: string[];

    @Field()
    city_filter: string;

    @Field()
    code: string;

    @Field()
    country_filter: string;

    @Field()
    cpm_price: number;

    @Field()
    end_on: string;

    @Field()
    end_on_date?: string;

    @Field()
    end_on_hour?: string;

    @Field()
    end_on_min?: string;

    @Field()
    lat_long_only?: boolean;

    @Field()
    lat_long_targeting?: LatLongTargeting[];

    @Field()
    name: string;

    @Field()
    region_filter: string;

    @Field()
    start_on: string;

    @Field()
    start_on_date?: string;

    @Field()
    start_on_hour?: string;

    @Field()
    start_on_min?: string;

    @Field({fromApi: DefaultTransforms.ToTemporalTargeting})
    temporal_targeting?: TemporalTargeting;

    @Field()
    unique_users: number;

    @BelongsTo({ deferredConstructor: () => ThirdPartyDataProvider })
    third_party_data_provider: IThirdPartyDataProvider | IResource;

    @BelongsTo({ deferredConstructor: () => ThirdPartyDataProvider })
    campaign: ICampaign;

    @HasMany({ deferredConstructor: () => City, apiRelationshipName: 'cities' })
    cities: ICity[] | IResource[];

    @HasMany({ deferredConstructor: () => Country })
    countries: ICountry[] | IResource[];

    @HasMany({ deferredConstructor: () => Language })
    languages: ILanguage[] | IResource[];

    @HasMany({ deferredConstructor: () => Region, apiRelationshipName: 'regions' })
    regions: IRegion[] | IResource[];
}

@Injectable()
@Changeable()
export class HyperlocalDataSegmentClient
    extends BasePersistentApiClient<IHyperlocalDataSegment,
    HyperlocalDataSegmentBelongsToRelationships,
    HyperlocalDataSegmentHasManyRelationships>
    implements IHyperlocalDataSegmentClient, IChangeable, IBulkEditable<IHyperlocalDataSegment>, IBulkRemovable {

    protected static classConfig: ISchema = {
        apiResourceName: 'hyperlocal_data_segments'
    };

    protected implementationClass(): IEntityConstructor<IHyperlocalDataSegment> {
        return HyperlocalDataSegment;
    }

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }


    bulkRemove(ids: string[]): Observable<IBulkResponse[]> {
        throw new Error('Method not implemented');
    }

    getChanges(
        changeableId: string,
        params?: IParam,
        include?: IRecursiveArray<string>
    ): Observable<IChangeCollection> {
        throw new Error('Method not implemented');
    }

    addChangeComment(comment: IPersistentComment, changeableId: string): Observable<IComment> {
        throw new Error('Method not implemented');
    }

    bulkEdit(entities: IHyperlocalDataSegment[]): Observable<IBulkResponse[]> {
        throw new Error('Method not implemented');
    }
}
