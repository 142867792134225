import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { forkJoin } from 'rxjs';
import { AclRulesProvider } from './shared/acl/service/acl-rules-provider.service';

@Injectable()
export class DataResolver implements Resolve<any> {
    constructor(private aclRulesProvider: AclRulesProvider) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // let's resolve acl rules before enter to app
        return forkJoin([
            this.aclRulesProvider.getPrivileges(),
            this.aclRulesProvider.getFeatures()
        ]);
    }
}
