import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AccessResolverProxy } from '../service/access-resolver-proxy.service';
import { IAclPrivilegeDescriptor } from '../acl.interfaces';

/**
 * Usage
 * ```
 * *privilege="{domains:[], matchAll?: true}"
 * [privilege]="..."
 * ```
 */
@Directive({
    selector: '[privilege]'
})
export class PrivilegeDirective {

    constructor(
                private templateRef: TemplateRef<any>,
                private viewContainer: ViewContainerRef,
                private accessResolver: AccessResolverProxy) {
    }

    @Input() set privilege(descriptor: IAclPrivilegeDescriptor) {
        if (!descriptor) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            return;
        }
        this.viewContainer.clear();
        this.accessResolver.hasPrivilege(descriptor)
            .subscribe(
                (hasAccess: boolean) => {
                    if (hasAccess) {
                        this.viewContainer.createEmbeddedView(this.templateRef);
                    }
                }
            )
        ;
    }
}
