import { Observable, throwError, forkJoin } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { HttpResponse, HttpParams, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import forEach from 'lodash-es/forEach';
import merge from 'lodash-es/merge';
import { BaseSerializer } from './base-serializer';
import {
    ISchema,
    ICollection,
    IParam,
    IResource,
    ISerializer,
    IBaseApiClient,
    IRecursiveArray,
    RelationshipMetadata,
    IPersistentApiClient,
    IInstanceCodeClient,
    IParamsBuilder, IEntityConstructor, IIncludesConverter, IParamsConverter, IParamKeyMap, IRequestOptions,
    Operation, IBulkableResponse
} from './interfaces';
import { ErrorResponse } from './errors';
import { ParamsConverter } from './params-converter';
import { IncludesConverter } from './includes-converter';
import { AuthHttp, HttpRequestInitOptions } from '../authentication/authentication.service';
import { environment } from 'environments/environment';

export class RelationsMismatchError extends Error {
}

export const MAX_GET_URL_LENGTH = 1024;
export const MAX_PAGE_SIZE = 500;

export abstract class BaseApiClient<T extends IResource> implements IBaseApiClient<T> {

    protected static classConfig: ISchema = null;

    protected params: HttpParams;
    protected customParams: HttpParams;

    protected paramsConverter: IParamsConverter;
    protected includesConverter: IIncludesConverter;
    protected serializer: ISerializer<T>;

    constructor(protected http: AuthHttp,
        protected instanceCodeClient: IInstanceCodeClient,
        protected paramsBuilder: IParamsBuilder) {

        this.params = new HttpParams();
        this.customParams = new HttpParams();
        let emptyEntity = new (this.implementationClass())(null);
        this.paramsConverter = new ParamsConverter(emptyEntity, this.implementationClass().type);
        this.includesConverter = new IncludesConverter(emptyEntity, this.implementationClass().type);
        this.serializer = new BaseSerializer(this.implementationClass());
    }

    protected get instanceCode(): string {
        return this.instanceCodeClient.getInstanceCode();
    }

    protected apiBase(): string {
        return `${environment.api.base}/${this.instanceCode}/${this.classConfig().apiResourceName}`;
    }

    protected apiInternal(): string {
        return `${environment.api.internal}/${this.instanceCode}`;
    }

    protected prepareRequestOptionsForDecorators(): HttpRequestInitOptions {
        this.recreateParams();
        this.setImpersonationParams();
        return { params: this.params };
    }

    cloneEntity(entity: T): T {
        return new (this.implementationClass())(entity, false);
    }

    getType(): string {
        return this.implementationClass().type;
    }

    detailsPage(id: string): string {
        return `/#/${this.instanceCode}/app/${this.getType().replace(/_/g, '-')}/detail/${id}`;
    }

    get(id: string, include?: IRecursiveArray<string>, options: IRequestOptions = {}): Observable<T> {
        this.recreateParams();
        let includes = this.buildIncludes(this.includesConverter.convert(include), options.ignoreMandatoryIncludes);
        if (includes) {
            this.params = this.params.set('include', includes);
        }
        this.setImpersonationParams();
        this.prepareFieldsParams(options);
        return this.http
            .get(`${this.apiBase()}/${id}`,
                { params: this.params })
            .pipe(
                map((response: HttpResponse<any>) => {
                    let res = this.serializer.deserialize(response.body, includes);
                    return res;
                }),
                catchError(this.handleRequestError)
            );
    }

    getAll(
        params?: IParam, include?: IRecursiveArray<string>, options: IRequestOptions = {}
    ): Observable<ICollection<T>> {
        // remove old unnecessary fields from params
        this.recreateParams();
        let includes = this.buildIncludes(this.includesConverter.convert(include), options.ignoreMandatoryIncludes);
        if (includes) {
            this.params = this.params.set('include', includes);
        }
        this.setImpersonationParams();
        this.prepareFieldsParams(options);
        params = params || {};
        if (params.limit && params.limit > MAX_PAGE_SIZE) {
            let totalItems = params.limit;
            let requests = [];
            params.limit = MAX_PAGE_SIZE;
            for (let page = 0; page < Math.ceil(totalItems / MAX_PAGE_SIZE); page++) {
                params.offset = page * MAX_PAGE_SIZE;
                requests.push(this.buildPageRequest(params));
            }
            return forkJoin(...requests).pipe(
                map((responses: HttpResponse<any>[]) => {
                    let allEntities = [];
                    let allIncluded = [];
                    let allMeta = {
                        current_page: 1,
                        total_count: totalItems,
                        total_pages: 1
                    };
                    responses.forEach(response => {
                        let rawData = response.body;
                        let entities = this.serializer.deserializeMany(rawData, includes);
                        allEntities.push(...entities);
                        allIncluded.push(...(rawData.included || []));
                    });
                    if (responses.length === 1) {
                        allMeta = responses[0].body.meta;
                    }
                    return { data: allEntities, meta: allMeta, included: allIncluded } as ICollection<T>;
                }),
                catchError(this.handleRequestError)
            );
        } else {
            let request = this.buildPageRequest(params);
            return request.pipe(
                map((response: HttpResponse<any>) => {
                    let rawData = response.body;
                    let entities = this.serializer.deserializeMany(rawData, includes);
                    return { data: entities, meta: rawData.meta, included: rawData.included } as ICollection<T>;
                }),
                catchError(this.handleRequestError)
            );
        }
    }

    getOperations(): Operation[] {
        let operations: Operation[] = [];
        Object.getOwnPropertyNames(Object.getPrototypeOf(this)).forEach(prop => {
            switch (prop) {
                case 'activate':
                case 'deactivate':
                case 'duplicate':
                case 'bulkRemove':
                case 'archive':
                case 'unarchive':
                case 'favorite':
                case 'unfavorite':
                    operations.push(Operation[prop]);
                    break;
                default:
            }
        });
        return operations;
    }

    fieldToDisplay(): string {
        return 'name';
    }

    protected setImpersonationParams() {
        let impersonatedUser = localStorage.getItem('impersonatedUser');
        if (impersonatedUser) {
            this.params = this.params.set('impersonated_user', impersonatedUser);
        }
    }

    protected abstract implementationClass(): IEntityConstructor<T>;

    protected classConfig(): ISchema {
        return (this.constructor as any).classConfig as ISchema;
    }

    protected prepareParams(params?: IParam, paramKeyMap?: IParamKeyMap): HttpParams {
        let convertedParams = this.paramsConverter.convert(params);
        let processedParams = this.paramsBuilder.build(convertedParams, paramKeyMap);

        return this.mergeParams(processedParams, this.params);
    }

    protected mergeParams(processedParams: HttpParams, currentParams: HttpParams = new HttpParams()): HttpParams {
        processedParams.keys().forEach((key: string) => {
            const values = processedParams.getAll(key) || [];
            currentParams = currentParams.delete(key);
            values.forEach((value: string) => {
                currentParams = currentParams.append(key, value);
            });
        });

        return currentParams;
    }

    protected clone(): BaseApiClient<T> {
        let cloneObject = new (this.constructor(this.http, this.instanceCodeClient, this.paramsBuilder) as any);
        cloneObject.params = this.params;
        return cloneObject as BaseApiClient<T>;
    }

    protected recreateParams(): void {
        if (this.customParams) {
            const paramCopy = this.customParams.keys().reduce((paramObject, paramName) => {
                paramObject[paramName] = this.customParams.getAll(paramName);
                return paramObject;
            }, {});
            this.params = new HttpParams({ fromObject: paramCopy });
        } else {
            this.params = new HttpParams();
        }
        this.customParams = null;
    }

    protected buildIncludes(includes: string, ignoreMandatoryIncludes: boolean = false): string {
        let mandatory = ignoreMandatoryIncludes ? null : this.buildMandatoryIncludes();
        if (includes) {
            return [mandatory, includes].filter((el: any) => { return !!el; }).join(',');
        } else {
            return mandatory;
        }
    }

    protected prepareFieldsParams(options: IRequestOptions = {}): void {
        if (options.fields) {
            Object.keys(options.fields).filter(
                fieldName => Array.isArray(options.fields[fieldName]) && options.fields[fieldName].length
            ).forEach(fieldName => {
                this.params = this.params.set(`fields[${fieldName}]`, options.fields[fieldName].join(','));
            });
        }

        if (options.all_included) {
            this.params = this.params.set('all_included', 'true');
        }

        if (options.with_dooh_screens) {
            this.params = this.params.set('with_dooh_screens', 'true');
        }
    }

    protected buildMandatoryIncludes(): string {
        let includes = [];
        let emptyEntity = new (this.implementationClass())(null);
        forEach<{ [P in keyof T]?: RelationshipMetadata }>(
            merge({}, emptyEntity.belongsTo || {}, emptyEntity.hasMany || {}),
            (relationshipMeta: RelationshipMetadata, key: string) => {
                if (!relationshipMeta.optional) {
                    includes.push(relationshipMeta.foreignKey);
                }
            });
        return includes.join(',');
    }

    protected handleRequestError(response: any): Observable<any> {
        if (response instanceof HttpErrorResponse) {
            return throwError(new ErrorResponse(response.status, response.error.errors || []));
        }

        return throwError(response);
    }

    private hasLongParams(params: HttpParams): boolean {
        return params.toString().length > MAX_GET_URL_LENGTH;
    }

    private buildPageRequest(params: IParam): Observable<HttpResponse<any>> {
        let requestParams = this.prepareParams(params);
        let request: Observable<HttpResponse<any>>;
        if (!this.hasLongParams(requestParams)) {
            request = this.http.get(`${this.apiBase()}`, { params: requestParams });
        } else {
            // With so many params, this request could end up generating a too long GET URL
            // For safety, we send it as POST, with the params in the body, and a header
            // telling the server to interpret the request as GET
            let getAsPostHeaders: HttpHeaders = new HttpHeaders({
                'X-HTTP-Method-Override': 'GET',
                'Content-Type': 'application/x-www-form-urlencoded'
            });
            request = this.http.post(`${this.apiBase()}`, requestParams.toString(), { headers: getAsPostHeaders });
        }
        return request;
    }
}

export abstract class BasePersistentApiClient<T extends IResource, U extends keyof T = never, Z extends keyof T = never>
    extends BaseApiClient<T> implements IPersistentApiClient<T, U, Z> {

    addRelationship<X extends T[U]>(id: string, relationship: U, entity: X): Observable<void> {
        return null;
    }

    removeRelationship<X extends T[U]>(id: string, relationship: U, entity: X): Observable<void> {
        return null;
    }

    addRelationships<X extends T[Z]>(id: string, relationship: Z, entities: X): Observable<void> {
        return null;
    }

    removeRelationships<X extends T[Z]>(id: string, relationship: Z, entities: X): Observable<void> {
        return null;
    }

    save(entity: T, include?: IRecursiveArray<string>, options: IRequestOptions = {}): Observable<T> {
        this.recreateParams();
        let includes = this.buildIncludes(this.includesConverter.convert(include), options.ignoreMandatoryIncludes);
        if (includes) {
            this.params = this.params.set('include', includes);
        }
        this.setImpersonationParams();
        this.prepareFieldsParams(options);
        let wrappedEntity = new (this.implementationClass())(entity);
        let data = this.serializer.serialize(wrappedEntity);
        if (entity.id) {
            return this.http.patch(`${this.apiBase()}/${entity.id}`, data, { params: this.params })
                .pipe(
                    map((response: HttpResponse<any>) => {
                        return this.serializer.deserialize(response.body, includes);
                    }),
                    catchError(this.handleRequestError)
                );
        } else {
            return this.http.post(`${this.apiBase()}`, data, { params: this.params })
                .pipe(
                    map((response: HttpResponse<any>) => {
                        return this.serializer.deserialize(response.body, includes);
                    }),
                    catchError(this.handleRequestError)
                );
        }
    }

    remove(id: string): Observable<void> {
        this.recreateParams();
        this.setImpersonationParams();
        return this.http.delete(`${this.apiBase()}/${id}`, { params: this.params })
            .pipe(
                map(() => null),
                catchError(this.handleRequestError)
            );
    }

    bulkSave(resources: Partial<T>[]): Observable<IBulkableResponse<T>> {
        const data = resources.map(resource => {
            return this.serializer.serialize(new (this.implementationClass())(resource)).data;
        });
        this.recreateParams();
        this.setImpersonationParams();
        const httpRequest = (url, body, options) => {
            return ((resources[0].id) ? this.http.patch(url, body, options) : this.http.post(url, body, options));
        };
        return httpRequest(`${this.apiBase()}/bulk`, { data }, { params: this.params }).pipe(
            map((response: HttpResponse<any>) => {
                return <IBulkableResponse<T>> {
                    data: response.body.data && response.body.data.map(_data => {
                        return this.serializer.deserialize({ data: _data });
                    }),
                    meta: response.body.meta
                };
            }),
            catchError(this.handleRequestError)
        );

    }

    bulkDestroy(resources: Partial<T>[]): Observable<void> {
        return null;
    }

}
