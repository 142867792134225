
import { of as observableOf,  Observable, forkJoin } from 'rxjs';

import { map, delay } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';



import { AclRulesProvider } from '../acl-rules-provider.service';
import { IAclPrivilegeDescriptor, PrivilegeAction } from '../../acl.interfaces';
import { IPrivilege } from '../../../../sdk.index';
import { ACL_DOMAIN_OBJECTS, AclDomain, ACL_DOMAIN_PRIORITY } from '../../acl.domains';

@Injectable()
export class PrivilegeAccessResolver {

    static cache: Map<string, boolean> = new Map<string, boolean>();

    constructor(private aclRulesProvider: AclRulesProvider) {
        PrivilegeAccessResolver.cache.clear();
    }

    public resolveFromRoute(route: ActivatedRouteSnapshot): Observable<boolean> {
        let componentName = route.component ? route.component['name'] : false;
        if (!componentName) {
            componentName =  route.firstChild && route.firstChild.component ? route.firstChild.component['name'] : false;
        }

        if (!componentName) {
            return observableOf(true);
        }

        let action = PrivilegeAction.VIEW;
        if (route.data && route.data.action !== undefined) {
            action = route.data.action;
        }

        return this.hasAccessToObject(
            componentName,
            action,
            route.data.matchAllDomains
        );
    }

    public resolveFromDescriptor(descriptor: IAclPrivilegeDescriptor): Observable<boolean> {
        if (typeof descriptor.extraCond === 'boolean') {
            if (descriptor.extraCond && !descriptor.matchAll) {
                // true or something => true
                return observableOf(true);
            } else if (!descriptor.extraCond && descriptor.matchAll) {
                // false and something => false
                return observableOf(false).pipe(delay(0));
            }
        }
        let subscriptions = descriptor.domains.map((domain: string) => {
            let [domainName, actionName] = domain.split('.');
            return this.hasAccess(domainName, PrivilegeAction[actionName.toString().toUpperCase()]);
        });
        if (subscriptions.length === 0) {
            return observableOf(false).pipe(delay(0));
        }

        return forkJoin(subscriptions)
            .pipe(map(
                (results: any) => this.matchAllAssertion(results, descriptor.matchAll)));
    }

    public cleanup(): void {
        PrivilegeAccessResolver.cache.clear();
        this.aclRulesProvider.cleanup();
    }

    private hasAccessToObject(objectName: string,
                              action: PrivilegeAction,
                              matchAllDomains: boolean = true): Observable<boolean> {
        let cacheKey = this.getCacheKey(objectName, action);

        if (PrivilegeAccessResolver.cache.has(cacheKey)) {
            return observableOf(PrivilegeAccessResolver.cache.get(cacheKey));
        }

        let domains: string[] = [];
        for (let domainName of Object.keys(ACL_DOMAIN_OBJECTS)) {
            let index = ACL_DOMAIN_OBJECTS[domainName].findIndex(domainObject => objectName === domainObject);
            if (index > -1) {
                domains.push(AclDomain[domainName].toString());
            }
        }

        // objects not defined in domains, are allowed by default
        if (domains.length === 0) {
            PrivilegeAccessResolver.cache.set(cacheKey, true);
            return observableOf(true);
        }

        let subscriptions = domains.map((domain: string) => {
            return this.hasAccess(domain, action, objectName);
        });

        if (subscriptions.length === 0) {
            return observableOf(false);
        }

        return forkJoin(subscriptions)
            .pipe(map((results: boolean[]) => {
                let result = this.matchAllAssertion(results, matchAllDomains);
                PrivilegeAccessResolver.cache.set(cacheKey, result);
                return result
            }));
    }

    private hasAccess(domain: string, action: PrivilegeAction, componentName?: string): Observable<boolean> {
        if (!domain) {
            return observableOf(false);
        }

        let cacheKey = this.getCacheKey(domain.toString(), action);
        if (PrivilegeAccessResolver.cache.has(cacheKey)) {
            return observableOf(PrivilegeAccessResolver.cache.get(cacheKey));
        }

        return this.aclRulesProvider.getPrivileges().pipe(
            map(
                (privileges: IPrivilege[]) => {
                    let result: boolean = false;

                    if (domain === AclDomain[AclDomain.Any] && privileges.length > 0) {
                        result = true;
                    } else {
                        let index: number = privileges.findIndex(
                            privilege => {
                                return this.matchDomain(domain, privilege) && this.matchAction(action, privilege);
                            }
                        );
                        result = index > -1;
                    }

                    PrivilegeAccessResolver.cache.set(cacheKey, result);

                    return result;
                }
            ));
    }

    private matchDomain(domain: string, privilege: IPrivilege): boolean {
        let privilegeAclDomain: number = AclDomain[privilege.domain_object];

        return privilege.domain_object === domain ||
            ACL_DOMAIN_PRIORITY[privilegeAclDomain] > ACL_DOMAIN_PRIORITY[AclDomain[domain]];
    }

    private matchAction(action: PrivilegeAction, privilege: IPrivilege): boolean {
        let privilegeAclDomain: number = AclDomain[privilege.domain_object];

        return PrivilegeAction[privilege.action.toUpperCase()] >= action;
    }

    private getCacheKey(name: string, action: PrivilegeAction): string {
        return name + '.' + action;
    }

    private matchAllAssertion(results: boolean[], matchAll?: boolean): boolean {
        let result = false;
        if (matchAll || typeof matchAll === 'undefined') {
            if (results.indexOf(false) === -1) {
                result = true;
            }
        } else {
            if (results.indexOf(true) > -1) {
                result = true;
            }
        }

        return result;
    }
}
