import { ICollection, IBaseApiClient, IResource } from '../common/interfaces';

export interface IMobileCarrier extends IResource {
    name: string;
}

export type IMobileCarrierCollection = ICollection<IMobileCarrier>;

export interface IMobileCarrierClient extends IBaseApiClient<IMobileCarrier> {
}
