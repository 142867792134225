/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map, catchError } from 'rxjs/operators';
import { ISchema } from '../common/index';
import { ITrackingUrl, ITrackingUrlClient } from './interfaces';

import {
    IParamsBuilder,
    IInstanceCodeClient,
    instanceCodeClientT, paramsBuilderT, IResource, IRecursiveArray, ICollection
} from '../common/interfaces';
import { Field, BaseEntity, HasMany, BelongsTo } from '../common/meta';
import { BaseApiClient } from '../common/base-api-client';
import { Creative } from '../creative/creative.service';
import { ICreative } from '../creative/interfaces';
import { IPublisher } from '../publisher/interfaces';
import { Publisher } from '../publisher/publisher.entity';
import { Section } from '../section/section.entity';
import { ISection } from '../section/interfaces';
import { Advertiser } from '../advertiser/advertiser.entity';
import { IAdvertiser } from '../advertiser/interfaces';
import { Campaign } from '../campaign/campaign.entity';
import { ICampaign } from '../campaign/interfaces';
import { AuthHttp } from '../authentication/authentication.service';
import { HttpResponse } from '@angular/common/http';

export class TrackingUrl extends BaseEntity<ITrackingUrl> implements ITrackingUrl {

    public static readonly type: string = 'tracking_urls';

    @Field()
    id: string;

    @Field()
    impression_tracking_url: string;

    @Field()
    click_tracking_url: string;

    @BelongsTo({ deferredConstructor: () => Campaign })
    campaign: ICampaign | IResource;

    @BelongsTo({ deferredConstructor: () => Advertiser })
    advertiser: IAdvertiser | IResource;

    @BelongsTo({ deferredConstructor: () => Section })
    section: ISection | IResource;

    @BelongsTo({ deferredConstructor: () => Publisher })
    publisher: IPublisher | IResource;

    @HasMany({ deferredConstructor: () => Creative })
    creative: ICreative[] | IResource[];
}

@Injectable()
export class TrackingUrlClient extends BaseApiClient<ITrackingUrl>
    implements ITrackingUrlClient {

    protected static classConfig: ISchema = {
        apiResourceName: 'tracking_urls'
    };

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return TrackingUrl;
    }

    getByParams(
        campaignId: string,
        advertiserId: string,
        sectionId: string,
        publisherId: string,
        creativeIds: string[],
        include?: IRecursiveArray<string>
    ): Observable<ICollection<ITrackingUrl>> {
        let includes = this.buildIncludes(this.includesConverter.convert(include));
        if (includes) {
            this.params = this.params.set('include', includes);
        }
        this.setImpersonationParams();

        this.params = this.params.set('campaign_id', campaignId)
            .set('advertiser_id', advertiserId)
            .set('section_id', sectionId)
            .set('publisher_id', publisherId)
            .delete('creative_ids[]');
        creativeIds.forEach(
            (creativeId) => {
                this.params = this.params.append('creative_ids[]', creativeId);
            }
        );

        return this.http.get(
            this.apiBase(), { params: this.params }
            )
            .pipe(
                map((response: HttpResponse<any>) => {
                     let rawData = response.body;
                     let entities = this.serializer.deserializeMany(rawData, includes);
                     return { data: entities, meta: rawData.meta } as ICollection<ITrackingUrl>;
                }),
                catchError(this.handleRequestError)
            );
    }
}
