import { ICollection, IBaseApiClient, IResource } from '../common/index';
import { ICountry } from '../country/interfaces';
import { IRegion } from '../region/interfaces';

export interface ICity extends IResource {
    name: string;
    code: string;
    region: IRegion | IResource;
    country: ICountry | IResource;
}

export type ICityCollection = ICollection<ICity>;

export interface ICityClient extends IBaseApiClient<ICity> {
}
