/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';
import { ISchema, BaseApiClient } from '../common/index';
import { BaseEntity, Field, BelongsTo } from '../common/meta';
import {
    IInstanceCodeClient, instanceCodeClientT, paramsBuilderT,
    IParamsBuilder, IResource
} from '../common/interfaces';
import { IAdvertiser, IDomainSet, ITargetingSet, ISupplySet, ISupplySetClient } from '@platform161-client';
import { Advertiser } from '../advertiser/advertiser.entity';
import { DomainSet } from '../domain-set/domain-set.service';
import { TargetingSet } from '../targeting-set/targeting-set.entity';
import { AuthHttp } from '../authentication/authentication.service';
import { environment } from 'environments/environment';

export class SupplySet extends BaseEntity<ISupplySet> implements ISupplySet {

    public static readonly type: string = 'supply_set';

    @Field()
    id: string;

    @Field()
    origin_id: string;

    @Field()
    origin_type: string;

    @Field()
    name: string;

    @Field()
    kind: string;

    @Field()
    archived: boolean;

    @Field()
    campaigns_count: number;

    @BelongsTo({ deferredConstructor: () => Advertiser })
    advertiser: IAdvertiser | IResource;

    @BelongsTo({ deferredConstructor: () => DomainSet })
    domain_set: IDomainSet | IResource;

    @BelongsTo({ deferredConstructor: () => TargetingSet })
    targeting_set: ITargetingSet | IResource;

}

@Injectable()
export class SupplySetClient
    extends BaseApiClient<ISupplySet>
    implements ISupplySetClient {

    protected static classConfig: ISchema = {
        apiResourceName: 'supply_sets'
    };

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected apiBase(): string {
        return `${environment.api.internal}/${this.instanceCode}/${this.classConfig().apiResourceName}`;
    }

    protected implementationClass() {
        return SupplySet;
    }

}
