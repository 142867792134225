/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';

import { ISchema, BasePersistentApiClient } from '../common/index';

import {
    IRtbSeat,
    RtbSeatBelongsToRelationships,
    RtbSeatHasManyRelationships
} from './interfaces';

import {
    instanceCodeClientT, IInstanceCodeClient, paramsBuilderT,
    IParamsBuilder
} from '../common/interfaces';
import { BaseEntity, Field } from '../common/meta';
import { AuthHttp } from '../authentication/authentication.service';

export class RtbSeat extends BaseEntity<IRtbSeat> implements IRtbSeat {

    public static readonly type: string = 'rtb_seat';

    @Field()
    id: string;

    @Field()
    code: string;

    @Field()
    name: string;
}
