export {
    Filters, Measure, Period, Interval, Grouping, GenerationStatus, Status, Result, FilterAssociation,
    FilterCurrency, ReportScheduleType, ReportSendAtType
};

type ReportScheduleType = 'none' | 'daily' | 'weekly' | 'monthly' | 'date';

type ReportSendAtType = {
    hour: number;
    day_of_week: number;
    day_of_month: number;
};

type Filters = {
    agency?: FilterAssociation;
    advertiser?: FilterAssociation;
    media_plan?: FilterAssociation;
    campaign?: FilterAssociation;
    device_type?: FilterAssociation;
    device?: FilterAssociation;
    mobile_app?: FilterAssociation;
    conversion_pixel?: FilterAssociation;
    sales_manager?: FilterAssociation;
    campaign_manager?: FilterAssociation;
    rtb_report_domain?: FilterAssociation;
    postal_code?: FilterAssociation;
    inventory_source?: FilterAssociation;
    publisher?: FilterAssociation;
    site?: FilterAssociation;
    section?: FilterAssociation;
    direct_deal?: FilterAssociation;
    mobile_carrier?: FilterAssociation;
    creative?: FilterAssociation;
    size?: FilterAssociation;

    mobile_optimised?: { value: true | false };
    campaign_active?: { value: 'active' | 'inactive' };
    campaign_archived?: { value: 'archived' | 'unarchived' };
    campaign_type?: { value: 'delivery' | 'direct_deal' | 'out_of_home' };
    private_exchange?: { value: 'managed' | 'non_managed'};
    return_tag?: { value: 'return_tag' | 'non_return_tag' };
    house?: { value: 'house' | 'non_house' };

    gross_price?: FilterCurrency;
    net_price?: FilterCurrency;
    budget?: FilterCurrency;

    internal_po?: { value: string };
    external_po?: { value: string };
    pricing_type?: { value: string };

    start_on?: FilterDateValue;
    end_on?: FilterDateValue;
    pace_end_on?: FilterDateValue;

    leading_invoice?: { value: 'first_party_ui' | 'third_party_ui' };
    connection_type?: { value: 'cellular' | 'ethernet' | 'wifi' | 'unknown' };
};

type FilterAssociation = {
    exclude: boolean;
    object_ids: string[];
};

type FilterCurrency = {
    amount: string

    operator:
    '<'
    | '>'
    | '>='
    | '<='
    | '=';
};

type FilterDateValue = {
    from_date: Date,
    to_date: Date
};

type Measure =
    | 'media_cost'
    | 'landings'
    | 'advertiser_db_cr_number'
    | 'agency_fee_percentage'
    | 'agency_margin'
    | 'agency_margin_for_margin_instances'
    | 'anomaly_clicks_filtered'
    | 'media_bid_ecpm'
    | 'bids'
    | 'billable_third_party_tech_cost'
    | 'booked_budget'
    | 'budget_based_agency_margin'
    | 'clicks'
    | 'conversions'
    | 'cost_based_agency_margin'
    | 'media_ecpa'
    | 'media_ecpc'
    | 'media_ecpm'
    | 'cross_device_conversions'
    | 'cross_device_post_click_conversions'
    | 'cross_device_post_view_conversions'
    | 'ctr'
    | 'cumulative_discount'
    | 'daily_budget_cap'
    | 'data_dmp_cost'
    | 'date_c'
    | 'days_spent'
    | 'days_spent_percentage'
    | 'days_to_go'
    | 'discount_percentage'
    | 'dsp_tech_fee'
    | 'end_on'
    | 'external_purchase_order_number'
    | 'final_media_ecpm'
    | 'flight'
    | 'flight_on_time_percentage'
    | 'frequency_cap'
    | 'gross_campaign_profit'
    | 'gross_ecpa'
    | 'gross_ecpm'
    | 'gross_margin_percentage'
    | 'gross_price'
    | 'gross_revenues'
    | 'impressions'
    | 'internal_purchase_order_number'
    | 'invoice_name'
    | 'leading_invoice'
    | 'life_spent'
    | 'life_spent_percentage'
    | 'loads'
    | 'margin'
    | 'margin_percentage'
    | 'measurable_impressions'
    | 'measurable_impressions_percentage'
    | 'media_budget'
    | 'media_plan_client_order_id'
    | 'media_plan_budget'
    | 'meetrics_area_0'
    | 'meetrics_area_1'
    | 'meetrics_area_2'
    | 'meetrics_area_3'
    | 'meetrics_area_4'
    | 'net_campaign_profit'
    | 'net_ecpa'
    | 'net_ecpc'
    | 'net_ecpm'
    | 'net_margin_percentage'
    | 'net_net_ecpa'
    | 'net_net_ecpc'
    | 'net_net_margin_percentage'
    | 'net_net_profit'
    | 'net_net_revenues'
    | 'net_price'
    | 'net_revenues'
    | 'non_billable_third_party_tech_cost'
    | 'optimal_spending_per_day'
    | 'platform161_tech_fee'
    | 'post_click_conversions'
    | 'post_view_conversions'
    | 'remaining_media_budget'
    | 'remaining_budget'
    | 'spend_yesterday'
    | 'split_third_party_tech_added_cost'
    | 'start_on'
    | 'dsp_tech_fee_percentage'
    | 'total_thirdparty_tech_cost'
    | 'total_conversions'
    | 'total_post_click_conversions'
    | 'total_post_view_conversions'
    | 'total_cost'
    | 'total_cost_ecpa'
    | 'total_cost_ecpc'
    | 'total_cost_ecpm'
    | 'total_life_spent'
    | 'total_audience_impressions'
    | 'total_on_time_percentage_yesterday'
    | 'total_optimal_spend_yesterday'
    | 'total_spend_yesterday'
    | 'vast_event_collapse'
    | 'vast_event_complete'
    | 'vast_event_expand'
    | 'vast_event_first_quartile'
    | 'vast_event_midpoint'
    | 'vast_event_mute'
    | 'vast_event_pause'
    | 'vast_event_resume'
    | 'vast_event_rewind'
    | 'vast_event_skip'
    | 'vast_event_start'
    | 'vast_complete_views_percentage'
    | 'vast_event_third_quartile'
    | 'vast_event_unmute'
    | 'view_conversions'
    | 'views_type'
    | 'visible_impressions'
    | 'visible_impressions_percentage'
    | 'win_rate'
    | 'winner_adv_bid_ecpm'
    | 'high_impact_events'
    | 'creative_tag'
    | 'boostr_line_rate_booked'
    | 'boostr_line_budget_booked'
    | 'boostr_line_quantity_booked'
    | 'cost_per_visit'
    | 'attributed_visits'
    | 'visit_rate'
    | 'impression_frequency'
    | 'thirdparty_clicks'
    | 'thirdparty_impressions'
    | 'delivered_units'
    | 'billable_units'
    | 'amount_invoiced'
    | 'screens_delivering';

type Period =
    'today'
    | 'yesterday'
    | 'last_2_days'
    | 'last_7_days'
    | 'last_30_days'
    | 'month_to_date'
    | 'last_month'
    | 'range'
    | 'lifetime';

type Interval =
    'none'
    | 'hourly'
    | 'daily'
    | 'weekly'
    | 'monthly'
    | 'yearly';


type Grouping =
    'campaign_id'
    | 'advertiser_id'
    | 'agency_id'
    | 'publisher'
    | 'site_id'
    | 'section_id'
    | 'size_id'
    | 'creative_id'
    | 'sequence_phase'
    | 'rtb_report_domain'
    | 'postal_code'
    | 'country'
    | 'region'
    | 'media_plan_id'
    | 'google_verticals'
    | 'lifetime_frequency'
    | 'in_app'
    | 'metro_code'
    | 'visibility_detection'
    | 'pricing_type'
    | 'conversion_pixel_id'
    | 'sales_manager_id'
    | 'direct_deal_id'
    | 'leading_invoice'
    | 'start_on'
    | 'end_on'
    | 'pace_end_on'
    | 'device_type'
    | 'meetrics_area_0'
    | 'meetrics_area_1'
    | 'meetrics_area_2'
    | 'meetrics_area_3'
    | 'meetrics_area_4'
    | 'customer_id'
    | 'customer_extra'
    | 'customer_amount'
    | 'ua_device_model'
    | 'mobile_carrier'
    | 'conn_type'
    | 'app_name'
    | 'campaign'
    | 'media_plan'
    | 'advertiser'
    | 'agency'
    | 'section'
    | 'site'
    | 'size'
    | 'creative'
    | 'inventory_source'
    | 'conversion_pixel'
    | 'sales_manager'
    | 'direct_deal'
    | 'mobile_optimised'
    | 'operating_system'
    | 'dooh_screen'
    | 'video_placement'
    | 'event_name'
    | 'city'
    | 'region'
    | 'country'
    | 'media_plan_external_id';

type GenerationStatus =
    'failed'
    | 'expired'
    | 'pending'
    | 'running'
    | 'completed';

type Status =
    'queued'
    | 'ready'
    | 'failed';

type Result = { [key: string]: string | number };

export const LEGACY_MEASURES = {
    'remaining_booked_budget': 'remaining_budget',
    'media_plan_booked_budget': 'media_plan_budget',
    'total_campaign_cost': 'total_cost',
    'tech_fee_percentage': 'dsp_tech_fee_percentage',
    'dsp_tech_markup': 'dsp_tech_fee',
    'bid_ecpm': 'media_bid_ecpm',
    'final_ecpm': 'final_media_ecpm',
    'cost_ecpm': 'media_ecpm',
    'cost_ecpa': 'media_ecpa',
    'cost_ecpc': 'media_ecpc',
    'advertiser_cost': 'media_cost'
};

