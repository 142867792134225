import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { RedirectUrlResolverService } from './shared/acl/service/redirect-url-resolver.service';
import { IdentityStorage } from './modules/common/service/storage/identity-storage';

@Component({
    template: ``
})
export class AppRouterDispatcherComponent {
    constructor(
        private router: Router,
        private redirectUrlResolverService: RedirectUrlResolverService,
        private identityStorage: IdentityStorage
    ) {
        if (!this.identityStorage.getInstanceCode()) {
            this.router.navigateByUrl('/forbidden');
        } else if (!this.identityStorage.hasUser()) {
            this.router.navigateByUrl('/login');
        } else {
            this.redirectUrlResolverService.resolve().subscribe(
                url => this.router.navigateByUrl(url)
            );
        }
    }
}
