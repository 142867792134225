/* tslint:disable:variable-name */

import { BaseEntity, Field, BelongsTo, HasMany } from '../common/meta';
import { IResource } from '../common/interfaces';
import { ITargetingSet } from './interfaces';
import { Advertiser } from '../advertiser/advertiser.entity';
import { Campaign } from '../campaign/campaign.entity';
import { City } from '../city/city.service';
import { Region } from '../region/region.entity';
import { Domain } from '../domain/domain.service';
import { Country } from '../country/country.entity';
import { MobileApp } from '../mobile-app/mobile-app.service';
import { DesignatedMarketArea } from '../designated-market-area/designated-market-area.service';
import { LatLongTargeting } from '../campaign/interfaces';
import {
    IAdvertiser,
    ICampaign,
    ICity,
    ICountry,
    IDesignatedMarketArea,
    IDomain,
    IMobileApp,
    IRegion
} from '@platform161-client';

export class TargetingSet extends BaseEntity<ITargetingSet> implements ITargetingSet {

    public static readonly type: string = 'targeting_set';

    @Field()
    id: string;

    @Field()
    name: string;

    @Field()
    kind: string;

    @Field()
    settings: any;

    @Field()
    archived: boolean;

    @Field()
    lat_long_targeting?: LatLongTargeting[];

    @BelongsTo({ deferredConstructor: () => Advertiser })
    advertiser: IAdvertiser | IResource;

    @HasMany({ deferredConstructor: () => City })
    cities: ICity[] | IResource[];

    @HasMany({ deferredConstructor: () => Country })
    countries: ICountry[] | IResource[];

    @HasMany({ deferredConstructor: () => Campaign })
    campaigns: ICampaign[] | IResource[];

    @HasMany({ deferredConstructor: () => Region })
    regions: IRegion[] | IResource[];

    @HasMany({ deferredConstructor: () => DesignatedMarketArea })
    designated_market_areas: IDesignatedMarketArea[] | IResource[];

    @HasMany({ deferredConstructor: () => Domain })
    domains: IDomain[] | IResource[];

    @HasMany({ deferredConstructor: () => Domain })
    isp_domains: IDomain[] | IResource[];

    @HasMany({ deferredConstructor: () => MobileApp })
    mobile_apps: IMobileApp[] | IResource[];
}
