<header class="error-page__header">
    <a class="error-logo">
        <img src="/assets/img/logo_hd.png" alt="Platform161">
    </a>
</header>

<div class="row error-container">
    <div class="col-xl-4 col-md-6 col-xs-10 offset-xl-4 offset-md-3 offset-xs-1">
        <h1 class="error-code">403</h1>
        <p class="error-info">
            Opps, it seems that you do not have enough permissions to access this page.
        </p>
        <p class="error-info">
            If you are sure you should have, contact with support.
        </p>
    </div>
</div>

<div class="error-page__image">
    <img src="/assets/icon/error_page_bg.png" alt="Platform161">
</div>
