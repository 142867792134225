/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map, catchError } from 'rxjs/operators';

import { ISchema, BaseApiClient } from '../common/index';
import {
    ITagGeneration, ITagGenerationClient, ITagGenerationResult, Kind
} from './interfaces';
import {
    instanceCodeClientT, IInstanceCodeClient,
    paramsBuilderT, IParamsBuilder, IResource
} from '../common/interfaces';
import { BaseEntity, Field, HasMany } from '../common/meta';
import { BaseSerializer } from '../common/base-serializer';
import { IPublisher } from '../publisher/interfaces';
import { Publisher } from '../publisher/publisher.entity';
import { ISize } from '../size/interfaces';
import { Size } from '../size/size.service';
import { ISection } from '../section/interfaces';
import { Section } from '../section/section.entity';
import { AuthHttp } from '../authentication/authentication.service';
import { HttpResponse } from '@angular/common/http';

@Injectable()
export class TagGenerationClient
    extends BaseApiClient<ITagGeneration>
    implements ITagGenerationClient {
    protected static classConfig: ISchema = {
        apiResourceName: 'section_tags'
    };

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return TagGeneration;
    }

    generate(entity: ITagGeneration): Observable<ITagGenerationResult[]> {
        this.recreateParams();
        this.setImpersonationParams();
        let serializer = new BaseSerializer<ITagGenerationResult>(TagGenerationResult);
        let wrappedEntity = new (this.implementationClass())(entity);
        let data = this.serializer.serialize(wrappedEntity);

        return this.http.post(`${this.apiBase()}`, data, { params: this.params })
            .pipe(
                map(
                (response: HttpResponse<any>) => {
                    let deserializeResponse = serializer.deserializeMany(response.body);
                    return [...deserializeResponse] as ITagGenerationResult[];
                }),
                catchError(this.handleRequestError)
            );
    }
}

export class TagGeneration extends BaseEntity<ITagGeneration> implements ITagGeneration {

    public static readonly type: string = 'section_tags';

    @Field()
    id: string;

    @Field()
    tag?: string;

    @Field()
    kind: Kind;

    @Field()
    click_macro: boolean;

    @Field()
    click_macro_placeholder: string;

    @Field()
    vast_v2_support: boolean;

    @Field()
    cookie_compliant: boolean;

    @HasMany({ deferredConstructor: () => Publisher, apiRelationshipName: 'publishers' })
    publisher_ids: IPublisher[] | IResource[];

    @HasMany({ deferredConstructor: () => Section, apiRelationshipName: 'sections' })
    section_ids: ISection[] | IResource[];

    @HasMany({ deferredConstructor: () => Size, apiRelationshipName: 'sizes' })
    size_ids: ISize[] | IResource[];
}

export class TagGenerationResult extends BaseEntity<ITagGenerationResult> implements ITagGenerationResult {
    public static readonly type: string = 'section_tags';

    @Field()
    tag: string;
}

