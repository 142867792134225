import { ICollection, IPersistentApiClient, IResource } from '../common/interfaces';
import { ICampaign, VideoVisibility, DisplayVisibility } from '../campaign/interfaces';
import { IMediaPlan } from '../media-plan/interfaces';
import { ICountry } from '../country/interfaces';
import { IUser } from '../user/interfaces';
import { IAgency } from '../agency/interfaces';
import { IArchivable } from './../common/archivable/interfaces';
import { IActivable } from './../common/activable/interfaces';
import { IBulkRemovable } from './../common/bulk-removable/interfaces';
import { IMarket } from '../market/interfaces';
import { IDomain } from '../domain/interfaces';
import { IGoogleProgrammaticInventory } from '../google-programmatic-inventory/interfaces';

export interface IAdvertiser extends IResource {
    name: string;
    advertiser_url: string;
    credit_limit?: number;
    db_cr_number?: number;
    default_video_visibility: VideoVisibility;
    default_display_visibility: DisplayVisibility;
    email?: string;
    address?: string;
    postal_code?: string;
    city?: string;
    region?: string;
    phone?: string;
    mobile?: string;
    archived?: boolean;
    active?: boolean;
    alert_on_campaigns?: boolean;
    fax?: string;
    fee_percentage: number;
    use_business_address_for_billing?: boolean;
    billing_address?: string;
    billing_postal_code?: string;
    billing_city?: string;
    billing_region?: string;
    billing_phone?: string;
    billing_mobile?: string;
    billing_fax?: string;
    billing_email?: string;
    discount_a?: number;
    discount_b?: number;
    confirmation_emails?: string;
    external_id?: string;
    return_tag?: boolean;
    campaigns?: ICampaign[] | IResource[];
    media_plans?: IMediaPlan[] | IResource[];
    sales_manager?: IUser | IResource;
    campaign_manager?: IUser | IResource;
    agency?: IAgency | IResource;
    google_programmatic_inventories?: IGoogleProgrammaticInventory[] | IResource[];
    country: ICountry | IResource;
    campaigns_ending_in_three_days?: number;
    market: IMarket | IResource;
    domains: IDomain[] | IResource[];
    google_advertiser_id?: number;
    legal_entity_name?: string;
    paid_on_behalf_of?: string;
}

export type AdvertiserBelongsToRelationships = never;
export type AdvertiserHasManyRelationships = never;

export type IAdvertiserCollection = ICollection<IAdvertiser>;

export interface IAdvertiserClient
    extends IPersistentApiClient<IAdvertiser, AdvertiserBelongsToRelationships, AdvertiserHasManyRelationships>,
        IArchivable<IAdvertiser>,
        IActivable<IAdvertiser>,
        IBulkRemovable {
}
