/* tslint:disable:variable-name */

import { IResource } from '../common/interfaces';
import { IThirdPartyTechnologyPartner } from './interfaces';
import { BaseEntity, Field, BelongsTo, HasMany } from '../common/meta';
import { Advertiser } from '../advertiser/advertiser.entity';
import { IAdvertiser } from '../advertiser/interfaces';
import { Campaign } from '../campaign/campaign.entity';
import { ICampaign } from '../campaign/interfaces';

export class ThirdPartyTechnologyPartner
    extends BaseEntity<IThirdPartyTechnologyPartner> implements IThirdPartyTechnologyPartner {

    public static readonly type: string = 'third_party_technology_partner';

    @Field()
    id: string;

    @Field()
    name: string;

    @Field()
    cpm_fee: number;

    @Field()
    converted_cpm_fee: number;

    @Field()
    default: boolean;

    @Field()
    archived?: boolean;

    @BelongsTo({deferredConstructor: () => Advertiser})
    advertiser: IAdvertiser | IResource;

    @HasMany({deferredConstructor: () => Campaign})
    campaigns: ICampaign[] | IResource[];
}

