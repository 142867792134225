/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';

import { ISchema } from '../common/index';
export { IBaseApiClient } from '../common/interfaces';
import {
    IInventorySet, IInventorySetClient,
    InventorySetBelongsToRelationships, InventorySetHasManyRelationships
} from './interfaces';
import { BaseEntity, BelongsTo, Field, HasMany } from '../common/meta';
import {
    instanceCodeClientT, IInstanceCodeClient, paramsBuilderT,
    IParamsBuilder, IResource
} from '../common/interfaces';
import { InventorySource } from '../inventory-source/inventory-source.service';
import { Domain } from '../domain/domain.service';
import { IDomain } from '../domain/interfaces';
import { DomainSet } from '../domain-set/domain-set.service';
import { IDomainSet } from '../domain-set/interfaces';
import { DirectDeal } from '../direct-deal/direct-deal.service';
import { IDirectDeal } from '../direct-deal/interfaces';
import { ISection } from '../section/interfaces';
import { Section } from '../section/section.entity';
import { RtbProduct } from '../rtb-product/rtb-product.service';
import { IRtbProduct } from '../rtb-product/interfaces';
import { MobileApp } from '../mobile-app/mobile-app.service';
import { IMobileApp } from '../mobile-app/interfaces';
import { BasePersistentApiClient } from '../common/base-api-client';
import { Advertiser } from '../advertiser/advertiser.entity';
import { IAdvertiser } from '../advertiser/interfaces';
import { AuthHttp } from '../authentication/authentication.service';

export class InventorySet extends BaseEntity<IInventorySet> implements IInventorySet {

    public static readonly type: string = 'inventory_template';

    @Field()
    id: string;

    @Field()
    name: string;

    @HasMany({ deferredConstructor: () => InventorySource })
    inventory_sources: InventorySource[] | IResource[];

    @Field()
    rtb_domain_filter: string;

    @HasMany({ deferredConstructor: () => Domain })
    rtb_domains: IDomain[] | IResource[];

    @Field()
    rtb_domain_sets_filter: string;

    @HasMany({ deferredConstructor: () => DomainSet })
    rtb_domain_sets: IDomainSet[] | IResource[];

    @HasMany({ deferredConstructor: () => DirectDeal, apiRelationshipName: 'direct_deals' })
    direct_deals: IDirectDeal[] | IResource[];

    @Field()
    managed: boolean;

    @Field()
    section_filter: string;

    @HasMany({ deferredConstructor: () => Section })
    filtered_sections: ISection[] | IResource[];

    @Field()
    domain_filter: string;

    @HasMany({ deferredConstructor: () => Domain })
    filtered_domains: IDomain[] | IResource[];

    @HasMany({ deferredConstructor: () => RtbProduct })
    rtb_products: IRtbProduct[] | IResource[];

    @Field()
    cookie_compliancy: boolean;

    @Field()
    rewarded_video: boolean;

    @Field()
    interstitial: boolean;

    @Field()
    mobile_app_filter: string;

    @Field()
    mobile_apps_filter: string;

    @HasMany({ deferredConstructor: () => MobileApp })
    mobile_apps: IMobileApp[] | IResource[];

    @Field()
    app_bundle_filter: string;

    @Field()
    app_bundle_targeting: string;

    @BelongsTo({ deferredConstructor: () => Advertiser })
    advertiser: IAdvertiser | IResource;

    public isOutOfHome(): boolean {
        return false;
    }

}

@Injectable()
export class InventorySetClient
    extends BasePersistentApiClient<IInventorySet, InventorySetBelongsToRelationships, InventorySetHasManyRelationships>
    implements IInventorySetClient {
    protected static classConfig: ISchema = {
        apiResourceName: 'inventory_templates'
    };

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return InventorySet;
    }
}
