import { ICollection, IResource, Filter, IPersistentApiClient } from '../common/interfaces';
import { IPublisher } from '../publisher/interfaces';
import { ISite } from '../site/interfaces';
import { IAdvertiser } from '../advertiser/interfaces';
import { ISize } from '../size/interfaces';
import { ICampaign } from '../campaign/interfaces';

import { IArchivable } from '../common/archivable/interfaces';
import { IActivable } from '../common/activable/interfaces';
import { IBulkRemovable } from '../common/bulk-removable/interfaces';
import { IDuplicable } from '../common/duplicable/interfaces';

export interface ISection extends IResource {
    name: string;
    price: number;
    pricing_type: SectionPricingType;
    advertiser_filter: Filter;
    campaign_filter: Filter;
    brand_protection: boolean;
    shared: boolean;
    excluded_pricing_types: string[];
    active: boolean;
    archived: boolean;

    filtered_advertisers: IAdvertiser[] | IResource[];
    filtered_campaigns: ICampaign[] | IResource[];
    publisher: IPublisher | IResource;
    site: ISite | IResource;
    advertiser: IAdvertiser | IResource;
    sizes: ISize[] | IResource[];
}

export type SectionPricingType = 'cpm' | 'rev_share';

export type ISectionCollection = ICollection<ISection>;

export type SectionBelongsToRelationships = never;
export type SectionHasManyRelationships = never;

export interface ISectionError {
    url: string;
    errors: string[];
}

export interface ISectionClient
    extends IPersistentApiClient<ISection, SectionBelongsToRelationships, SectionHasManyRelationships>,
IArchivable<ISection>,
IActivable<ISection>,
    IBulkRemovable,
IDuplicable<ISection> {
}
