/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { map, catchError } from 'rxjs/operators';

import { ISchema } from '../../common/index';
import { BaseEntity, Field } from '../../common/meta';
import {
    IInstanceCodeClient, instanceCodeClientT, paramsBuilderT,
    IParamsBuilder
} from '../../common/interfaces';
import { SupplyType } from '@platform161-client';

import {
    ICampaignConfirmationEmail, ICampaignConfirmationEmailClient, ICampaignConfirmationEmailClientFactory
} from './interfaces';
import { BaseApiClient } from '../../common/base-api-client';
import { AuthHttp } from '../../authentication/authentication.service';
import { environment } from 'environments/environment';

export class CampaignConfirmationEmail
    extends BaseEntity<ICampaignConfirmationEmail>
    implements ICampaignConfirmationEmail {

    public static readonly type: string = 'confirmation_emails';

    @Field()
    connection_type_targeting: string;

    @Field()
    name: string;

    @Field()
    impressions: string;

    @Field()
    price: string;

    @Field()
    media_budget: string;

    @Field()
    booked_budget: string;

    @Field()
    start_on: string;

    @Field()
    end_on: string;

    @Field()
    isp_domain_filter: string;

    @Field()
    targeting_logic: string;

    @Field()
    supply_types: SupplyType[];

    @Field()
    weekdays: string;

    @Field()
    hours: string;

    @Field()
    vpaid_targeting?: string;

    @Field()
    player_size_targeting?: string;

    @Field()
    secure_traffic_targeting?: string;

    @Field()
    visibility_targeting: string;

    @Field()
    appnexus_segment_id_targeting?: string;

    @Field()
    device_types: string;

    @Field()
    isp_domains: string;

    @Field()
    advertiser: string;

    @Field()
    media_plan: string;

    @Field()
    devices: string;

    @Field()
    browsers: string;

    @Field()
    operating_systems: string;

    @Field()
    mobile_carriers: string;

    @Field()
    languages: string;

    @Field()
    content_categories: string;

    @Field()
    creatives: string[];
}

@Injectable()
export class CampaignConfirmationEmailClientFactory implements ICampaignConfirmationEmailClientFactory {

    constructor(
        private http: AuthHttp,
        @Inject(instanceCodeClientT) private instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) private paramsBuilder: IParamsBuilder
    ) {
    }

    createCampaignConfirmationEmailClient(
        campaignId: string
    ): CampaignConfirmationEmailClient {
        return new CampaignConfirmationEmailClient(
            campaignId,
            this.http,
            this.instanceCodeClient,
            this.paramsBuilder);
    }
}

export class CampaignConfirmationEmailClient
    extends BaseApiClient<ICampaignConfirmationEmail>
    implements ICampaignConfirmationEmailClient {

    protected static classConfig: ISchema = {
        apiResourceName: 'confirmation_emails'
    };

    constructor(
        private campaignId: string,
        http: AuthHttp,
        instanceCodeClient: IInstanceCodeClient,
        paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    sendMail(entity: ICampaignConfirmationEmail): Observable<ICampaignConfirmationEmail> {
        this.setImpersonationParams();
        let wrappedEntity = new (this.implementationClass())(entity);
        let data = this.serializer.serialize(wrappedEntity);

        return this.http.post(`${ this.apiBase() }`, data)
            .pipe(
                map((response: HttpResponse<any>) => {
                    return this.serializer.deserialize(response.body);
                }),
                catchError(this.handleRequestError)
            );
    }

    protected apiBase(): string {
        return `${environment.api.base}/${this.instanceCode}/campaigns/${this.campaignId}/${this.classConfig().apiResourceName}`;
    }

    protected implementationClass() {
        return CampaignConfirmationEmail;
    }
}
