import { ICollection, IBaseApiClient, IResource } from '../common/index';

export interface IAdServer extends IResource {
    name: string;
    code: string;
}

export type IAdServerCollection = ICollection<IAdServer>;

export interface IAdServerClient extends IBaseApiClient<IAdServer> { }
