/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map, catchError } from 'rxjs/operators';

import { BaseApiClient } from '../../common/index';
import { BaseSerializer } from '../../common/base-serializer';
import { BaseEntity, Field } from '../../common/meta';
import {
    IInstanceCodeClient, ICollection, IParam, instanceCodeClientT
} from '../../common/interfaces';
import {
    ICreativeSubmission, ISubmissionStatus, ICreativeSubmissionClient, Approver, SubmissionStatusValue
} from './interfaces';
import { AuthHttp } from '../../authentication/authentication.service';
import { HttpResponse } from '@angular/common/http';
import { environment } from 'environments/environment';

export class SubmissionStatus extends BaseEntity<ISubmissionStatus> implements ISubmissionStatus {
    public static readonly type: string = 'approval_status';

    @Field()
    approver?: string;

    @Field()
    approver_id?: string;

    @Field()
    inventory?: string;

    @Field()
    status?: SubmissionStatusValue;

    @Field()
    rejection_reason?: string;

    @Field()
    updated_at?: string;
}

class CreativeSubmission extends BaseEntity<ICreativeSubmission> implements ICreativeSubmission {

    public static readonly type: string = 'creative_submission';

    @Field()
    statuses: ISubmissionStatus[];

}

@Injectable()
export class CreativeSubmissionClient extends BaseApiClient<ICreativeSubmission>
    implements ICreativeSubmissionClient {

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient
    ) {
        super(http, instanceCodeClient, null);
    }

    protected apiBase(): string {
        return `${ environment.api.base }/${ this.instanceCode }/creatives`;
    }

    get(id: string): Observable<ICreativeSubmission> {
        this.setImpersonationParams();
        let serializer = new BaseSerializer<ICreativeSubmission>(CreativeSubmission);
        return this.http.get(`${ this.apiBase() }/${ id }/approval_statuses`)
            .pipe(
                map((response: HttpResponse<any>) => { return serializer.deserialize(response.body); })
            );
    }

    getAll(params?: IParam): Observable<ICollection<ICreativeSubmission>> {
        throw 'not available';
    }

    submitApproval(id: string, approver: Approver): Observable<ICreativeSubmission> {
        this.setImpersonationParams();
        let serializer = new BaseSerializer<ICreativeSubmission>(CreativeSubmission);
        return this.http.post(`${ this.apiBase() }/${ id }/submit/${ approver }`, null)
            .pipe(
                map((response: HttpResponse<any>) => { return serializer.deserialize(response.body); }),
                catchError(this.handleRequestError)
            );
    }

    protected implementationClass() {
        return CreativeSubmission;
    }

}
