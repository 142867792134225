import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

import { ISchema, BasePersistentApiClient } from '../common/index';
import {
    ISite,
    ISiteClient,
    SiteBelongsToRelationships,
    SiteHasManyRelationships
} from './interfaces';
import {
    instanceCodeClientT, IInstanceCodeClient, paramsBuilderT,
    IParamsBuilder, IParam, IRecursiveArray
} from '../common/interfaces';
import { IChangeable, IChangeCollection } from '../change/interfaces';
import { IComment, IPersistentComment } from '../comment/interfaces';
import { Archivable } from '../common/archivable/archivable';
import { BulkRemovable } from '../common/bulk-removable/bulk-removable';
import { IBulkResponse } from './../common/errors';
import { Duplicable } from './../common/duplicable/duplicable';
import { Changeable } from '../common/changeable/changeable';
import { AuthHttp } from '../authentication/authentication.service';
import { Site } from './site.entity';

@Injectable()
@Archivable()
@BulkRemovable()
@Duplicable()
@Changeable()
export class SiteClient extends BasePersistentApiClient<ISite, SiteBelongsToRelationships, SiteHasManyRelationships>
    implements ISiteClient, IChangeable {
    protected static classConfig: ISchema = {
        apiResourceName: 'sites'
    };

    archive: (id: string) => Observable<ISite>;
    unarchive: (id: string) => Observable<ISite>;
    bulkRemove: (ids: string[]) => Observable<IBulkResponse[]>;
    duplicate: (id: string) => Observable<ISite>;

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    getChanges(
        changeableId: string, params?: IParam, include?: IRecursiveArray<string>
    ): Observable<IChangeCollection> {
        throw new Error('Method not implemented.');
    }

    addChangeComment(note: IPersistentComment, changeableId: string): Observable<IComment> {
        throw new Error('Method not implemented.');
    }

    protected implementationClass() {
        return Site;
    }

}
