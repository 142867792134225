/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';

import { IGoogleVertical, IGoogleVerticalClient } from './interfaces';
import { BaseEntity, Field, BelongsTo } from '../common/meta';
import { ISchema, BaseApiClient } from '../common/index';
import { IResource } from '../common/interfaces';

import { instanceCodeClientT, IInstanceCodeClient, paramsBuilderT, IParamsBuilder } from '../common/interfaces';
import { AuthHttp } from '../authentication/authentication.service';

export class GoogleVertical extends BaseEntity<IGoogleVertical> implements IGoogleVertical {

    public static readonly type: string = 'google_vertical';

    @Field()
    id: string;

    @Field()
    name: string;

    @BelongsTo({ deferredConstructor: () => GoogleVertical })
    parent: IGoogleVertical | IResource;

}

@Injectable()
export class GoogleVerticalClient extends BaseApiClient<IGoogleVertical> implements IGoogleVerticalClient {
    protected static classConfig: ISchema = {
        apiResourceName: 'google_verticals'
    };

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return GoogleVertical;
    }

}
