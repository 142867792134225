import { ICollection, IPersistentApiClient, IResource } from '../common/interfaces';
import { IAdvertiser } from '../advertiser/interfaces';
import { IBulkRemovable } from '../common/bulk-removable/interfaces';

export interface IMarket extends IResource {
    external_id: string;
    name: string;
    advertiser: IAdvertiser | IResource;
}

export type IMarketCollection = ICollection<IMarket>;

export type MarketBelongsToRelationships = never;
export type MarketHasManyRelationships = never;

export interface IMarketClient
    extends IPersistentApiClient<IMarket, MarketBelongsToRelationships, MarketHasManyRelationships>, IBulkRemovable {

}
