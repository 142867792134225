import {
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component, Input, OnInit, ViewEncapsulation
} from '@angular/core';
import { AccessResolverProxy } from '../service/access-resolver-proxy.service';
import { IAclFeatureDescriptor } from '../acl.interfaces';

/**
 * Usage
 *
 * ```
 * <acl-feature [descriptor]="{domains: ['...']}">...</acl-feature>
 * ```
 */
@Component({
    selector: 'acl-feature, [acl-feature]',
    template: `<ng-content *ngIf="render"></ng-content>`,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeatureComponent implements OnInit {

    @Input() descriptor: IAclFeatureDescriptor;

    render: boolean = false;

    constructor(private accessResolver: AccessResolverProxy,
                private changeDetector: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        if (!this.descriptor) {
            this.render = true;
            this.changeDetector.markForCheck();
            return;
        }
        this.accessResolver.hasFeature(this.descriptor)
            .subscribe(
                (hasAccess: boolean) => {
                    this.render = hasAccess;
                    this.changeDetector.markForCheck();
                }
            )
        ;
    }
}
