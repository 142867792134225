/* tslint:disable:variable-name */

import { IPublisher } from './interfaces';
import { IResource } from '../common/interfaces';
import { HasMany, BaseEntity, Field, BelongsTo } from '../common/meta';
import { Section } from '../section/section.entity';
import { ISection } from '../section/interfaces';
import { ICountry } from '../country/interfaces';
import { Country } from '../country/country.entity';
import { Advertiser } from '../advertiser/advertiser.entity';
import { IAdvertiser } from '../advertiser/interfaces';
import { Site } from '../site/site.entity';
import { ISite } from '../site/interfaces';

export class Publisher extends BaseEntity<IPublisher> implements IPublisher {

    public static readonly type: string = 'publisher';

    @Field()
    id: string;

    @Field()
    name: string;

    @Field()
    code: string;

    @Field()
    credit_limit: number;

    @Field()
    db_cr_number: string;

    @Field()
    address: string;

    @Field()
    postal_code: string;

    @Field()
    active: boolean;

    @Field()
    archived: boolean;

    @Field()
    phone: string;

    @Field()
    mobile: string;

    @Field()
    fax: string;

    @Field()
    city: string;

    @Field()
    region: string;

    @BelongsTo({ deferredConstructor: () => Country })
    country: ICountry | IResource;

    @Field()
    email: string;

    @Field()
    is_publisher: boolean;

    @Field()
    filter_anomalies: boolean;

    @Field()
    use_business_address_for_billing: boolean;

    @Field()
    billing_address: string;

    @Field()
    billing_region: string;

    @Field()
    billing_postal_code: string;

    @Field()
    billing_city: string;

    @Field()
    billing_phone: string;

    @Field()
    billing_mobile: string;

    @Field()
    billing_fax: string;

    @Field()
    billing_email: string;

    @BelongsTo({ deferredConstructor: () => Country })
    billing_country: ICountry | IResource;

    @HasMany({ deferredConstructor: () => Section })
    sections: ISection[] | IResource[];

    @HasMany({ deferredConstructor: () => Site })
    sites: ISite[] | IResource[];

    @BelongsTo({ deferredConstructor: () => Advertiser, optional: true })
    advertiser: IAdvertiser | IResource;
}

