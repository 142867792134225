import { forwardRef, Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

import { PrivilegeAccessResolver } from '../access-resolver/privilege';
import { IAclFeatureDescriptor, IAclPrivilegeDescriptor } from '../../acl.interfaces';
import { FeatureAccessResolver } from '../access-resolver/feature';

@Injectable()
export class AccessResolverHelper {

    constructor(
        @Inject(forwardRef(() => PrivilegeAccessResolver)) private privilegeAccessResolver: PrivilegeAccessResolver,
        @Inject(forwardRef(() => FeatureAccessResolver)) private featureAccessResolver: FeatureAccessResolver
    ) {}

    public hasPrivilege(descriptor: IAclPrivilegeDescriptor): Observable<boolean> {
        return this.privilegeAccessResolver.resolveFromDescriptor(descriptor);
    }

    public hasFeature(descriptor: IAclFeatureDescriptor): Observable<boolean> {
        return this.featureAccessResolver.resolveFromDescriptor(descriptor);
    }
}
