/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';

import { ISchema, BasePersistentApiClient } from '../common/index';

import {
    IRtbUrl,
    IRtbUrlClient, IRtbUrlClientFactory,
    RtbUrlBelongsToRelationships,
    RtbUrlHasManyRelationships
} from './interfaces';

import {
    instanceCodeClientT, IInstanceCodeClient, paramsBuilderT,
    IParamsBuilder
} from '../common/interfaces';
import { BaseEntity, Field } from '../common/meta';
import { AuthHttp } from '../authentication/authentication.service';
import { environment } from 'environments/environment';

export class RtbUrl extends BaseEntity<IRtbUrl> implements IRtbUrl {

    public static readonly type: string = 'rtb_url';

    @Field()
    id: string;

    @Field()
    url: string;
}

@Injectable()
export class RtbUrlClientFactory implements IRtbUrlClientFactory {

    constructor(
        private http: AuthHttp,
        @Inject(instanceCodeClientT) private instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) private paramsBuilder: IParamsBuilder
    ) {
    }

    createRtbUrlClient(campaignId: string): RtbUrlClient {
        return new RtbUrlClient(campaignId, this.http, this.instanceCodeClient, this.paramsBuilder);
    }
}

export class RtbUrlClient
    extends BasePersistentApiClient<IRtbUrl,
        RtbUrlBelongsToRelationships,
        RtbUrlHasManyRelationships>
    implements IRtbUrlClient {

    protected static classConfig: ISchema = {
        apiResourceName: 'rtb_urls'
    };

    protected apiBase(): string {
        return `${environment.api.base}/${this.instanceCode}/campaigns/${this.campaignId}/relationships/${this.classConfig().apiResourceName}`;
    }

    constructor(private campaignId: string,
                http: AuthHttp,
                instanceCodeClient: IInstanceCodeClient,
                paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return RtbUrl;
    }
}
