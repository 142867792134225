import { ICollection, IBaseApiClient, IResource } from '../common/index';
import { ICity } from '../city/interfaces';
import { IRegion } from '../region/interfaces';

export interface ICountry extends IResource {
    name: string;
    code: string;
    currency: string;
    vat_percentage: number;
    cities: ICity[] | IResource[];
    regions: IRegion[] | IResource[];
}

export type ICountryCollection = ICollection<ICountry>;

export interface ICountryClient extends IBaseApiClient<ICountry> {
}
