import { IArchivable } from './../common/archivable/interfaces';
import { ICollection, IResource, IPersistentApiClient } from '../common/interfaces';

import { IInventorySource } from '../inventory-source/interfaces';
import { IAdvertiser } from '../advertiser/interfaces';
import { IDoohPublisher } from '../dooh_publisher/interfaces';
import { ISize } from '../size/interfaces';
import { ICountry } from '../country/interfaces';
import { ICampaign } from '../campaign/interfaces';
import { ICampaignsDirectDeal } from '../campaigns-direct-deal/interfaces';
import { IBulkRemovable } from './../common/bulk-removable/interfaces';

export interface IDirectDeal extends IResource {
    name: string;
    deal_id: string;
    start_on: string;
    end_on: string;
    price: number;
    inventory_description: string;
    rich_media: boolean;
    rich_media_format: string;
    archived: boolean;
    inventory_source: IInventorySource | IResource;
    advertiser: IAdvertiser | IResource;
    dooh_publisher: IDoohPublisher | IResource;
    sizes: ISize[] | IResource[];
    countries: ICountry[] | IResource[];
    campaigns: ICampaign[] | IResource[];
    campaigns_direct_deals: ICampaignsDirectDeal[] | IResource[];
    statusForCampaign(campaignId: string): string;
    bidPriceForCampaign(campaignId: string): number;
}

export type IDirectDealCollection = ICollection<IDirectDeal>;

export type DirectDealBelongsToRelationships = never;
export type DirectDealHasManyRelationships = 'countries' | 'campaigns';

export interface IDirectDealClient
    extends IPersistentApiClient<IDirectDeal, DirectDealBelongsToRelationships, DirectDealHasManyRelationships>,
    IArchivable<IDirectDeal>,
    IBulkRemovable {
}
