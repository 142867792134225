import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ISchema } from '../common/index';
import {
    ISection, ISectionClient, SectionBelongsToRelationships, SectionHasManyRelationships
} from './interfaces';

import {
    IParam,
    IParamsBuilder,
    IInstanceCodeClient,
    instanceCodeClientT,
    paramsBuilderT,
    IRecursiveArray
} from '../common/interfaces';
import { BasePersistentApiClient } from '../common/base-api-client';

import { IChangeable, IChangeCollection } from '../change/interfaces';
import { INote, INoteable, INoteCollection } from '../note/interfaces';
import { IComment, IPersistentComment } from '../comment/interfaces';

import { Archivable } from '../common/archivable/archivable';
import { Activable } from '../common/activable/activable';
import { BulkRemovable } from '../common/bulk-removable/bulk-removable';
import { Duplicable } from '../common/duplicable/duplicable';

import { IBulkResponse } from './../common/errors';
import { Changeable } from '../common/changeable/changeable';
import { Noteable } from '../common/noteable/noteable';
import { AuthHttp } from '../authentication/authentication.service';
import { Section } from './section.entity';

@Injectable()
@Archivable()
@Activable()
@Duplicable()
@BulkRemovable()
@Changeable()
@Noteable()
export class SectionClient
    extends BasePersistentApiClient<ISection, SectionBelongsToRelationships, SectionHasManyRelationships>
    implements ISectionClient, IChangeable, INoteable {

    protected static classConfig: ISchema = {
        apiResourceName: 'sections'
    };

    archive: (id: string) => Observable<ISection>;
    unarchive: (id: string) => Observable<ISection>;
    activate: (id: string) => Observable<ISection>;
    deactivate: (id: string) => Observable<ISection>;
    duplicate: (id: string) => Observable<ISection>;
    bulkRemove: (ids: string[]) => Observable<IBulkResponse[]>;

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    getChanges(
        changeableId: string, params?: IParam, include?: IRecursiveArray<string>
    ): Observable<IChangeCollection> {
        throw new Error('Method not implemented.');
    }

    addChangeComment(note: IPersistentComment, changeableId: string): Observable<IComment> {
        throw new Error('Method not implemented.');
    }

    getNotes(noteableId: string, params?: IParam, include?: IRecursiveArray<string>): Observable<INoteCollection> {
        throw new Error('Method not implemented.');
    }

    addNote(note: INote): Observable<INote> {
        throw new Error('Method not implemented.');
    }

    addNoteComment(note: IPersistentComment): Observable<IComment> {
        throw new Error('Method not implemented.');
    }

    protected implementationClass() {
        return Section;
    }
}
