import { ICollection, IBaseApiClient, IResource } from '../common/interfaces';

export interface IGridView extends IResource {
    name: string;
    resource_name: ResourceName;
    columns: string[];
}

export type ResourceName = 'campaign' | 'media_plan' | string;

export type IGridViewCollection = ICollection<IGridView>;

export interface IGridViewClient extends IBaseApiClient<IGridView> {
}
