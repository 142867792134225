import { ICollection, IPersistentApiClient, IResource } from '../common/interfaces';
import { IConversionPixel } from '../conversion-pixel/interfaces';

export interface ICampaignConversionPixel extends IResource {
    post_click_price: number;
    post_view_price: number;
    optimizing: boolean;
    view_price: number;
    loads: number;
    conversions: number;
    post_view_conversions: number;
    post_click_conversions: number;
    cross_device_post_click_conversions?: number;
    cross_device_post_view_conversions?: number;
    total_post_click_conversions?: number;
    total_post_view_conversions?: number;
    total_conversions?: number;
    cross_device_conversions?: number;
    gross_price?: number;
    conversion_pixel: IConversionPixel | IResource;
}

export type ICampaignConversionPixelCollection = ICollection<ICampaignConversionPixel>;

export type CampaignConversionPixelBelongsToRelationships = never;
export type CampaignConversionPixelHasManyRelationships = never;

export interface ICampaignConversionPixelClientFactory {
    createCampaignConversionPixelClient(campaignId: string): ICampaignConversionPixelClient;
}

export interface ICampaignConversionPixelClient
    extends IPersistentApiClient<ICampaignConversionPixel,
        CampaignConversionPixelBelongsToRelationships,
        CampaignConversionPixelHasManyRelationships> {
}

