import isEmpty from 'lodash-es/isEmpty';
import omit from 'lodash-es/omit';
import hasIn from 'lodash-es/hasIn';
import forEach from 'lodash-es/forEach';
import find from 'lodash-es/find';

import {
    ISerializer,
    IResource,
    Serializable,
    AttributeMetadata,
    RelationshipMetadata, IEntityConstructor
} from './interfaces';

import {
    HAS_MANY_META,
    BELONGS_TO_META,
    ATTRIBUTE_META
} from './meta';

export class BaseSerializer<T extends IResource> implements ISerializer<T> {

    private type: string;

    constructor(private entityClass: IEntityConstructor<T>) {
        this.type = entityClass.type;
    }

    serialize(entity: Serializable<T>): any {
        let serialized: any = {
            data: {
                type: this.type
            }
        };
        if (entity.id) {
            serialized.data.id = entity.id.toString();
        }
        let attributes = this.serializeAttributes(entity);
        if (!isEmpty(attributes)) {
            serialized.data.attributes = attributes;
        }
        let relationships = this.serializeRelationships(entity);
        if (!isEmpty(relationships)) {
            serialized.data.relationships = relationships;
        }
        return serialized;
    }

    deserialize(data: any, includes?: string): Serializable<T> {
        if (!data ||
            !hasIn(data, 'data.type') ||
            !hasIn(data, 'data.id') ||
            !data.data.id) {
            return null;
        }
        let attributes: { [key: string]: AttributeMetadata<any, any> } = Reflect.getMetadata(ATTRIBUTE_META, this.entityClass);
        let hasMany: { [key: string]: RelationshipMetadata } = Reflect.getMetadata(HAS_MANY_META, this.entityClass);
        let belongsTo: { [key: string]: RelationshipMetadata } = Reflect.getMetadata(BELONGS_TO_META, this.entityClass);
        let processedAttributes: any = this.deserializeAttributes(data.data, attributes);
        let processedRelationships: any = this.deserializeRelationships(data, hasMany, belongsTo, includes);

        let processedData: any = { ...processedAttributes, ...processedRelationships };

        if (isEmpty(processedData)) {
            return null;
        } else {
            return new this.entityClass(processedData);
        }
    }

    deserializeMany(data: any, includes?: string): Serializable<T>[] {
        if ('data' in data) {
            let info = omit(data, 'data');
            let entities = [];
            forEach(data['data'], (element: any) => {
                let parsed = this.deserialize({ data: element, ...info }, includes);
                if (parsed) {
                    entities.push(parsed);
                }
            });
            return entities;
        } else {
            return [];
        }
    }

    private prepareIncludes(includes: string): any {
        /*
         for the case like "campaign,campaign.advertiser,campaign.advertiser.campaigns,offers"
         includes field should be parsed like: {
         campaign: 'advertiser,advertiser.campaigns',
         offers: ''
         }
         */
        let includesArray = includes.split(',');
        let parsedIncludes: any = {};
        forEach(includesArray, (element: string) => {
            let nestedIncludes = element.split('.');
            if (nestedIncludes.length > 1) {
                let key = nestedIncludes[0];
                let value = nestedIncludes.slice(1).join('.');
                if (key in parsedIncludes && parsedIncludes[key]) {
                    parsedIncludes[key] = [parsedIncludes[key], value].join(',');
                } else {
                    parsedIncludes[key] = value;
                }
            } else {
                if (!(element in parsedIncludes)) {
                    parsedIncludes[element] = '';
                }
            }
        });

        return parsedIncludes;
    }

    private deserializeAttributes(data: any, attributesMeta: { [key: string]: AttributeMetadata<any, any> }): any {
        let result = {};
        let attributes = { id: data.id, ...(data.attributes || {}) };
        forEach(attributesMeta || {}, (attributeMeta: AttributeMetadata<any, any>, key: string) => {

            let value = attributes[attributeMeta.foreignKey];
            let preparedValue = null;

            if (attributeMeta.fromApi) {
                preparedValue = attributeMeta.fromApi(value);
            } else {
                preparedValue = value;
            }

            if (preparedValue !== undefined) {
                result[key] = preparedValue;
            }
        });
        return result;
    }

    private deserializeRelationships(
        data: any,
        hasMany: { [key: string]: RelationshipMetadata },
        belongsTo: { [key: string]: RelationshipMetadata },
        includes?: string
    ): any {
        let result = {};
        let relationships = data.data.relationships || {};
        let included = data.included;
        let preparedIncludes = null;
        if (includes) {
            preparedIncludes = this.prepareIncludes(includes);
        }
        forEach(hasMany || {}, (relationshipMeta: RelationshipMetadata, key: string) => {
            if (!(relationshipMeta.foreignKey in relationships)) {
                return;
            }
            let relationship = relationships[relationshipMeta.foreignKey];
            if (relationship && relationship['data'] && Array.isArray(relationship['data'])) {
                result[key] = relationship['data'].filter((relData: any) => {
                    return 'id' in relData && relData.id !== undefined && relData.id !== null;
                }).map((relData: any) => {
                    if (preparedIncludes && relationshipMeta.foreignKey in preparedIncludes) {
                        let ctor = relationshipMeta.typeResolver();
                        let newData = find(included, { 'id': relData.id, 'type': relData.type });
                        if (newData) {
                            let serializer = new BaseSerializer(ctor);
                            return serializer.deserialize({
                                data: newData,
                                included: included
                            }, preparedIncludes[relationshipMeta.foreignKey]);
                        } else {
                            return { 'id': relData.id, 'loaded': false } as IResource;
                        }
                    }
                    return { 'id': relData.id, 'loaded': false } as IResource;
                });
            } else {
                result[key] = [];
            }
        });
        forEach(belongsTo || {}, (relationshipMeta: RelationshipMetadata, key: string) => {
            if (!(relationshipMeta.foreignKey in relationships)) {
                return;
            }
            let relationship = relationships[relationshipMeta.foreignKey];
            if (relationship && hasIn(relationship, 'data.id')) {
                let relData = relationship['data'];
                if (relData['id'] !== undefined && relData['id'] !== null) {
                    if (preparedIncludes && relationshipMeta.foreignKey in preparedIncludes) {
                        let ctor = relationshipMeta.typeResolver();
                        let newData = find(included, { 'id': relData.id, 'type': relData.type });
                        if (newData) {
                            let serializer = new BaseSerializer(ctor);
                            result[key] = serializer.deserialize({
                                data: newData,
                                included: included
                            }, preparedIncludes[relationshipMeta.foreignKey]);
                        } else {
                            result[key] = { 'id': relData.id, 'loaded': false } as IResource;
                        }
                    } else {
                        result[key] = { 'id': relData.id, 'loaded': false } as IResource;
                    }
                } else {
                    result[key] = null;
                }
            } else {
                result[key] = null;
            }
        });
        return result;
    }

    private serializeRelationships(entity: Serializable<T>): any {
        let relationships = {};
        if (!isEmpty(entity.hasMany)) {
            forEach(entity.hasMany, (relationshipMeta: RelationshipMetadata, relationType: string) => {
                if (relationType in entity) {
                    let ctor = relationshipMeta.typeResolver();
                    let relationshipArray: Serializable<IResource>[] = entity[relationType];
                    relationships[relationshipMeta.foreignKey] = { data: [] };
                    if (relationshipArray && Array.isArray(relationshipArray)) {
                        forEach(relationshipArray, (relationship: Serializable<IResource>) => {
                            if (!relationship.isEmpty && relationship.id) {
                                relationships[relationshipMeta.foreignKey].data.push({
                                    type: ctor.type,
                                    id: relationship.id
                                });
                            }
                        });
                    }
                }
            });
        }
        if (!isEmpty(entity.belongsTo)) {
            forEach(entity.belongsTo, (relationshipMeta: RelationshipMetadata, relationType: string) => {
                if (relationType in entity) {
                    let ctor = relationshipMeta.typeResolver();
                    let relationship: Serializable<IResource> = entity[relationType];
                    relationships[relationshipMeta.foreignKey] = { data: null };
                    if (relationship && !relationship.isEmpty && relationship.id) {
                        relationships[relationshipMeta.foreignKey].data = {
                            type: ctor.type,
                            id: relationship.id
                        };
                    }
                }
            });
        }
        return relationships;
    }

    private serializeAttributes(entity: Serializable<T>): any {
        let attributes = {};
        if (!isEmpty(entity.attributes)) {
            forEach(entity.attributes, (attributeMeta: AttributeMetadata<any, any>, attributeName: string) => {
                if (attributeName in entity) {
                    let attribute = entity[attributeName];
                    let preparedValue = null;

                    if (attributeMeta.toApi) {
                        preparedValue = attributeMeta.toApi(attribute);
                    } else {
                        preparedValue = attribute;
                    }

                    if (preparedValue !== undefined) {
                        attributes[attributeMeta.foreignKey] = preparedValue;
                    }
                }
            });
        }
        return attributes;
    }
}
