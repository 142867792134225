import { IAdvertiser } from '../advertiser/interfaces';
import { IArchivable } from './../common/archivable/interfaces';
import { IBulkRemovable } from './../common/bulk-removable/interfaces';
import {
    ICampaign,
    ICity,
    ICollection,
    ICountry,
    IDesignatedMarketArea,
    IDomain,
    IMobileApp,
    IPersistentApiClient,
    IRegion,
    IResource
} from '@platform161-client';
import { LatLongTargeting } from '../campaign/interfaces';

export interface ITargetingSet extends IResource {
    name: string;
    kind: string;
    archived: boolean;
    settings: any;
    lat_long_targeting?: LatLongTargeting[];
    advertiser: IResource | IAdvertiser;
    campaigns: ICampaign[] | IResource[];
    cities: IResource[] | ICity[];
    countries: IResource[] | ICountry[];
    regions: IResource[] | IRegion[];
    designated_market_areas: IDesignatedMarketArea[] | IResource[];
    domains: IResource[] | IDomain[];
    isp_domains: IResource[] | IDomain[];
    mobile_apps: IResource[] | IMobileApp[];
}

export type ITargetingSetCollection = ICollection<ITargetingSet>;

export type TargetingSetBelongsToRelationships = never;
export type TargetingSetHasManyRelationships = 'mobile_apps' | 'isp_domains' | 'countries' | 'regions';

export interface ITargetingSetClient
    extends IPersistentApiClient<ITargetingSet, TargetingSetBelongsToRelationships, TargetingSetHasManyRelationships>,
        IArchivable<ITargetingSet>,
        IBulkRemovable {
}
