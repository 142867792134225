
import { of as observableOf, throwError as observableThrowError,  Observable } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router,
    RouterStateSnapshot
} from '@angular/router';

import { PrivilegeAccessResolver } from './access-resolver/privilege';
import { FeatureAccessResolver } from './access-resolver/feature';
import { AclRulesProvider } from './acl-rules-provider.service';
import { IPrivilege } from '../../../sdk.index';
import { ErrorResponse } from '../../../sdk.index';

@Injectable()
export class AclGuard implements CanActivate, CanActivateChild {

    constructor(private router: Router,
                private accessResolver: PrivilegeAccessResolver,
                private featureAccessResolver: FeatureAccessResolver,
                private aclRulesProvider: AclRulesProvider,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {

        return this.aclRulesProvider.getPrivileges()
            .pipe(
                mergeMap(
                (privileges: IPrivilege[]) => {
                    if (privileges.length === 0) {
                        return observableThrowError('No privileges');
                    }

                    return observableOf(route);
                }
            ),
                mergeMap((theRoute) => {
                    return this.accessResolver.resolveFromRoute(theRoute).pipe(
                        map(
                            (allowed: boolean) => {
                                if (!allowed) {
                                    this.router.navigate(['/app/dashboard']);
                                    return false;
                                }
                                return true;
                            }
                        ));
                }),
                mergeMap((allowed: boolean) => {
                    if (allowed && route.data['features_descriptor']) {
                        return this.featureAccessResolver.resolveFromDescriptor(route.data['features_descriptor'])
                            .pipe(
                                map(
                                (allowedByFeature: boolean) => {
                                    if (!allowedByFeature) {
                                        this.router.navigate(['/app/dashboard']);
                                    }

                                    return allowedByFeature;
                                }
                            )
                        );
                    }

                    return observableOf(allowed);
                }),
                catchError((error) => {
                    let navigate = true;
                    if (error && error instanceof ErrorResponse && error.isUnauthorizedResponse()) {
                        navigate = false;
                    }
                    if (navigate) {
                        this.router.navigate(['/app/dashboard']);
                    }
                    return observableOf(false);
                })
            );
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
        return this.canActivate(route, state);
    }
}
