import {
    IBaseEntity, IResource, AttributeMetadata, RelationshipMetadata,
    IEntityConstructor
} from './interfaces';
import { TemporalTargeting } from '../targeting-default/interfaces';
import cloneDeep from 'lodash-es/cloneDeep';
import isEmpty from 'lodash-es/isEmpty';
import keys from 'lodash-es/keys';
import forEach from 'lodash-es/forEach';
import filter from 'lodash-es/filter';
import intersection from 'lodash-es/intersection';
import difference from 'lodash-es/difference';
import 'reflect-metadata';

export const HAS_MANY_META = 'sdk:metadata:has_many';
export const BELONGS_TO_META = 'sdk:metadata:belongs_to';
export const ATTRIBUTE_META = 'sdk:metadata:attribute';

export abstract class BaseEntity<T extends IResource> implements IBaseEntity<T> {
    private _loaded: boolean = false;
    private _isEmpty: boolean = false;

    constructor(entityLikeData: Partial<T>, shallow: boolean = true) {
      let data = entityLikeData as any;

      if (data == null) {
        this._isEmpty = true;
      } else {
        let attributeKeys = difference(Object.keys(this.attributes), ['id']);
        let dataKeys = Object.keys(data);
        this._loaded = isEmpty(attributeKeys) || !isEmpty(intersection(attributeKeys, dataKeys));

        forEach(this.attributes, (value: AttributeMetadata<any, any>, key: string) => {
          if (key in data) {
            if (shallow) {
              this[key] = data[key];
            } else {
              let val = data[key];
              this[key] = cloneDeep(val);
            }
          }
        });
        forEach(this.belongsTo, (value: RelationshipMetadata, key: string) => {
          if (key in data) {
            let type = value.typeResolver();
            let entity: IBaseEntity<IResource> = null;
            if (data[key] instanceof type) {
              entity = data[key];
            } else {
              entity = new type(data[key], true);
            }
            if (entity.isEmpty) {
              this[key] = null;
            } else {
              this[key] = entity;
            }
          }
        });
        forEach(this.hasMany, (value: RelationshipMetadata, key: string) => {
          let type = value.typeResolver();
          if (key in data) {
            if (Array.isArray(data[key])) {
              this[key] = data[key].map((element: any) => {
                if (element instanceof type) {
                  return element;
                }
                return new type(element, true);
              });
            } else {
              if (data[key] instanceof type) {
                return [data[key]];
              }
              this[key] = [new type(data[key], true)];
            }
            this[key] = filter(this[key], function (element: IBaseEntity<IResource>) {
              return !element.isEmpty;
            });
          }
        });
      }
    }

    protected getAttributeNames(): string[] {
      return keys(this.attributes);
    }

    get loaded(): boolean {
      return this._loaded;
    }

    get isEmpty(): boolean {
      return this._isEmpty;
    }

    get attributes(): { [P in keyof T]?: AttributeMetadata<any, any> } {
      return Reflect.getOwnMetadata(ATTRIBUTE_META, this.constructor);
    }

    get belongsTo(): { [P in keyof T]?: RelationshipMetadata } {
      return Reflect.getOwnMetadata(BELONGS_TO_META, this.constructor);
    }

    get hasMany(): { [P in keyof T]?: RelationshipMetadata } {
      return Reflect.getOwnMetadata(HAS_MANY_META, this.constructor);
    }
}

function relationshipMetadata(
  relationshipType: string,
  typeResolver: () => IEntityConstructor<IResource>,
  foreignKey?: string, optional?: boolean) {
  return function (target: any, propertyName: string): void {
    let meta = Reflect.getOwnMetadata(relationshipType, target.constructor) || {};
    meta[propertyName] = {
        typeResolver: typeResolver,
        foreignKey: foreignKey || propertyName,
        optional: optional
    };
    Reflect.defineMetadata(relationshipType, meta, target.constructor);
  };
}

export function Field<T, U>({ fromApi, apiFieldName, toApi = fromApi }:
  {
    fromApi?: (value: any) => any,
    apiFieldName?: string,
    toApi?: (value: any) => U
  } = {}) {
  return function (target: any, propertyName: string): void {
    let meta = Reflect.getOwnMetadata(ATTRIBUTE_META, target.constructor) || {};
    meta[propertyName] = {
      fromApi: fromApi,
      toApi: toApi,
      foreignKey: apiFieldName || propertyName
    } as AttributeMetadata<T, U>;
    Reflect.defineMetadata(ATTRIBUTE_META, meta, target.constructor);
  };
}

export function HasMany({ deferredConstructor, apiRelationshipName, optional = true }:
  {
    deferredConstructor: () => IEntityConstructor<IResource>,
    apiRelationshipName?: string, optional?: boolean
  }) {
  return relationshipMetadata(HAS_MANY_META, deferredConstructor, apiRelationshipName, optional);
}

export function BelongsTo({ deferredConstructor, apiRelationshipName, optional = false }:
  {
    deferredConstructor: () => IEntityConstructor<IResource>,
    apiRelationshipName?: string, optional?: boolean
  }) {
  return relationshipMetadata(BELONGS_TO_META, deferredConstructor, apiRelationshipName, optional);
}

export namespace DefaultTransforms {
  function filterEmpty(element: any) {
    return element !== undefined && element !== null;
  }

  function transform<T>(value: any, transformation: (value: any) => T) {
    if (value === null || value === undefined) {
      return value;
    } else {
      return transformation(value);
    }
  }

  function transformArray<T>(value: any, transformation: (value: any) => T) {
    if (Array.isArray(value)) {
      return filter(value, filterEmpty).map((element: any): T =>  {
        return transformation(value);
      });
    } else {
      if (value === null || value === undefined) {
        return [];
      } else {
        return [transformation(value)];
      }
    }
  }

  export function pipe<T>(...transformations: ((value: any) => any )[]): ((value: any) => T) {
    return function(value: any): T {
      let intermediateResult: any = value;
      transformations.forEach((transformation) => {
        intermediateResult = transformation(value);
      });
      return intermediateResult as T;
    };
  }

  export function ToString(value: any): string {
    return transform(value, () => value.toString());
  }

  export function ToNumber(value: any): number {
    return transform(value, () => +value);
  }

  export function ToBoolean(value: any): boolean {
    return transform(value, () => !!value);
  }

  export function ToAny(value: any): any {
    return transform(value, () => value);
  }

  export function ToArrayOfStrings(value: any): string[] {
    return transformArray(value, () => value.toString());
  }

  export function ToArrayOfAny(value: any): any[] {
    return transformArray(value, () => value);
  }

  export function ToArrayOfNumbers(value: any): number[] {
    return transformArray(value, () => +value);
  }

  export function ToArrayOfBooleans(value: any): boolean[] {
    return transformArray(value, () => !!value);
  }

  export function ToTemporalTargeting(value: any): TemporalTargeting {
    // converts json object {"0": ["1", "8"], ...}
    // to all numbers TemporalTargeting object: {0: [1, 8], ...}

    let targeting: TemporalTargeting = {};

    if (value === null || value === undefined) {
      return targeting;
    }

    for (const key of Object.keys(value)) {
      targeting[+key] = [];
      for (const element of value[key]) {
        targeting[+key].push(+element);
      }
    }
    return targeting;
  }

}
