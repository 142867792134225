import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

import { PrivilegeAccessResolver } from './access-resolver/privilege';
import { FeatureAccessResolver } from './access-resolver/feature';
import { IAclFeatureDescriptor, IAclPrivilegeDescriptor } from '../acl.interfaces';

@Injectable()
export class AccessResolverProxy {

    constructor(private privilegeAccessResolver: PrivilegeAccessResolver,
                private featureAccessResolver: FeatureAccessResolver) {}

    public hasPrivilege(descriptor: IAclPrivilegeDescriptor): Observable<boolean> {
        return this.privilegeAccessResolver.resolveFromDescriptor(descriptor);
    }

    public hasFeature(descriptor: IAclFeatureDescriptor): Observable<boolean> {
        return this.featureAccessResolver.resolveFromDescriptor(descriptor);
    }
}
