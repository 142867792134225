import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { of } from 'rxjs/internal/observable/of';
import { map } from 'rxjs/operators';

import { AclRulesProvider } from '../acl-rules-provider.service';
import { IAclFeatureDescriptor } from '../../acl.interfaces';
import { IFeature } from '../../../../sdk.index';

@Injectable()
export class FeatureAccessResolver {

    static cache: Map<string, boolean> = new Map<string, boolean>();

    constructor(private aclRulesProvider: AclRulesProvider) {
        FeatureAccessResolver.cache.clear();
    }

    public resolveFromDescriptor(descriptor: IAclFeatureDescriptor): Observable<boolean> {
        // validate invertedChecks
        switch (typeof descriptor.invertedCheck) {
            // if it's a single value it refers to all of the features
            case 'boolean':
                descriptor.invertedCheck = new Array(descriptor.features.length).fill(descriptor.invertedCheck);
                break;
            // if it's array check if length is equal, if invertedCheck is shorter - fill up with false
            case 'object':
                let icAmount = Object.keys(descriptor.invertedCheck).length;
                let featAmount = descriptor.features.length;
                if (icAmount < featAmount) {
                    descriptor.features.forEach((feature, key) => {
                        if (key >= icAmount) {
                            descriptor.invertedCheck[key] = false;
                        }
                    });
                }
                break;
            // inverted checks are not defined, set false for all features by default
            default:
                descriptor.invertedCheck = new Array(descriptor.features.length).fill(false);
                break;
        }

        let subscriptions = descriptor.features.map((feature: string) => {
            return this.hasAccess(feature);
        });

        return this.joinAccessResult(subscriptions, descriptor);
    }

    public cleanup(): void {
        FeatureAccessResolver.cache.clear();
        this.aclRulesProvider.cleanup();
    }

    private hasAccess(featureName: string): Observable<boolean> {
        if (FeatureAccessResolver.cache.has(featureName)) {
            return of(FeatureAccessResolver.cache.get(featureName));
        }

        return this.aclRulesProvider.getFeatures()
            .pipe(
                map(
                    (features: IFeature[]) => {
                        let index: number = features.findIndex(
                            feature => feature.code === featureName
                        );
                        let result: boolean = index > -1;
                        FeatureAccessResolver.cache.set(featureName, result);

                        return result;
                    }
                )
            );
    }

    private joinAccessResult(subscriptions, descriptor) {
        return forkJoin(subscriptions)
            .pipe(
                map(
                    (results: boolean[]) => {
                        let correctResults = 0;
                        results.forEach((hasAccess, k) => {
                            if (hasAccess === (descriptor.invertedCheck[k] !== true)) {
                                // count matching results
                                correctResults++;
                            }
                        });
                        // if we need to match all results check if amount is the same,
                        // otherwise check if there is any correct result
                        return descriptor.matchAll ? correctResults === results.length : correctResults > 0;
                    }
                )
            );
    }
}
