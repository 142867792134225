/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';

import { ISchema, BaseApiClient } from '../common/index';
export { IBaseApiClient } from '../common/interfaces';
import { IDesignatedMarketArea, IDesignatedMarketAreaClient } from './interfaces';
import {
    instanceCodeClientT, IInstanceCodeClient,
    IParamsBuilder, paramsBuilderT, IResource
} from '../common/interfaces';
import { Field, BaseEntity, BelongsTo } from '../common/meta';

import { Country } from '../country/country.entity';
import { ICountry } from '../country/interfaces';
import { AuthHttp } from '../authentication/authentication.service';

export class DesignatedMarketArea extends BaseEntity<IDesignatedMarketArea> implements IDesignatedMarketArea {
    public static readonly type: string = 'designated_market_area';

    @Field()
    id: string;

    @Field()
    metro_name: string;

    @Field()
    metro_code: number;

    @Field()
    regions: string;

    @BelongsTo({ deferredConstructor: () => Country })
    country: ICountry | IResource;
}

@Injectable()
export class DesignatedMarketAreaClient extends BaseApiClient<IDesignatedMarketArea> implements IDesignatedMarketAreaClient {
    protected static classConfig: ISchema = {
        apiResourceName: 'designated_market_areas'
    };

    constructor(http: AuthHttp,
                @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
                @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return DesignatedMarketArea;
    }
}
