import { IPersistentApiClient } from './../common/interfaces';
import { ICollection, IResource } from '../common/index';
import { ICountry } from '../country/interfaces';
import { IUser } from '../user/interfaces';
import { IArchivable } from './../common/archivable/interfaces';
import { IBulkRemovable } from './../common/bulk-removable/interfaces';

export interface IAgency extends IResource {
    name: string;
    email?: string;
    address?: string;
    archived: boolean;
    postal_code?: string;
    city?: string;
    phone?: string;
    credit_limit?: number;
    db_cr_number?: string;
    country?: ICountry | IResource;
    sales_manager?: IUser | IResource;
}

export type IAgencyCollection = ICollection<IAgency>;

export type AgencyBelongsToRelationships = never;
export type AgencyHasManyRelationships = never;

export interface IAgencyClient
    extends IPersistentApiClient<IAgency, AgencyBelongsToRelationships, AgencyHasManyRelationships>,
        IArchivable<IAgency>,
        IBulkRemovable {
}
