/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { map, catchError } from 'rxjs/operators';

import {
    instanceCodeClientT,
    IInstanceCodeClient,
    paramsBuilderT,
    IParamsBuilder,
    IResource, IRequestOptions
} from '../common/interfaces';
import { ISchema, BaseApiClient } from '../common/index';
import { IInstance, IInstanceClient, LogoFile, VisibleStrategyCard } from './interfaces';
import { BaseEntity, Field, BelongsTo } from '../common/meta';
import { BaseSerializer } from '../common/base-serializer';

import { TargetingDefault } from '../targeting-default/targeting-default.service';
import { ITargetingDefault } from '../targeting-default/interfaces';
import { AuthHttp } from '../authentication/authentication.service';
import { environment } from 'environments/environment';

export class Instance extends BaseEntity<IInstance> implements IInstance {

    public static readonly type: string = 'instance';

    @Field()
    id: string;

    @Field()
    name: string;

    @Field()
    code: string;

    @Field()
    currency: string;

    @Field()
    time_zone: string;

    @Field()
    locale: string;

    @Field()
    active: boolean;

    @Field()
    maximum_daily_spend: number;

    @Field()
    support_tier: string;

    @Field()
    budget_spend: string;

    @Field()
    theme_color: string;

    @Field()
    iponweb_code: string;

    @Field()
    use_old_seat_logic_for_rubicon: boolean;

    @Field()
    clearing_fee: number;

    @Field()
    logo: LogoFile;

    @Field()
    login_logo: LogoFile;

    @Field()
    date_locale: string;

    @Field()
    video_visibility_values: string[];

    @Field()
    display_visibility_values: string[];

    @Field()
    visible_strategy_cards?: VisibleStrategyCard[];

    @Field()
    optimized_margin_fee_percentage?: number;

    @BelongsTo({ deferredConstructor: () => TargetingDefault })
    targeting_default: ITargetingDefault | IResource;

    @Field()
    reporting_package: string;

    @Field()
    reporting_package_values: {[key: string]: any}[];

    @Field()
    first_weekday: number;

    @Field()
    open_auction_allowed: 'disabled' | 'instance' | 'campaign';

    @Field()
    has_appnexus_inventory: boolean;

    @Field()
    has_google_inventory: boolean;

    @Field()
    has_viooh_inventory: boolean;

    @Field()
    has_broadsign_inventory: boolean;

    @Field()
    appnexus_connection: 'default' | 'direct' | 'bidswitch';

    @Field()
    default_radius: string;

    @Field()
    distance_unit: string;

    @Field()
    has_campaigns_older_than_180_days: boolean;
}

@Injectable()
export class InstanceClient extends BaseApiClient<IInstance> implements IInstanceClient {
    protected static classConfig: ISchema = {
        apiResourceName: 'instances'
    };

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    enableFeature(id: string, code: string): Observable<IInstance> {
        this.recreateParams();
        let serializer = new BaseSerializer<IInstance>(Instance);
        this.setImpersonationParams();
        return this.http.post(`${this.apiBase()}/${id}/enable_feature/${code}/`, '', { params: this.prepareParams() })
            .pipe(
                map(
                (response: HttpResponse<any>) => {
                    return serializer.deserialize(response.body);
                }), catchError(this.handleRequestError)
            );
    }

    getEnabledDoohInventorySources(options?: IRequestOptions) {
        this.recreateParams();
        this.setImpersonationParams();
        this.prepareFieldsParams(options);
        return this.http
            .get(`${ environment.api.internal }/${ this.instanceCode }/instance_inventory_sources/active_dooh_inventories`, { params: this.params })
            .pipe(
                map((response: HttpResponse<any>) => {
                    return response.body;
                }),
                catchError(this.handleRequestError)
            );
    }

    protected implementationClass() {
        return Instance;
    }

    protected apiBase(): string {
        return `${ environment.api.internal }/${ this.instanceCode }/${ this.classConfig().apiResourceName }`;
    }
}
