/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';

import { ISchema, BasePersistentApiClient } from '../common/index';
import { BaseEntity, Field, BelongsTo } from '../common/meta';
import {
    IInstanceCodeClient, instanceCodeClientT, paramsBuilderT,
    IParamsBuilder, IResource
} from '../common/interfaces';

import {
    ICampaignInternetServiceProvider, ICampaignInternetServiceProviderClientFactory,
    ICampaignInternetServiceProviderClient, CampaignInternetServiceProviderBelongsToRelationships,
    CampaignInternetServiceProviderHasManyRelationships
} from './interfaces';

import {
    ICampaign
} from '../campaign/interfaces';

import { Campaign } from '../campaign/campaign.entity';

import {
    IInternetServiceProvider
} from '../internet-service-provider/interfaces';

import {
    InternetServiceProvider
} from '../internet-service-provider/internet-service-provider.service';
import { AuthHttp } from '../authentication/authentication.service';
import { environment } from 'environments/environment';

export class CampaignInternetServiceProvider
    extends BaseEntity<ICampaignInternetServiceProvider> implements ICampaignInternetServiceProvider {

    public static readonly type: string = 'campaign_internet_service_provider';

    @Field()
    id: string;

    @Field()
    revenue_share: number;

    @BelongsTo({ deferredConstructor: () => Campaign })
    campaign: ICampaign | IResource;

    @BelongsTo({ deferredConstructor: () => InternetServiceProvider })
    internet_service_provider: IInternetServiceProvider | IResource;
}

@Injectable()
export class CampaignInternetServiceProviderClientFactory implements ICampaignInternetServiceProviderClientFactory {

    constructor(
        private http: AuthHttp,
        @Inject(instanceCodeClientT) private instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) private paramsBuilder: IParamsBuilder
    ) {
    }

    createCampaignInternetServiceProviderClient(
        campaignId: string
    ): CampaignInternetServiceProviderClient {
        return new CampaignInternetServiceProviderClient(
            campaignId,
            this.http,
            this.instanceCodeClient,
            this.paramsBuilder);
    }
}

export class CampaignInternetServiceProviderClient
    extends BasePersistentApiClient
        <
            ICampaignInternetServiceProvider,
            CampaignInternetServiceProviderBelongsToRelationships,
            CampaignInternetServiceProviderHasManyRelationships
        >
    implements ICampaignInternetServiceProviderClient {

    protected static classConfig: ISchema = {
        apiResourceName: 'internet_service_providers'
    };

    protected apiBase(): string {
        return `${environment.api.base}/${this.instanceCode}/campaigns/${this.campaignId}/${this.classConfig().apiResourceName}`;
    }

    constructor(
        private campaignId: string,
        http: AuthHttp,
        instanceCodeClient: IInstanceCodeClient,
        paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return CampaignInternetServiceProvider;
    }
}
