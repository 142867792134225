/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';

import { BaseApiClient } from '../../common/index';
import { BelongsTo, BaseEntity, Field } from '../../common/meta';
import {
    IResource, IInstanceCodeClient, instanceCodeClientT, IParamsBuilder, ISchema, paramsBuilderT
} from '../../common/interfaces';
import {
    IPreviewTrackClick, IPreviewTrackClickClient, IPreviewTrackClickClientFactory,
    ITrackClickUrlFactory
} from './interfaces';
import { ICreative } from '../interfaces';
import { Creative } from '../creative.service';
import { AuthHttp } from '../../authentication/authentication.service';
import { environment } from 'environments/environment';

export class PreviewTrackClick extends BaseEntity<IPreviewTrackClick> implements IPreviewTrackClick {

    public static readonly type: string = 'preview_track_click';

    @Field()
    id: string;

    @BelongsTo({ deferredConstructor: () => Creative, optional: true })
    creative: ICreative | IResource;

    @Field()
    created_at: string;

    @Field()
    landing_page: string;
}

export class PreviewTrackClickClient extends BaseApiClient<IPreviewTrackClick> implements IPreviewTrackClickClient {
    protected static classConfig: ISchema = {
        apiResourceName: 'preview_track_clicks'
    };
    constructor(
        private creativeId: string,
        http: AuthHttp,
        instanceCodeClient: IInstanceCodeClient,
        paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected apiBase(): string {
        return `${environment.api.internal}/${this.instanceCode}/creatives/${this.creativeId}/${this.classConfig().apiResourceName}`;
    }

    protected implementationClass() {
        return PreviewTrackClick;
    }
}

@Injectable()
export class PreviewTrackClickClientFactory implements IPreviewTrackClickClientFactory {

    constructor(
        private http: AuthHttp,
        @Inject(instanceCodeClientT) private instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) private paramsBuilder: IParamsBuilder
    ) {
    }

    createPreviewTrackClickClient(
        creativeId: string
    ): PreviewTrackClickClient {
        return new PreviewTrackClickClient(
            creativeId,
            this.http,
            this.instanceCodeClient,
            this.paramsBuilder);
    }
}

@Injectable()
export class TrackClickUrlFactory implements ITrackClickUrlFactory {
    private instanceCode: string;
    constructor(
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient
    ) {
        this.instanceCode = instanceCodeClient.getInstanceCode();
    }

    createTrackClickUrl(creativeId: string, clickUrlIndex: number = 1): string {
        return `${environment.api.internal}/${this.instanceCode}/creatives/${creativeId}/preview_track_click/${clickUrlIndex}/`;
    }
}
