/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

import { ISchema, BasePersistentApiClient } from '../common/index';
import { BaseEntity, Field, BelongsTo, HasMany } from '../common/meta';
import {
    IInstanceCodeClient, instanceCodeClientT, paramsBuilderT,
    IParamsBuilder, IResource, IParam, IRecursiveArray
} from '../common/interfaces';

import {
    IRetargetingPixel, IRetargetingPixelClient, RetargetingPixelBelongsToRelationships,
    RetargetingPixelHasManyRelationships
} from './interfaces';
import { Advertiser } from '../advertiser/advertiser.entity';
import { IAdvertiser } from '../advertiser/interfaces';
import { PiggybackUrl } from '../piggyback-url/piggyback-url.service';
import { Pixel } from '../pixel/pixel.entity';
import { IPiggybackUrl } from '../piggyback-url/interfaces';
import { IPixel } from '../pixel/interfaces';
import { IDataProvider } from '../data-provider/interfaces';
import { DataProvider } from '../data-provider/data-provider.service';
import { Archivable } from '../common/archivable/archivable';
import { BulkRemovable } from '../common/bulk-removable/bulk-removable';
import { IBulkResponse } from './../common/errors';
import { IChangeable, IChangeCollection } from '../change/interfaces';
import { IComment, IPersistentComment } from '../comment/interfaces';
import { Campaign } from '../campaign/campaign.entity';
import { ICampaign } from '../campaign/interfaces';
import { Changeable } from '../common/changeable/changeable';
import { AuthHttp } from '../authentication/authentication.service';

class RetargetingPixel extends BaseEntity<IRetargetingPixel> implements IRetargetingPixel {

    public static readonly type: string = 'retargeting_pixel';

    @Field()
    id: string;

    @Field()
    archived: boolean;

    @Field()
    name: string;

    @Field()
    conversion_type: string;

    @Field()
    cpm_price?: number;

    @Field()
    unique_users: number;

    @Field()
    loads: number;

    @Field()
    sync: boolean;

    @Field()
    dynamic_creatives_retargeting?: boolean;

    @BelongsTo({ deferredConstructor: () => Advertiser })
    advertiser: IAdvertiser | IResource;

    @BelongsTo({ deferredConstructor: () => DataProvider })
    data_provider: IDataProvider | IResource;

    @HasMany({ deferredConstructor: () => Campaign })
    campaigns: ICampaign[] | IResource[]; // be careful, it is returned as relation but isn't and include won't work

    @HasMany({ deferredConstructor: () => PiggybackUrl })
    piggyback_urls: IPiggybackUrl[] | IResource[];

    @HasMany({ deferredConstructor: () => Pixel, apiRelationshipName: 'piggybacks' })
    piggybacks: IPixel[] | IResource[];
}

@Injectable()
@Archivable()
@BulkRemovable()
@Changeable()
export class RetargetingPixelClient extends BasePersistentApiClient<IRetargetingPixel,
    RetargetingPixelBelongsToRelationships, RetargetingPixelHasManyRelationships>
    implements IRetargetingPixelClient, IChangeable {

    protected static classConfig: ISchema = {
        apiResourceName: 'retargeting_pixels'
    };

    archive: (id: string) => Observable<IRetargetingPixel>;
    unarchive: (id: string) => Observable<IRetargetingPixel>;
    bulkRemove: (ids: string[]) => Observable<IBulkResponse[]>;

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return RetargetingPixel;
    }

    getChanges(
        changeableId: string, params?: IParam, include?: IRecursiveArray<string>
    ): Observable<IChangeCollection> {
        throw new Error('Method not implemented.');
    }

    addChangeComment(note: IPersistentComment, changeableId: string): Observable<IComment> {
        throw new Error('Method not implemented.');
    }
}
