/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';

import { ISchema, BasePersistentApiClient, IParam } from '../common/index';
import { BaseEntity, Field, BelongsTo, HasMany, DefaultTransforms } from '../common/meta';
import {
    IInstanceCodeClient,
    instanceCodeClientT,
    paramsBuilderT,
    IParamsBuilder,
    Filter,
    IRecursiveArray,
    IBulkEditable,
    IResource
} from '../common/interfaces';

import {
    ICreative,
    ICreativeClient,
    IVideoUrl,
    UtmCode,
    FileAttributes,
    CreativeBelongsToRelationships,
    CreativeHasManyRelationships,
    CreativeKind,
    DataType
} from './interfaces';
import { Advertiser } from '../advertiser/advertiser.entity';
import { IAdvertiser } from '../advertiser/interfaces';
import { ICreativeAttribute } from './attributes/interfaces';
import { Country } from '../country/country.entity';
import { Region } from '../region/region.entity';
import { City } from '../city/city.service';
import { IChangeable, IChangeCollection } from '../change/interfaces';
import { Observable } from 'rxjs/internal/Observable';
import { IComment, IPersistentComment } from '../comment/interfaces';
import { IabCategory } from '../iab-category/iab-category.service';
import { CreativeAttribute } from './attributes/creative-attribute.service';
import { Campaign } from '../campaign/campaign.entity';
import { Language } from '../language/language.service';
import { ICountry } from '../country/interfaces';
import { ICity, IMediaPlan, ISize } from '../index';
import { IRegion } from '../region/interfaces';
import { IIabCategory } from '../iab-category/interfaces';
import { ICampaign } from '../campaign/interfaces';
import { ILanguage } from '../language/interfaces';
import { Archivable } from '../common/archivable/archivable';
import { Activable } from '../common/activable/activable';
import { Duplicable } from '../common/duplicable/duplicable';
import { IBulkResponse } from './../common/errors';
import { Size } from '../size/size.service';
import { IPixel } from '../pixel/interfaces';
import { Pixel } from '../pixel/pixel.entity';
import { IThirdPartyPixelTracking } from '../third-party-pixel-tracking/interfaces';
import { ThirdPartyPixelTracking } from '../third-party-pixel-tracking/third-party-pixel-tracking.entity';
import { Section } from '../section/section.entity';
import { ISection } from '../section/interfaces';
import { ICreativeFile } from '../creative-file/interfaces';
import { CreativeFile } from '../creative-file/creative-file.service';
import { IAdServer } from '../ad-server/interfaces';
import { AdServer } from '../ad-server/ad-server';
import { ISubmissionStatus } from './submission/interfaces';
import { SubmissionStatus } from './submission/creative-submission.service';
import { Changeable } from '../common/changeable/changeable';
import { AuthHttp } from '../authentication/authentication.service';
import { TemporalTargeting } from '../targeting-default/interfaces';
import { MediaPlan } from '../media-plan/media-plan.entity';
import { catchError, map } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';

export class Creative extends BaseEntity<ICreative> implements ICreative {

    public static readonly type: string = 'creative';

    @Field()
    id: string;

    @Field()
    name: string;

    @Field()
    start_on: string;

    @Field()
    end_on: string;

    @Field()
    active: boolean;

    @Field()
    archived: boolean;

    @Field()
    kind: CreativeKind;

    @Field()
    data_type: DataType;

    @Field()
    width: number;

    @Field()
    height: number;

    @Field()
    click_url: string;

    @Field()
    custom_url_parameters: string;

    @Field()
    sides_number: number;

    @Field()
    force_landing_page_decode: boolean;

    @Field()
    landing_page_additional_value_support: boolean;

    @Field()
    section_filter: Filter;

    @Field()
    cap: boolean;

    @Field()
    companion_banner_url?: string;

    @Field()
    ad_server_logo_url?: string;

    @Field()
    sequence_cap: string;

    @Field()
    sequence_cap_type: string;

    @Field()
    sequence_cap_minimum: string;

    @Field()
    sequence_cap_maximum: string;

    @Field()
    sequence_phase: string;

    @Field()
    sequenced_campaign_id?: string;

    @Field()
    targeting_logic: any;

    @Field()
    appnexus_id: string;

    @Field()
    evidon: boolean;

    @Field()
    video_url: IVideoUrl;

    @Field()
    duration: number;

    @Field()
    video_media_id: string;

    @Field()
    dma_tracker: boolean;

    @Field()
    cs_digital_creative_id: string;

    @Field()
    skippable: boolean;

    @Field()
    scannable: boolean;

    @Field()
    ad_tag_id: string;

    @Field()
    use_targeting_sets_in_behavioural_targeting: boolean;

    @Field()
    reference_id: string;

    @Field()
    dma: boolean;

    @Field()
    dma_tag?: string;

    @Field()
    postback: boolean;

    @Field()
    cors: boolean;

    @Field()
    bitrate: string;

    @Field()
    hours?: number[];

    @Field()
    weekdays?: number[];

    @Field({fromApi: DefaultTransforms.ToTemporalTargeting})
    temporal_targeting?: TemporalTargeting;

    @Field()
    data?: string;

    @Field()
    external_id?: string;

    @Field()
    utm_codes?: UtmCode;

    @Field()
    creative_file_attributes?: FileAttributes;

    @Field()
    companion_banner_attributes?: FileAttributes;

    @Field()
    ad_server_logo_attributes?: FileAttributes;

    @Field()
    native_title?: string;

    @Field()
    native_description?: string;

    @Field()
    native_headline?: string;

    @Field()
    native_body?: string;

    @Field()
    native_cta?: string;

    @Field()
    broadsign_id?: number;

    @Field()
    is_broadsign: boolean;

    @Field()
    uploaded_to_broadsign?: boolean;

    @Field()
    everflow_offer_id?: number;

    @Field()
    custom_vast_events?: boolean;

    @Field()
    vast_start?: string;

    @Field()
    vast_first_quartile?: string;

    @Field()
    vast_midpoint?: string;

    @Field()
    vast_third_quartile?: string;

    @Field()
    vast_complete?: string;

    @Field()
    vast_skip?: string;

    @Field()
    postback_imp_tracking_url?: string;

    @Field()
    postback_click_tracking_url?: string;

    @Field()
    odoscope_pid?: string;

    @Field()
    use_odoscope_optimization?: boolean;

    @Field()
    rich_media_url: string;

    @Field()
    cb_id?: string;

    @BelongsTo({ deferredConstructor: () => Advertiser })
    advertiser: IAdvertiser | IResource;

    @BelongsTo({ deferredConstructor: () => MediaPlan })
    media_plans: IMediaPlan[] | IResource[];

    @HasMany({ deferredConstructor: () => Country })
    countries: ICountry[] | IResource[];

    @HasMany({ deferredConstructor: () => Region, apiRelationshipName: 'regions' })
    regions: IRegion[] | IResource[];

    @HasMany({ deferredConstructor: () => City, apiRelationshipName: 'cities' })
    cities: ICity[] | IResource[];

    @HasMany({ deferredConstructor: () => CreativeAttribute, apiRelationshipName: 'creative_attributes' })
    creative_attributes: ICreativeAttribute[] | IResource[];

    @HasMany({ deferredConstructor: () => IabCategory, apiRelationshipName: 'iab_categories' })
    iab_categories: IIabCategory[] | IResource[];

    @HasMany({ deferredConstructor: () => Campaign, apiRelationshipName: 'campaigns' })
    campaigns: ICampaign[] | IResource[];

    @HasMany({ deferredConstructor: () => Pixel, apiRelationshipName: 'pixels' })
    pixels: IPixel[] | IResource[];

    @BelongsTo({ deferredConstructor: () => Language, apiRelationshipName: 'language_declaration' })
    language_declaration: ILanguage | IResource;

    @HasMany({ deferredConstructor: () => Language, apiRelationshipName: 'languages' })
    languages: ILanguage[] | IResource[];

    @BelongsTo({ deferredConstructor: () => Size, apiRelationshipName: 'size' })
    size?: ISize | IResource;

    @BelongsTo({ deferredConstructor: () => Size, apiRelationshipName: 'companion_banner_size' })
    companion_banner_size?: ISize | IResource;

    @HasMany({ deferredConstructor: () => ThirdPartyPixelTracking, apiRelationshipName: 'third_party_pixel_trackings' })
    third_party_pixel_trackings: IThirdPartyPixelTracking[] | IResource[];

    @HasMany({ deferredConstructor: () => Section, apiRelationshipName: 'sections' })
    sections: ISection[] | IResource[];

    @BelongsTo({ deferredConstructor: () => CreativeFile })
    file?: ICreativeFile | IResource;

    @BelongsTo({ deferredConstructor: () => CreativeFile })
    companion_banner?: ICreativeFile | IResource;

    @BelongsTo({ deferredConstructor: () => CreativeFile })
    ad_server_logo?: ICreativeFile | IResource;

    @BelongsTo({ deferredConstructor: () => AdServer, apiRelationshipName: 'ad_server' })
    ad_server?: IAdServer | IResource;

    @HasMany({ deferredConstructor: () => SubmissionStatus, apiRelationshipName: 'approval_statuses' })
    approval_statuses?: ISubmissionStatus[] | IResource[];
}

@Injectable()
@Archivable()
@Changeable()
@Activable()
@Duplicable()
export class CreativeClient
    extends BasePersistentApiClient<ICreative, CreativeBelongsToRelationships, CreativeHasManyRelationships>
    implements ICreativeClient, IChangeable, IBulkEditable<ICreative> {
    protected static classConfig: ISchema = {
        apiResourceName: 'creatives'
    };

    archive: (id: string) => Observable<ICreative>;
    unarchive: (id: string) => Observable<ICreative>;
    activate: (id: string) => Observable<ICreative>;
    deactivate: (id: string) => Observable<ICreative>;
    duplicate: (id: string) => Observable<ICreative>;

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return Creative;
    }

    getChanges(
        changeableId: string, params?: IParam, include?: IRecursiveArray<string>
    ): Observable<IChangeCollection> {
        throw new Error('Method not implemented.');
    }

    addChangeComment(note: IPersistentComment, changeableId: string): Observable<IComment> {
        throw new Error('Method not implemented.');
    }

    bulkEdit(entities: ICreative[]): Observable<IBulkResponse[]> {
        throw new Error('Method not implemented.');
    }

    submitForEverflow(entity: ICreative): Observable<ICreative> {
        let wrappedEntity = new (this.implementationClass())(entity);
        let data = this.serializer.serialize(wrappedEntity);

        return this.http.patch(`${ this.apiBase() }/${entity.id}/everflow_tracking`,
            data)
            .pipe(
                map((response: HttpResponse<any>) => {
                    return this.serializer.deserialize(response.body);
                }),
                catchError(this.handleRequestError)
            );
    }

    getEverflowOffer(id: number): Observable<any> {
        return this.http.get(`${ this.apiBase() }/everflow_offer/${id}`)
            .pipe(
                map((response: HttpResponse<any>) => {
                    return response.body;
                }),
                catchError(this.handleRequestError)
            );
    }
}
