/* tslint:disable:variable-name */
import { BaseEntity, Field, HasMany } from '../common/meta';
import { IResource, Filter } from '../common/interfaces';
import { ICountry } from '../country/interfaces';
import { Country } from '../country/country.entity';
import { ITargetingDefault } from './interfaces';

export class TargetingDefault extends BaseEntity<ITargetingDefault> implements ITargetingDefault {
    public static readonly type: string = 'region';

    @Field()
    country_filter: Filter;

    @HasMany({ deferredConstructor: () => Country })
    countries: ICountry[] | IResource[];

}
