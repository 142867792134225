import { IBaseEntity, IIncludesConverter, IRecursiveArray } from './interfaces';
import forEach from 'lodash-es/forEach';
import merge from 'lodash-es/merge';

export class IncludesConverter<T> implements IIncludesConverter {
    constructor(private namesResolver: IBaseEntity<T>, private resource: string) {

    }

    convert(includes: IRecursiveArray<string>): string {
        let results = [];
        let allRelations = merge({}, this.namesResolver.belongsTo || {}, this.namesResolver.hasMany || {});
        // @ts-ignore
        forEach(includes, (element: string | IRecursiveArray<string>) => {
            if (typeof element === 'string') {
                let relation = allRelations[element];
                if (relation) {
                    results.push(relation.foreignKey);
                } else {
                    results.push(element);
                }
            } else {
                // @ts-ignore
                forEach(element, (nestedElement: IRecursiveArray<string>, key: string) => {
                    let relation = allRelations[key];
                    if (relation) {
                        let ctor = relation.typeResolver();
                        let newEntity = new ctor(null);
                        forEach((new IncludesConverter(newEntity, ctor.type)).convert(nestedElement).split(','), (preparedNested: string) => {
                            results.push([relation.foreignKey, preparedNested].join('.'));
                        });
                    } else {
                        forEach(this.convert(nestedElement).split(','), (preparedNested: string) => {
                            results.push([key, preparedNested].join('.'));
                        });
                    }
                });
            }
        });
        return results.join(',');
    }
}
