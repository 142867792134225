/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';
import { HttpParams } from '@angular/common/http';

import {
    instanceCodeClientT,
    IInstanceCodeClient,
    paramsBuilderT,
    IParamsBuilder,
    IResource
} from '../common/interfaces';
import { ISchema, BaseApiClient } from '../common/index';
import { IPixelOverview, IPixelOverviewParams, IPixelOverviewClient } from './interfaces';
import { BaseEntity, Field, BelongsTo } from '../common/meta';
import { Advertiser } from '../advertiser/advertiser.entity';
import { IAdvertiser } from '../advertiser/interfaces';
import { AuthHttp } from '../authentication/authentication.service';
import { environment } from 'environments/environment';

export class PixelOverview extends BaseEntity<IPixelOverview> implements IPixelOverview {

    public static readonly type: string = 'pixel_overview';

    @Field()
    id: string;

    @Field()
    name: string;

    @Field()
    type: string;

    @Field()
    unique_users: number;

    @Field()
    overview_loads: number;

    @Field()
    cpm_price: number;

    @Field()
    conversion_type: string;

    @Field()
    postback_tracking: boolean;

    @Field()
    overview_post_click_conversions: number;

    @Field()
    overview_post_view_conversions: number;

    @Field()
    date?: string;

    @Field()
    year?: string;

    @Field()
    month?: string;

    @Field()
    week?: string;

    @BelongsTo({ deferredConstructor: () => Advertiser })
    advertiser: IAdvertiser | IResource;

}

@Injectable()
export class PixelOverviewClient extends BaseApiClient<IPixelOverview> implements IPixelOverviewClient {
    protected static classConfig: ISchema = {
        apiResourceName: 'pixel_overviews'
    };

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return PixelOverview;
    }

    protected apiBase(): string {
        return `${environment.api.internal}/${this.instanceCode}/${this.classConfig().apiResourceName}`;
    }

    applyExtraFilters(params: IPixelOverviewParams) {
        this.customParams = new HttpParams();
        Object.keys(params).forEach((key) => {
            this.customParams = this.customParams.set(key, params[key]);
        });
    }

}
