/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';

import { ISchema, BaseApiClient } from '../common/index';
export { IBaseApiClient } from '../common/interfaces';
import { IRole, IRoleClient, RoleBelongsToRelationships, RoleHasManyRelationships } from './interfaces';
import {
    instanceCodeClientT, IInstanceCodeClient, paramsBuilderT,
    IParamsBuilder, IResource
} from '../common/interfaces';
import { BaseEntity, BelongsTo, Field, HasMany } from '../common/meta';
import { IPrivilege } from '../privilege/interfaces';
import { Privilege } from '../privilege/privilege.service';
import { BasePersistentApiClient } from '../common/base-api-client';
import { IRoleTemplate } from '../role-template/interfaces';
import { RoleTemplate } from '../role-template/role-template.service';
import { AuthHttp } from '../authentication/authentication.service';

export class Role extends BaseEntity<IRole> implements IRole {
    public static readonly type: string = 'role';

    @Field()
    id: string;

    @Field()
    name: string;

    @Field()
    default: boolean;

    @BelongsTo({ deferredConstructor: () => RoleTemplate })
    role_template: IRoleTemplate | IResource;

    @HasMany({ deferredConstructor: () => Privilege })
    privileges: IPrivilege[] | IResource[];
}

@Injectable()
export class RoleClient extends
    BasePersistentApiClient<IRole, RoleBelongsToRelationships, RoleHasManyRelationships>
    implements IRoleClient {
    protected static classConfig: ISchema = {
        apiResourceName: 'roles'
    };

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return Role;
    }
}
