const ESC_MAP = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#39;'
};

// WARNING: Removing '<' from RegExp will open this to a xss attack
// a string  ["><img src=x onerror=alert(2343)>."s] will be treated as image
// and there will be a request to src 'x'
// Currently we do not match '>' because it's used in some resource names
const RE = new RegExp(/[&<'"]/, 'g');

export function escapeHtml(data): object | string {
    if (typeof(data) === 'string') {
        return escapeStr(data);
    }
    return escapeObj(data);
}

function escapeStr(str): string {
    return str.replace(RE, function(char) {
        return ESC_MAP[char];
    });
}

function escapeObj(dataObj): object {
    for (let attr in dataObj) {
        if (dataObj.hasOwnProperty(attr)) {
            if (typeof(dataObj[attr]) === 'string') {
                dataObj[attr] = escapeStr(dataObj[attr]);
            } else {
                escapeObj(dataObj[attr]);
            }
        }
    }
    return dataObj;
}
