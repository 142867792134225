/* tslint:disable:variable-name */
import { IDomainStatistics } from './interfaces';
import { BaseEntity, Field } from '../common/meta';

export class DomainStatistics extends BaseEntity<IDomainStatistics> implements IDomainStatistics {

    public static readonly type: string = 'domain_statistics';

    @Field()
    period: string;

    @Field()
    start_on: string;

    @Field()
    end_on: string;

    @Field()
    unique_users?: number;

    @Field()
    unique_users_confidence_range?: number;

    @Field()
    impressions?: number;

    @Field()
    impressions_confidence_range?: number;

}
