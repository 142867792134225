/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';

import { ISchema, BasePersistentApiClient } from '../common/index';
export { IBaseApiClient } from '../common/interfaces';
import {
    IInternetServiceProvider, IInternetServiceProviderClient, InternetServiceProviderHasManyRelationships,
    InternetServiceProviderBelongsToRelationships
} from './interfaces';
import {
    instanceCodeClientT,
    paramsBuilderT,
    IParamsBuilder,
    IInstanceCodeClient,
    IResource
} from '../common/interfaces';
import { Field, HasMany, BaseEntity } from '../common/meta';
import { ICampaign } from '../campaign/interfaces';
import { Campaign } from '../campaign/campaign.entity';
import { BulkRemovable } from '../common/bulk-removable/bulk-removable';
import { AuthHttp } from '../authentication/authentication.service';

export class InternetServiceProvider extends BaseEntity<IInternetServiceProvider> implements IInternetServiceProvider {
    public static readonly type: string = 'internet_service_provider';

    @Field()
    id: string;

    @Field()
    name: string;

    @Field()
    external_id: string;

    @HasMany({ deferredConstructor: () => Campaign })
    campaigns: ICampaign[] | IResource[];
}

@Injectable()
@BulkRemovable()
export class InternetServiceProviderClient
    extends BasePersistentApiClient<IInternetServiceProvider, InternetServiceProviderBelongsToRelationships, InternetServiceProviderHasManyRelationships>
    implements IInternetServiceProviderClient {

    protected static classConfig: ISchema = {
        apiResourceName: 'internet_service_providers'
    };

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return InternetServiceProvider;
    }
}
