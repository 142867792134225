/* tslint:disable:variable-name */
import { IImpersonation } from './interfaces';

export const IMPERSONATE_KEY = 'impersonatedUser';

export class Impersonation implements IImpersonation {
    start(userId: string) {
        this.clearStorage();
        localStorage.setItem(IMPERSONATE_KEY, userId);
    }
    stop() {
        this.clearStorage();
        localStorage.removeItem(IMPERSONATE_KEY);
    }

    clearStorage() {
      ['tables', 'global_search', 'campaign_dashboard', 'tables_params', 'standard_report'].forEach(key => {
          localStorage.removeItem(key);
      });
    }
}
