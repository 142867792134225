import { IBaseApiClient, ICollection, IPersistentApiClient, IResource } from '../common/interfaces';
import { IPrivilege } from '../privilege/interfaces';
import { IRoleTemplate } from '../role-template/interfaces';

export interface IRole extends IResource {
    name: string;
    default?: boolean;
    role_template: IRoleTemplate | IResource;
    privileges: IPrivilege[] | IResource[];
}

export type IRoleCollection = ICollection<IRole>;

export type RoleBelongsToRelationships = never;
export type RoleHasManyRelationships = never;

export interface IRoleClient extends IBaseApiClient<IRole>,
    IPersistentApiClient<IRole, RoleBelongsToRelationships, RoleHasManyRelationships> {}
