/* tslint:disable:variable-name */

import {
    IThirdPartyPixelTracking,
    DeliveryType
} from './interfaces';

import { IResource } from '../common/interfaces';
import { ICreative } from '../creative/interfaces';
import { Creative } from '../creative/creative.service';
import { BelongsTo, BaseEntity, Field } from '../common/meta';

export class ThirdPartyPixelTracking extends BaseEntity<IThirdPartyPixelTracking> implements IThirdPartyPixelTracking {

    public static readonly type: string = 'third_party_pixel_tracking';

    @Field()
    id: string;

    @Field()
    content: string;

    @Field()
    delivery_type: DeliveryType;

    @BelongsTo({ deferredConstructor: () => Creative })
    creative: ICreative | IResource;
}
