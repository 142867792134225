import { Observable } from 'rxjs/internal/Observable';
import { Injectable, Inject } from '@angular/core';

import { ISchema } from '../common/index';
import { IPublisher, IPublisherClient } from './interfaces';
import {
    instanceCodeClientT, IInstanceCodeClient, paramsBuilderT,
    IParamsBuilder, IRecursiveArray, IParam
} from '../common/interfaces';
import { BasePersistentApiClient } from '../common/base-api-client';
import { IChangeable, IChangeCollection } from '../change/interfaces';
import { IComment, IPersistentComment } from '../comment/interfaces';
import { INote, INoteable, INoteCollection } from '../note/interfaces';
import { Activable } from '../common/activable/activable';
import { Archivable } from '../common/archivable/archivable';
import { Changeable } from '../common/changeable/changeable';
import { Noteable } from '../common/noteable/noteable';
import { AuthHttp } from '../authentication/authentication.service';
import { Publisher } from './publisher.entity';

@Injectable()
@Activable()
@Archivable()
@Changeable()
@Noteable()
export class PublisherClient
    extends BasePersistentApiClient<IPublisher, never, never>
    implements IPublisherClient, IChangeable, INoteable {

    protected static classConfig: ISchema = {
        apiResourceName: 'publishers'
    };

    activate: (id: string) => Observable<IPublisher>;
    deactivate: (id: string) => Observable<IPublisher>;
    archive: (id: string) => Observable<IPublisher>;
    unarchive: (id: string) => Observable<IPublisher>;

    constructor(http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return Publisher;
    }

    getChanges(
        changeableId: string, params?: IParam, include?: IRecursiveArray<string>
    ): Observable<IChangeCollection> {
        throw new Error('Method not implemented.');
    }

    addChangeComment(note: IPersistentComment, changeableId: string): Observable<IComment> {
        throw new Error('Method not implemented.');
    }

    getNotes(noteableId: string, params?: IParam, include?: IRecursiveArray<string>): Observable<INoteCollection> {
        throw new Error('Method not implemented.');
    }

    addNote(note: INote): Observable<INote> {
        throw new Error('Method not implemented.');
    }

    addNoteComment(note: IPersistentComment): Observable<IComment> {
        throw new Error('Method not implemented.');
    }
}
