import { ICollection, IBaseApiClient, IResource } from '../common/index';

export interface IExchangeRate extends IResource {
    rates: any;
}

export type IExchangeRateCollection = ICollection<IExchangeRate>;

export interface IExchangeRateClient extends IBaseApiClient<IExchangeRate> {
}
