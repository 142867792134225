import { ICollection, IBaseApiClient, IResource } from '../common/interfaces';

export interface IThirdPartyDataProvider extends IResource {
    name: string;
    prefix: string;
    currency: string;
    logo_file?: string;
    editable_segments: boolean;
    data_provider_type?: string;
}

export type IThirdPartyDataProviderCollection = ICollection<IThirdPartyDataProvider>;

export interface IThirdPartyDataProviderClient extends IBaseApiClient<IThirdPartyDataProvider> {
}
