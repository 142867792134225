import { IArchivable } from './../common/archivable/interfaces';
import { IBulkRemovable } from './../common/bulk-removable/interfaces';
import { ICollection, IResource } from '../common/index';
import { ICountry } from '../country/interfaces';
import { IPersistentApiClient } from './../common/interfaces';
import { IUser } from '../user/interfaces';

export type IRealTimeDataCollection = ICollection<IRealTimeData>;

export type RealTimeDataBelongsToRelationships = never;
export type RealTimeDataHasManyRelationships = never;

export interface IRealTimeParams {
    start_from: number;
    measures: Array<string>;
    filters: IRealTimeDataFilter;
    local_currency: boolean;
}

export interface IRealTimeData extends IResource {
    start_from: number;
    measures: Array<string>;
    filters: IRealTimeDataFilter;
    results: IRealTimeDataResult;
    local_currency: boolean;
}


export interface IRealTimeDataClient
extends IPersistentApiClient<
            IRealTimeData,
            RealTimeDataBelongsToRelationships,
            RealTimeDataHasManyRelationships
        > { }

export interface IRealTimeDataFilter {
    campaign?: IRealTimeDataObjectIds;
    mediaplan?: IRealTimeDataObjectIds;
}

export interface IRealTimeDataObjectIds {
    object_ids: Array<string>;
}

export interface IRealTimeDataResult {
    appnexus?: [string, number][];
    clicks?: [string, number][];
    gross_payout?: [string, number][];
    impressions?: [string, number][];
    improvedigital?: [string, number][];
    pubmatic?: [string, number][];
    rtb_adaptv?: [string, number][];
    rtb_adconductor?: [string, number][];
    rtb_adform?: [string, number][];
    rtb_adlantic?: [string, number][];
    rtb_admax?: [string, number][];
    rtb_admedia?: [string, number][];
    rtb_admeld?: [string, number][];
    rtb_admeta?: [string, number][];
    rtb_admixer?: [string, number][];
    rtb_admized?: [string, number][];
    rtb_adru?: [string, number][];
    rtb_adscale?: [string, number][];
    rtb_adscalebsw?: [string, number][];
    rtb_adskom?: [string, number][];
    rtb_adspruce?: [string, number][];
    rtb_adsupply?: [string, number][];
    rtb_adthinkmedia?: [string, number][];
    rtb_adunity?: [string, number][];
    rtb_adxpremium?: [string, number][];
    rtb_aerserv?: [string, number][];
    rtb_altitude?: [string, number][];
    rtb_amobee?: [string, number][];
    rtb_ancora?: [string, number][];
    rtb_aol?: [string, number][];
    rtb_appnexus?: [string, number][];
    rtb_avocarrot?: [string, number][];
    rtb_axonic?: [string, number][];
    rtb_beachfront?: [string, number][];
    rtb_betweendigital?: [string, number][];
    rtb_brightroll?: [string, number][];
    rtb_broadsign?: [string, number][];
    rtb_casalemedia?: [string, number][];
    rtb_centro?: [string, number][];
    rtb_converge?: [string, number][];
    rtb_coull?: [string, number][];
    rtb_cox?: [string, number][];
    rtb_cs?: [string, number][];
    rtb_facebook?: [string, number][];
    rtb_falktech?: [string, number][];
    rtb_fidelitymedia?: [string, number][];
    rtb_fmx?: [string, number][];
    rtb_geniee?: [string, number][];
    rtb_google?: [string, number][];
    rtb_googleadx?: [string, number][];
    rtb_gumgum?: [string, number][];
    rtb_ibillboard?: [string, number][];
    rtb_improve?: [string, number][];
    rtb_improvedigital?: [string, number][];
    rtb_inneractive?: [string, number][];
    rtb_kiosked?: [string, number][];
    rtb_ligatus?: [string, number][];
    rtb_liveintent?: [string, number][];
    rtb_liveintentbsw?: [string, number][];
    rtb_liverailbsw?: [string, number][];
    rtb_lkqd?: [string, number][];
    rtb_mads?: [string, number][];
    rtb_mobfox?: [string, number][];
    rtb_monarch?: [string, number][];
    rtb_mopub?: [string, number][];
    rtb_nativeads?: [string, number][];
    rtb_nexage?: [string, number][];
    rtb_openx?: [string, number][];
    rtb_opera?: [string, number][];
    rtb_optimatic?: [string, number][];
    rtb_personagraph?: [string, number][];
    rtb_pubmatic?: [string, number][];
    rtb_pulsepoint?: [string, number][];
    rtb_rmx?: [string, number][];
    rtb_rubicon?: [string, number][];
    rtb_rubicon_one?: [string, number][];
    rtb_rubiconbsw?: [string, number][];
    rtb_scaleout?: [string, number][];
    rtb_smaato?: [string, number][];
    rtb_smartadserver?: [string, number][];
    rtb_sonobi?: [string, number][];
    rtb_spotxchangebsw?: [string, number][];
    rtb_stickyads?: [string, number][];
    rtb_switchconcepts?: [string, number][];
    rtb_teads?: [string, number][];
    rtb_tremor?: [string, number][];
    rtb_vdopia?: [string, number][];
    rtb_vi?: [string, number][];
    rtb_vmg?: [string, number][];
    rtb_wordpress?: [string, number][];
    rtb_yieldlab?: [string, number][];
    rtb_yieldone?: [string, number][];
    rtb_yieldpartners?: [string, number][];
    rtb_yume?: [string, number][];
    rtb_zedo?: [string, number][];
    rubicon_one?: [string, number][];
}
