/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';

import { ISchema, BaseApiClient } from '../common/index';
export { IBaseApiClient } from '../common/interfaces';
import { IIabCategory, IIabCategoryClient } from './interfaces';
import {
    instanceCodeClientT, IInstanceCodeClient, paramsBuilderT,
    IParamsBuilder, IResource
} from '../common/interfaces';
import { Field, BaseEntity, BelongsTo, HasMany } from '../common/meta';
import { AuthHttp } from '../authentication/authentication.service';

export class IabCategory extends BaseEntity<IIabCategory> implements IIabCategory {

    public static readonly type: string = 'iab_category';

    @Field()
    id: string;

    @Field()
    code: string;

    @Field()
    name: string;

    @BelongsTo({ deferredConstructor: () => IabCategory, apiRelationshipName: 'tier1_category' })
    parent: IIabCategory | IResource;

    @HasMany({ deferredConstructor: () => IabCategory, apiRelationshipName: 'tier2_categories' })
    children: IIabCategory[] | IResource[];

}

@Injectable()
export class IabCategoryClient extends BaseApiClient<IIabCategory> implements IIabCategoryClient {
    protected static classConfig: ISchema = {
        apiResourceName: 'iab_categories'
    };

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return IabCategory;
    }
}
