import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map, tap } from 'rxjs/operators';
import { IParam, IInstance, IInstanceCollection, InstanceClient } from '@platform161-client';
import { SimpleCache } from '@resources/simple-cache';
import { IDropdownSearcherItem } from '@components/dropdown-searcher/dropdown-searcher.interfaces';

@Injectable()
export class InstanceDataProviderService {
    includes = [];
    private cache = new SimpleCache(-1);

    constructor(private instanceClient: InstanceClient) {}

    loadData(searchPhrase: string, withCache: boolean = false): Observable<Array<IDropdownSearcherItem<IInstance>>> {
        let expressions = [];
        if (withCache && this.cache.exists(searchPhrase)) {
            return this.cache.getResult(searchPhrase);
        }

        if (searchPhrase) {
            expressions.push({
                field: 'name',
                cond: { like: searchPhrase }
            });
        }

        expressions.push(
            {
                field: 'budget_spend',
                cond: { '=': `cost` }
            },
            {
                field: 'active',
                cond: { '=': `true` }
            }
        );

        let searchParam: IParam = {
            search: {
                operation: 'and',
                expressions: expressions
            }
        };

        if (!searchPhrase) {
            searchParam.limit = 300;
        }

        searchParam.order = {by: 'name', sort: 'ASC'};

        return this.instanceClient.getAll(searchParam, this.includes, {ignoreMandatoryIncludes: true})
            .pipe(
              map((instanceCollection: IInstanceCollection) => {
                return instanceCollection.data.map((instance: IInstance) => {
                  return {id: instance.id, text: instance.name, origin: instance};
                });
              }),
              tap(instances => this.cache.setResolvedResult(searchPhrase, instances))
            );
    }

    getByCode(searchPhrase: string): Observable<any> {
        let expressions = [];

        if (searchPhrase) {
            expressions.push({
                field: 'code',
                cond: { '=': `${searchPhrase}` }
            });
        }

        expressions.push(
            {
                field: 'budget_spend',
                cond: { '=': `cost` }
            },
            {
                field: 'active',
                cond: { '=': `true` }
            }
        );

        let searchParam: IParam = {
            search: {
                operation: 'and',
                expressions: expressions
            },
            limit: 1
        };

        searchParam.order = {by: 'name', sort: 'ASC'};

        return this.instanceClient.getAll(searchParam, this.includes, {ignoreMandatoryIncludes: true})
          .pipe(
            map((instanceCollection: IInstanceCollection) => instanceCollection.data)
          );
    }
}
