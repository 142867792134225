import { IBaseApiClient, ICollection, IResource } from '../common/interfaces';
import { IDoohScreen } from '../dooh_screen/interfaces';

export interface IDoohPublisher extends IResource {
    name: string;
    provider: string;
    active: boolean;

    dooh_screens: IDoohScreen[] | IResource[];
}

export type IDoohPublisherCollection = ICollection<IDoohPublisher>;

export interface IDoohPublisherClient extends IBaseApiClient<IDoohPublisher> {
}
