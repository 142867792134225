import { ICollection, IBaseApiClient, IResource } from '../common/index';

export interface ILanguage extends IResource {
    name: string;
    code: string;
}

export type ILanguageCollection = ICollection<ILanguage>;

export interface ILanguageClient extends IBaseApiClient<ILanguage> {
}
