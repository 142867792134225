/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';
import { ISchema, BasePersistentApiClient } from '../common/index';
import { IAgency, IAgencyClient, AgencyBelongsToRelationships, AgencyHasManyRelationships } from './interfaces';
import {
    instanceCodeClientT, IInstanceCodeClient,
    paramsBuilderT, IParamsBuilder, IResource, IRecursiveArray, IParam
} from '../common/interfaces';
import { Field, BaseEntity, BelongsTo } from '../common/meta';
import { Country } from '../country/country.entity';
import { User } from '../user/user.entity';
import { ICountry } from '../country/interfaces';
import { IUser } from '../user/interfaces';
import { INote, INoteable, INoteCollection } from '../note/interfaces';
import { IChangeable, IChangeCollection } from '../change/interfaces';
import { Observable } from 'rxjs/internal/Observable';
import { IComment, IPersistentComment } from '../comment/interfaces';
import { Archivable } from '../common/archivable/archivable';
import { BulkRemovable } from '../common/bulk-removable/bulk-removable';
import { IBulkResponse } from './../common/errors';
import { Changeable } from '../common/changeable/changeable';
import { Noteable } from '../common/noteable/noteable';
import { AuthHttp } from '../authentication/authentication.service';

export class Agency extends BaseEntity<IAgency> implements IAgency {

    public static readonly type: string = 'agency';

    @Field()
    id: string;

    @Field()
    name: string;

    @Field()
    email: string;

    @Field()
    address: string;

    @Field()
    archived: boolean;

    @Field()
    postal_code: string;

    @Field()
    city: string;

    @Field()
    phone: string;

    @Field()
    credit_limit: number;

    @Field()
    db_cr_number: string;

    @BelongsTo({ deferredConstructor: () => Country })
    country: ICountry | IResource;

    // @todo remove optional option as sales manager will be added to agency
    @BelongsTo({ deferredConstructor: () => User, apiRelationshipName: 'sales_manager', optional: true })
    sales_manager: IUser | IResource;

}

@Injectable()
@Archivable()
@BulkRemovable()
@Changeable()
@Noteable()
export class AgencyClient extends BasePersistentApiClient<IAgency, AgencyBelongsToRelationships, AgencyHasManyRelationships>
    implements IAgencyClient, INoteable, IChangeable {

    protected static classConfig: ISchema = {
        apiResourceName: 'agencies'
    };

    archive: (id: string) => Observable<IAgency>;
    unarchive: (id: string) => Observable<IAgency>;
    bulkRemove: (ids: string[]) => Observable<IBulkResponse[]>;

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return Agency;
    }

    getChanges(
        changeableId: string, params?: IParam, include?: IRecursiveArray<string>
    ): Observable<IChangeCollection> {
        throw new Error('Method not implemented.');
    }

    addChangeComment(note: IPersistentComment, changeableId: string): Observable<IComment> {
        throw new Error('Method not implemented.');
    }

    getNotes(noteableId: string, params?: IParam, include?: IRecursiveArray<string>): Observable<INoteCollection> {
        throw new Error('Method not implemented.');
    }

    addNote(note: INote): Observable<INote> {
        throw new Error('Method not implemented.');
    }

    addNoteComment(note: IPersistentComment): Observable<IComment> {
        throw new Error('Method not implemented.');
    }
}
