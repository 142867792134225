import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AccessResolverProxy } from '../service/access-resolver-proxy.service';
import { IAclFeatureDescriptor } from '../acl.interfaces';

/**
 * Usage
 * ```
 * *feature="{features:[], matchAll?: true}"
 * [feature]="..."
 * ```
 */
@Directive({
    selector: '[feature]'
})
export class FeatureDirective {

    constructor(private templateRef: TemplateRef<any>,
                private viewContainer: ViewContainerRef,
                private accessResolver: AccessResolverProxy) {
    }

    @Input() set feature(descriptor: IAclFeatureDescriptor) {
        if (!descriptor) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            return;
        }
        this.viewContainer.clear();
        this.accessResolver.hasFeature(descriptor)
            .subscribe(
                (hasAccess: boolean) => {
                    if (hasAccess) {
                        this.viewContainer.createEmbeddedView(this.templateRef);
                    }
                }
            );
    }
}
