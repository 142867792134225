import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import * as pluralize from 'pluralize';
import { BaseApiClient } from '../base-api-client';
import { IFavoriteProvider } from './interfaces';

export function Favoriteable<T>() {

    return function (target) {

        const favorite = function (id: string, provider?: IFavoriteProvider<T>): Observable<T> {
            return this.toggleFavorite(id, true, provider);
        };

        const unfavorite = function (id: string, provider?: IFavoriteProvider<T>): Observable<T> {
            return this.toggleFavorite(id, false, provider);
        };

        const toggleFavorite = function (
            id: string,
            favoriteValue: boolean,
            provider?: IFavoriteProvider<T>
        ): Observable<T> {
            if (!(this instanceof BaseApiClient)) {
                throw new Error('Decorated element must by type BaseApiClient');
            }
            let options = this.prepareRequestOptionsForDecorators();

            return this.http
                .patch(`${this.apiBase()}/${id}`,
                    {
                        'data': {
                            'id': id,
                            'type': pluralize.singular(this.classConfig().apiResourceName),
                            'attributes': {
                                'favorite': favoriteValue
                            }
                        }
                    },
                    options
                )
                .pipe(
                    map((response: HttpResponse<any>) => {
                        let attributes = response.body.data.attributes;
                        attributes = Object.assign({}, attributes, { id: response.body.data.id }) as T;

                        if (provider) {
                            favoriteValue ? provider.addToList(attributes) : provider.removeFromList(attributes);
                        }

                        return attributes;
                    })
                );
        };

        target.prototype.favorite = favorite;
        target.prototype.unfavorite = unfavorite;
        target.prototype.toggleFavorite = toggleFavorite;

    };

}
