import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

import { AccessResolverProxy } from '../service/access-resolver-proxy.service';
import { IAclPrivilegeDescriptor } from '../acl.interfaces';

@Pipe({
    name: 'privilege'
})
export class PrivilegePipe implements PipeTransform {

    constructor(private accessResolver: AccessResolverProxy) {}

    transform(value: any, descriptor: IAclPrivilegeDescriptor, exclude: any[]): Observable<any> {
        return this.accessResolver.hasPrivilege(descriptor)
            .pipe(
                map(
                    (hasAccess: boolean) => {
                        if (!hasAccess) {
                            return value.filter((val) => {
                                return exclude.indexOf(val) > -1;
                            });
                        } else {
                            return value;
                        }
                    }
                )
            );
    }
}
