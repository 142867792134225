/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';
import { HttpParams } from '@angular/common/http';

import { IDoohScreen, IDoohScreenClient, BidFloorPrice } from './interfaces';
import { IDoohPublisher } from '../dooh_publisher/interfaces';
import { IDoohNetwork } from '../dooh_network/interfaces';
import { IDoohChannel } from '../dooh_channel/interfaces';
import { ISize } from '../size/interfaces';
import { ICountry } from '../country/interfaces';
import { IRegion } from '../region/interfaces';
import { ICity } from '../city/interfaces';
import { DoohPublisher } from '../dooh_publisher/dooh-publisher.service';
import { DoohNetwork } from '../dooh_network/dooh-network.service';
import { DoohChannel } from '../dooh_channel/dooh-channel.service';
import { Size } from '../size/size.service';
import { Country } from '../country/country.entity';
import { Region } from '../region/region.entity';
import { City } from '../city/city.service';
import { BaseEntity, Field, BelongsTo } from '../common/meta';
import { ISchema, BaseApiClient } from '../common/index';
import { IResource } from '../common/interfaces';

import { instanceCodeClientT, IInstanceCodeClient, paramsBuilderT, IParamsBuilder } from '../common/interfaces';
import { AuthHttp } from '../authentication/authentication.service';

export class DoohScreen extends BaseEntity<IDoohScreen> implements IDoohScreen {

    public static readonly type: string = 'dooh_screen';

    @Field()
    id: string;

    @Field()
    name: string;

    @Field()
    screen_id: string;

    @Field()
    location: string;

    @Field()
    provider: string;

    @Field()
    instance_bid_floor: number;

    @Field()
    instance_bid_floor_currency: string;

    @Field()
    all_bid_floors: BidFloorPrice;

    @Field()
    lat: string;

    @Field()
    long: string;

    @Field()
    active: boolean;

    @Field()
    country: string;

    @Field()
    region: string;

    @Field()
    city: string;

    @Field()
    included_in_evergreen?: boolean;

    @Field()
    ad_duration: number;

    @Field()
    ad_types: string;

    @Field()
    screen_image_url: string;

    @Field()
    avg_monthly_reach: number;


    @BelongsTo({ deferredConstructor: () => DoohPublisher })
    dooh_publisher: IDoohPublisher | IResource;

    @BelongsTo({ deferredConstructor: () => DoohNetwork })
    dooh_network: IDoohNetwork | IResource;

    @BelongsTo({ deferredConstructor: () => DoohChannel })
    dooh_channel: IDoohChannel | IResource;

    @BelongsTo({ deferredConstructor: () => Size })
    size: ISize | IResource;

    @BelongsTo({ deferredConstructor: () => Country })
    osm_country: ICountry | IResource;

    @BelongsTo({ deferredConstructor: () => Region })
    osm_region: IRegion | IResource;

    @BelongsTo({ deferredConstructor: () => City })
    osm_city: ICity | IResource;

    public publisherName(): string | null {
        return this.dooh_publisher ? (<IDoohPublisher>this.dooh_publisher).name : null;
    }

    public networkName(): string | null {
        return this.dooh_network ? (<IDoohNetwork>this.dooh_network).name : null;
    }

    public channelName(): string | null {
        return this.dooh_channel ? (<IDoohChannel>this.dooh_channel).name : null;
    }

    public countryName(): string | null {
        return this.osm_country ? (<ICountry>this.osm_country).name : null;
    }

    public regionName(): string | null {
        return this.osm_region ? (<IRegion>this.osm_region).name : null;
    }

    public cityName(): string | null {
        return this.osm_city ? (<ICity>this.osm_city).name : null;
    }

    public latLong(): string {
        return `${this.lat};${this.long}`;
    }

    public screenCurrency(): string {
        switch (this.instance_bid_floor_currency) {
            case('EUR'):
                return '€ ';
            case('USD'):
                return '$ ';
            default:
                return '';
        }
    }
}

@Injectable()
export class DoohScreenClient extends BaseApiClient<IDoohScreen> implements IDoohScreenClient {
    protected static classConfig: ISchema = {
        apiResourceName: 'dooh_screens'
    };

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    // It's used for batch operations "activate/deactivate all screens"
    // without these methods the custom batch operation
    // button won't appear
    activate(id: string): void {
    }

    deactivate(id: string): void {
    }

    addCampaignFilter(campaignId: string): void {
        this.customParams = new HttpParams();
        this.customParams = this.customParams.set('campaign_id', campaignId);
    }

    protected implementationClass() {
        return DoohScreen;
    }

}
