
import { of as observableOf,  Observable ,  Subject } from 'rxjs';

import { map } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';

import {
    ICollection, IFeature, IFeatureClient, IPrivilege, IParam, FeatureClient, IUser, IRole
} from '../../../sdk.index';
import { IdentityStorage } from '../../../modules/common/service/storage/identity-storage';
import { PrivilegeAction } from '../acl.interfaces';
import { UserFactory } from '../../../resources/user.resource';

@Injectable()
export class AclRulesProvider implements OnDestroy {

    private static privilegeHandler: AclScopeHandler<IPrivilege>;
    private static featureHandler: AclScopeHandler<IFeature>;

    constructor(
        userFactory: UserFactory,
        private featureClient: FeatureClient,
        private identityStorage: IdentityStorage
    ) {
        if (!AclRulesProvider.privilegeHandler) {
            AclRulesProvider.privilegeHandler = new AclScopeHandler<IPrivilege>(() => {
                if (!this.identityStorage.getInstanceCode()) {
                    return observableOf([]);
                }

                let options = {
                    fields: {
                        role: ['name', 'privileges' ],
                        user: [
                            'email',
                            'login',
                            'first_name',
                            'last_name',
                            'roles',
                            'is_advertiser',
                            'is_superadmin',
                            'notification_count'
                        ],
                    }
                };
                return userFactory.getUserFromCache(['roles.privileges'], options).pipe(
                    map((user: IUser) => {
                        let privileges: IPrivilege[] = [];
                        for (let role of user.roles) {
                            privileges = privileges.concat((<IPrivilege[]>(<IRole>role).privileges));
                        }

                        this.setArtificialPrivileges(user, privileges);

                        return privileges;
                    }));
            });
        }

        this.setFeatures();
    }

    ngOnDestroy(): void {
        this.cleanup();
    }

    public cleanup() {
        AclRulesProvider.privilegeHandler.cleanup();
        AclRulesProvider.featureHandler.cleanup();
    }

    public cleanupFeature() {
        AclRulesProvider.featureHandler.cleanup();
        delete AclRulesProvider.featureHandler;
    }

    public getPrivileges(): Observable<IPrivilege[]> {
        return AclRulesProvider.privilegeHandler.getRules();
    }

    public getFeatures(): Observable<IFeature[]> {
        return AclRulesProvider.featureHandler.getRules();
    }

    public setFeatures() {
        if (!AclRulesProvider.featureHandler) {
            AclRulesProvider.featureHandler = new AclScopeHandler<IFeature>(() => {
                if (!this.identityStorage.getInstanceCode()) {
                    return observableOf([]);
                }

                return this.featureClient.getAll(<IParam>{limit: 100}).pipe(
                    map((collection: ICollection<IFeature>) => {
                        return collection.data;
                    }));
            });
        }
    }

    private setArtificialPrivileges(user: IUser, privileges: IPrivilege[]): void {
        let campaignPrivilege: number[] = privileges
            .filter((el: IPrivilege) => el.domain_object === 'Campaign')
            .map((el: IPrivilege) => PrivilegeAction[el.action.toUpperCase()]);

        const isCampaignView = campaignPrivilege.indexOf(PrivilegeAction.VIEW) > -1 ||
              campaignPrivilege.indexOf(PrivilegeAction.ALL) > -1;
        const isCampaignAll = campaignPrivilege.indexOf(PrivilegeAction.ALL) > -1;

        this.setAdvertiserPrivilege(user, privileges, isCampaignView, isCampaignAll);
        this.setAgencyPrivilege(user, privileges, isCampaignView, isCampaignAll);
        this.setSuperadminPrivilege(user, privileges);
        this.setInvoiceReportPrivilege(privileges);
    }

    private setAdvertiserPrivilege(
        user: IUser, privileges: IPrivilege[], isCampaignView: boolean, isCampaignAll: boolean
    ): void {

        if (isCampaignView) {
            privileges.push({
                domain_object: 'Advertiser',
                action: 'view',
                description: 'Advertiser synthetic'
            });
        }

        if (!user.is_advertiser && isCampaignAll) {
            privileges.push({
                domain_object: 'Advertiser',
                action: 'all',
                description: 'Advertiser synthetic'
            });
        }
    }

    private setAgencyPrivilege(
        user: IUser, privileges: IPrivilege[], isCampaignView: boolean, isCampaignAll: boolean
    ): void {

        if (isCampaignView) {
            privileges.push({
                domain_object: 'Agency',
                action: 'view',
                description: 'Agency synthetic'
            });
        }

        if (!user.is_advertiser && isCampaignAll) {
            privileges.push({
                domain_object: 'Agency',
                action: 'all',
                description: 'Agency synthetic'
            });
        }
    }

    private setSuperadminPrivilege(user: IUser, privileges: IPrivilege[]): void {
        if (user.is_superadmin) {
            privileges.push({
                domain_object: 'Superadmin',
                action: 'all',
                description: 'User is a superadmin'
            });
        }
    }

    private setInvoiceReportPrivilege(privileges: IPrivilege[]): void {
        if (!this.identityStorage.getUser().is_client) {
            privileges.push({
                domain_object: 'InvoiceReport',
                action: 'all',
                description: 'User not a client'
            });
        }
    }
}

class AclScopeHandler<T> {

    private rules: T[] = null;
    private subject: Subject<T[]>;

    constructor(
        private dataProvider: () => Observable<T[]>
    ) {}

    public cleanup(): void {
            this.rules = null;
        if (this.subject) {
            this.subject.unsubscribe();
            this.subject = null;
        }
    }

    public getRules(): Observable<T[]> {
        if (this.rules !== null) {
            return observableOf(this.rules);
        }
        if (this.subject instanceof Subject && !this.subject.isStopped) {
            return this.subject;
        }

        this.subject = new Subject<T[]>();
        this.loadRules()
            .subscribe(
                (data: T[]) => {
                    if (this.subject) {
                        this.subject.next(data);
                        this.subject.complete();
                    }
                },
                (error) => {
                    if (this.subject) {
                        this.subject.error(error);
                        this.subject.complete();
                    }
                }
            );

        return this.subject;
    }

    private loadRules(): Observable<T[]> {
        return this.dataProvider().pipe(
            map(
                (data: T[]) => {
                    this.persist(data);
                    return data;
                }
            ));
    }

    private persist(rules: T[]): void {
        this.rules = rules;
    }
}
