import { IBaseApiClient, ICollection, IResource } from '../common/interfaces';

export interface IContentCategory extends IResource {
    name: string;
}

export type IContentCategoryCollection = ICollection<IContentCategory>;

export interface IContentCategoryClient extends IBaseApiClient<IContentCategory> {
}
