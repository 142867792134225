/* tslint:disable:variable-name */
import { Injectable, InjectionToken, Inject } from '@angular/core';
import {
    Router, RouterStateSnapshot, NavigationExtras, CanActivate, CanActivateChild, ActivatedRouteSnapshot
} from '@angular/router';

import { HttpClient, HttpResponse, HttpRequest, HttpHeaders, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { throwError as observableThrowError,  Observable ,  Subject, defer, from, of } from 'rxjs';
import { filter, map, mergeMap, catchError, switchMap } from 'rxjs/operators';
import { environment } from 'environments/environment';

import { IAuthConfig, IAuthConfigOptional, ITokenStorage } from './interfaces';
import { IInstanceCodeClient } from '../common';
import { HttpParams } from '@angular/common/http';
import { instanceCodeClientT } from '../common/interfaces';

type RequestCall = (req: HttpRequest<any>, token: string) => Observable<HttpEvent<any>>;

export type HttpRequestInitOptions = {
    headers?: HttpHeaders;
    reportProgress?: boolean;
    params?: HttpParams;
    responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
    withCredentials?: boolean;
};
export type HttpRequestOptions = {
    method: 'DELETE' | 'GET' | 'HEAD' | 'JSONP' | 'OPTIONS' | 'POST' | 'PUT' | 'PATCH';
    url: string;
    body: any | null;
    init?: HttpRequestInitOptions;
};

export class AuthHttpError extends Error { }

export class AuthConfigConsts {
    static DEFAULT_TOKEN_NAME = 'csrf_token';
    static DEFAULT_HEADER_NAME = 'X-CSRF-Token';
}

const authConfigDefaults: IAuthConfig = {
    globalHeaders:  {},
    headerName: AuthConfigConsts.DEFAULT_HEADER_NAME,
    tokenName: AuthConfigConsts.DEFAULT_TOKEN_NAME,
    withCredentials: true
};

@Injectable()
export class BaseTokenStorage implements ITokenStorage {
    get(): string {
        return localStorage.getItem(authConfigDefaults.tokenName) as string;
    }
    set(token: string): void {
        localStorage.setItem(authConfigDefaults.tokenName, token);
    }
    remove(): void {
        localStorage.removeItem(authConfigDefaults.tokenName);
    }
}
@Injectable()
export class AuthConfig {
    private _config: IAuthConfig;

    constructor(config?: IAuthConfigOptional) {
        config = config || {};
        this._config = objectAssign({}, authConfigDefaults, config) as IAuthConfig;
    }

    getConfig(): IAuthConfig {
        return this._config;
    }
}

export let tokenStorageT = new InjectionToken('token-storage');

const ensureIsHttResponse = r => r instanceof HttpResponse;

// Allows for explicit authenticated HTTP requests.

@Injectable()
export class AuthHttp {
    private config: IAuthConfig;
    private refreshTokenRequest: Observable <HttpEvent<any>> = null;
    private newTokenWaitingList: Subject <string>[] = [];

    constructor(
        @Inject(HttpClient) private http: HttpClient,
        @Inject(tokenStorageT) private tokenStorage: ITokenStorage,
        @Inject(Router) private router: Router,
        @Inject(AuthConfig) options: AuthConfig,
        @Inject(instanceCodeClientT) private instanceCodeClient: IInstanceCodeClient,
    ) {
        this.config = options.getConfig();
    }


    requestWithToken(
        req: HttpRequest<any>,
        token: string,
        callback: (req: HttpRequest<any>) => Observable<HttpEvent<any>>
    ): Observable<HttpEvent<any>> {
        let _this: object = this;
        if (!token) {
            let url: string = this.router.routerState.snapshot.url;
            return new Observable<HttpResponse<any>>((obs: any) => {
                this.navigateToLogin(url);
                obs.error(new AuthHttpError('No CSRF token present'));
            });
        }
        const request = this.setHeaders({[this.config.headerName]: token}, req);

        if (callback.toString() === this.http.request.toString()) {
            _this = this.http;
        }

        return callback.call(_this, request);
    }

    setHeaders(headers: {[name: string]: string | string[]}, request: HttpRequest<any>): HttpRequest<any> {
        let httpHeaders = request.headers || new HttpHeaders();

        Object.keys(headers).forEach((headerName: string) => {
            const headerValue: string | string[] = headers[headerName];
            if (!httpHeaders.get(headerName)) {
                // If the header was already in the request, we don't overwrite it
                httpHeaders = httpHeaders.set(headerName, headerValue);
            }
        });

        return request.clone({ headers: httpHeaders });
    }

    request(url: string | HttpRequest<any>, options?: HttpRequestInitOptions): Observable<HttpResponse<any>> {
        if (typeof url === 'string') {
            return this.get(url, options); // Recursion: transform url from String to Request
        }

        // from this point url is always an instance of Request;
        let req: HttpRequest<any> = url as HttpRequest<any>;

        // Create a cold observable and load the token just in time
        return defer(() => {
            const method: string = req.method;
            const callWithToken: boolean = !(method === 'GET' || method === 'HEAD');
            if (callWithToken) {
                return this.resolveCsrfToken(req, (_req, token) => {
                    return this.requestWithToken(_req, token, this.requestWithRefresh)
                      .pipe(
                        filter(ensureIsHttResponse)
                      );
                });
            }

            return this.requestWithRefresh(req).pipe(filter(ensureIsHttResponse));
        });
    }

    requestWithoutToken(
        url: number | HttpRequest<any>, options?: HttpRequestInitOptions
    ): Observable<HttpResponse<any>> {
        if (typeof url === 'string') {
            return this.get(url, options); // Recursion: transform url from String to Request
        }

        // from this point url is always an instance of Request;
        let req: HttpRequest<any> = url as HttpRequest<any>;

        // Create a cold observable and load the token just in time
        return defer(() => {
            return this.requestWithRefresh(req);
        });
    }

    get(url: string, options?: HttpRequestInitOptions): Observable<HttpResponse<any>> {
        return this.requestHelper({init: {...options }, body: '', method: 'GET', url });
    }

    post(url: string, body: any, options?: HttpRequestInitOptions): Observable<HttpResponse<any>> {
        return this.requestHelper({init: {...options }, body, method: 'POST', url });
    }

    put(url: string, body: any, options?: HttpRequestInitOptions): Observable<HttpResponse<any>> {
        return this.requestHelper({init: {...options }, body, method: 'PUT', url });
    }

    delete(url: string, options?: HttpRequestInitOptions): Observable<HttpResponse<any>> {
        return this.requestHelper({init: {...options }, body: '', method: 'DELETE', url });
    }

    patch(url: string, body: any, options?: HttpRequestInitOptions): Observable<HttpResponse<any>> {
        return this.requestHelper({init: {...options }, body: body, method: 'PATCH', url: url });
    }

    head(url: string, options?: HttpRequestInitOptions): Observable<HttpResponse<any>> {
        return this.requestHelper({init: {...options }, body: '', method: 'HEAD', url: url });
    }

    options(url: string, options?: HttpRequestInitOptions): Observable<HttpResponse<any>> {
        return this.requestHelper({init: {...options }, body: '', method: 'OPTIONS', url: url });
    }

    private createRequest(requestOptions: HttpRequestOptions) {
        const {method, url, body, init} = requestOptions;
        const initCopy: HttpRequestInitOptions = {...init};
        if (this.config.withCredentials) {
            initCopy.withCredentials = true;
        }

        const isRequestWithBody = ['POST', 'PUT', 'PATCH'].indexOf(method) > -1;
        let request = isRequestWithBody ?
            new HttpRequest(method, url, body, initCopy) : new HttpRequest(method, url, initCopy);
        if (this.config.globalHeaders) {
            request = this.setHeaders(this.config.globalHeaders, request);
        }

        return request;
    }

    private requestHelper(
        requestOptions: HttpRequestOptions
    ): Observable<HttpResponse<any>> {
        if (!requestOptions.init || requestOptions.init.withCredentials === false) {
            return this.requestWithoutToken(this.createRequest(requestOptions))
                .pipe(
                  filter(ensureIsHttResponse)
                );
        }

        return this.request(this.createRequest(requestOptions))
            .pipe(
                filter(ensureIsHttResponse)
            );
    }

    private navigateToLogin(returnUrl?: string): void {
        let navigationExtras: NavigationExtras = {};
        if (returnUrl) {
            navigationExtras.queryParams = { 'returnUrl': returnUrl };
        }
        this.router.navigate(['/login'], navigationExtras);
    }

    private resolveCsrfToken(req: HttpRequest<any>, callback: RequestCall): Observable<HttpResponse<any>> {
        let csrfToken: string | Promise<string> = this.tokenStorage.get();
        if (csrfToken instanceof Promise) {
            return from(csrfToken)
                .pipe(
                    mergeMap((_csrfToken: string) => callback.call(this, req, _csrfToken))
                ) as Observable<HttpResponse<any>>;
        } else {
            return callback.call(this, req, csrfToken);
        }
    }

    private requestWithRefresh(req: HttpRequest<any>): Observable<HttpResponse<any>> {
        return this.http.request(req)
            .pipe(
                filter(ensureIsHttResponse),
                catchError((err) => {
                        if (err.status === 401 && this.config.withRefreshEndpoint) {
                            this.logTokenRefresh('Request Failed due to expired CSRF token', req);
                            return this.repeatRequestAfterRefreshingToken(req, err);
                        } else {
                            return observableThrowError(err);
                        }
                    })
            );
    }

    private repeatRequestAfterRefreshingToken(req: HttpRequest<any>, initialError): Observable<HttpEvent<any>> {
        return this.getFreshCsrfToken(req)
            .pipe(
                switchMap((updatedToken) => {
                        return this.requestWithToken(req, updatedToken, (_req: HttpRequest<any>) => {
                            this.logTokenRefresh('Invoking the original request again', req);
                            return this.http.request(_req);
                        });
                    }),
                catchError((_err) => {
                        if (_err) {
                            return observableThrowError(_err);
                        } else {
                            return observableThrowError(initialError);
                        }
                    })
            );
    }

    private getFreshCsrfToken(req: HttpRequest<any>): Observable <string> {
        if (this.isWaitingForTokenRefresh()) {
            return this.addRequestToTokenQueue(req);
        } else {
            return this.fetchCsrfToken(req);
        }
    }

    private fetchCsrfToken(req: HttpRequest<any>): Observable <string> {
        let url = this.urlForRefresh();
        if (!url) {
            return observableThrowError('Cannot refresh token');
        }

        let options: HttpRequestOptions = { method: 'POST', url: url, body: '' };
        let result: Observable<HttpEvent<any>> = null;
        let refreshRequest = this.createRequest(options);
        let token = req.headers.get(this.config.headerName);
        if (token) {
            // For POST, PATCH and DELETE requests
            refreshRequest = this.setHeaders({[this.config.headerName]: token}, refreshRequest);
            this.logTokenRefresh('Requesting a fresh CSRF token (q)', req);
            result = this.http.request(refreshRequest);
        } else {
            // For GET requests
            result = this.resolveCsrfToken(refreshRequest, (_req, _token) => {
                this.logTokenRefresh('Requesting a fresh CSRF token (s)', req);
                return this.requestWithToken(_req, _token, this.http.request);
            });
        }
        this.initializeTokenQueue(result);

        return result
            .pipe(
                filter(ensureIsHttResponse),
                switchMap((response: HttpResponse<{ csrf_token: string }>) => {
                    let csrfToken = response && response.body.csrf_token;
                    this.logTokenRefresh('Got a fresh CSRF token');
                    if (csrfToken) {
                        this.tokenStorage.set(csrfToken);
                    }
                    this.flushTokenQueue(csrfToken);
                    return of(csrfToken);
                }),
                catchError((_err) => {
                        this.logTokenRefresh('Token Refresh failed!: ' + _err, req);
                        this.clearTokenQueueAfterError('Token Refresh failed');
                        if (!this.router.url.match(/\/login(.*)/)) {
                            this.navigateToLogin(this.router.routerState.snapshot.url);
                            this.logTokenRefresh('Navigating to login page');
                            return observableThrowError('No token available. Login again, please');
                        } else {
                            return observableThrowError(null);
                        }
                    })
            );
    }

    private isWaitingForTokenRefresh(): boolean {
        return !!this.refreshTokenRequest;
    }

    private initializeTokenQueue(obs: Observable <HttpEvent<any>>): void {
        this.refreshTokenRequest = obs;
        this.newTokenWaitingList = [];
    }

    private addRequestToTokenQueue(req: HttpRequest<any>): Observable <string> {
        let subscription = new Subject<string>();
        this.newTokenWaitingList.push(subscription);
        this.logTokenRefresh('Adding to the waiting list (' + this.newTokenWaitingList.length + ')', req);
        return subscription;
    }

    private flushTokenQueue(newToken: string): void {
        // Do with timeout so it's done after current event
        setTimeout(() => {
            this.logTokenRefresh('Processing the token waiting list: ' + this.newTokenWaitingList.length);
            this.newTokenWaitingList.forEach((subject: Subject<string>) => {
                subject.next(newToken);
                subject.complete();
            });
            this.newTokenWaitingList = [];
            this.refreshTokenRequest = null;
        });
    }

    private clearTokenQueueAfterError(err: any): void {
        this.newTokenWaitingList.forEach((s: Subject <string>) => {
            s.error(err);
            s.complete();
        });
        this.newTokenWaitingList = [];
        this.refreshTokenRequest = null;
    }

    private urlForRefresh(): string {
        if (this.config.withRefreshEndpoint) {
            let instanceCodeFromLocalStorage = this.instanceCodeClient.getInstanceCode();
            if (!instanceCodeFromLocalStorage) {
                this.router.navigate(['/forbidden']);
                return null;
            }
            return `${ environment.api.base }/${instanceCodeFromLocalStorage}/${ environment.api.refresh }`;
        } else {
            return null;
        }
    }

    private logTokenRefresh(msg: string, req?: HttpRequest<any>): void {
        let reqUrl = req ? ' -> ' + req.method + ' ' + req.url : '';
    }
}

export function authConfigFactory(config?: IAuthConfigOptional) {
    return new AuthConfig(config);
}


let hasOwnProperty = Object.prototype.hasOwnProperty;
let propIsEnumerable = Object.prototype.propertyIsEnumerable;

function toObject(val: any) {
    if (val === null || val === undefined) {
        throw new TypeError('Object.assign cannot be called with null or undefined');
    }

    return Object(val);
}

function objectAssign(target: any, ...source: any[]) {
    let fromObject: any;
    let to = toObject(target);
    let symbols: any;

    for (let s = 1; s < arguments.length; s++) {
        fromObject = Object(arguments[s]);

        for (let key in fromObject) {
            if (hasOwnProperty.call(fromObject, key)) {
                to[key] = fromObject[key];
            }
        }

        if ((<any>Object).getOwnPropertySymbols) {
            symbols = (<any>Object).getOwnPropertySymbols(fromObject);
            for (let i = 0; i < symbols.length; i++) {
                if (propIsEnumerable.call(fromObject, symbols[i])) {
                    to[symbols[i]] = fromObject[symbols[i]];
                }
            }
        }
    }
    return to;
}

@Injectable()
export class AuthenticationService {

    constructor(
        @Inject(AuthHttp) private http: AuthHttp,
        @Inject(tokenStorageT) private tokenStorage: ITokenStorage
    ) { }

    login(login: string, password: string, instance: string): Observable<void> {
        let apiUrl = `${ environment.api.base }/${ instance }/${ environment.api.login }`;
        return this.http.post(apiUrl, { user: { login, password } }, { withCredentials: false })
            .pipe(
                filter(ensureIsHttResponse),
                map((response: HttpResponse<any>) => {
                    // login successful if there's a jwt token in the response
                    let csrfToken = response.body && response.body.csrf_token;
                    if (csrfToken) {
                        this.tokenStorage.set(csrfToken);
                    }
                }),
                catchError(this.handleError)
            );
    }

    logout(instance: string): Observable<HttpResponse<any>> {
        let apiUrl = `${ environment.api.base }/${ instance }/${ environment.api.login }`;

        return this.http.delete(apiUrl, { withCredentials: true })
            .pipe(
                map((response: HttpResponse<any>) => {
                    this.tokenStorage.remove();
                    return response;
                }),
                catchError(this.handleError)
            );
    }

    private handleError(error: HttpResponse<any> | any) {
        let errMsg: string;
        let status: number;
        let statusText: string = 'error';
        if (error instanceof HttpResponse || error instanceof HttpErrorResponse) {
            errMsg = error.statusText;
            status = error.status;
            statusText = error.statusText;
        } else {
            errMsg = error.message ? error.message : error.toString();
        }

        return observableThrowError({ status, statusText, message: errMsg });
    }
}

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {

    constructor(
        @Inject(tokenStorageT) private tokenStorage: ITokenStorage,
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
        let url: string = state.url;
        const urlSegments = url.split('/');
        let csrfToken: string | Promise<string> = this.tokenStorage.get();
        if (csrfToken instanceof Promise) {
            return from(csrfToken)
                .pipe(
                    map((_csrfToken: string) => {
                        if (_csrfToken) {
                            return true;
                        } else {
                            let navigationExtras: NavigationExtras = {
                                queryParams: { 'returnUrl': url }
                            };
                            this.router.navigate([`/${urlSegments[1]}/login`], navigationExtras);
                            return false;
                        }
                    })
                );
        } else {
            if (csrfToken) {
                return true;
            } else {
                let navigationExtras: NavigationExtras = {
                    queryParams: { 'returnUrl': url }
                };
                this.router.navigate([`/${urlSegments[1]}/login`], navigationExtras);
                return false;
            }
        }
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
        return this.canActivate(route, state);
    }
}
