import { HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import { map, catchError } from 'rxjs/operators';

import { BaseApiClient } from '../base-api-client';
import { IChange, IChangeCollection } from '../../change/interfaces';
import { Change } from '../../change/change.service';
import { ICollection, IParam, IRecursiveArray } from '../interfaces';
import { BaseSerializer } from '../base-serializer';
import { IComment, IPersistentComment } from '../../comment/interfaces';
import { Comment } from '../../comment/comment.service';

export function Changeable() {

    return function (target) {

        const getChanges = function (
            id: string, params?: IParam, include?: IRecursiveArray<string>
        ): Observable<IChangeCollection> {
            if (!(this instanceof BaseApiClient)) {
                throw new Error('Decorated element must by type BaseApiClient');
            }

            let serializer = new BaseSerializer<IChange>(Change);
            this.recreateParams();
            let includes = this.includesConverter.convert(include);
            if (includes) {
                this.params = this.params.set('include', includes);
            }

            this.setImpersonationParams();

            return this.http
                .get(`${this.apiBase()}/${id}/changes`,
                    {params: this.prepareParams(params)}
                )
                .pipe(
                    map((response: HttpResponse<any>) => {
                        let rawData = response.body;
                        let entities = serializer.deserializeMany(rawData, includes);
                        return {data: entities, meta: rawData.meta} as ICollection<IChange>;
                    })
                );
        };

        const addChangeComment = function (entity: IPersistentComment, changeableId: string): Observable<IComment> {
            if (!(this instanceof BaseApiClient)) {
                throw new Error('Decorated element must by type BaseApiClient');
            }
            let serializer = new BaseSerializer<IComment>(Comment);
            let data = serializer.serialize(new Comment(entity));

            this.recreateParams();
            let includes = this.includesConverter.convert(['user']);
            if (includes) {
                this.params = this.params.set('include', includes);
            }

            this.setImpersonationParams();
            return this.http.post(
                `${this.apiBase()}/${changeableId}/changes/${entity.commentable.id}/comments`,
                data,
                {params: this.params}
            ).pipe(
                map((response: HttpResponse<any>) => {
                    return serializer.deserialize(response.body, includes);
                }),
                catchError(this.handleRequestError)
            );
        };

        target.prototype.getChanges = getChanges;
        target.prototype.addChangeComment = addChangeComment;

    };

}
