import { ICollection, IBaseApiClient, IResource } from '../common/interfaces';

export interface IAccount extends IResource {
    name: string;
    account_type: string;
}

export interface IAccountCollection extends ICollection<IAccount> { }

export interface IAccountClient extends IBaseApiClient<IAccount> { }
