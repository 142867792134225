/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';
import { AuthHttp } from '../authentication/authentication.service';

import { ISchema, BaseApiClient } from '../common/index';
export { IBaseApiClient } from '../common/interfaces';

import { IResource } from '../common/interfaces';
import { IFeatureReminder, IFeatureReminderClient } from './interfaces';
import { IFeature } from '../feature/interfaces';
import { Feature } from '../feature/feature.service';
import {
    instanceCodeClientT, IInstanceCodeClient, paramsBuilderT, IParamsBuilder,
} from '../common/interfaces';
import { BaseEntity, Field, BelongsTo } from '../common/meta';
import { environment } from 'environments/environment';

export class FeatureReminder extends BaseEntity<IFeatureReminder> implements IFeatureReminder {

    public static readonly type: string = 'feature_reminder';

    @Field()
    id: string;

    @Field()
    last_shown_at: string;

    @BelongsTo({ deferredConstructor: () => Feature })
    feature: IFeature | IResource;
}

@Injectable()
export class FeatureReminderClient extends BaseApiClient<IFeatureReminder> implements IFeatureReminderClient {
    protected static classConfig: ISchema = {
        apiResourceName: 'feature_reminders'
    };

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return FeatureReminder;
    }

    protected apiBase(): string {
        return `${ environment.api.internal }/${ this.instanceCode }/${ this.classConfig().apiResourceName }`;
    }
}
