import { Component } from '@angular/core';

@Component({
    selector: 'forbidden',
    templateUrl: './forbidden.template.html',
    host: {
        class: 'error-page app'
    },
})
export class ForbiddenComponent {}
