import { ITargetingDefault } from '../targeting-default/interfaces';
import { ICollection, IBaseApiClient, IResource } from '../common/index';

export interface IInstance extends IResource {
    name: string;
    code: string;
    currency: string;
    time_zone: string;
    locale: string;
    active?: boolean;
    maximum_daily_spend?: number;
    support_tier?: string;
    budget_spend?: string;
    theme_color?: string;
    iponweb_code: string;
    use_old_seat_logic_for_rubicon?: boolean;
    clearing_fee?: number;
    logo?: LogoFile;
    login_logo?: LogoFile;
    date_locale: string;
    video_visibility_values: string[];
    display_visibility_values: string[];
    visible_strategy_cards?: VisibleStrategyCard[];
    optimized_margin_fee_percentage?: number;
    targeting_default: ITargetingDefault | IResource;
    reporting_package: string;
    reporting_package_values: {[key: string]: any}[];
    first_weekday: number;
    open_auction_allowed: 'disabled' | 'instance' | 'campaign';
    has_appnexus_inventory: boolean;
    has_google_inventory: boolean;
    has_viooh_inventory: boolean;
    has_broadsign_inventory: boolean;
    appnexus_connection: 'default' | 'direct' | 'bidswitch';
    default_radius: string;
    distance_unit: string;
    has_campaigns_older_than_180_days: boolean;
}

export type IInstanceCollection = ICollection<IInstance>;

export interface IInstanceClient extends IBaseApiClient<IInstance> {
}

export type LogoFile = {
    url: string;
    content_type: string;
};

export type VisibleStrategyCard = {
    id: string;
    name: string;
};
