/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';

import { Observable } from 'rxjs/internal/Observable';
import { map, catchError } from 'rxjs/operators';

import { ISchema } from '../common/index';
import { BaseEntity, Field, BelongsTo } from '../common/meta';
import {
    IInstanceCodeClient,
    instanceCodeClientT,
    paramsBuilderT,
    IParamsBuilder, IResource
} from '../common/interfaces';

import { IUserNotification, IUserNotificationClient } from './interfaces';
import { User } from '../user/user.entity';
import { IUser } from '../user/interfaces';
import { BasePersistentApiClient } from '../common/base-api-client';
import { IBulkResponse } from '../common/errors';
import { BulkRemovable } from '../common/bulk-removable/bulk-removable';
import { Readable } from '../common/readable/readable';
import { AuthHttp } from '../authentication/authentication.service';
import { HttpResponse } from '@angular/common/http';


class UserNotification extends BaseEntity<IUserNotification> implements IUserNotification {

    public static readonly type: string = 'user_notification';

    @Field()
    id: string;

    @Field()
    title: string;

    @Field()
    seen: boolean;

    @Field()
    body: string;

    @Field()
    date: string;

    @Field()
    notification_type: string;

    @BelongsTo({ deferredConstructor: () => User })
    user: IUser | IResource;
}

@Injectable()
@Readable()
@BulkRemovable()
export class UserNotificationClient extends BasePersistentApiClient<IUserNotification, never, never>
    implements IUserNotificationClient {

    protected static classConfig: ISchema = {
        apiResourceName: 'user_notifications'
    };

    markAsRead: (id: string) => Observable<IUserNotification>;
    markAsUnread: (id: string) => Observable<IUserNotification>;
    bulkRemove: (ids: string[]) => Observable<IBulkResponse[]>;

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    public markAllRead(): Observable<number> {
        let apiUrl = `${this.apiBase()}/bulk/all_seen`;
        this.recreateParams();
        this.setImpersonationParams();
        return this.http.post(apiUrl, {}, { params: this.params })
            .pipe(
                map((response: HttpResponse<any>) => {
                    return +response['body']['data']['attributes']['affected_count'];
                }),
                catchError(this.handleRequestError)
            );
    }

    protected implementationClass() {
        return UserNotification;
    }
}
