interface IErrors {
    errors: IError[];
}

interface IError {
    // This is an interface for a generic JSON API error:
    // http://jsonapi.org/format/#errors

    id?: string; // a unique identifier for this particular occurrence of the problem
    links?: any; // a links object
    status?: number; // the HTTP status code applicable to this problem
    code?: number; // an application-specific error code
    title?: string; // a short, human-readable summary of the problem
    detail?: string; // a human-readable explanation specific to this occurrence of the problem
    source?: IErrorSource; // an object containing references to the source of the error
    meta?: any; // non-standard meta-information about the error
}

interface IErrorSource {
    pointer?: string; // a JSON Pointer [RFC6901] to the associated entity in the request document
    parameter?: string; // a string indicating which URI query parameter caused the error
}

export interface IErrorResponse {
    code: number;
    errors?: Map<string, string[]>;
    meta?: any;
}

export class ErrorResponse implements IErrorResponse {
    code: number;
    errors?: Map<string, string[]>;
    meta?: any;

    public constructor(private status: number, private bodyErrors: IError[]) {
        this.code = status;
        if (bodyErrors) this.convertErrors(bodyErrors);
    }

    isBadRequestResponse(): boolean {
        return this.code === 400;
    }

    isUnauthorizedResponse(): boolean {
        return this.code === 401;
    }

    isForbiddenResponse(): boolean {
        return this.code === 403;
    }

    isNotFoundResponse(): boolean {
        return this.code === 404;
    }

    isUnprocessableEntity(): boolean {
        return this.code === 422;
    }

    isInternalServerError(): boolean {
        return this.code === 500;
    }

    toFlatArray(): string[] {
        let errorCollection: string[] = [];

        if (!this.errors) {
            return errorCollection;
        }

        this.errors.forEach((errorMessages: string[]) => {
            errorCollection.push(...errorMessages);
        });

        return errorCollection;
    }

    private convertErrors(bodyErrors: IError[]) {
        this.errors = new Map();
        for (let error of bodyErrors) {
            if (('source' in error) && ('detail' in error) && ('pointer' in error.source)) {
                let detail = error.detail;
                let field = error.source.pointer.split('/').pop();
                let details = this.errors.get(field);
                if (!details) {
                    details = new Array;
                }
                details.push(detail);
                this.errors.set(field, details);
            } else if ('title' in error || 'detail' in error) {
                let field = 'general';
                let details = this.errors.get(field);
                if (!details) {
                    details = new Array;
                }
                if (error.title) {
                    details.push(error.title);
                }
                if (this.isUsefulError(error.detail)) {
                    details.push(error.detail);
                }
                this.errors.set(field, details);
            }
        }
    }

    private isUsefulError(error: string): boolean {
        let forbiddenStrings = ['Api::V3'];
        let isUseful = true;
        forbiddenStrings.forEach(forbidden => {
            if (!error || error.indexOf(forbidden) !== -1) {
                isUseful = false;
            }
        });
        return isUseful;
    }

}

export interface IBulkResponse {
    id: string;
    success: boolean;
    errorResponse?: IErrorResponse;
}
