/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';

import { ISchema, BaseApiClient } from '../common/index';
import { IPlatform161TechFee, IPlatform161TechFeeClient } from './interfaces';
import { BaseEntity, Field } from '../common/meta';
import {
    instanceCodeClientT, IInstanceCodeClient, paramsBuilderT,
    IParamsBuilder
} from '../common/interfaces';
import { AuthHttp } from '../authentication/authentication.service';

export class Platform161TechFee extends BaseEntity<IPlatform161TechFee> implements IPlatform161TechFee {

    public static readonly type: string = 'platform161_tech_fee';

    @Field()
    id: string;

    @Field()
    value: string;

}

@Injectable()
export class Platform161TechFeeClient extends BaseApiClient<IPlatform161TechFee> implements IPlatform161TechFeeClient {
    protected static classConfig: ISchema = {
        apiResourceName: 'platform161_tech_fees'
    };

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return Platform161TechFee;
    }
}
