/* tslint:disable:variable-name */
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map, catchError } from 'rxjs/operators';
import {
    IGeneralReportClient, IGeneralReport, IGeneralReportResult,
    GeneralReportHasManyRelationships, GeneralReportBelongsToRelationships, ISimpleResult
} from './interfaces';
import { Serializable } from '../common/interfaces';

import { Field, BaseEntity, BelongsTo, HasMany } from '../common/meta';
import { BaseSerializer } from '../common/base-serializer';
import { BasePersistentApiClient } from '../common/index';
import {
    Period, Interval, Measure, Grouping, Filters, GenerationStatus, Status, Result,
    ReportScheduleType, ReportSendAtType, LEGACY_MEASURES
} from './types';
import {
    ISchema,
    instanceCodeClientT,
    paramsBuilderT,
    IParamsBuilder,
    IInstanceCodeClient, IResource, IParam,
    IRecursiveArray,
    IRequestOptions
} from '../common/interfaces';
import { IUser } from '../user/interfaces';
import { User } from '../user/user.entity';
import { IReportFormat } from '../../src/app/modules/report/common/interfaces';
import { BulkRemovable } from '../common/bulk-removable/bulk-removable';
import { Duplicable } from '../common/duplicable/duplicable';
import { AuthHttp } from '../authentication/authentication.service';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

export class SimpleResult extends BaseEntity<SimpleResult> implements ISimpleResult {
    public static readonly type: string = 'simple_result';

    @Field()
    id: string;

    @Field()
    generation_status: GenerationStatus;
}
@Injectable()
@BulkRemovable()
@Duplicable()
export class GeneralReportClient
    extends BasePersistentApiClient
        <IGeneralReport, GeneralReportBelongsToRelationships, GeneralReportHasManyRelationships>
    implements IGeneralReportClient {
    protected static classConfig: ISchema = {
        apiResourceName: 'general_reports'
    };

    // Temporary solution to test ClickHouse Reporting //
    useClickHouse: boolean;

    duplicate: (id: string) => Observable<IGeneralReport>;

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder,
        protected activatedRoute: ActivatedRoute
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return GeneralReport;
    }

    get(id: string, include?: IRecursiveArray<string>, options: IRequestOptions = {}): Observable<IGeneralReport> {
        return super.get(id, include, options)
            .pipe(
                map((r: IGeneralReport) => {
                    if (r.measures) {
                        r.measures = r.measures.map(m => LEGACY_MEASURES[m] || m);
                    }
                    return r;
                })
            );
    }

    generate(reportId: string): Observable<IGeneralReportResult> {
        this.recreateParams();
        this.setImpersonationParams();
        return this.http.post(
                `${ this.apiBase() }/${ reportId }/relationships/results`, '',
                { params: this.prepareParams() }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) => {
                        return this.deserializeWithMeta(response);
                    }
                ),
                catchError(this.handleRequestError)
            );
    }

    download(reportId: string, extension: string = 'xlsx', impersonatedUser = null): Observable<any> {
        this.recreateParams();
        let url = `${ this.apiBase() }/${ reportId }/${ extension }_results`;
        if (impersonatedUser) {
            url = url + '/?impersonated_user=' + impersonatedUser;
        }
        return this.http.get(
            url, { responseType: 'blob' }
        )
            .pipe(
                map(
                    (response: HttpResponse<any>) => { return response; }
                ),
                catchError(this.handleRequestError)
            );
    }

    saveAndGenerate(entity: IGeneralReport): Observable<IGeneralReport> {
        this.customParams = new HttpParams();
        this.customParams = this.customParams.append('generate', 'true');

        // Temporary solution to test ClickHouse Reporting //
        this.checkClickHouse();
        if (this.useClickHouse) {
            this.customParams = this.customParams.append('clickhouse', 'true');
        }

        return super.save(entity);
    }

    getResult(reportId: string, resultId: string, params?: IParam): Observable<IGeneralReportResult> {
        this.recreateParams();
        this.setImpersonationParams();

        // Temporary solution to test ClickHouse Reporting //
        this.checkClickHouse();
        if (this.useClickHouse) {
            params.clickhouse = true;
        }

        return this.http.get(
                `${ this.apiBase() }/${ reportId }/relationships/results/${ resultId }`,
                { params: this.prepareParams(params, {page: 'report_page'}) }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) => {
                        return this.deserializeWithMeta(response);
                    }
                ),
                catchError(this.handleRequestError)
            );
    }

    getCacheReportResult(reportId: string, resultId: string, data: any): Observable<IGeneralReportResult> {
        this.recreateParams();
        let serializer = new BaseSerializer<IGeneralReportResult>(GeneralReportResult);
        this.setImpersonationParams();
        return this.http.post(
                `${ this.apiInternal() }/report_cache`, data,
                { params: this.prepareParams({}, {page: 'report_page'}) }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) => {
                        return this.deserializeWithMeta(response);
                    }
                ),
                catchError(this.handleRequestError)
            );
    }

    enqueueEmail(reportId: string): Observable<IGeneralReport> {
        this.recreateParams();
        let serializer = new BaseSerializer<IGeneralReport>(GeneralReport);
        this.setImpersonationParams();
        return this.http.post(`${this.apiBase()}/${reportId}/email`, '', { params: this.prepareParams() })
            .pipe(
                map(
                (response: HttpResponse<any>) => {
                    return serializer.deserialize(response.body);
                }
                ),
                catchError(this.handleRequestError)
            );
    }

    saveWithFile(entity: IGeneralReport, format: IReportFormat): Observable<IGeneralReport> {
        this.customParams = new HttpParams();
        this.customParams = this.customParams.append('generate_file', format);
        return super.save(entity);
    }

    deserializeWithMeta(response: HttpResponse<any>): Serializable<IGeneralReportResult> {
        let rawData = response.body;
        let serializer = new BaseSerializer<IGeneralReportResult>(GeneralReportResult);
        let result = serializer.deserialize(rawData);
        if (!rawData.meta) {
            result['meta'] = {
                total_pages: 1,
                total_count: result.data_rows.length,
                current_page: 1,
                next_page: null,
                previous_page: null
            };
        } else {
            result['meta'] = rawData.meta.report_pages;
        }

        return result;
    }

    // Temporary solution to test ClickHouse Reporting //
    checkClickHouse(): void {
        this.activatedRoute.queryParams.subscribe((params) => {
            this.useClickHouse = params['clickhouse'];
        });
    }

    // Overwritten to force the addition of the 'force_new_measures' option
    protected recreateParams(): void {
        super.recreateParams();
        this.params = this.params.append('force_new_measures', 'true');
        if (this.useClickHouse) {
            this.params = this.params.append('clickhouse', 'true');
        }
    }

}

export class GeneralReport extends BaseEntity<IGeneralReport> implements IGeneralReport {

    public static readonly type: string = 'general_report';

    @Field()
    id: string;

    @Field()
    name: string;

    @Field()
    period: Period;

    @Field()
    date_limit: 'none' | 'range' | 'period';

    @Field()
    interval: Interval;

    @Field()
    start_on: string;

    @Field()
    end_on: string;

    @Field()
    measures: Measure[];

    @Field()
    groupings: Grouping[];

    @Field()
    filters: Filters;

    @Field()
    recipients: string;

    @Field()
    body: string;

    @Field()
    schedule: ReportScheduleType;

    @Field()
    schedule_format: string;

    @Field()
    schedule_start_on: string;

    @Field()
    schedule_end_on: string;

    @Field()
    send_at: ReportSendAtType;

    @Field()
    sort: string[] | string;

    @Field()
    on_business_days: boolean;

    @Field()
    date: string;

    @Field()
    status: Status;

    @Field()
    xlsx: string;

    @Field()
    csv: string;

    @Field()
    shared_to_me?: boolean;

    @Field()
    shared_by?: string;

    @BelongsTo({ deferredConstructor: () => SimpleResult, apiRelationshipName: 'enqueued_result', optional: true })
    enqueued_result?: SimpleResult;

    @HasMany({ deferredConstructor: () => User })
    authorized_users?: IUser[] | IResource[];
}

export class GeneralReportResult extends BaseEntity<IGeneralReportResult> implements IGeneralReportResult {
    public static readonly type: string = 'general_report_result';

    @Field()
    id: string;

    @Field()
    data_rows: Result[];

    @Field()
    totals: Result[];

    @Field()
    generation_status: GenerationStatus;

    @BelongsTo({ deferredConstructor: () => GeneralReport })
    report: IGeneralReport | IResource;

    meta: {
        current_page?: number,
        next_page?: number,
        previous_page?: number,
        total_pages?: number,
        total_count?: number
    };

}
