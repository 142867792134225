import * as pluralize from 'pluralize';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { BaseApiClient } from '../base-api-client';

export function Activable<T>() {

    return function (target) {

        const activate = function (id: string): Observable<T> {
            return this.toggleActive(id, true);
        };

        const deactivate = function (id: string): Observable<T> {
            return this.toggleActive(id, false);
        };

        const toggleActive = function (id: string, active: boolean): Observable<T> {
            if (!(this instanceof BaseApiClient)) {
                throw new Error('Decorated element must by type BaseApiClient');
            }
            let options = this.prepareRequestOptionsForDecorators();

            return this.http
                .patch(`${this.apiBase()}/${id}`,
                    {
                        'data': {
                            'id': id,
                            'type': pluralize.singular(this.classConfig().apiResourceName),
                            'attributes': {
                                'active': active
                            }
                        }
                    },
                    options
                )
                .pipe(
                    map((response: HttpResponse<any>) => {
                        return Object.assign({},
                            response.body.data.attributes, { id: response.body.data.id }) as T;
                    })
                );
        };

        target.prototype.activate = activate;
        target.prototype.deactivate = deactivate;
        target.prototype.toggleActive = toggleActive;

    };

}
