import { Injectable, Inject } from '@angular/core';

import {
    instanceCodeClientT,
    IInstanceCodeClient,
    paramsBuilderT,
    IParamsBuilder,
} from '../common/interfaces';
import { ISchema, BaseApiClient } from '../common/index';
import { IPixel, IPixelClient } from './interfaces';
import { AuthHttp } from '../authentication/authentication.service';
import { environment } from 'environments/environment';
import { Pixel } from './pixel.entity';

@Injectable()
export class PixelClient extends BaseApiClient<IPixel> implements IPixelClient {
    protected static classConfig: ISchema = {
        apiResourceName: 'pixels'
    };

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return Pixel;
    }

    protected apiBase(): string {
        return `${environment.api.internal}/${this.instanceCode}/${this.classConfig().apiResourceName}`;
    }

}
