import { Router, ActivatedRoute } from '@angular/router';
import { Archivable } from '../common/archivable/archivable';
import { Injectable, Inject } from '@angular/core';
import { Observable, throwError, of } from 'rxjs';
import { map, catchError, delay } from 'rxjs/operators';

import { ISchema } from '../common/index';
export { IBaseApiClient } from '../common/interfaces';
import {
    IUser,
    IUserClient,
    IUserCollection,
    UserBelongsToRelationships,
    UserHasManyRelationships
} from './interfaces';
import {
    instanceCodeClientT, IInstanceCodeClient, paramsBuilderT,
    IParamsBuilder, IRecursiveArray, IParam, IRequestOptions
} from '../common/interfaces';
import { ErrorResponse } from '../common/errors';

import { BasePersistentApiClient } from '../common/base-api-client';
import { IChangeable, IChangeCollection } from '../change/interfaces';
import { IComment, IPersistentComment } from '../comment/interfaces';
import { AuthHttp } from '../authentication/authentication.service';
import { HttpResponse, HttpParams } from '@angular/common/http';
import { User } from './user.entity';
import { Changeable } from '../common/changeable/changeable';
import { environment } from 'environments/environment';

@Changeable()
@Injectable()
@Archivable()
export class UserClient
    extends BasePersistentApiClient<IUser, UserBelongsToRelationships, UserHasManyRelationships>
    implements IUserClient, IChangeable {
    protected static classConfig: ISchema = {
        apiResourceName: 'users'
    };

    unarchive: (id: string) => Observable<IUser>;
    archive: (id: string) => Observable<IUser>;

    private router: Router;
    private _route: ActivatedRoute;

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder,
        router: Router,
        route: ActivatedRoute
    ) {
        super(http, instanceCodeClient, paramsBuilder);
        this.router = router;
        this._route = route;
    }


    protected implementationClass() {
        return User;
    }

    resetPassword(email: string): Observable<any> {
        let apiUrl = `${environment.api.base}/${ this.instanceCodeFromRoute() }/user_reset_tokens`;
        return this.http.post(apiUrl, { email: email}, {withCredentials: false})
            .pipe(
                map((response: HttpResponse<any>) => {
                    if (response) {
                        if (response.status >= 200 && response.status < 300) {
                            return of(true).pipe(delay(0));
                        } else {
                            return throwError(new ErrorResponse(response.status, response.body.errors));
                        }
                    }
                }),
                catchError(this.handleRequestError)
            );
    }

    me(include?: IRecursiveArray<string>, options?: IRequestOptions): Observable<IUser> {
        this.recreateParams();
        this.setImpersonationParams();
        let includes = this.buildIncludes(this.includesConverter.convert(include));
        if (includes) {
            this.params = this.params.set('include', includes);
        }
        this.prepareFieldsParams(options);
        return this.http
            .get(`${this.apiBase()}/me/`, { params: this.params })
            .pipe(
                map((response: HttpResponse<any>) => {
                    let rawData = response.body;
                    let entity = this.serializer.deserialize(rawData, includes);
                    return entity as IUser;
                }),
                catchError(this.handleRequestError)
            );
    }

    apiBase(): string {
        return  `${ environment.api.base }/${ this.instanceCodeFromRoute() }/${ this.classConfig().apiResourceName }`;
    }

    getChanges(
        changeableId: string, params?: IParam, include?: IRecursiveArray<string>
    ): Observable<IChangeCollection> {
        throw new Error('Method not implemented.');
    }

    addChangeComment(note: IPersistentComment, changeableId: string): Observable<IComment> {
        throw new Error('Method not implemented.');
    }

    getCampaignManagers(
        params?: IParam, include?: IRecursiveArray<string>, options: IRequestOptions = {}
    ): Observable<IUserCollection> {
        // remove old unnecessary fields from params
        this.recreateParams();

        let includes = this.buildIncludes(this.includesConverter.convert(include), options.ignoreMandatoryIncludes);
        if (includes) {
            this.params.set('include', includes);
        }
        this.setImpersonationParams();
        this.prepareFieldsParams(options);

        return this.http
            .get(`${ this.apiBase() + '/campaign_managers' }`,
                { params: this.prepareParams(params) })
            .pipe(map((response: HttpResponse<any>) => {
                let rawData = response.body;
                let entities = this.serializer.deserializeMany(rawData, includes);
                return { data: entities, meta: rawData.meta } as IUserCollection;
            }),
            catchError(this.handleRequestError));
    }

    getSalesManagers(
        params?: IParam, include?: IRecursiveArray<string>, options: IRequestOptions = {}
    ): Observable<IUserCollection> {
        // remove old unnecessary fields from params
        this.recreateParams();

        let includes = this.buildIncludes(this.includesConverter.convert(include), options.ignoreMandatoryIncludes);
        if (includes) {
            this.params.set('include', includes);
        }
        this.setImpersonationParams();
        this.prepareFieldsParams(options);

        return this.http
            .get(`${ this.apiBase() + '/sales_managers' }`,
                { params: this.prepareParams(params) })
            .pipe(map((response: HttpResponse<any>) => {
                let rawData = response.body;
                let entities = this.serializer.deserializeMany(rawData, includes);
                return { data: entities, meta: rawData.meta } as IUserCollection;
            }),
            catchError(this.handleRequestError));
    }

    enableShowAll(showAll: boolean): void {
        if (!this.customParams) {
            this.customParams = new HttpParams();
        }
        this.customParams = this.customParams.append('show_all', showAll);
    }

    private instanceCodeFromRoute(): any {
        let url = this.router.url && this.router.url.split('/');
        let instanceFromUrl = url && url.length > 2 && url[1];
        let instanceFromStoredRoute = this._route.snapshot.firstChild &&
            this._route.snapshot.firstChild.params.instance;
        return instanceFromUrl || instanceFromStoredRoute || this.instanceCode;
    }
}
