import { IBaseApiClient, ICollection, IResource } from '../../common/interfaces';

export interface IPreviewTrackClick extends IResource {
    created_at: string;
    landing_page: string;
}

export interface IPreviewTrackClickCollection extends ICollection<IPreviewTrackClick> { }

export interface IPreviewTrackClickClient extends IBaseApiClient<IPreviewTrackClick> { }

export interface IPreviewTrackClickClientFactory {
    createPreviewTrackClickClient(creativeId: string): IPreviewTrackClickClient;
}

export interface ITrackClickUrlFactory {
    createTrackClickUrl(creativeId: string, clickUrlIndex?: number): string;
}
