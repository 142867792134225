import { Injectable, Inject } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';

import {
    IThirdPartyDataSegment, IThirdPartyDataSegmentClient, IThirdPartyDataSegmentCollection, IZipCodesEntity,
    ThirdPartyDataSegmentBelongsToRelationships, ThirdPartyDataSegmentHasManyRelationships
} from './interfaces';
import {
    ICollection, IInstanceCodeClient, instanceCodeClientT, IParam, IParamsBuilder, IRecursiveArray, IRequestOptions,
    ISchema, paramsBuilderT
} from '../../common/interfaces';
import { BasePersistentApiClient } from '../../common/base-api-client';
import { BulkRemovable } from './../../common/bulk-removable/bulk-removable';
import { IBulkResponse } from './../../common/errors';
import { AuthHttp } from '../../authentication/authentication.service';
import { ThirdPartyDataSegment } from './third-party-data-segment.entity';
import { catchError, map } from 'rxjs/operators';

@Injectable()
@BulkRemovable()
export class ThirdPartyDataSegmentClient
    extends BasePersistentApiClient
        <IThirdPartyDataSegment, ThirdPartyDataSegmentBelongsToRelationships, ThirdPartyDataSegmentHasManyRelationships>
    implements IThirdPartyDataSegmentClient {

    protected static classConfig: ISchema = {
        apiResourceName: 'third_party_data_segments'
    };

    bulkRemove: (ids: string[]) => Observable<IBulkResponse[]>;

    constructor(http: AuthHttp,
                @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
                @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    getAllByProvider(providerId: string,
                     params?: IParam): Observable<IThirdPartyDataSegmentCollection> {
        throw new Error('Method not implemented.');
    }

    loadZipCodes(entity: IZipCodesEntity, include?: IRecursiveArray<string>, options: IRequestOptions = {})
        : Observable<IThirdPartyDataSegmentCollection> {
        this.recreateParams();
        let includes = this.buildIncludes(this.includesConverter.convert(include), options.ignoreMandatoryIncludes);
        if (includes) {
            this.params = this.params.set('include', includes);
        }
        this.setImpersonationParams();
        this.prepareFieldsParams(options);
        return this.http.post(`${this.apiBase()}/zip_codes`, { data: entity }, { params: this.params }).pipe(
            map((response: HttpResponse<any>) => {
                let rawData = response.body;
                let entities = this.serializer.deserializeMany(rawData, includes);
                return { data: entities, meta: rawData.meta } as ICollection<IThirdPartyDataSegment>;
            }),
            catchError(this.handleRequestError)
        );
    }

    protected implementationClass() {
        return ThirdPartyDataSegment;
    }
}
