import { ICollection, IPersistentApiClient, IResource } from '../common/interfaces';
import { IBulkRemovable } from './../common/bulk-removable/interfaces';
import { IAdvertiser } from '../advertiser/interfaces';

export interface ICustomDataSegment extends IResource {
    name: string;
    code: string;
    campaign_ids: string[];
    advertiser: IAdvertiser | IResource;
}

export type CustomDataSegmentBelongsToRelationships = never;
export type CustomDataSegmentHasManyRelationships = never;

export interface ICustomDataSegmentCollection extends ICollection<ICustomDataSegment> { }

export interface ICustomDataSegmentClient
    extends IPersistentApiClient<ICustomDataSegment,
    CustomDataSegmentBelongsToRelationships,
    CustomDataSegmentHasManyRelationships>,
    IBulkRemovable { }
