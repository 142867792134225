/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';
import { instanceCodeClientT, IInstanceCodeClient, paramsBuilderT, IParamsBuilder } from '../common/interfaces';
import { IContentCategory, IContentCategoryClient } from './interfaces';
import { BaseEntity, Field } from '../common/meta';
import { ISchema, BaseApiClient } from '../common/index';
import { AuthHttp } from '../authentication/authentication.service';

export class ContentCategory extends BaseEntity<IContentCategory> implements IContentCategory {

    public static readonly type: string = 'content_category';

    @Field()
    id: string;

    @Field()
    name: string;

}

@Injectable()
export class ContentCategoryClient extends BaseApiClient<IContentCategory> implements IContentCategoryClient {
    protected static classConfig: ISchema = {
        apiResourceName: 'content_categories'
    };

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return ContentCategory;
    }
}
