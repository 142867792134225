import { ICollection, IBaseApiClient, IResource } from '../common/interfaces';

export interface IOperatingSystem extends IResource {
    name: string;
    code: string;
    parent?: IOperatingSystem | IResource;
}

export type IOperatingSystemCollection = ICollection<IOperatingSystem>;

export interface IOperatingSystemClient extends IBaseApiClient<IOperatingSystem> { }
