import { HttpParams } from '@angular/common/http';
import {
    ICond, IOrderBy, IParam, IParamKeyMap, IParamsBuilder, ISearch, ISearchExpr, ISortCaseParam
} from './interfaces';
import isObject from 'lodash-es/isObject';
import merge from 'lodash-es/merge';

const AND = '__and';
const OR = '__or';
const operationsHash = {
    'and': AND,
    'or': OR
};

const getOperation = (key) => {
    return operationsHash[key];
};

export class ParamsBuilder implements IParamsBuilder {

    build(params: IParam, paramKeyMap?: IParamKeyMap): HttpParams {
        let builtParams = {};
        if (params) {
            builtParams = this.buildParams(params, paramKeyMap || {});
        }

        return this.prepareQuery(builtParams, new HttpParams());
    }

    private prepareQuery(query: any, search: HttpParams, currentParam: string = null): HttpParams {
        if (Array.isArray(query)) {
            query.forEach((value) => {
                search = search.append(`${currentParam}[]`, value.toString());
            });
        } else if (isObject(query)) {
            let keys = Object.keys(query);
            keys.forEach((key) => {
                let nextParam = (currentParam === null ? key : `${currentParam}[${key}]`);
                search = this.prepareQuery(query[key], search, nextParam);
            });
        } else {
            search = search.append(currentParam, query.toString());
        }
        return search;
    }

    private buildParams(params: IParam, paramKeyMap: IParamKeyMap) {
        let preparedParams = this.permittedParams().map((key) => {
            if (key in params) {
                let value = params[key];
                if (value === null || value === undefined) {
                    return {};
                }
                switch (key) {
                    case 'limit':
                        return this.buildLimit(value as number, params);
                    case 'offset':
                        return this.buildOffset(value as number, params);
                    case 'order':
                        return this.buildOrder(value as IOrderBy | IOrderBy[], params);
                    case 'search':
                        return { 'filter': this.buildSearchParams(value as ISearch, params) };
                    case 'sortCase':
                        return this.buildSortCase(value as ISortCaseParam[]);
                    // Temporary solution to test ClickHouse Reporting //
                    case 'clickhouse':
                        return { 'clickhouse': 'true' };
                    default:
                        throw new Error(`unidentified key in query params: ${key}`);
                }
            }
        }).filter(param => !!param);

        preparedParams = this.replaceParamNames(preparedParams, paramKeyMap);
        return merge({}, ...preparedParams);
    }

    private buildSortCase(values: ISortCaseParam[]) {
        const preparedParams = values.reduce((value, caseParam: ISortCaseParam) => {
            let preparedValues = {};
            if (Array.isArray(caseParam.field)) {
                caseParam.field.reduce((obj, key, i, array) => {
                    return obj[key] = i + 1 === array.length ? caseParam.value : {};
                }, preparedValues);
            } else {
                preparedValues[caseParam.field] = caseParam.value;
            }
            return { ...value, ...preparedValues };
        }, {});
        return { 'sort_case': preparedParams };
    }


    private buildLimit(value: number, params: IParam) {
        return { 'page': { 'size': value } };
    }

    private buildOffset(value: number, params: IParam) {
        if ('limit' in params) {
            let pageNumber = Math.floor(value / params['limit']) + 1;
            return { 'page': { 'number': pageNumber } };
        } else {
            return { 'page': { 'number': 1 } };
        }
    }

    private buildOrder(value: IOrderBy | IOrderBy[], params: IParam) {
        let orders: IOrderBy[] = [].concat(value);
        let result = {
            sort: []
        };
        orders.forEach((order: IOrderBy) => {
            if (order.sort === 'RAND()') result.sort.push(`RAND()`);
            else result.sort.push(`${order.sort === 'DESC' ? '-' : ''}${order.by}`);
        });
        return result;
    }

    private replaceParamNames(preparedParams: Array<Object>, paramKeyMap: IParamKeyMap): Array<Object> {
        let paramNamesToReplace = Object.keys(paramKeyMap);
        if (paramNamesToReplace.length > 0 && preparedParams.length > 0) {
            preparedParams.forEach(paramItem => {
                paramNamesToReplace.forEach(paramName => {
                    if (paramItem && typeof paramItem[paramName] !== 'undefined') {
                        let key = paramKeyMap[paramName];
                        paramItem[key] = paramItem[paramName];
                        delete paramItem[paramName];
                    }
                });
            });
        }

        return preparedParams;
    }

    private traverseField(result: any, field: string[]) {
        let lastIndex = field.length - 1;
        let lastHash = null;
        field.reduce((previousValue, currentValue, currentIndex) => {
            if (!(currentValue in previousValue)) {
                previousValue[currentValue] = {};
                if (currentIndex === lastIndex) {
                    lastHash = previousValue[currentValue];
                }
            }
            return previousValue[currentValue];
        }, result);
        return lastHash;
    }

    private buildSearchExpression(value: ISearchExpr[], params: IParam) {
        let preparedParams = value.map((param: ISearchExpr) => {
            let result = {};
            let field = [].concat(param.field);
            let fieldHash = this.traverseField(result, field);
            let [key, _value] = this.prepareCondition(param.cond);
            fieldHash[key] = _value;
            if (param.nested) {
                merge(fieldHash, this.buildSearchParams(param.nested, params));
            }
            return result;
        });
        return merge({}, ...preparedParams);
    }

    private buildSearchParams(value: ISearch, params: IParam) {
        let operation = getOperation(value.operation);
        let result = {};
        result[operation] = this.buildSearchExpression(value.expressions, params);
        return result;
    }

    private permittedParams(): (keyof IParam)[] {
        // Temporary solution to test ClickHouse Reporting //
        return ['order', 'limit', 'offset', 'search', 'sortCase', 'clickhouse'];
    }

    private prepareCondition(condition: ICond) {
        let operation = Object.keys(condition)[0];
        let value = condition[operation];
        if (typeof value !== 'undefined') {
            switch (operation) {
                case '=':
                    return ['eq', value];
                case '!=':
                    return ['ne', value];
                case '<':
                    return ['lt', value];
                case '<=':
                    return ['le', value];
                case '>':
                    return ['gt', value];
                case '>=':
                    return ['ge', value];
                case 'in':
                    return ['in', value];
                case 'notIn':
                    return ['not_in', value];
                case 'like':
                    return ['like', value];
                case 'isNull':
                    return ['is_null', ''];
                case 'isNotNull':
                    return ['is_not_null', ''];
                default:
                    throw new Error(`unexpected filter condition: ${operation}`);
            }
        }

        throw new Error(`unexpected filter condition: ${operation} with value ${value}`);
    }

}
