/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';

import { ISchema, BaseApiClient } from '../common/index';
import { IGoogleBrowser, IGoogleBrowserClient } from './interfaces';
import { BaseEntity, Field, BelongsTo } from '../common/meta';
import {
    instanceCodeClientT, IInstanceCodeClient, paramsBuilderT, IParamsBuilder, IResource
} from '../common/interfaces';
import { AuthHttp } from '../authentication/authentication.service';

export class GoogleBrowser extends BaseEntity<IGoogleBrowser> implements IGoogleBrowser {

    public static readonly type: string = 'google_browser';

    @Field()
    id: string;

    @Field()
    name: string;

    @Field()
    code: string;

    @BelongsTo({ deferredConstructor: () => GoogleBrowser })
    parent: IGoogleBrowser | IResource;

}

@Injectable()
export class GoogleBrowserClient extends BaseApiClient<IGoogleBrowser> implements IGoogleBrowserClient {
    protected static classConfig: ISchema = {
        apiResourceName: 'google_browsers'
    };

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return GoogleBrowser;
    }
}
