/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';

import { ISchema, BaseApiClient, BasePersistentApiClient } from '../common/index';

export { IBaseApiClient } from '../common/interfaces';
import { INote, INoteClient, NoteBelongsToRelationships, NoteHasManyRelationships } from './interfaces';
import {
    instanceCodeClientT, IInstanceCodeClient, paramsBuilderT,
    IParamsBuilder, IResource
} from '../common/interfaces';
import { Field, BelongsTo, BaseEntity, HasMany } from '../common/meta';
import { User } from '../user/user.entity';
import { IUser } from '../user/interfaces';
import { IComment } from '../comment/interfaces';
import { Comment } from '../comment/comment.service';
import { AuthHttp } from '../authentication/authentication.service';

export class Note extends BaseEntity<INote> implements INote {

    public static readonly type: string = 'note';

    @Field()
    id: string;

    @Field()
    created_at: string;

    @Field()
    subject: string;

    @Field()
    body: string;

    @Field()
    noteable_id: string;

    @HasMany({ deferredConstructor: () => Comment })
    comments: IComment[] | IResource[];

    @BelongsTo({ deferredConstructor: () => User })
    creator: IUser | IResource;
}

@Injectable()
export class NoteClient extends BasePersistentApiClient<INote, NoteBelongsToRelationships, NoteHasManyRelationships> implements INoteClient {
    protected static classConfig: ISchema = {
        apiResourceName: 'notes'
    };

    constructor(http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return Note;
    }
}
