import { InjectionToken } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { IBulkResponse } from './errors';

export type Order = 'DESC' | 'ASC' | string;

export type Filter = 'include' | 'exclude';

export interface ICollectionMeta {
    current_page?: number;
    next_page?: number;
    previous_page?: number;
    total_pages?: number;
    total_count?: number;
    all_ids?: string;
    [key: string]: any;
}

export interface ICollection<T> {
    data: T[];
    meta: ICollectionMeta;
    included?: IResource[];
}

export type Serializable<T extends IResource> = IBaseEntity<T> & T;

export interface ISerializer<T extends IResource> {
    serialize(entity: Serializable<T>): any;
    deserialize(data: any, includes?: string): Serializable<T>;
    deserializeMany(data: any, includes?: string): Serializable<T>[];
}

export interface ILinks {
    self?: string;
    next?: string;
    last?: string;
    prev?: string;
    related?: string;
    meta?: any;
}

export interface IRecursiveArray<T> {
    [index: number]: T | { [key: string]: IRecursiveArray<T> };
}

export type RelationshipMetadata = {
    typeResolver: () => IEntityConstructor<IResource>;
    foreignKey: string;
    optional?: boolean;
};

export type AttributeMetadata<T, U> = {
    fromApi?: (value: any) => T;
    toApi?: (value: any) => U;
    foreignKey: string;
};

export interface IResource {
    id?: string;
    loaded?: boolean;
}

export interface IEntityConstructor<T> {
    readonly type: string;
    new(data: Partial<T>, shallow?: boolean): Serializable<T>;
}

export interface IBaseEntity<T extends IResource> {
    isEmpty: boolean;
    hasMany: { [P in keyof T]?: RelationshipMetadata };
    belongsTo: { [P in keyof T]?: RelationshipMetadata };
    attributes: { [P in keyof T]?: AttributeMetadata<any, any> };
}

export interface ISchema {
    apiResourceName: string;
}

export interface IOrderBy {
    by: string;
    sort: Order;
}

export interface ICond {
    '>='?: number | string;
    '>'?: number | string;
    '<='?: number | string;
    '<'?: number | string;
    '='?: number | string | boolean;
    '!='?: number | string | boolean;
    'in'?: string | string[] | number[];
    'notIn'?: string[] | number[];
    'like'?: string | string[];
    'isNull'?: null;
    'isNotNull'?: null;
}

export interface ISearch {
    operation: 'and' | 'or';
    expressions: ISearchExpr[];
}

export interface ISearchExpr {
    field: string | string[];
    cond: ICond;
    nested?: ISearch;
}

export interface ISortCaseParam {
    field: string | string[];
    value: string | string[];
}

export interface IParamKeyMap {
    [key: string]: string;
}

export interface IParamsBuilder {
    build(param: IParam, paramKeyMap?: IParamKeyMap): HttpParams;
}

export interface IParamsConverter {
    convert(params: IParam): IParam;
}

export interface IIncludesConverter {
    convert(includes: IRecursiveArray<string>): string;
}

export let paramsBuilderT = new InjectionToken<IParamsBuilder>('params-builder');

export interface IParam {
    offset?: number;
    limit?: number;
    order?: IOrderBy[] | IOrderBy;
    search?: ISearch;
    sortCase?: ISortCaseParam[];
    // Temporary solution to test ClickHouse Reporting //
    clickhouse?: boolean;
}

export interface IRequestOptions {
    ignoreMandatoryIncludes?: boolean;
    fields?: { [key: string]: string[] };
    all_included?: true;
    with_dooh_screens?: true;
}

export interface IBaseApiClient<T> {
    cloneEntity(entity: T): T;
    getType(): string;
    get(id: string, includes?: IRecursiveArray<string>, options?: IRequestOptions): Observable<T>;
    getOperations(): Operation[];
    getAll(
        params?: IParam, include?: IRecursiveArray<string>, options?: IRequestOptions
    ): Observable<ICollection<T>>;
    detailsPage(id: string): string;
    fieldToDisplay(): string;
}

export interface IBasePersistentApiClient<T extends IResource>
    extends IBaseApiClient<T> {
    save(resource: T, includes?: IRecursiveArray<string>, options?: IRequestOptions): Observable<T>;
    remove(id: string): Observable<void>;
    bulkSave(resource: Partial<T>[]): Observable<IBulkableResponse<T>>;
    bulkDestroy(resource: T[]): Observable<void>;
}
export interface IBaseReportApiClient<T extends IResource> extends IBaseApiClient<T> {
    generate(resource: T, params: IParam): Observable<T>;
}

export interface IBulkableResponse<T> {
    data: T[];
    meta: {[key: string]: any};
}

export interface IPersistentApiClient<T extends IResource, U extends keyof T = never, Z extends keyof T = never>
    extends IBasePersistentApiClient<T> {
    addRelationship<X extends T[U]>(id: string, relationship: U, entity: X): Observable<void>;
    removeRelationship<X extends T[U]>(id: string, relationship: U, entity: X): Observable<void>;
    addRelationships<X extends T[Z]>(id: string, relationship: Z, entities: X): Observable<void>;
    removeRelationships<X extends T[Z]>(id: string, relationship: Z, entities: X): Observable<void>;
}

export interface IInstanceCodeClient {
    getInstanceCode(): string;
}

export let instanceCodeClientT = new InjectionToken<IInstanceCodeClient>('instance-code-client');

export interface IBulkEditable<T> {
    bulkEdit(entities: T[]): Observable<IBulkResponse[]>;
}

export enum Operation {
    activate,
    deactivate,
    duplicate,
    bulkRemove,
    archive,
    unarchive,
    favorite,
    unfavorite,

    goToArchive,
    goToList,
    downloadPdf,
    downloadCsv,
    downloadXlsx,
    previewCreatives,
    generatePixelTag,
    generateTag,
    confirmationEmails,
    gridView,
    app_nexus,
    google,
    broadsign,
    cancel,
    generateReport,

    bulkEdit,
    bulkPreview,
    bulkAddToList,
    generate,
    markAsRead,
    markAsUnread,
    whiteList,
    blackList,
    bulkUnassign,
    deactivateAllCampaigns,
    activateAllCampaigns,
    deactivateAllScreens,
    activateAllScreens
}
