/* tslint:disable:variable-name */
import {
    IAdvertiser
} from './interfaces';
import { IMediaPlan } from '../media-plan/interfaces';
import { IResource } from '../common/interfaces';
import { Field, HasMany, BaseEntity, BelongsTo } from '../common/meta';
import { Campaign } from '../campaign/campaign.entity';
import { Country } from '../country/country.entity';
import { Agency } from '../agency/agency.service';
import { MediaPlan } from '../media-plan/media-plan.entity';
import { ICampaign, VideoVisibility, DisplayVisibility } from '../campaign/interfaces';
import { ICountry } from '../country/interfaces';

import { User } from '../user/user.entity';
import { IAgency } from '../agency/interfaces';
import { IUser } from '../index';
import { Market } from '../market/market.service';
import { IMarket } from '../market/interfaces';
import { Domain } from '../domain/domain.service';
import { IDomain } from '../domain/interfaces';
import { IGoogleProgrammaticInventory } from '../google-programmatic-inventory/interfaces';
import { GoogleProgrammaticInventory } from '../google-programmatic-inventory/google-programmatic-inventory.service';

export class Advertiser extends BaseEntity<IAdvertiser> implements IAdvertiser {
    public static readonly type: string = 'advertiser';

    @Field()
    id: string;

    @Field()
    name: string;

    @Field()
    advertiser_url: string;

    @Field()
    credit_limit: number;

    @Field()
    db_cr_number: number;

    @Field()
    default_video_visibility: VideoVisibility;

    @Field()
    default_display_visibility: DisplayVisibility;

    @Field()
    archived?: boolean;

    @Field()
    email: string;

    @Field()
    address: string;

    @Field()
    postal_code: string;

    @Field()
    city: string;

    @Field()
    region: string;

    @Field()
    phone: string;

    @Field()
    mobile: string;

    @Field()
    fax: string;

    @Field()
    fee_percentage: number;

    @Field()
    use_business_address_for_billing: boolean;

    @Field()
    billing_address: string;

    @Field()
    billing_postal_code: string;

    @Field()
    billing_city: string;

    @Field()
    billing_region: string;

    @Field()
    billing_phone: string;

    @Field()
    billing_mobile: string;

    @Field()
    billing_fax: string;

    @Field()
    billing_email: string;

    @Field()
    discount_a: number;

    @Field()
    discount_b: number;

    @Field()
    confirmation_emails?: string;

    @Field()
    alert_on_campaigns?: boolean;

    @Field()
    active?: boolean;

    @Field()
    atchived?: boolean;

    @Field()
    campaigns_ending_in_three_days: number;

    @Field()
    external_id?: string;

    @Field()
    legal_entity_name?: string;

    @Field()
    paid_on_behalf_of?: string;

    @HasMany({ deferredConstructor: () => Campaign })
    campaigns: ICampaign[] | IResource[];

    @HasMany({ deferredConstructor: () => MediaPlan, apiRelationshipName: 'media_plans' })
    media_plans: IMediaPlan[] | IResource[];

    @BelongsTo({ deferredConstructor: () => Country })
    country: ICountry | IResource;

    @BelongsTo({ deferredConstructor: () => Agency })
    agency: IAgency | IResource;

    @BelongsTo({ deferredConstructor: () => User, optional: true })
    sales_manager: IUser | IResource;

    @BelongsTo({ deferredConstructor: () => User })
    campaign_manager: IUser | IResource;

    @BelongsTo({ deferredConstructor: () => Market })
    market: IMarket | IResource;

    @HasMany({ deferredConstructor: () => Domain })
    domains: IDomain[] | IResource[];

    @HasMany({
        deferredConstructor: () => GoogleProgrammaticInventory,
        apiRelationshipName: 'google_programmatic_inventories'
    })
    google_programmatic_inventories: IGoogleProgrammaticInventory[] | IResource[];

}
