/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';

import { ISchema, BaseApiClient } from '../common/index';
export { IBaseApiClient } from '../common/interfaces';
import { IFeature, IFeatureClient } from './interfaces';

import {
    instanceCodeClientT, IInstanceCodeClient, paramsBuilderT,
    IParamsBuilder, IResource
} from '../common/interfaces';

import { BelongsTo, BaseEntity, Field } from '../common/meta';
import { AuthHttp } from '../authentication/authentication.service';

export class Feature extends BaseEntity<IFeature> implements IFeature {

    public static readonly type: string = 'feature';

    @Field()
    id: string;

    @Field()
    name: string;

    @Field()
    code: string;

    @Field()
    description: string;

}

@Injectable()
export class FeatureClient extends BaseApiClient<IFeature> implements IFeatureClient {
    protected static classConfig: ISchema = {
        apiResourceName: 'features'
    };

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return Feature;
    }
}
