import { ICollection, IPersistentApiClient, IResource } from '../common/index';
import { IArchivable } from './../common/archivable/interfaces';
import { IBulkRemovable } from './../common/bulk-removable/interfaces';
import { IDuplicable } from './../common/duplicable/interfaces';
import { IPublisher } from '../publisher/interfaces';
import { IAdvertiser } from '../advertiser/interfaces';
import { ISection } from '../../src/app/sdk.index';

export interface ISite extends IResource {
    name: string;
    shared?: boolean;
    archived?: boolean;
    publisher: IPublisher | IResource;
    advertiser?: IAdvertiser | IResource;
    sections?: ISection[] | IResource[];
}

export type ISiteCollection = ICollection<ISite>;

export type SiteBelongsToRelationships = never;
export type SiteHasManyRelationships = never;

export interface ISiteClient
    extends IPersistentApiClient<ISite, SiteBelongsToRelationships, SiteHasManyRelationships>,
        IArchivable<ISite>,
        IBulkRemovable,
        IDuplicable<ISite> {
}
