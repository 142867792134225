/* tslint:disable:variable-name */
export { IBaseApiClient } from '../common/interfaces';
import { ICampaignsDirectDeal } from './interfaces';
import { BaseEntity, BelongsTo, Field } from '../common/meta';
import { Campaign } from '../campaign/campaign.entity';
import { ICampaign } from '../campaign/interfaces';
import { DirectDeal } from '../direct-deal/direct-deal.service';
import { IDirectDeal } from '../direct-deal/interfaces';
import {
    BasePersistentApiClient, IInstanceCodeClient, instanceCodeClientT, IParamsBuilder, ISchema, paramsBuilderT
} from '../common';
import { Inject, Injectable } from '@angular/core';
import { AuthHttp } from '../authentication/authentication.service';

export class CampaignsDirectDeal extends BaseEntity<ICampaignsDirectDeal> implements ICampaignsDirectDeal {
    public static readonly type: string = 'campaigns_direct_deal';

    @Field()
    id: string;

    @Field()
    campaign_id: string;

    @Field()
    direct_deal_id: string;

    @Field()
    status: string;

    @Field()
    bid_price: number;

    @BelongsTo({ deferredConstructor: () => DirectDeal })
    direct_deal: IDirectDeal;

    @BelongsTo({ deferredConstructor: () => Campaign })
    campaign: ICampaign;
}

@Injectable()
export class CampaignsDirectDealClient extends
    BasePersistentApiClient<ICampaignsDirectDeal, ('direct_deal_id' | 'campaign_id')> {
    protected static classConfig: ISchema = {
        apiResourceName: 'campaigns_direct_deals'
    };

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder,
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return CampaignsDirectDeal;
    }
}
