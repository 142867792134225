import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

import { ISchema, BasePersistentApiClient } from '../common/index';
import {
    IInstanceCodeClient, instanceCodeClientT, paramsBuilderT,
    IParamsBuilder, IResource
} from '../common/interfaces';

import {
    ITargetingSet, ITargetingSetClient, TargetingSetBelongsToRelationships,
    TargetingSetHasManyRelationships
} from './interfaces';
import { Archivable } from '../common/archivable/archivable';
import { BulkRemovable } from '../common/bulk-removable/bulk-removable';
import { IBulkResponse } from './../common/errors';
import { AuthHttp } from '../authentication/authentication.service';
import { TargetingSet } from './targeting-set.entity';

@Injectable()
@Archivable()
@BulkRemovable()
export class TargetingSetClient
    extends BasePersistentApiClient<ITargetingSet, TargetingSetBelongsToRelationships, TargetingSetHasManyRelationships>
    implements ITargetingSetClient {

    protected static classConfig: ISchema = {
        apiResourceName: 'targeting_sets'
    };

    archive: (id: string) => Observable<ITargetingSet>;
    unarchive: (id: string) => Observable<ITargetingSet>;
    bulkRemove: (ids: string[]) => Observable<IBulkResponse[]>;

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return TargetingSet;
    }

}
