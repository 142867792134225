import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot } from '@angular/router';
import { IdentityStorage } from '../../../modules/common/service/storage/identity-storage';

@Injectable()
export class PageTitleHelper {
    constructor(private title: Title,
                private identityStorageService: IdentityStorage) {}

    resolve(route: ActivatedRouteSnapshot): void {
        this.resolveFor(route.data['title']);
    }

    resolveFor(title?: string): void {

        let titleParts = title ? [title] : [];
        // When we switch to another instance:
        //   - the new instance is stored in the session storage
        //   - this way we can work with different instances in different tabs
        //   - when we open a new tab:
        //       - we'll have a new and empty session storage for a while
        //       - in this case we don't want to get the instance from the local storage because the wrong instance
        //         name will appear in the page title
        let instance = this.identityStorageService.getInstanceFromSession();
        if (instance !== null && instance.name) {
            titleParts.push(instance.name);
        }

        this.title.setTitle(titleParts.join(' | '));
    }
}
