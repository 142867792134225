import { Injectable, Inject } from '@angular/core';
import { HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import { map, catchError } from 'rxjs/operators';

import { ISchema } from '../common/index';
import {
    IMediaPlan, IMediaPlanClient, MediaPlanBelongsToRelationships, MediaPlanHasManyRelationships
} from './interfaces';
import {
    instanceCodeClientT, IInstanceCodeClient, paramsBuilderT, IParamsBuilder
} from '../common/interfaces';

import { BasePersistentApiClient } from '../common/base-api-client';
import { BulkRemovable } from '../common/bulk-removable/bulk-removable';
import { IBulkResponse } from './../common/errors';
import { Duplicable } from './../common/duplicable/duplicable';
import { Favoriteable } from '../common/favoriteable/favoriteable';
import { IFavoriteProvider } from '../common/favoriteable/interfaces';
import { AuthHttp } from '../authentication/authentication.service';
import { MediaPlan } from './media-plan.entity';
import { BaseSerializer } from '../common/base-serializer';

@Injectable()
@BulkRemovable()
@Duplicable()
@Favoriteable()
export class MediaPlanClient
    extends BasePersistentApiClient<IMediaPlan, MediaPlanBelongsToRelationships, MediaPlanHasManyRelationships>
    implements IMediaPlanClient {

    protected static classConfig: ISchema = {
        apiResourceName: 'media_plans'
    };

    bulkRemove: (ids: string[]) => Observable<IBulkResponse[]>;
    duplicate: (id: string) => Observable<IMediaPlan>;
    favorite: (id: string, provider?: IFavoriteProvider<IMediaPlan>) => Observable<IMediaPlan>;
    unfavorite: (id: string, provider?: IFavoriteProvider<IMediaPlan>) => Observable<IMediaPlan>;

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    activate(id: string): Observable<IMediaPlan> {
        this.recreateParams();
        let serializer = new BaseSerializer<IMediaPlan>(MediaPlan);
        this.setImpersonationParams();
        return this.http.post(`${this.apiBase()}/${id}/activate`, '', { params: this.prepareParams() })
            .pipe(
                map(
                (response: HttpResponse<any>) => {
                    return serializer.deserialize(response.body);
                }), catchError(this.handleRequestError)
            );
    }

    deactivate(id: string): Observable<IMediaPlan> {
        this.recreateParams();
        let serializer = new BaseSerializer<IMediaPlan>(MediaPlan);
        this.setImpersonationParams();
        return this.http.post(`${this.apiBase()}/${id}/deactivate`, '', { params: this.prepareParams() })
            .pipe(
                map(
                (response: HttpResponse<any>) => {
                    return serializer.deserialize(response.body);
                }), catchError(this.handleRequestError)
            );
    }

    protected implementationClass() {
        return MediaPlan;
    }
}
