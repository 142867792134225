import { IArchivable } from './../common/archivable/interfaces';
import { ICollection, IResource, IPersistentApiClient } from '../common/interfaces';
import { IInventorySource } from '../inventory-source/interfaces';

export interface IDealIdAnalyzer extends IResource {
    requests: number;
    bids: number;
    no_bids: number;
    wins: number;
    loss: number;
    deal_id: string;
    inventory_source_id: string;
    value?: string | number;
    category: string;
    video_min_duration?: number;
    video_max_duration?: number;
    video_min_bitrate?: number;
    video_max_bitrate?: number;
    video_file_type?: string;
    hour?: string;
    day?: string;
}

export interface IDealIdAnalyzerValues {
    value: string | number;
    min_duration: number;
    max_duration: number;
    min_bitrate: number;
    max_bitrate: number;
    file_type: string;
}

export interface IDealIdAnalyzerStats {
    tag_type?: string[];
    size?: string[];
    creative_attributes?: string[];
    bitrate?: string[];
    duration?: string[];
    country?: string[];
    supply_type?: string[];
    iab_categories?: string[];
    detected_language?: string[];
    ua_device_type?: string[];
    seat_id?: string[];
    deal_floor?: any[];
    video_file_type?: string[];
}

export interface IDealIdAnalyzerStatsResults {
    campaign_stats?: IDealIdAnalyzerStats;
    deal_stats?: IDealIdAnalyzerStats;
}

export type IDealIdAnalyzerCollection = ICollection<IDealIdAnalyzer>;

export type DealIdAnalyzerBelongsToRelationships = never;
export type DealIdAnalyzerHasManyRelationships = never;

export interface IDealIdAnalyzerClient
    extends IPersistentApiClient<IDealIdAnalyzer, DealIdAnalyzerBelongsToRelationships, DealIdAnalyzerHasManyRelationships> {
}
