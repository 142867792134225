import { ICollection, IBaseApiClient, IResource } from '../common/index';

export interface IFeature extends IResource {
    name: string;
    code: string;
    description: string;
}

export type IFeatureCollection = ICollection<IFeature>;

export interface IFeatureClient extends IBaseApiClient<IFeature> {
}
