import { ICollection, IBaseApiClient, IResource } from '../common/index';

export interface IDevice extends IResource {
    name: string;
    code: string;
    order?: number;
    parent?: IDevice | IResource;
    versions: IDevice[] | IResource[];
}

export type IDeviceCollection = ICollection<IDevice>;

export interface IDeviceClient extends IBaseApiClient<IDevice> { }
