/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

import { AuthHttp } from '../authentication/authentication.service';
import { BaseEntity, Field } from '../common/meta';
import { ISchema, BaseApiClient } from '../common/index';
import { IAdServer, IAdServerClient } from './interfaces';
import { instanceCodeClientT, IInstanceCodeClient, paramsBuilderT, IParamsBuilder } from '../common/interfaces';

export class AdServer extends BaseEntity<IAdServer> implements IAdServer {

    public static readonly type: string = 'ad_server';

    @Field()
    id: string;

    @Field()
    name: string;

    @Field()
    code: string;

}

@Injectable()
export class AdServerClient extends BaseApiClient<IAdServer> implements IAdServerClient {

    protected static classConfig: ISchema = {
        apiResourceName: 'ad_servers'
    };

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    get(id: string): Observable<IAdServer> {
        throw new Error('Method not implemented.');
    }

    protected implementationClass() {
        return AdServer;
    }
}
