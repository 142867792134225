import { ICollection, IPersistentApiClient, IResource, Filter } from '../common/interfaces';
import { IAdvertiser } from '../advertiser/interfaces';
import { ICreativeAttribute } from './attributes/interfaces';
import { ICreativeFile } from '../creative-file/interfaces';
import { ICountry } from '../country/interfaces';
import { IRegion } from '../region/interfaces';
import { ICity } from '../city/interfaces';
import { IIabCategory } from '../iab-category/interfaces';
import { ILanguage } from '../language/interfaces';
import { IArchivable } from './../common/archivable/interfaces';
import { IActivable } from './../common/activable/interfaces';
import { ISize } from '../size/interfaces';
import { ICampaign } from '../campaign/interfaces';
import { IPixel } from '../pixel/interfaces';
import { IThirdPartyPixelTracking } from '../third-party-pixel-tracking/interfaces';
import { ISection } from '../section/interfaces';
import { IAdServer } from '../ad-server/interfaces';
import { ISubmissionStatus } from './submission/interfaces';
import { ILanguageAttribute } from '../common/language';
import { TemporalTargeting } from '../targeting-default/interfaces';
import { IMediaPlan } from '..';
import { IDataForPreviewState } from '@components/category-groups/category-groups.interfaces';

export interface IVideoUrl {
    video_url: {
        secure: {
            webm: string,
            mp4: string,
            flv: string
        },
        webm: string,
        mp4: string,
        flv: string
    };
}

export type UtmCode = {
    utm_source?: string;
    utm_medium?: string;
    utm_content?: string;
    utm_campaign?: string;
    utm_term?: string;
};

export type FileAttributes = {
    base64_data?: string;
    original_filename?: string;
    content_type?: string;
};

export type CreativeKind =
    'audio' |
    'cs_digital' |
    'dooh' |
    'facebook' |
    'flash' |
    'html5' |
    'image' |
    'native' |
    'thirdparty' |
    'thirdparty_vast_tag' |
    'video' |
    'high_impact' |
    string;

export type DataType =
    'display' |
    'video';

export interface ICreative extends IResource, ILanguageAttribute {
    name: string;
    start_on: string;
    end_on: string;
    kind: CreativeKind;
    data_type?: DataType;
    width?: number;
    height?: number;
    active: boolean;
    archived?: boolean;
    click_url: string;
    custom_url_parameters?: string;
    sides_number: number;
    force_landing_page_decode: boolean;
    landing_page_additional_value_support: boolean;
    section_filter: Filter;
    cap: boolean;
    companion_banner_url?: string;
    ad_server_logo_url?: string;
    sequence_cap: string;
    sequence_cap_type?: string;
    sequence_cap_minimum?: string;
    sequence_cap_maximum?: string;
    sequence_phase?: string;
    sequenced_campaign_id?: string;
    targeting_logic?: any;
    appnexus_id?: string;
    evidon: boolean;
    video_url: IVideoUrl;
    duration: number;
    video_media_id?: string;
    dma_tracker: boolean;
    cs_digital_creative_id?: string;
    skippable: boolean;
    scannable: boolean;
    ad_tag_id?: string;
    use_targeting_sets_in_behavioural_targeting: boolean;
    reference_id: string;
    dma: boolean;
    dma_tag?: string;
    postback: boolean;
    cors: boolean;
    bitrate?: string;
    hours?: number[];
    weekdays?: number[];
    data?: string;
    external_id?: string;
    utm_codes?: UtmCode;
    upload_to_appnexus?: boolean;
    native_title?: string;
    native_description?: string;
    native_body?: string;
    native_headline?: string;
    native_cta?: string;
    creative_file_attributes?: FileAttributes;
    companion_banner_attributes?: FileAttributes;
    ad_server_logo_attributes?: FileAttributes;
    advertiser: IAdvertiser | IResource;
    broadsign_id?: number;
    is_broadsign?: boolean;
    uploaded_to_broadsign?: boolean;
    everflow_offer_id?: number;
    custom_vast_events?: boolean;
    vast_start?: string;
    vast_first_quartile?: string;
    vast_midpoint?: string;
    vast_third_quartile?: string;
    vast_complete?: string;
    vast_skip?: string;
    postback_imp_tracking_url?: string;
    postback_click_tracking_url?: string;
    odoscope_pid?: string;
    use_odoscope_optimization?: boolean;
    rich_media_url: string;
    cb_id?: string;
    temporal_targeting?: TemporalTargeting;
    creative_attributes: ICreativeAttribute[] | IResource[];
    countries: ICountry[] | IResource[];
    regions: IRegion[] | IResource[];
    cities: ICity[] | IResource[];
    iab_categories: IIabCategory[] | IResource[];
    campaigns: ICampaign[] | IResource[];
    pixels: IPixel[] | IResource[];
    language_declaration?: ILanguage | IResource;
    size?: ISize | IResource;
    companion_banner_size?: ISize | IResource;
    third_party_pixel_trackings?: IThirdPartyPixelTracking[] | IResource[];
    sections: ISection[] | IResource[];
    file?: ICreativeFile | IResource;
    companion_banner?: ICreativeFile | IResource;
    ad_server?: IAdServer | IResource;
    approval_statuses?: ISubmissionStatus[] | IResource[];
    media_plans?: IMediaPlan[] | IResource[];
    iab_preview?: IDataForPreviewState[];
    ad_server_logo?: ICreativeFile | IResource;
}

export type CreativeBelongsToRelationships = 'advertiser';
export type CreativeHasManyRelationships = never;

export interface ICreativeCollection extends ICollection<ICreative> { }

export interface ICreativeClient
    extends IPersistentApiClient<ICreative, CreativeBelongsToRelationships, CreativeHasManyRelationships>,
    IArchivable<ICreative>,
    IActivable<ICreative> {
}
