import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { Location } from '@angular/common';
import { IdentityStorage } from '../storage/identity-storage';
import { environment } from '../../../../../environments/environment';

@Injectable()
export class InternalURLService {
    constructor(
        private router: Router,
        private location: Location,
        private identityStorage: IdentityStorage
    ) {
    }

    // Returns the complete URL (except the host part) for a certain page of the app
    // Send the different path segments of the desired page in segments:
    // Example: ['campaign', 'details', '234']
    // Send query params and anchor in urlExtras
    // The params are the same that you send to Router.createUrlTree()
    // See https://stackoverflow.com/questions/47616845/angular-get-full-url-for-a-route
    public getAppUrl(segments: string[], urlExtras?: NavigationExtras): string {
        let instanceCode = this.identityStorage.getInstanceCode();
        let prefix = [instanceCode, 'app'];
        let urlTree = this.router.createUrlTree(prefix.concat(segments), urlExtras);
        let url = this.router.serializeUrl(urlTree);
        return this.location.prepareExternalUrl(url);
    }

    // Returns the URL for a safe preview of a dangerous creative,
    // using a different domain from that of the main API server
    public getSafeCreativePreviewUrl(creativeId: string): string {
        return `${environment.api.safeCreatives}/` +
            `${this.identityStorage.getInstanceCode()}/` +
            `creatives/${creativeId}/` +
            `preview`;
    }

    public getSafeCreativePreviewIframeUrl(creativeId: string, options?: object): string {
        let params = '';
        if ('lat' in options && 'lon' in options) params = `?user_lat=${options['lat']}&user_lon=${options['lon']}`;
        return `${environment.api.safeCreatives}/` +
            `${this.identityStorage.getInstanceCode()}/` +
            `creatives/${creativeId}/` +
            `preview/iframe` + params;
    }

    public getSafeCreativePreviewRemoteJavascriptUrl(creativeId: string, options?: object): string {
        let params = '';
        if ('lat' in options && 'lon' in options) params = `?user_lat=${options['lat']}&user_lon=${options['lon']}`;
        return `${environment.api.safeCreatives}/` +
            `${this.identityStorage.getInstanceCode()}/` +
            `creatives/${creativeId}/` +
            `preview/remote_javascript` + params;
    }
}
