import { IPersistentApiClient, ICollection, IResource } from '../common/interfaces';

export interface IRtbSeat extends IResource {
    code: string;
    name: string;
}

export type IRtbSeatCollection = ICollection<IRtbSeat>;

export type RtbSeatBelongsToRelationships = never;
export type RtbSeatHasManyRelationships = never;

