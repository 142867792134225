import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { ErrorResponse } from '@platform161-client';

export interface IErrorInterceptorAction {
    show(title?: string, config?: Partial<IndividualConfig>): void;
    injectAsHtml(context: IHtmlErrorsContainerAware): void;
    getAsHtml(): string;
}

export interface IHtmlErrorsContainerAware {
    errors: string;
}

@Injectable()
export class ErrorInterceptor {
    constructor(private toastrService: ToastrService) { }

    intercept(response: any): IErrorInterceptorAction {
        const friendlyName = (_name: string) => {
            return this.toUpperCase(_name.split('/').reverse()[0].replace('_', ' '));
        };
        const prepare = (_errors) => {
            // custom html structure??
            return _errors ? _errors.join('<br />') : 'Unknown error';
        };
        let errors: string[];

        if (response instanceof Map) {
            errors = this.flattenMapOfErrors(response);
        } else if (response instanceof ErrorResponse) {
            errors = this.handleErrorResponse(response);
        } else if (response instanceof HttpErrorResponse && response.error && response.error.errors) {
            errors = response.error.errors.map(_error => {
                if (!!_error.source && !!_error.source.pointer && friendlyName(_error.source.pointer) !== 'Base') {
                    return `<b>${friendlyName(_error.source.pointer)}:</b> ${_error.detail}`;
                } else {
                    return _error.detail;
                }
            });
        } else if (typeof response === 'string') {
            errors = [response];
        }

        return <IErrorInterceptorAction>{
            show: (title: string = 'Server returned errors', config: Partial<IndividualConfig> = {}) => {
                this.toastrService.error(
                    prepare(errors),
                    title,
                    Object.assign({}, {enableHtml: true, progressAnimation: 'increasing'}, config)
                );
            },
            injectAsHtml: (context: IHtmlErrorsContainerAware) => {
                context.errors = prepare(errors);
            },
            getAsHtml: () => prepare(errors)
        };
    }

    private flattenMapOfErrors(response: Map<string, string[]>): string[] {
        let errorMessages: string[] = [];
        response.forEach((messages: string[]) => {
            errorMessages.push(...messages.map((message, fieldName) => {
                return this.toUpperCase(`<b>${fieldName}:</b> ${message}`);
            }));
        });
        return errorMessages;
    }

    private handleErrorResponse(response: ErrorResponse): string[] {
        let errorMessages: string[] = [];
        response.errors.forEach((messages: string[], fieldName) => {
            errorMessages.push(...messages.map((message) => {
                return `<b>${this.toUpperCase(fieldName.replace('_', ' '))}:</b> ${message}`;
            }));
        });
        return errorMessages;
    }

    private toUpperCase(str: string): string {
        return str.length ? str[0].toUpperCase() + str.substr(1) : str;
    }
}
