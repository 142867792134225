import { Injectable, Inject } from '@angular/core';

import { ISchema, BaseApiClient } from '../common/index';
import { IRegion, IRegionClient } from './interfaces';
import {
    instanceCodeClientT, IInstanceCodeClient, paramsBuilderT,
    IParamsBuilder
} from '../common/interfaces';
import { AuthHttp } from '../authentication/authentication.service';
import { Region } from './region.entity';


@Injectable()
export class RegionClient extends BaseApiClient<IRegion> implements IRegionClient {
    protected static classConfig: ISchema = {
        apiResourceName: 'regions'
    };

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return Region;
    }
}
