/* tslint:disable:variable-name */

import { IResource } from '../common/interfaces';
import { IThirdPartyDataProvider } from './interfaces';
import { IThirdPartyDataSegment } from './segment/interfaces';
import { BaseEntity, Field, HasMany } from '../common/meta';
import { ThirdPartyDataSegment } from './segment/third-party-data-segment.entity';

export class ThirdPartyDataProvider extends BaseEntity<IThirdPartyDataProvider> implements IThirdPartyDataProvider {

    public static readonly type: string = 'third_party_data_provider';

    @Field()
    id: string;

    @Field()
    name: string;

    @Field()
    prefix: string;

    @Field()
    currency: string;

    @Field()
    logo_file: string;

    @Field()
    editable_segments: boolean;

    @Field()
    data_provider_type: string;

    @HasMany({ deferredConstructor: () => ThirdPartyDataSegment })
    third_party_data_segments: IThirdPartyDataSegment[] | IResource[];
}
