/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';

import { ISchema } from '../common/index';
import {
    IMobileApp, IMobileAppClient
} from './interfaces';
import {
    instanceCodeClientT, IInstanceCodeClient, paramsBuilderT,
    IParamsBuilder, IResource
} from '../common/interfaces';
import { Field, BaseEntity, HasMany } from '../common/meta';
import { BaseApiClient } from '../common/base-api-client';
import { MobileAppStatistic } from '../mobile-app-statistic/mobile-app-statistic.service';
import { IMobileAppStatistic } from '../mobile-app-statistic/interfaces';
import { AuthHttp } from '../authentication/authentication.service';

export class MobileApp extends BaseEntity<IMobileApp> implements IMobileApp {

    public static readonly type: string = 'mobile_app';

    @Field()
    id: string;

    @Field()
    name: string;

    @Field()
    blacklist: string;

    @HasMany({ deferredConstructor: () => MobileAppStatistic })
    statistics: IMobileAppStatistic[] | IResource[];

}

@Injectable()
export class MobileAppClient extends BaseApiClient<IMobileApp>
    implements IMobileAppClient {
    protected static classConfig: ISchema = {
        apiResourceName: 'mobile_apps'
    };

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return MobileApp;
    }

}
