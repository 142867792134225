import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AclGuard } from './service/acl-guard.service';
import { AclRulesProvider } from './service/acl-rules-provider.service';
import { AccessResolverProxy } from './service/access-resolver-proxy.service';
import { PrivilegeDirective } from './directive/privilege.directive';
import { PrivilegeAccessResolver } from './service/access-resolver/privilege';
import { FeatureAccessResolver } from './service/access-resolver/feature';
import { FeatureDirective } from './directive/feature.directive';
import { FeaturePipe } from './pipe/feature.pipe';
import { PrivilegePipe } from './pipe/privilege.pipe';
import { PrivilegeComponent } from './component/privilege.component';
import { FeatureComponent } from './component/feature.component';
import { RedirectUrlResolverService } from './service/redirect-url-resolver.service';
import { AclTabsHelper } from './service/helper/tabs.service';
import { AclTableHelper } from './service/helper/table.service';
import { ReportOptionFilterService } from './service/report-option-filter.service';
import { AclGarbageCollectorService } from './service/acl-garbage.collector.service';
import { AccessResolverHelper } from './service/helper/access-resolver.service';
import { InstanceGuard } from './service/instance-guard.service';
import { UserFactory, UserProxy } from '../../resources/user.resource';
import { InvoiceReportGuard } from './service/invoice-report-guard.service';
import { UserClient, RoleClient } from '@platform161-client';
import { UserTemporaryAccountClient, FeatureClient, InstanceClient } from '../../../../platform161-client';
import { InstanceDataProviderService } from '@shared/instance-list/instance-data-provider.service';

@NgModule({
    declarations: [
        FeaturePipe,
        FeatureDirective,
        PrivilegePipe,
        PrivilegeDirective,
        PrivilegeComponent,
        FeatureComponent
    ],
    imports: [
        CommonModule,
        RouterModule
    ],
    providers: [
        UserFactory,
        UserProxy,
        AclRulesProvider,
        AclGuard,
        InstanceGuard,
        InvoiceReportGuard,
        FeaturePipe,
        PrivilegePipe,
        AccessResolverProxy,
        RedirectUrlResolverService,
        ReportOptionFilterService,
        PrivilegeAccessResolver,
        FeatureAccessResolver,
        AclTabsHelper,
        AclTableHelper,
        AclGarbageCollectorService,
        AccessResolverHelper,
        // clients used by this module
        UserClient,
        RoleClient,
        UserTemporaryAccountClient,
        FeatureClient,
        InstanceDataProviderService,
        InstanceClient,
    ],
    exports: [
        FeaturePipe,
        FeatureDirective,
        PrivilegePipe,
        PrivilegeDirective,
        PrivilegeComponent,
        FeatureComponent
    ]
})
export class AclModule {}
