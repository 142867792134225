import { ICountry } from '../country/interfaces';
import { IResource, Filter } from '../common/interfaces';

export interface ITargetingDefault extends IResource {
    country_filter: Filter;
    countries: ICountry[] | IResource[];
}

export type TemporalTargeting = {
    [day: number]: number[];
};

