import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { IdentityStorage } from '../../../modules/common/service/storage/identity-storage';

@Injectable()
export class InvoiceReportGuard implements CanActivate {
    constructor(private identityStorage: IdentityStorage) { }

    canActivate(): boolean {
        return !this.identityStorage.getUser().is_client;
    }
}
