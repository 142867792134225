/* tslint:disable:variable-name */
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

import { Injectable, Inject } from '@angular/core';

import { ISchema, BasePersistentApiClient } from '../common/index';
import {
    DomainBelongsToRelationships,
    DomainHasManyRelationships,
    IDomain,
    IDomainBulk,
    IDomainBulkCollection,
    IDomainClient
} from './interfaces';
import { IDomainStatistics } from '../domain-statistics/interfaces';
import { DomainStatistics } from './../domain-statistics/domain-statistics.service';
import { BaseEntity, Field, BelongsTo, HasMany } from '../common/meta';
import {
    IInstanceCodeClient, instanceCodeClientT, paramsBuilderT,
    IParamsBuilder, IParam, IRecursiveArray, IResource
} from '../common/interfaces';
import { Advertiser } from '../advertiser/advertiser.entity';
import { IAdvertiser } from '../advertiser/interfaces';
import { BaseSerializer } from '../common/base-serializer';
import { BulkRemovable } from '../common/bulk-removable/bulk-removable';
import { IBulkResponse } from './../common/errors';
import { IChangeable, IChangeCollection } from '../change/interfaces';
import { IComment, IPersistentComment } from '../comment/interfaces';
import { WhiteList } from '../common/white-list/white-list';
import { Changeable } from '../common/changeable/changeable';
import { AuthHttp } from '../authentication/authentication.service';
import { HttpResponse } from '@angular/common/http';

export class Domain extends BaseEntity<IDomain> implements IDomain {

    public static readonly type: string = 'domain';

    @Field()
    id: string;

    @Field()
    url: string;

    @Field()
    isp: boolean;

    @Field()
    is_wildcard: boolean;

    @Field()
    blacklist: boolean;

    @HasMany({ deferredConstructor: () => DomainStatistics })
    statistics: IDomainStatistics[];
}

export class DomainBulk extends BaseEntity<IDomainBulk> implements IDomainBulk {

    public static readonly type: string = 'domain';

    @Field()
    urls: string[];

    @Field()
    isp: boolean;

    @Field()
    is_wildcard: boolean;

    @Field()
    blacklist: boolean;

}

@Injectable()
@BulkRemovable()
@Changeable()
@WhiteList()
export class DomainClient
    extends BasePersistentApiClient<IDomain, DomainBelongsToRelationships, DomainHasManyRelationships>
    implements IDomainClient, IChangeable {
    protected static classConfig: ISchema = {
        apiResourceName: 'domains'
    };

    bulkRemove: (ids: string[]) => Observable<IBulkResponse[]>;
    whiteList: (id: string) => Observable<IDomain>;
    blackList: (id: string) => Observable<IDomain>;

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return Domain;
    }

    saveBulk(resource: IDomainBulk): Observable<IDomainBulkCollection> {
        let wrappedEntity = new DomainBulk(resource);
        let bulkSerializer = new BaseSerializer(DomainBulk);
        let data = bulkSerializer.serialize(wrappedEntity);
        return this.http.post(`${this.apiBase()}/bulk`, data)
            .pipe(
                map((response: HttpResponse<any>) => {
                    let rawData = response.body;
                    let responseData = this.serializer.deserializeMany(rawData);
                    return {
                        data: responseData,
                        meta: rawData.meta
                    } as IDomainBulkCollection;
                })
            );
    }

    getChanges(
        changeableId: string, params?: IParam, include?: IRecursiveArray<string>
    ): Observable<IChangeCollection> {
        throw new Error('Method not implemented.');
    }

    addChangeComment(note: IPersistentComment, changeableId: string): Observable<IComment> {
        throw new Error('Method not implemented.');
    }

    fieldToDisplay(): string {
        return 'url';
    }

}
