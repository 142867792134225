/* tslint:disable:variable-name */
import {
    IResource
} from '../common/interfaces';
import { IUserPreference } from './interfaces';
import { BaseEntity, Field, BelongsTo } from '../common/meta';

export class UserPreference extends BaseEntity<IUserPreference> implements IUserPreference {

    public static readonly type: string = 'user_preference';

    @Field()
    id: string;

    @Field()
    data: object;

    @Field()
    scope: 'standard_report' | 'tables' | 'campaign_dashboard' | 'global_search' | 'table_params' | 'dashboard';

}
