/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';

import { ISchema, BaseApiClient, IResource } from '../common/index';
export { IBaseApiClient } from '../common/interfaces';
import { IOperatingSystem, IOperatingSystemClient } from './interfaces';
import { BaseEntity, Field, BelongsTo } from '../common/meta';
import {
    instanceCodeClientT, IInstanceCodeClient, paramsBuilderT,
    IParamsBuilder
} from '../common/interfaces';
import { AuthHttp } from '../authentication/authentication.service';

export class OperatingSystem extends BaseEntity<IOperatingSystem> implements IOperatingSystem {

    public static readonly type: string = 'operating_system';

    @Field()
    id: string;

    @Field()
    name: string;

    @Field()
    code: string;

    @BelongsTo({ deferredConstructor: () => OperatingSystem })
    parent: IOperatingSystem | IResource;

}

@Injectable()
export class OperatingSystemClient extends BaseApiClient<IOperatingSystem> implements IOperatingSystemClient {
    protected static classConfig: ISchema = {
        apiResourceName: 'operating_systems'
    };

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return OperatingSystem;
    }

}
