/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';

import { ISchema, BaseApiClient } from '../common/index';
export { IBaseApiClient } from '../common/interfaces';
import { IDeviceType, IDeviceTypeClient } from './interfaces';
import { BaseEntity, Field } from '../common/meta';
import {
    instanceCodeClientT, IInstanceCodeClient, paramsBuilderT,
    IParamsBuilder
} from '../common/interfaces';
import { AuthHttp } from '../authentication/authentication.service';

export class DeviceType extends BaseEntity<IDeviceType> implements IDeviceType {

    public static readonly type: string = 'device_type';

    @Field()
    id: string;

    @Field()
    code: string;

}

@Injectable()
export class DeviceTypeClient extends BaseApiClient<IDeviceType> implements IDeviceTypeClient {
    protected static classConfig: ISchema = {
        apiResourceName: 'device_types'
    };

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    fieldToDisplay() {
        return 'code';
    }

    protected implementationClass() {
        return DeviceType;
    }
}
