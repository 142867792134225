export enum PrivilegeAction {
    VIEW,
    ALL
}

export interface IAclPrivilegeDescriptor {
    domains: string[];
    matchAll?: boolean;
    extraCond?: boolean;
}

export interface IAclFeatureDescriptor {
    features: string[];
    matchAll?: boolean;
    invertedCheck?: boolean | boolean[];
}
