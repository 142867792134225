/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';

import { ISchema, BaseApiClient } from '../common/index';

export { IBaseApiClient } from '../common/interfaces';
import { IChange, IChangeClient } from './interfaces';
import {
    instanceCodeClientT, IInstanceCodeClient, paramsBuilderT,
    IParamsBuilder, IResource
} from '../common/interfaces';
import { Field, BelongsTo, BaseEntity, HasMany } from '../common/meta';
import { User } from '../user/user.entity';
import { IUser } from '../user/interfaces';
import { IComment } from '../comment/interfaces';
import { Comment } from '../comment/comment.service';
import { AuthHttp } from '../authentication/authentication.service';

export class Change extends BaseEntity<IChange> implements IChange {

    public static readonly type: string = 'change';

    @Field()
    id: string;

    @Field()
    created_at: string;

    @Field()
    from: string;

    @Field()
    to: string;

    @Field()
    name: string;

    @Field()
    resource_names_to: Array<string>;

    @Field()
    resource_names_from: Array<string>;

    @HasMany({ deferredConstructor: () => Comment })
    comments: IComment[] | IResource[];

    @BelongsTo({deferredConstructor: () => User})
    user: IUser | IResource;
}

@Injectable()
export class ChangeClient extends BaseApiClient<IChange> implements IChangeClient {
    protected static classConfig: ISchema = {
        apiResourceName: 'changes'
    };

    constructor(http: AuthHttp,
                @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
                @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return Change;
    }
}
