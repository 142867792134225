/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';
import { ISchema, BasePersistentApiClient, IResource } from '../common/index';
import {
    RuleBelongsToRelationships,
    RuleHasManyRelationships,
    IRule,
    IRuleClient
} from './interfaces';
import {
    instanceCodeClientT, IInstanceCodeClient,
    paramsBuilderT, IParamsBuilder, IRecursiveArray, IParam
} from '../common/interfaces';
import { Field, BaseEntity, BelongsTo, HasMany } from '../common/meta';
import { INote, INoteable, INoteCollection } from '../note/interfaces';
import { IChangeable, IChangeCollection } from '../change/interfaces';
import { Observable } from 'rxjs/internal/Observable';
import { IComment, IPersistentComment } from '../comment/interfaces';
import { BulkRemovable } from '../common/bulk-removable/bulk-removable';
import { IBulkResponse } from './../common/errors';
import { Changeable } from '../common/changeable/changeable';
import { Noteable } from '../common/noteable/noteable';
import { AuthHttp } from '../authentication/authentication.service';
import { Advertiser } from '@platform161-client/advertiser/advertiser.entity';
import { IAdvertiser } from '@platform161-client/advertiser/interfaces';
import { IConversionPixel } from '@platform161-client/conversion-pixel/interfaces';
import { ConversionPixel } from '@platform161-client/conversion-pixel/conversion-pixel.service';


export class Rule extends BaseEntity<IRule> implements IRule {

    public static readonly type: string = 'rules';

    @Field()
    id: string;

    @Field()
    name: string;

    @Field()
    conversion_selector: string;

    @Field()
    match_type: string;

    @Field()
    value: string;

    @Field()
    code: string;

    @BelongsTo({ deferredConstructor: () => Advertiser })
    advertiser: IAdvertiser | IResource;

    @HasMany({ deferredConstructor: () => ConversionPixel})
    conversion_pixels: IConversionPixel[] | IResource[];

}

@Injectable()
@BulkRemovable()
@Changeable()
@Noteable()
export class RuleClient extends BasePersistentApiClient<IRule,
    RuleBelongsToRelationships, RuleHasManyRelationships>
    implements IRuleClient, INoteable, IChangeable {

    protected static classConfig: ISchema = {
        apiResourceName: 'rules'
    };

    bulkRemove: (ids: string[]) => Observable<IBulkResponse[]>;

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return Rule;
    }

    getChanges(
        changeableId: string, params?: IParam, include?: IRecursiveArray<string>
    ): Observable<IChangeCollection> {
        throw new Error('Method not implemented.');
    }

    addChangeComment(note: IPersistentComment, changeableId: string): Observable<IComment> {
        throw new Error('Method not implemented.');
    }

    getNotes(noteableId: string, params?: IParam, include?: IRecursiveArray<string>): Observable<INoteCollection> {
        throw new Error('Method not implemented.');
    }

    addNote(note: INote): Observable<INote> {
        throw new Error('Method not implemented.');
    }

    addNoteComment(note: IPersistentComment): Observable<IComment> {
        throw new Error('Method not implemented.');
    }
}
