import { IPersistentApiClient } from './../common/interfaces';
import { ICollection, IResource } from '../common/index';
import { IBulkRemovable } from './../common/bulk-removable/interfaces';
import { IAdvertiser } from '@platform161-client/advertiser/interfaces';
import { IConversionPixel } from '../../src/app/sdk.index';

export interface IRule extends IResource {
    name: string;
    conversion_selector: string;
    match_type: string;
    value: string;
    advertiser: IResource | IAdvertiser;
    conversion_pixels?: IResource[] | IConversionPixel[];
}

export type IRuleCollection = ICollection<IRule>;

export type RuleBelongsToRelationships = never;
export type RuleHasManyRelationships = never;

export interface IRuleClient
    extends IPersistentApiClient<IRule, RuleBelongsToRelationships, RuleHasManyRelationships>,
        IBulkRemovable {
}
