/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

import { BaseEntity, Field, BelongsTo } from '../common/meta';
import {
    IInstanceCodeClient, instanceCodeClientT, paramsBuilderT, IParamsBuilder, IResource, ISchema,
    BasePersistentApiClient
} from '../common';
import { BulkRemovable } from '../common/bulk-removable/bulk-removable';
import { IBulkResponse } from './../common/errors';
import { IMarket, IMarketClient, MarketBelongsToRelationships, MarketHasManyRelationships } from './interfaces';
import { Advertiser } from '../advertiser/advertiser.entity';
import { IAdvertiser } from '../advertiser/interfaces';
import { AuthHttp } from '../authentication/authentication.service';

export class Market extends BaseEntity<IMarket> implements IMarket {

    public static readonly type: string = 'market';

    @Field()
    id: string;

    @Field()
    external_id: string;

    @Field()
    name: string;

    @BelongsTo({ deferredConstructor: () => Advertiser, optional: true })
    advertiser: IAdvertiser | IResource;

}

@Injectable()
@BulkRemovable()
export class MarketClient
    extends BasePersistentApiClient<IMarket, MarketBelongsToRelationships, MarketHasManyRelationships>
    implements IMarketClient {

    protected static classConfig: ISchema = {
        apiResourceName: 'markets'
    };

    bulkRemove: (ids: string[]) => Observable<IBulkResponse[]>;

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return Market;
    }
}
