import { Injectable, Inject } from '@angular/core';
import { HttpResponse, HttpParams, HttpHeaders } from '@angular/common/http';

import { Activable } from '../common/activable/activable';
import { Archivable } from '../common/archivable/archivable';
import { BulkRemovable } from '../common/bulk-removable/bulk-removable';
import { Duplicable } from '../common/duplicable/duplicable';
import { IBulkResponse } from './../common/errors';
import {
    ICampaign, ICampaignClient, IProcessScreens,
    CampaignBelongsToRelationships, CampaignHasManyRelationships, Bv4BookedBudget
} from './interfaces';
import { IChangeable, IChangeCollection } from '../change/interfaces';
import { IComment, IPersistentComment } from '../comment/interfaces';
import { INote, INoteable, INoteCollection } from '../note/interfaces';
import { ISchema, BasePersistentApiClient } from '../common';
import { Observable } from 'rxjs/internal/Observable';
import {
    instanceCodeClientT,
    paramsBuilderT,
    IParamsBuilder,
    IInstanceCodeClient,
    IParam,
    IRecursiveArray,
} from '../common/interfaces';

import { Favoriteable } from '../common/favoriteable/favoriteable';
import { IFavoriteProvider } from '../common/favoriteable/interfaces';
import { Changeable } from '../common/changeable/changeable';
import { Noteable } from '../common/noteable/noteable';
import { AuthHttp } from '../authentication/authentication.service';
import { Campaign } from './campaign.entity';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import * as moment from 'moment';

@Injectable()
@Archivable()
@Activable()
@Favoriteable()
@Changeable()
@BulkRemovable()
@Duplicable()
@Noteable()
export class CampaignClient
    extends BasePersistentApiClient<ICampaign, CampaignBelongsToRelationships, CampaignHasManyRelationships>
    implements ICampaignClient, INoteable, IChangeable {

    protected static classConfig: ISchema = {
        apiResourceName: 'campaigns'
    };

    archive: (id: string) => Observable<ICampaign>;
    unarchive: (id: string) => Observable<ICampaign>;
    activate: (id: string) => Observable<ICampaign>;
    deactivate: (id: string) => Observable<ICampaign>;
    favorite: (id: string, provider?: IFavoriteProvider<ICampaign>) => Observable<ICampaign>;
    unfavorite: (id: string, provider?: IFavoriteProvider<ICampaign>) => Observable<ICampaign>;
    duplicate: (id: string) => Observable<ICampaign>;

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return Campaign;
    }

    getChanges(
        changeableId: string, params?: IParam, include?: IRecursiveArray<string>
    ): Observable<IChangeCollection> {
        throw new Error('Method not implemented.');
    }

    addChangeComment(note: IPersistentComment, changeableId: string): Observable<IComment> {
        throw new Error('Method not implemented.');
    }

    getNotes(noteableId: string, params?: IParam, include?: IRecursiveArray<string>): Observable<INoteCollection> {
        throw new Error('Method not implemented.');
    }

    addNote(note: INote): Observable<INote> {
        throw new Error('Method not implemented.');
    }

    addNoteComment(note: IPersistentComment): Observable<IComment> {
        throw new Error('Method not implemented.');
    }

    getBookedBudget(id: string): Observable<Bv4BookedBudget> {
        const cachedData: Bv4BookedBudget = JSON.parse(localStorage.getItem(`bv4_budget_campaign_${id}`));

        if (cachedData && moment(cachedData.created_at).isAfter(moment().subtract(1, 'hours'))) {
            return of(cachedData);
        }

        return this.http.get(`${ this.apiBase() }/${id}/booked_budget`, null)
            .pipe(
                map((response: HttpResponse<any>) => {
                    let bv4data: Bv4BookedBudget = response.body.data.attributes;
                    bv4data.created_at = (new Date()).getTime();

                    localStorage.setItem(`bv4_budget_campaign_${id}`, JSON.stringify(bv4data));
                    return response.body.data ? response.body.data.attributes : {};
                }),
                catchError(this.handleRequestError)
            );
    }

    bulkEdit(entities: ICampaign[]): Observable<IBulkResponse[]> {
        throw new Error('Method not implemented.');
    }

    submitForGoogle(id: string): Observable<ICampaign> {
        return this.http.post(`${ this.apiBase() }/${id}/submit_to_google`, null)
            .pipe(
                map((response: HttpResponse<any>) => {
                    return this.serializer.deserialize(response.body);
                }),
                catchError(this.handleRequestError)
            );
    }

    processScreens(action: IProcessScreens, campaign: ICampaign, options?: any): Observable<ICampaign> {
        let params = new HttpParams();

        if (options.params) {
            options.params.addSearchExpr(true, '=', 'active');
            options.params.addSearchExpr(true, '=', 'dooh_publisher.active');
            this.recreateParams();
            params = this.prepareParams(options.params.getParams());
        }

        let longPayloadHeaders: HttpHeaders = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded'
        });

        return this.http.post(`${ this.apiBase() }/${campaign.id}/dooh_screens/${action}`, params.toString(),
            { headers: longPayloadHeaders })
            .pipe(
                map((response: HttpResponse<any>) => {
                    return this.serializer.deserialize(response.body);
                }),
                catchError(this.handleRequestError)
            );
    }

}
