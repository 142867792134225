import { forwardRef, Inject, Injectable } from '@angular/core';

import { PrivilegeAccessResolver } from './access-resolver/privilege';
import { FeatureAccessResolver } from './access-resolver/feature';
import { AclRulesProvider } from './acl-rules-provider.service';

@Injectable()
export class AclGarbageCollectorService {

    constructor(
        @Inject(forwardRef(() => PrivilegeAccessResolver)) private privilegeAccessResolver: PrivilegeAccessResolver,
        @Inject(forwardRef(() => FeatureAccessResolver)) private featureAccessResolver: FeatureAccessResolver,
        private aclRulesProvider: AclRulesProvider
    ) {}

    public cleanup(): void {
        this.privilegeAccessResolver.cleanup();
        this.featureAccessResolver.cleanup();
        this.aclRulesProvider.cleanup();
    }

    public restart(): void {
        this.cleanup();
    }
}
