/* tslint:disable:variable-name */
import {
    ISection,
    SectionPricingType
} from './interfaces';

import { Filter, IResource } from '../common/interfaces';
import { BaseEntity, Field, BelongsTo, HasMany } from '../common/meta';
import { IPublisher } from '../publisher/interfaces';
import { Publisher } from '../publisher/publisher.entity';
import { Advertiser } from '../advertiser/advertiser.entity';
import { Campaign } from '../campaign/campaign.entity';
import { Site } from '../site/site.entity';
import { Size } from '../size/size.service';
import { ISite } from '../site/interfaces';
import { IAdvertiser } from '../advertiser/interfaces';
import { ISize } from '../size/interfaces';
import { ICampaign } from '../campaign/interfaces';

export class Section extends BaseEntity<ISection> implements ISection {

    public static readonly type: string = 'section';

    @Field()
    id: string;

    @Field()
    name: string;

    @Field()
    price: number;

    @Field()
    pricing_type: SectionPricingType;

    @Field()
    advertiser_filter: Filter;

    @Field()
    campaign_filter: Filter;

    @Field()
    brand_protection: boolean;

    @Field()
    shared: boolean;

    @Field()
    excluded_pricing_types: string[];

    @Field()
    active: boolean;

    @Field()
    archived: boolean;

    @HasMany({ deferredConstructor: () => Advertiser })
    filtered_advertisers: IAdvertiser[] | IResource[];

    @HasMany({ deferredConstructor: () => Campaign })
    filtered_campaigns: ICampaign[] | IResource[];

    @BelongsTo({ deferredConstructor: () => Publisher })
    publisher: IPublisher | IResource;

    @BelongsTo({ deferredConstructor: () => Site })
    site: ISite | IResource;

    @BelongsTo({ deferredConstructor: () => Advertiser })
    advertiser: IAdvertiser | IResource;

    @HasMany({ deferredConstructor: () => Size })
    sizes: ISize[] | IResource[];
}
