/* tslint:disable:variable-name */
import { Injectable, Inject } from '@angular/core';

import { IDoohPublisher, IDoohPublisherClient } from './interfaces';
import { BaseEntity, Field, HasMany } from '../common/meta';
import { ISchema, BaseApiClient } from '../common/index';
import { IResource } from '../common/interfaces';

import { instanceCodeClientT, IInstanceCodeClient, paramsBuilderT, IParamsBuilder } from '../common/interfaces';

import { IDoohScreen } from '../dooh_screen/interfaces';
import { DoohScreen } from '../dooh_screen/dooh-screen.service';
import { AuthHttp } from '../authentication/authentication.service';

export class DoohPublisher extends BaseEntity<IDoohPublisher> implements IDoohPublisher {

    public static readonly type: string = 'dooh_publisher';

    @Field()
    id: string;

    @Field()
    name: string;

    @Field()
    provider: string;

    @Field()
    active: boolean;

    @HasMany({ deferredConstructor: () => DoohScreen })
    dooh_screens: IDoohScreen[] | IResource[];

}

@Injectable()
export class DoohPublisherClient extends BaseApiClient<IDoohPublisher> implements IDoohPublisherClient {
    protected static classConfig: ISchema = {
        apiResourceName: 'dooh_publishers'
    };

    constructor(
        http: AuthHttp,
        @Inject(instanceCodeClientT) instanceCodeClient: IInstanceCodeClient,
        @Inject(paramsBuilderT) paramsBuilder: IParamsBuilder
    ) {
        super(http, instanceCodeClient, paramsBuilder);
    }

    protected implementationClass() {
        return DoohPublisher;
    }

}
